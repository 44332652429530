import {Form, Input, Select} from 'antd';
import styles from './mobileSetting.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useMutation} from '@apollo/client';
import {UPDATE_APP_CONFIGURATION} from 'graphql/queries/mobileSettings';
import {toast} from 'react-toastify';
import {enabledPaymentMethod} from 'utils/mock';
import {fieldRules} from 'utils/helper';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.png';

export const PlatformProviderSettings = ({data, refetch}) => {
  const [openModal, setOpenModal] = useState(false);
  const [form] = Form.useForm();

  const [updateAppConfig, {loading}] = useMutation(UPDATE_APP_CONFIGURATION, {
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  useEffect(() => {
    if (data?.length) {
      form.setFieldsValue({
        depositTags: data?.map(item => ({
          ...item,
          enTutorial: item?.tutorialLink?.EN,
          frTutorial: item?.tutorialLink?.FR,
        })),
      });
    }
  }, [data]);

  return (
    <div className={styles.body}>
      <div className={styles.mHeader}>
        <h2>Update Revolut and Wise Payment Provider Tag Names</h2>
        <p>
          This set the Tag Name used for Daba Deposit Revolut and Wise Options
        </p>
      </div>
      <Form onFinish={() => setOpenModal(true)} form={form} layout="vertical">
        <Form.List name="depositTags">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name}) => {
                return (
                  <div className={styles.tagName} key={name}>
                    <div>
                      <Form.Item
                        rules={[fieldRules('This')]}
                        label="Payment Method"
                        className={styles.tagField}
                        name={[name, 'paymentMethod']}>
                        <Select>
                          {enabledPaymentMethod
                            .filter(item =>
                              [
                                'THIRD_PARTY_REVOLUT',
                                'THIRD_PARTY_WISE',
                              ].includes(item.key),
                            )
                            .map(data => (
                              <Select.Option key={data.key}>
                                {data.value}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        rules={[fieldRules('This')]}
                        className={styles.tagField}
                        label="Provider Name"
                        name={[name, 'providerName']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        rules={[fieldRules('This')]}
                        className={styles.tagField}
                        tooltip="This is the Tag name users will use in deposit"
                        label="Tag Name"
                        name={[name, 'tagName']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="English Tutorial Link"
                        className={styles.tagField}
                        rules={[
                          {type: 'url', message: 'A Valid URL is required'},
                        ]}
                        name={[name, 'enTutorial']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        className={styles.tagField}
                        label="French Tutorial Link"
                        rules={[
                          {type: 'url', message: 'A Valid URL is required'},
                        ]}
                        name={[name, 'frTutorial']}>
                        <Input />
                      </Form.Item>
                    </div>
                    <div className={styles['delete-icon']}>
                      <img
                        onClick={() => remove(name)}
                        src={deleteIcon}
                        alt=""
                      />
                    </div>
                  </div>
                );
              })}
              <div onClick={add} className={styles['add-button']}>
                <img src={plusIcon} alt="add-founder" />
                <h2>Add Tag</h2>
              </div>
            </>
          )}
        </Form.List>
        <SubmitButton label="Proceed" />
        <ConfirmModal
          openModal={openModal}
          loading={loading}
          setOpenModal={() => setOpenModal(false)}
          title="Update Changes?"
          confirmText="Please make sure you have the correct information before proceeding"
          handleOperation={() => {
            updateAppConfig({
              variables: {
                data: {
                  paymentMethodSettings: form
                    .getFieldValue('depositTags')
                    ?.map(item => ({
                      paymentMethod: item?.paymentMethod,
                      providerName: item?.providerName,
                      tagName: item?.tagName,
                      tutorialLink: {
                        EN: item?.enTutorial,
                        FR: item?.frTutorial,
                      },
                    })),
                },
              },
            })
              .then(({data: {updateAppConfig}}) => {
                if (
                  updateAppConfig.__typename === 'Error' ||
                  updateAppConfig.statusCode === 400
                ) {
                  toast.error(updateAppConfig.message);
                } else {
                  toast.success('Updated Successfully');
                  setOpenModal(false);
                  refetch();
                }
              })
              .catch(error => toast.error(error.message));
          }}
        />
      </Form>
    </div>
  );
};
