import {gql} from '@apollo/client';

export const GET_APP_CONFIGURATION = gql`
  query GetAppConfig {
    getAppConfig {
      ... on Error {
        message
        statusCode
      }
      ... on AppConfigType {
        config {
          documentSettings {
            combineStatement
            sendToUserAutomatically
            statementPeriods {
              monthly
              annually {
                day
                month
              }
            }
          }
          inAppReviewSettings {
            isEnabled
            frequencyControl {
              maxPrompts
              daysInterval
              cooldownPeriod
            }
            filters {
              hasMadeInvestment
            }
          }
          # recurringInvestmentSettings {
          #   scheduledStartTime
          #   scheduledEndTime
          #   maxRetries
          # }
          discountSettings {
            pro
          }
          paymentMethodSettings {
            paymentMethod
            providerName
            tagName
            tutorialLink
          }
          kyc {
            nationalIdentityNumber
            socialInsuranceNumber
            ssn
            idcard
            passport
            license
          }
          versionInfo {
            isCritical
            android
            ios
            latestVersion
            message
          }
          securitySettings {
            admin2FA
          }
          dabaWhatsappNumber
          brvmVideoId
          platformReviewSettings {
            filters {
              hasCompletedProfile
              hasInvestedInAnyCampaign
            }
            reviewPeriodEnd
          }
          totalEstimatedRewardsValue {
            amount
            currency
          }
        }
      }
    }
  }
`;

export const UPDATE_APP_CONFIGURATION = gql`
  mutation UpdateAppConfig($data: UpdateAppConfigInput) {
    updateAppConfig(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on AppConfigType {
        config {
          versionInfo {
            latestVersion
          }
        }
      }
    }
  }
`;

export const FORCE_SIGN_OUT = gql`
  mutation ForceSignOutUsers($forceSignOut: Boolean!) {
    forceSignOutUsers(forceSignOut: $forceSignOut) {
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const CHANGE_ALL_USER_DOCUMENT_STATUS = gql`
  mutation ChangeAllUserDocumentStatus(
    $filter: ChangeAllUserDocumentStatusFilter!
    $active: Boolean
  ) {
    changeAllUserDocumentStatus(filter: $filter, active: $active) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithUserDocument {
        message
      }
    }
  }
`;

export const UPDATE_MAINTENANCE_MODE = gql`
  mutation SetMaintenanceMode($data: MaintenanceModeInput!) {
    setMaintenanceMode(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const GET_MAINTENANCE_MODE = gql`
  query GetMaintenanceMode {
    getMaintenanceMode {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAnything {
        data
        message
      }
    }
  }
`;

export const GET_PHONE_NUMBER_EXAMPLES = gql`
  query GetPhoneExamples($filter: PhoneExampleFilter!) {
    getPhoneExamples(filter: $filter) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithPhoneNumberExamples {
        data {
          id
          countryCode
          countryName
          numberExample
          createdAt
        }
        pagination {
          total
        }
      }
    }
  }
`;

export const ADD_PHONE_NUMBER_EXAMPLES = gql`
  mutation AddMultiplePhoneExample($inputs: [PhoneExampleInput!]) {
    addMultiplePhoneExample(inputs: $inputs) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithPhoneNumberExamples {
        message
      }
    }
  }
`;

export const DELETE_PHONE_NUMBER_EXAMPLE = gql`
  mutation DeletePhoneExampleById($deletePhoneExampleByIdId: ID!) {
    deletePhoneExampleById(id: $deletePhoneExampleByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithPhoneNumberExample {
        message
      }
    }
  }
`;

export const UPDATE_PHONE_NUMBER_EXAMPLE = gql`
  mutation UpdatePhoneExample(
    $updatePhoneExampleId: ID!
    $input: PhoneExampleInput!
  ) {
    updatePhoneExample(id: $updatePhoneExampleId, input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithPhoneNumberExample {
        message
      }
    }
  }
`;
