import {Form, Input, InputNumber, Select, Switch} from 'antd';
import styles from './feeSchedule.module.scss';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.png';

export const FeeSchedule = ({
  feeName,
  form,
  isInstrument,
  forMutualFund,
  isOfStockExchange,
}) => {
  const feeTypes = Form.useWatch(feeName, form) || [];

  const getUsedFeeTypes = (currentField, fields) => {
    const usedTypes = [];
    fields.forEach(field => {
      if (field.key !== currentField && feeTypes[field.name]?.feeType) {
        usedTypes.push(feeTypes[field.name]?.feeType);
      }
    });
    return usedTypes.includes(currentField);
  };

  const isDisabled = (feeType, field, feeTypes, name) => {
    const fixedFeeAmount = feeTypes[name]?.fixedFeeAmount;
    const variableFeeAmount = feeTypes[name]?.variableFeeAmount;
    if (field === 'fixedFeeAmount') {
      return (
        feeType === 'VARIABLE' ||
        feeType === 'FLOATING' ||
        !feeType ||
        variableFeeAmount
      );
    }
    if (field === 'variableFeeAmount') {
      return feeType === 'FIXED' || !feeType || fixedFeeAmount;
    }
    return false;
  };

  return (
    <Form.List name={feeName}>
      {(fields, {add, remove}) => (
        <>
          {fields.map(({name}) => {
            const feeType = feeTypes[name]?.feeType;
            return (
              <div className={styles['card-container']} key={name}>
                <div className={styles['left-grid']}>
                  <Form.Item
                    rules={[
                      {required: true, message: 'This is a required field'},
                    ]}
                    label="Fee Name"
                    className={styles['card-item']}
                    name={[name, 'feeName']}>
                    <Input name="feeName" />
                  </Form.Item>
                  <Form.Item
                    label="Fee Type"
                    name={[name, 'feeType']}
                    rules={[
                      {required: true, message: 'This is a required field'},
                    ]}
                    className={styles['card-item']}>
                    <Select
                      allowClear
                      placeholder="select fee type"
                      name="feeType">
                      <Select.Option value="FIXED">Fixed</Select.Option>
                      <Select.Option value="VARIABLE">Variable</Select.Option>
                      {isOfStockExchange && (
                        <>
                          <Select.Option value="FLOATING">
                            Floating
                          </Select.Option>
                          <Select.Option value="STATIC">Static</Select.Option>
                        </>
                      )}
                      {isInstrument && (
                        <>
                          <Select.Option
                            disabled={getUsedFeeTypes('RECURRING', fields)}
                            value="RECURRING">
                            Recurring
                          </Select.Option>
                          <Select.Option
                            disabled={getUsedFeeTypes('EXIT', fields)}
                            value="EXIT">
                            Exit
                          </Select.Option>
                        </>
                      )}
                      {forMutualFund && (
                        <Select.Option value="ENTRY">Entry</Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Fixed Fee Amount"
                    name={[name, 'fixedFeeAmount']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            isDisabled(
                              feeType,
                              'fixedFeeAmount',
                              feeTypes,
                              name,
                            )
                          ) {
                            return Promise.resolve();
                          }
                          if (value === undefined || value === '') {
                            return Promise.reject(
                              new Error('This field is required'),
                            );
                          }
                          return value >= 0
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Value must be 0 or greater'),
                              );
                        },
                      },
                    ]}
                    className={styles['card-item']}>
                    <InputNumber
                      className="input-number"
                      style={{width: 280}}
                      min={0}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      disabled={isDisabled(
                        feeType,
                        'fixedFeeAmount',
                        feeTypes,
                        name,
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Variable Fee Amount (%)"
                    name={[name, 'variableFeeAmount']}
                    rules={[
                      {
                        validator: (_, value) => {
                          if (
                            isDisabled(
                              feeType,
                              'variableFeeAmount',
                              feeTypes,
                              name,
                            )
                          ) {
                            return Promise.resolve();
                          }
                          if (value === undefined || value === '') {
                            return Promise.reject(
                              new Error('This field is required'),
                            );
                          }
                          return value >= 0 && value <= 100
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error('Value must be between 0 and 100'),
                              );
                        },
                      },
                    ]}
                    className={styles['card-item']}>
                    <InputNumber
                      className="input-number"
                      style={{width: 280}}
                      min={0}
                      max={100}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      disabled={isDisabled(
                        feeType,
                        'variableFeeAmount',
                        feeTypes,
                        name,
                      )}
                    />
                  </Form.Item>
                  {feeTypes[name]?.feeType === 'ENTRY' && forMutualFund && (
                    <Form.Item
                      rules={[
                        {required: true, message: 'This is a Required Field'},
                      ]}
                      className={styles['card-item']}
                      label="Fee Applies To"
                      tooltip="Select where this fee applies: to the Asset Class (e.g., Funds), the Financial Instrument (e.g., Mutual Funds), or the specific Instrument being created."
                      name={[name, 'level']}>
                      <Select
                        placeholder="Select where this is to be applied"
                        allowClear>
                        <Select.Option key="ASSET">
                          Asset - (All Funds)
                        </Select.Option>
                        <Select.Option key="INSTRUMENTS">
                          Instruments - (All Mutual Fund)
                        </Select.Option>
                        <Select.Option key="INSTRUMENT">
                          Instrument - (This Specific Instrument)
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  )}
                  {isInstrument &&
                    ['RECURRING', 'EXIT'].includes(feeTypes[name]?.feeType) && (
                      <>
                        <hr className={styles.rule} />
                        <Form.Item
                          className={styles['card-item']}
                          name={[name, 'scalable']}
                          initialValue={
                            form.getFieldValue([feeName, name])?.scalable
                          }
                          tooltip="defines a fee that increases or decreases depending on duration. Only RECURRING & EXIT fees will respect the scalable value"
                          label="Proratable">
                          <Switch
                            defaultChecked={
                              form.getFieldValue([feeName, name])?.scalable
                            }
                          />
                        </Form.Item>
                        {feeTypes[name]?.feeType === 'RECURRING' && (
                          <>
                            <div className={styles['card-item']} />
                            <Form.Item
                              tooltip="it allows you to specify how often a fee is charged. Only RECURRING fees will respect the frequency value"
                              className={styles['card-item']}
                              name={[name, 'frequency']}
                              rules={[
                                {required: true, message: 'This is Required'},
                              ]}
                              label="Frequency">
                              <Select allowClear>
                                <Select.Option key="YEARLY">
                                  Yearly
                                </Select.Option>
                                <Select.Option key="MONTHLY">
                                  Monthly
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              className={styles['card-item']}
                              tooltip="The day in the year or the month for the Recursion. Only RECURRING fees will respect the origin value"
                              name={[name, 'origination']}
                              rules={[
                                {required: true, message: 'This is Required'},
                              ]}
                              label="Recursion Day">
                              <InputNumber
                                className="input-number"
                                style={{width: 280}}
                                min={0}
                                max={365}
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                              />
                            </Form.Item>
                          </>
                        )}

                        {feeTypes[name]?.feeType === 'EXIT' && (
                          <Form.List name={[name, 'vector']}>
                            {(
                              vectorFields,
                              {add: addVector, remove: removeVector},
                            ) => (
                              <div className={styles.vectorContainer}>
                                {vectorFields.map(({name: vectorIndex}) => (
                                  <div
                                    className={styles.vectorFields}
                                    key={vectorIndex}>
                                    <div className={styles['vector-single']}>
                                      <Form.Item
                                        name={[vectorIndex, 'duration']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'This is Required',
                                          },
                                        ]}
                                        label="Duration (In DAYS)">
                                        <InputNumber
                                          className="input-number"
                                          style={{width: 200}}
                                          parser={value =>
                                            value.replace(/\$\s?|(,*)/g, '')
                                          }
                                        />
                                      </Form.Item>
                                      <div className={styles.magnitude}>
                                        <Form.Item
                                          name={[vectorIndex, 'feeType']}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'This is Required',
                                            },
                                          ]}
                                          label="Duration Type">
                                          <Select
                                            placeholder="Fixed"
                                            style={{width: 200}}>
                                            <Select.Option value="FIXED">
                                              Fixed
                                            </Select.Option>
                                            <Select.Option value="VARIABLE">
                                              Variable
                                            </Select.Option>
                                          </Select>
                                        </Form.Item>
                                        {form.getFieldValue([
                                          feeName,
                                          name,
                                          'vector',
                                          vectorIndex,
                                          'feeType',
                                        ]) === 'FIXED' && (
                                          <Form.Item
                                            name={[vectorIndex, 'fixedAmount']}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This is Required',
                                              },
                                            ]}
                                            label="Fixed Amount">
                                            <InputNumber
                                              className="input-number"
                                              style={{width: 200}}
                                              min={0}
                                              parser={value =>
                                                value.replace(/\$\s?|(,*)/g, '')
                                              }
                                            />
                                          </Form.Item>
                                        )}
                                        {form.getFieldValue([
                                          feeName,
                                          name,
                                          'vector',
                                          vectorIndex,
                                          'feeType',
                                        ]) === 'VARIABLE' && (
                                          <Form.Item
                                            name={[
                                              vectorIndex,
                                              'variableAmount',
                                            ]}
                                            rules={[
                                              {
                                                required: true,
                                                message: 'This is Required',
                                              },
                                            ]}
                                            label="Variable Amount(%)">
                                            <InputNumber
                                              className="input-number"
                                              style={{width: 200}}
                                              min={0}
                                              max={100}
                                              parser={value =>
                                                value.replace(/\$\s?|(,*)/g, '')
                                              }
                                            />
                                          </Form.Item>
                                        )}
                                      </div>
                                    </div>
                                    <div className={styles['delete-icon']}>
                                      <img
                                        onClick={() =>
                                          removeVector(vectorIndex)
                                        }
                                        src={deleteIcon}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                ))}
                                <div
                                  onClick={addVector}
                                  className={styles['add-faq']}>
                                  <span>Add Duration</span>
                                </div>
                              </div>
                            )}
                          </Form.List>
                        )}
                      </>
                    )}
                </div>
                <div className={styles['delete-icon']}>
                  <img onClick={() => remove(name)} src={deleteIcon} alt="" />
                </div>
              </div>
            );
          })}
          <div onClick={add} className={styles['add-faq']}>
            <img src={plusIcon} alt="add-founder" />
            <span>Add Fee</span>
          </div>
        </>
      )}
    </Form.List>
  );
};
