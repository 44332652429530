import {InputNumber, Form, Select} from 'antd';
import {enabledPaymentMethod} from 'utils/mock';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.png';
import styles from '../walletDetails.module.scss';

export const ProviderFeeSchedule = ({
  feeName,
  customAddLabel = 'Add Fee',
  form,
}) => {
  const width = 190;
  const formWatch = Form.useWatch(feeName, form) || [];

  return (
    <Form.List name={feeName}>
      {(fields, {add, remove}) => (
        <>
          {fields.map(({name}) => (
            <div key={name} className={styles.fees}>
              <Form.Item name={[name, 'feeType']} label="Fee Type">
                <Select allowClear>
                  <Select.Option value="FIXED">Fixed</Select.Option>
                  <Select.Option value="VARIABLE">Variable</Select.Option>
                </Select>
              </Form.Item>
              {formWatch?.[name]?.feeType === 'FIXED' ? (
                <Form.Item name={[name, 'fixedFee']} label="Fixed Fee">
                  <InputNumber
                    className="input-number"
                    disabled={formWatch[name]?.variableFee}
                    style={{width, marginRight: 10}}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name={[name, 'variableFee']}
                  label="Variable Fee (%)">
                  <InputNumber
                    className="input-number"
                    disabled={formWatch[name]?.fixedFee}
                    style={{width, marginRight: 10}}
                  />
                </Form.Item>
              )}
              <Form.Item
                rules={[{required: true, message: 'This is a Required Field'}]}
                name={[name, 'paymentMethodType']}
                label="Payment Method Type">
                <Select>
                  {enabledPaymentMethod.map(data => (
                    <Select.Option key={data.key} value={data.key}>
                      {data.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className={styles['delete-icon']}>
                <img
                  onClick={() => remove(name)}
                  src={deleteIcon}
                  alt="Delete"
                />
              </div>
            </div>
          ))}
          <div onClick={() => add()} className={styles['add-button']}>
            <img src={plusIcon} alt="Add" />
            <h1>{customAddLabel}</h1>
          </div>
        </>
      )}
    </Form.List>
  );
};
