import {LineChart, BarChart} from '@dabafinance/react-components';
import classnames from 'classnames/bind';
import moment from 'moment';
import styles from './userChart.module.scss';
import {useEffect, useState} from 'react';
import {
  barChartDataset,
  chartConfig,
  usersLineChartControls,
} from 'utils/constants';
import {getUsersChartData} from 'utils/helper';
import {Spin} from 'antd';

export const UsersChart = ({users, loading, chartStyle}) => {
  const ctx = classnames.bind(styles);
  const [chartData, setChartData] = useState([]);
  const [BarChartData, setBarChartdata] = useState(barChartDataset());
  const [maxYAxis, setMaxYAxis] = useState(0);
  const [activeControl, setActiveControl] = useState({
    label: 'DAY',
    format: 'L',
    title: 'DAILY',
  });
  useEffect(() => {
    if (users) {
      setChartData(getUsersChartData(activeControl.label, users));
      const totalData = getUsersChartData('YEAR', users).map(
        data => data?.amount,
      );
      const dates = getUsersChartData('YEAR', users).map(data => data?.date);
      setBarChartdata(barChartDataset(totalData, dates));
    }
  }, [users, activeControl, setChartData, setBarChartdata]);

  useEffect(() => {
    // Get the maximum amount of users per format(DAY, WEEK, MONTH, YEAR)
    setMaxYAxis(Math.max(...chartData?.map(data => data?.amount)));
  }, [chartData]);

  const options = {
    scales: {
      x: {
        min: moment('20220701').format(activeControl.format),
      },
      y: {
        min: 0,
        max: maxYAxis + 20,
      },
    },
  };

  return (
    <div className={styles['user-chart-container']}>
      {loading && !users ? (
        <div className={styles.loader}>
          <Spin size="large" />
          <h2>Loading Users Chart Data</h2>
        </div>
      ) : (
        <>
          <div>
            {chartStyle === 'modal-line-chart' ? (
              <h1 className={styles['modal-chart-title']}>
                {activeControl?.title} USERS CHART
              </h1>
            ) : (
              <h3 className={styles['chart-title']}>
                {activeControl?.title} USERS CHART
              </h3>
            )}
          </div>
          <div className={styles['chart-wrapper']}>
            <div>
              {activeControl.label === 'YEAR' ? (
                <div
                  className={
                    chartStyle === 'modal-line-chart'
                      ? styles['modal-bar-chart']
                      : styles['bar-chart']
                  }>
                  <BarChart data={BarChartData} options={options} />
                </div>
              ) : (
                <div className={styles[chartStyle]}>
                  <LineChart
                    data={chartData}
                    options={options}
                    borderWidth={2}
                    color="#8d6cfa"
                    showPoints={true}
                    {...chartConfig(activeControl.label === 'WEEK')}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles['lineChart-cotrol-wrap']}>
            {usersLineChartControls.map((control, index) => (
              <div
                key={index}
                className={ctx(
                  'lineChart-control',
                  activeControl.label === control.label && 'active',
                )}
                onClick={() => setActiveControl(control)}>
                {control.label}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
