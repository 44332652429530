import {DatePicker, Form, Input, Select, Switch} from 'antd';
import styles from './documentFilter.module.scss';
import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import moment from 'moment';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {GENERATE_USER_DOCUMENT} from 'graphql/mutations/userDocuments';
import {GET_USER_DOCUMENT_CATEGORIES} from 'graphql/queries/userDocuments';
import {GET_COMPANIES} from 'graphql/queries/company';

const DocumentFilter = ({refetch, userId, setOpenModal}) => {
  const [category, setCategory] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const {Option} = Select;

  const {data: getCategory, loading: catLoading} = useQuery(
    GET_USER_DOCUMENT_CATEGORIES,
  );
  const {data: getCompany, loading: companyLoading} = useQuery(GET_COMPANIES);
  const [generateUserDocument, {loading}] = useMutation(GENERATE_USER_DOCUMENT);

  const [form] = Form.useForm();

  const handleConfirm = () => {
    setOpenConfirmModal(true);
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    generateUserDocument({
      variables: {
        data: {
          userId,
          category,
          companyId: values?.companyId,
          endDate: values?.endDate ? moment(values?.endDate).format() : null,
          quantity: Number(values?.quantity),
          shouldAllowUserView: values?.shouldAllowUserView,
          shouldCombine: values?.shouldCombine,
          startDate: values?.startDate
            ? moment(values?.startDate).format()
            : null,
        },
      },
    })
      .then(({data: {generateUserDocument}}) => {
        if (generateUserDocument?.__typename === 'Error') {
          toast.error(generateUserDocument?.message);
        }
        refetch();
        toast.success(generateUserDocument?.message);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        form.resetFields();
        setCategory('');
        setOpenConfirmModal(false);
        setOpenModal(false);
      });
  };

  return (
    <div className={styles.root}>
      <h1>Document Filter</h1>
      <Form
        form={form}
        className={styles.form}
        onFinish={handleConfirm}
        layout="vertical">
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Document category is required',
            },
          ]}
          label="Document Category"
          className={styles.field}
          name="documentCategory">
          <Select
            value={category}
            onChange={e => setCategory(e)}
            showSearch
            loading={catLoading}>
            {getCategory?.getUserDocumentCategories?.data?.map(
              ({label, name}, index) => (
                <Option key={index} value={label}>
                  <div className={styles['all-data']}>
                    <span>{name}</span>
                  </div>
                </Option>
              ),
            )}
          </Select>
        </Form.Item>
        <Form.Item name="shouldAllowUserView" label="Should Notify User?">
          <Switch defaultChecked size="large" className={styles.switch} />
        </Form.Item>
        {(category === 'GENERAL_ACCOUNT_STATEMENT' ||
          category === 'INVESTMENT_REPORT_STATEMENT') && (
          <div>
            <div className={styles.dates}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker format={'YYYY-MM-DD'} />
              </Form.Item>
              <Form.Item label="End Date" name="endDate">
                <DatePicker format={'YYYY-MM-DD'} />
              </Form.Item>
            </div>
            <Form.Item name="shouldCombine" label="Should Combine Wallet?">
              <Switch defaultChecked size="large" className={styles.switch} />
            </Form.Item>
          </div>
        )}
        {(category === 'STOCK_CERTIFICATION' ||
          category === 'STARTUP_CERTIFICATION' ||
          category === 'BOND_CERTIFICATION') && (
          <div>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Company is required',
                },
              ]}
              label="Company"
              name="companyId">
              <Select showSearch loading={companyLoading}>
                {getCompany?.getAllCompanies?.map(data => (
                  <Option key={data?.id}>
                    <div className={styles.allData}>
                      <img src={data?.logoImgURL} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Units is required',
                },
              ]}
              label="Unit(s)"
              name="quantity">
              <Input type="number" placeholder="0" />
            </Form.Item>
          </div>
        )}
        <SubmitButton label={'Generate Document'} disabled={loading} />
      </Form>

      <ConfirmModal
        title={'Confirm Document Generation'}
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmText={'Are you sure you want to GENERATE this document?'}
        loading={loading}
        handleOperation={handleSubmit}
      />
    </div>
  );
};

export default DocumentFilter;
