import {useQuery} from '@apollo/client';
import {Dropdown, Pagination, Table, Tag} from 'antd';
import {useParams} from 'react-router-dom';
import styles from './transactions.module.scss';

import {GET_USER_DIVIDENDS_HISTORY} from 'graphql/queries/dividends';
import {useState} from 'react';
import {toast} from 'react-toastify';
import moment from 'moment';
import {
  CreditOrDebitUserDividend,
  DividendHistory,
  ProcessUserDividend,
} from 'pages/dividends/create/updateUserDividend/UpdateUserDividend';
import DBModal from 'components/modal/modal';
import dotsIcon from 'icons/dots.svg';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';

const UserDividends = () => {
  const [openUpdatePayoutModal, setOpenUpdatePayoutModal] = useState('');
  const [processData, setProcessData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dividendHistory, setDividendHistory] = useState({
    open: false,
    record: null,
  });

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const {id} = useParams();
  const {loading, data, refetch} = useQuery(GET_USER_DIVIDENDS_HISTORY, {
    variables: {
      input: {
        page: 1,
        'limit': 10,
        userId: id,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onError: error => toast.error(error.message),
    notifyOnNetworkStatusChange: true,
  });

  const handlePageChange = (page, pageSize) => {
    if (page === currentPage && pageSize === limit) return;
    setCurrentPage(page);
    setLimit(pageSize);
    refetch({
      input: {
        page,
        limit: pageSize,
        userId: id,
      },
    });
  };

  const columns = [
    {
      dataIndex: 'company',
      title: 'Company',
      key: 'company',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img src={record?.company?.logoImgURL} alt="" />
          <h4 style={{width: 100}}>{record?.company?.legalName}</h4>
        </div>
      ),
    },
    {
      dataIndex: 'investmentCategory',
      title: 'Category',
      key: 'category',
      render: (text, render) => (
        <Tag
          color={
            {
              'BOND': 'blue',
              'STOCK': 'red',
              'VENTURE': 'orange',
              'IPO': 'green',
            }[render?.investmentCategory]
          }>
          {render?.investmentCategory}
        </Tag>
      ),
    },
    {
      dataIndex: 'units',
      title: 'No. of Shared Units',
      key: 'Number of Shared',
    },
    {
      dataIndex: 'status',
      title: 'Status',
      key: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'PAID': 'green',
              'UNPAID': 'default',
            }[render?.status] || 'blue'
          }
          key={render.status}>
          {render.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'payoutCurrency',
      title: 'Currency',
      key: 'currency',
    },
    {
      dataIndex: 'amountPerShare',
      title: 'Expected Payout (Per Share)',
      key: 'amountPerShare',
      render: (text, render) => (
        <span>
          {render?.amountPerShare} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'userTotalDividendPayout',
      title: 'User Total Dividend Payout',
      key: 'userTotalDividendPayout',
      render: (text, render) => (
        <span>
          {render?.payoutAmount?.toLocaleString()} {render?.payoutCurrency}
        </span>
      ),
    },
    {
      dataIndex: 'payoutDate',
      title: 'Payout Date',
      key: 'payoutDate',
      render: (text, render) => (
        <p style={{minWidth: 100}}>
          {render?.payoutDate
            ? moment(render?.payoutDate).format('MMM DD, YYYY')
            : 'N/A'}
        </p>
      ),
    },
    {
      dataIndex: 'Actions',
      title: 'Actions',
      key: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: 'Credit / Debit User',
                key: 1,
                disabled: !tabOperations?.update,
                onClick: e => {
                  e.domEvent.stopPropagation();
                  setOpenUpdatePayoutModal('CREDIT');
                  setProcessData({
                    id: record?.id,
                    amount: record?.payoutAmount,
                    currency: record?.payoutCurrency,
                  });
                },
              },
              {
                key: 2,
                label: 'Payout Dividend',
                disabled: !tabOperations?.update,
                onClick: e => {
                  e.domEvent.stopPropagation();
                  setOpenUpdatePayoutModal('PROCESS');
                  setProcessData(record?.id);
                },
              },
            ],
          }}>
          <img
            src={dotsIcon}
            role="button"
            onClick={e => e.stopPropagation()}
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <h2>User Dividends Payout History</h2>
      <Table
        columns={columns}
        dataSource={data?.getUserDividendHistory?.data}
        loading={loading}
        onRow={record => ({
          onClick: () => setDividendHistory({open: true, record: record}),
        })}
        className="antd-table"
        pagination={false}
      />
      <Pagination
        current={currentPage}
        onChange={handlePageChange}
        total={data?.getUserDividendHistory?.pagination?.total}
        showQuickJumper
        className="ant-table-pagination"
        showSizeChanger
        pageSize={limit}
      />
      <DBModal
        isOpen={dividendHistory.open}
        handleClose={() => setDividendHistory({open: false, record: null})}
        width={1000}
        content={<DividendHistory data={dividendHistory.record} />}
      />
      <DBModal
        isOpen={openUpdatePayoutModal}
        handleClose={() => setOpenUpdatePayoutModal(null)}
        content={
          openUpdatePayoutModal === 'CREDIT' ? (
            <CreditOrDebitUserDividend
              data={processData}
              refetch={refetch}
              closeModal={() => {
                setOpenUpdatePayoutModal(null);
                setProcessData(null);
              }}
            />
          ) : (
            <ProcessUserDividend
              id={processData}
              refetch={refetch}
              closeModal={() => {
                setOpenUpdatePayoutModal(null);
                setProcessData(null);
              }}
            />
          )
        }
      />
    </div>
  );
};

export default UserDividends;
