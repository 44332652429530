// All actions for the auth store goes here
import {loginSuccess, setTempUserData, setOnboardingProgress} from './index';
import {api} from 'redux/api';
import {
  ADD_USER_INTEREST,
  ADD_COUNTRY_INTEREST,
  ADD_USER_GROUPS,
  CHECK_EMAIL_EXIST,
  FETCH_COUNTRIES,
  FETCH_GROUPS,
  FETCH_TOPICS,
  LOGIN_MUTATION,
  SIGN_UP_MUTATION,
  UPDATE_PROFILE_MUTATION,
  VERIFY_OTP_MUTATION,
} from './queries';
import {setCampaign} from '../campaign';

export const loginUser = payload => async dispatch => {
  let optionalAuth = {};
  if (payload.twoFactorAuthCode) {
    optionalAuth.twoFactorAuthCode = payload.twoFactorAuthCode;
  }
  try {
    const result = await api.post(
      '/graphql',
      {
        query: LOGIN_MUTATION,
        variables: {
          data: {
            email: payload.email,
            password: payload.password,
            ...optionalAuth,
          },
        },
      },
      {withCredentials: true},
    );

    const {loginAdmin} = result.data.data;

    if (loginAdmin.statusCode) {
      throw new Error(loginAdmin.message);
    }
    if (!loginAdmin) {
      throw new Error(
        'Something went wrong. Please try again later, contact support if persists',
      );
    }

    localStorage.setItem('login', 'loggedIn');
    localStorage.setItem('token', loginAdmin.jwtAccessToken);
    await dispatch(
      loginSuccess({
        userData: {
          ...loginAdmin,
          email: payload.email,
          password: payload.password,
        },
      }),
    );
    return loginAdmin;
  } catch (error) {
    return {error};
  }
};

export const signup = payload => async dispatch => {
  try {
    const exist = await api.post('/graphql', {
      query: CHECK_EMAIL_EXIST,
      variables: {
        data: {
          email: payload.email,
        },
      },
    });

    const {checkUserEmail} = exist.data.data;

    if (checkUserEmail.emailExist && checkUserEmail.isEmailVerified) {
      return {error: {message: 'Email is already taken'}};
    } else if (checkUserEmail.emailExist && !checkUserEmail.isEmailVerified) {
      return true;
    } else {
      const result = await api.post('/graphql', {
        query: SIGN_UP_MUTATION,
        variables: {
          data: {
            email: payload.email,
            password: payload.password,
          },
        },
      });
      const {createUser} = result.data.data;
      if (createUser.statusCode) {
        throw new Error(createUser.message);
      } else {
        await dispatch(setTempUserData({userData: createUser}));
        await dispatch(setOnboardingProgress(0.1));
      }

      return createUser;
    }
  } catch (error) {
    return {error};
  }
};

export const campaignOverview = payload => async dispatch => {
  dispatch(setCampaign({overview: payload.keyFactsValue}));
};

export const sendOtp = payload => async dispatch => {
  try {
    const result = await api.post('/graphql', {
      query: VERIFY_OTP_MUTATION,
      variables: {
        code: payload.code,
      },
    });

    const {verifyEmail} = result.data.data;
    if (verifyEmail.statusCode) {
      throw new Error(verifyEmail.message);
    } else {
      await dispatch(setTempUserData({userData: verifyEmail}));
      await dispatch(setOnboardingProgress(0.2));
    }
    return verifyEmail;
  } catch (error) {
    return {error};
  }
};

export const completeSignup = payload => async dispatch => {
  try {
    const result = await api.post('/graphql', {
      query: UPDATE_PROFILE_MUTATION,
      variables: {
        data: {
          lastName: payload.name,
          firstName: payload.firstname,
          dateOfBirth: payload.dob,
          username: payload.username,
        },
      },
    });

    const {updateProfile} = result.data.data;

    if (updateProfile.statusCode) {
      throw new Error(updateProfile.message);
    } else {
      await dispatch(
        setTempUserData({
          userData: {...updateProfile, isFirstTimeUser: true},
        }),
      );
      await dispatch(setOnboardingProgress(0.3));
    }

    return updateProfile;
  } catch (error) {
    return {error};
  }
};

export const fetchInterests = () => async dispatch => {
  try {
    const getInterest = await api.post('/graphql', {
      query: FETCH_COUNTRIES,
    });
    const {addCountryOfInterests} = getInterest.data.data;
    if (addCountryOfInterests.statusCode) {
      throw new Error(addCountryOfInterests.message);
    } else {
      await dispatch(setTempUserData({userData: addCountryOfInterests}));
      await dispatch(setOnboardingProgress(0.4));
    }
    return addCountryOfInterest;
  } catch (err) {
    return {error: err};
  }
};

export const fetchTopics = () => async dispatch => {
  try {
    const getInterest = await api.post('/graphql', {
      query: FETCH_TOPICS,
    });
    const {getTopics} = getInterest.data.data;
    if (getTopics.statusCode) {
      throw new Error(getTopics.message);
    } else {
      await dispatch(setTempUserData({userData: getTopics}));
      await dispatch(setOnboardingProgress(0.4));
    }

    return getTopics;
  } catch (err) {
    return {error: err};
  }
};

// export const updateProfile = payload => async dispatch => {
//   const fileName = `${Date.now()}-${payload?.file?.name}`;
//   const target = {
//     Bucket: 'users-profile-pictures',
//     Key: fileName,
//     Body: payload.file,
//   };
//   const cred = {
//     accessKeyId: process.env.REACT_APP_ACCESS_KEY,
//     secretAccessKey: process.env.REACT_APP_SECRET_KEY,
//   };
//   let imageUrl = '';
//   try {
//     if (payload.file) {
//       const parallelUpload3 = new Upload({
//         client: new S3Client({
//           region: 'us-east-1',
//           credentials: cred,
//         }),
//         leavePartsOnError: false,
//         params: target,
//       });
//       const res = await parallelUpload3.done();

//       if (res.$metadata.httpStatusCode === 200) {
//         imageUrl = `https://users-profile-pictures.s3.amazonaws.com/${fileName}`;
//       }
//     }
//     const result = await api.post(
//       '/graphql',
//       {
//         query: UPDATE_PROFILE_MUTATION,
//         variables: {
//           data: {
//             imageUrl,
//             bio: payload.bio,
//           },
//         },
//       },
//       {
//         withCredentials: true,
//       },
//     );

//     const getCountries = await api.post('/graphql', {
//       query: FETCH_COUNTRIES,
//     });

//     const {updateProfile} = result.data.data;
//     const {getCountryInterests} = getCountries.data.data;

//     if (updateProfile.statusCode) {
//       throw new Error(updateProfile.message);
//     } else {
//       await dispatch(setTempUserData({userData: getCountryInterests}));
//       await dispatch(setOnboardingProgress(0.4));
//     }
//     return updateProfile;
//   } catch (error) {
//     return {error};
//   }
// };

export const addCountryOfInterest = payload => async dispatch => {
  try {
    const result = await api.post('/graphql', {
      query: ADD_COUNTRY_INTEREST,
      variables: {
        data: {
          input: payload,
        },
      },
    });
    const getInterest = await api.post('/graphql', {
      query: FETCH_TOPICS,
    });
    const {getTopics} = getInterest.data.data;

    const {addCountryOfInterests} = result.data.data;

    if (addCountryOfInterests.statusCode) {
      throw new Error(addCountryOfInterests.message);
    } else {
      await dispatch(setTempUserData({userData: getTopics}));
      await dispatch(setOnboardingProgress(0.5));
    }

    return addCountryOfInterests;
  } catch (error) {
    return {error};
  }
};

export const fetchGroups = () => async dispatch => {
  try {
    const result = await api.post('/graphql', {
      query: FETCH_GROUPS,
      variables: {
        page: 1,
        limit: 20,
      },
    });

    const {getGroups} = result.data.data;

    if (getGroups.statusCode) {
      throw new Error(getGroups.message);
    } else {
      await dispatch(setTempUserData({userData: getGroups}));
      await dispatch(setOnboardingProgress(0.6));
    }

    return getGroups;
  } catch (error) {
    return {error};
  }
};

export const areasOfInterest = payload => async dispatch => {
  try {
    const result = await api.post('/graphql', {
      query: ADD_USER_INTEREST,
      variables: {
        data: {
          input: payload,
        },
      },
    });

    const {addTopics} = result.data.data;

    if (addTopics.statusCode) {
      throw new Error(addTopics.message);
    } else {
      await dispatch(fetchGroups());
      await dispatch(setOnboardingProgress(0.6));
    }

    return addTopics;
  } catch (error) {
    return {error};
  }
};

export const addUserGroups = payload => async dispatch => {
  try {
    const result = await api.post('/graphql', {
      query: ADD_USER_GROUPS,
      variables: {
        groupIds: payload,
      },
    });

    const {addUserGroups} = result.data.data;

    if (addUserGroups.statusCode) {
      throw new Error(addUserGroups.message);
    } else {
      await dispatch(setOnboardingProgress(0.6));
    }

    return addUserGroups;
  } catch (error) {
    return {error};
  }
};
