import {Button, Dropdown, Form, Input, Pagination, Select, Table} from 'antd';
import styles from './mobileSetting.module.scss';
import {GET_ALL_COUNTRY_CODES} from 'graphql/queries/trendingCountries';
import trashIcon from 'images/trash-can-outline.png';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {
  ADD_PHONE_NUMBER_EXAMPLES,
  DELETE_PHONE_NUMBER_EXAMPLE,
  GET_PHONE_NUMBER_EXAMPLES,
  UPDATE_PHONE_NUMBER_EXAMPLE,
} from 'graphql/queries/mobileSettings';
import {toast} from 'react-toastify';
// import {Button} from '@dabafinance/react-components';
import {useCallback, useEffect, useState} from 'react';
import DBModal from 'components/modal/modal';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import dotsIcon from 'images/dots.svg';
import {Search} from '@dabafinance/react-components';
import {debounce, sanitizeInput} from 'utils/helper';
import {ArrowDownOutlined} from '@ant-design/icons';

export const PhoneNumberExamples = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchedValue] = useState(null);
  const [searchType, setSearchType] = useState('NAME');

  const [openAddModal, setOpenAddModal] = useState({
    open: false,
    confirmOpen: false,
  });
  const [openConfirmAddModal, setOpenConfirmAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    id: null,
  });
  const [openEditModal, setOpenEditModal] = useState({
    open: false,
    record: null,
  });
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const {data, refetch, loading} = useQuery(GET_PHONE_NUMBER_EXAMPLES, {
    variables: {
      filter: {
        page: 1,
        limit: 10,
        paginate: true,
      },
    },
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
  });

  const handlePageChange = (page, pageSize) => {
    if (page === currentPage && pageSize === limit) return;
    setCurrentPage(page);
    setLimit(pageSize);
    refetch({
      filter: {
        page: page,
        limit: pageSize,
        paginate: true,
      },
    });
  };

  useEffect(() => {
    if (openEditModal?.record) {
      editForm.setFieldsValue({
        ...openEditModal.record,
      });
    }
  }, [openEditModal]);

  const [addMultiplePhoneExample, {loading: loadingAddPhoneNumbers}] =
    useMutation(ADD_PHONE_NUMBER_EXAMPLES, {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    });

  const {loading: loadingCodes, data: codes} = useQuery(GET_ALL_COUNTRY_CODES, {
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const [deletePhoneExampleById, {loading: loadingDelete}] = useMutation(
    DELETE_PHONE_NUMBER_EXAMPLE,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const [updatePhoneExample, {loading: loadingUpdate}] = useMutation(
    UPDATE_PHONE_NUMBER_EXAMPLE,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const handleSearch = (value, type) => {
    const sanitizedValue = sanitizeInput(value);
    setCurrentPage(1);
    setLimit(10);
    if (!sanitizedValue) {
      return refetch({
        filter: {
          page: 1,
          limit: 10,
          paginate: true,
        },
      });
    }
    refetch({
      filter: {
        page: 1,
        limit: 10,
        ...(type === 'NAME' && {search: sanitizedValue}),
        ...(type === 'CODE' && {countryCode: sanitizedValue.toUpperCase()}),
      },
    });
  };

  const optimizedSearchCall = useCallback(debounce(handleSearch), []);

  const tableColumns = [
    {
      dataIndex: 'countryCode',
      key: 'countryCode',
      title: 'Country Code',
    },
    {
      dataIndex: 'countryName',
      key: 'countryName',
      title: 'Country Name',
    },
    {
      dataIndex: 'numberExample',
      key: 'numberExample',
      title: 'Phone Number Example',
    },
    {
      dataIndex: 'Actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: 'Edit Example',
                key: 0,
                onClick: () =>
                  setOpenEditModal({
                    open: true,
                    record,
                  }),
              },
              // {
              //   label: 'Delete Example',
              //   key: 1,
              //   onClick: () => setOpenDeleteModal({open: true, id: record.id}),
              // },
            ],
          }}>
          <div>
            <img src={dotsIcon} />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.body}>
      <div className={styles.mHeader}>
        <h2>Phone Number Examples</h2>
        {/* <Button
          label="Add Example(s)"
          onClick={() => setOpenAddModal({open: true})}
        /> */}
        <div className={styles.pNumberSearch}>
          <Search
            value={searchValue}
            onChange={e => {
              setSearchedValue(e);
              optimizedSearchCall(e, searchType);
            }}
            id="search"
            placeholder={`Input  ${
              searchType === 'CODE' ? 'Country Code' : 'Country Name'
            }`}
          />
          <Dropdown
            menu={{
              items: [
                {
                  label: 'Country Code',
                  key: 0,
                  onClick: () => setSearchType('CODE'),
                },
                {
                  label: 'Country Name',
                  key: 1,
                  onClick: () => setSearchType('NAME'),
                },
              ],
            }}
            trigger="click">
            <div>
              <Button>
                Search By <ArrowDownOutlined />{' '}
              </Button>
            </div>
          </Dropdown>
        </div>
      </div>

      <Table
        columns={tableColumns}
        loading={loading}
        pagination={false}
        dataSource={data?.getPhoneExamples?.data}
      />
      <Pagination
        showQuickJumper
        className="ant-table-pagination"
        showSizeChanger
        current={currentPage}
        onChange={handlePageChange}
        total={data?.getPhoneExamples?.pagination?.total}
        pageSize={limit}
      />
      <DBModal
        isOpen={openAddModal.open}
        width={1000}
        handleClose={() => setOpenAddModal({open: false})}
        content={
          <Form
            form={form}
            onFinish={() => setOpenConfirmAddModal(true)}
            layout="vertical">
            <div className={styles.mHeader}>
              <h2>Add Phone Number Examples</h2>
              <p>
                List of Phone Number Examples to display for Users in the Mobile
                Application during Signup
              </p>
            </div>
            <Form.List name="phoneNumberExamplesByCountryCode">
              {(fields, {add, remove}) => (
                <>
                  <div className={styles.pNumberContainer}>
                    {fields.map(({name}) => (
                      <div className={styles.pNumber} key={name}>
                        <div className={styles.pNumberFields}>
                          <Form.Item
                            label="Select Country Code"
                            rules={[
                              {
                                required: true,
                                message: 'This is a Required Field',
                              },
                            ]}
                            name={[name, 'countryCode']}>
                            <Select
                              placeholder="NG - Nigeria"
                              optionFilterProp="filter"
                              allowClear
                              loading={loadingCodes}
                              showSearch>
                              {codes?.getAllCountryCodes?.countries?.map(
                                country => (
                                  <Select.Option
                                    filter={country?.name}
                                    disabled={data?.getPhoneExamples?.data.some(
                                      ({countryCode}) =>
                                        countryCode === country?.code,
                                    )}
                                    key={JSON.stringify({
                                      name: country?.name,
                                      code: country?.code,
                                    })}>
                                    {country?.name}
                                  </Select.Option>
                                ),
                              )}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            label="Phone Number Example"
                            rules={[
                              {
                                required: true,
                                message: 'This is a Required Field',
                              },
                            ]}
                            name={[name, 'numberExample']}>
                            <Input />
                          </Form.Item>
                        </div>
                        <img
                          src={trashIcon}
                          alt=""
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                  </div>

                  <div
                    onClick={add}
                    role="button"
                    className={styles['add-button']}>
                    <img src={plusIcon} />
                    <h1>Add Field</h1>
                  </div>
                </>
              )}
            </Form.List>
            <SubmitButton label="Proceed to Update" />
            <ConfirmModal
              openModal={openConfirmAddModal}
              setOpenModal={() => setOpenConfirmAddModal(false)}
              title="Update Phone Number Examples"
              confirmText="Are you sure you want to update the Phone Number Examples?"
              loading={loadingAddPhoneNumbers}
              handleOperation={() => {
                addMultiplePhoneExample({
                  variables: {
                    inputs: form
                      .getFieldValue('phoneNumberExamplesByCountryCode')
                      .map(({countryCode, numberExample}) => {
                        const parsedCountryCode = JSON.parse(countryCode);
                        return {
                          countryCode: parsedCountryCode.code,
                          countryName: parsedCountryCode.name,
                          numberExample,
                        };
                      }),
                  },
                }).then(({data: {addMultiplePhoneExample}}) => {
                  if (
                    addMultiplePhoneExample.message === 'Error' &&
                    addMultiplePhoneExample.__typename === 'Error'
                  ) {
                    toast.error(addMultiplePhoneExample.message);
                  } else {
                    setOpenConfirmAddModal(false);
                    toast.success('Updated Successfully');
                    refetch();
                    form.resetFields();
                    setOpenAddModal({});
                  }
                });
              }}
            />
          </Form>
        }
      />
      <DBModal
        isOpen={openEditModal.open}
        handleClose={() => {
          setOpenEditModal({open: false});
          editForm.resetFields();
        }}
        content={
          <>
            <div className={styles.mHeader}>
              <h2>Edit Phone Number Example</h2>
              <p>NOTE: You can only edit the Example Text</p>
              <Form
                onFinish={values => {
                  updatePhoneExample({
                    variables: {
                      updatePhoneExampleId: openEditModal.record.id,
                      input: {
                        ...values,
                      },
                    },
                  })
                    .then(({data: {updatePhoneExample}}) => {
                      if (updatePhoneExample.message === 'Error') {
                        toast.error(updatePhoneExample.message);
                      } else {
                        toast.success('Updated Successfully');
                        setOpenEditModal({open: false});
                        refetch();
                      }
                    })
                    .catch(error => toast.error(error.message));
                }}
                form={editForm}
                layout="vertical">
                <Form.Item name="countryCode" label="Country Code">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="countryName" label="Country Name">
                  <Input disabled />
                </Form.Item>
                <Form.Item name="numberExample" label="Number Example">
                  <Input />
                </Form.Item>
                <SubmitButton disabled={loadingUpdate} label="Save Changes" />
              </Form>
            </div>
          </>
        }
      />
      <ConfirmModal
        openModal={openDeleteModal.open}
        setOpenModal={() => setOpenDeleteModal({open: false})}
        title="Delete Phone Number Example"
        confirmText="Are you sure you want to delete this Phone Number Example?"
        loading={loadingDelete}
        handleOperation={() => {
          deletePhoneExampleById({
            variables: {
              deletePhoneExampleByIdId: openDeleteModal.id,
            },
          }).then(({data: {deletePhoneExampleById}}) => {
            if (deletePhoneExampleById.message === 'Error') {
              toast.error(deletePhoneExampleById.message);
            } else {
              toast.success('Deleted Successfully');
              setOpenDeleteModal({open: false});
              refetch();
            }
          });
        }}
      />
    </div>
  );
};
