import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {BaseGeneralDetails} from '../BaseFinancialInstruments/generalDetails/BaseGeneralDetails';
import {useMutation, useQuery} from '@apollo/client';
import {Spin} from 'antd';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useState} from 'react';
import {currencySymbols} from 'utils/mock';
import {toast} from 'react-toastify';
import {
  currentInstrumentKey,
  financialInstrumentConfig,
} from '../modules/financialInstrumentConfig';

export const BondsGeneralDetails = () => {
  const location = useLocation();
  const {
    mutation,
    handleUpdate,
    route,
    handleDispatch,
    title,
    overviewSelector,
    QUERY,
  } = financialInstrumentConfig[currentInstrumentKey(location.pathname)];

  const [updatedValues, setUpdatedValues] = useState({});
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const navigate = useNavigate();
  const {assetId, lang, id} = useParams();
  const dispatch = useDispatch();
  const savedData = useSelector(overviewSelector);

  const [updateBond, {loading: loadingUpdate}] = useMutation(mutation);
  const {
    data: updateData,
    refetch,
    loading,
  } = useQuery(QUERY, {
    variables: {
      getFinancialInstrumentId: id,
      language: lang,
    },
    skip: !id,
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const handleSubmit = values => {
    if (id) {
      const {currency, isShariaCompliant, issuer, issuerType, ...restValues} =
        values;
      setUpdatedValues({
        ...restValues,
        isShariaCompliant: isShariaCompliant ? true : false,
        sectorIndustry: values?.sectorIndustry ?? null,
        currency: {
          currencyCode: currency,
          name: currencySymbols[currency]?.name,
          symbol: currencySymbols[currency]?.symbol,
        },
        issuer: {
          id: issuer,
          issuerType,
        },
      });
      setOpenUpdateModal(true);
    } else {
      dispatch(
        handleDispatch({
          ...values,
          isShariaCompliant: values?.isShariaCompliant ? true : false,
          exchange: values?.listingType === 'LISTED' ? values.exchange : null,
        }),
      );
      navigate(route({param: assetId, name: 'settings'}));
    }
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <div>
      <BaseGeneralDetails
        onSubmit={handleSubmit}
        title={title}
        existingValues={
          id ? updateData?.getFinancialInstrument?.data : savedData
        }
      />
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        title={`Update ${title}`}
        confirmText={`Are you sure you want update to this ${title}?`}
        handleOperation={() =>
          handleUpdate(
            updateBond,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
          )
        }
      />
    </div>
  );
};
