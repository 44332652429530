import {Upload as ImageUpload} from 'antd';
import {useState, useCallback} from 'react'; // Import useCallback
import styles from './companyImage.module.scss';
import imgUpload from 'images/upload_image.png';
import {Button} from '@dabafinance/react-components';
import '../companyVideo/player.css';
import {toast} from 'react-toastify';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import _ from 'lodash'; // Import debounce

const UploadImage = ({
  bucket,
  large,
  label = 'Upload Image',
  setPreviewUrl = () => {},
  isACL,
  previewUrl,
  setImgUrl,
  thumbnail,
  folder,
  icon,
  /**
   * Limits acceptable file types a user can upload. Defaults to image type.
   */
  accept = 'image/*',
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);

  const classNameStyle = large
    ? styles['large-image-uploaded']
    : thumbnail
    ? styles['thumbail-upload']
    : icon
    ? styles['icon-upload']
    : styles['image-uploaded'];

  const UploadToBucket = async file => {
    const payload = await resizeImageFile(file);
    try {
      const fileUrl = await UploadDocumentToBucket(
        payload,
        () => {},
        bucket,
        isACL,
        folder,
      );
      setUploadedImageUrl(fileUrl);
      setImgUrl(fileUrl);
    } catch (error) {
      toast.error('Failed to upload image');
    }
  };

  // Debounced version of UploadToBucket
  const debouncedUpload = useCallback(
    _.debounce(file => {
      UploadToBucket(file);
    }, 500),
    [],
  );

  const handleChange = async ({file}) => {
    const temp = file.originFileObj;
    const url = URL.createObjectURL(file.originFileObj);
    setPreviewUrl(url);
    setLoading(true);
    debouncedUpload(temp); // Call the debounced function
    setLoading(false);
  };

  const uploadButton = (
    <div
      className={
        large
          ? styles['large-company-upload']
          : thumbnail
          ? styles['thumbail-image']
          : icon
          ? styles['icon-image']
          : styles['company-upload']
      }>
      <img className={styles['company-image']} src={imgUpload} alt="upload" />
      <div className={styles['upload-text']}>
        <h3>{loading ? 'Uploading...' : label}</h3>
      </div>
    </div>
  );

  return (
    <div>
      {(previewUrl || uploadedImageUrl) && !loading ? (
        <div className={classNameStyle}>
          <img src={uploadedImageUrl ?? previewUrl} alt="company" />
          <Button
            label="Delete Image"
            type="secondary"
            className={styles['button-red']}
            onClick={() => {
              setImgUrl(null);
              setPreviewUrl(null);
              setUploadedImageUrl(null);
            }}
          />
        </div>
      ) : (
        <ImageUpload
          name="image"
          listType="picture"
          onChange={handleChange}
          showUploadList={false}
          maxCount={1}
          accept={accept}>
          {uploadButton}
        </ImageUpload>
      )}
    </div>
  );
};

export default UploadImage;
