import {Collapse, DatePicker, Form, Select} from 'antd';
import styles from './newNotification.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import moment from 'moment';
import {
  audienceTypes,
  notificationAssetClasses,
  notificationFilterOptions,
  notificationLastVisitOptions,
  notificationOrderStatusOptions,
} from 'utils/constants';
import {
  InvestingExperienceEnum,
  investmentPreferenceEnum,
  investmentHorizonEnum,
  investmentAffiliationEnum,
  visaTypeEnum,
  citizenStatusEnum,
  annualNetIncomeEnum,
  taxFilingStatusEnum,
  employmentStatusEnum,
  estimatedNetWorthEnum,
  investmentGoalsEnum,
  investmentStrategiesEnum,
} from 'utils/mock';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {getUserTimezone} from 'utils/helper';
import {useEffect, useState} from 'react';
import {GET_ASSET_CLASS_INSTRUMENTS} from 'graphql/queries/assetManagement';
import {useLazyQuery} from '@apollo/client';
import _ from 'lodash';
import {GET_COMPANIES_IN_STOCK_EXCHANGE} from 'graphql/queries/stocks';
import {toast} from 'react-toastify';

const NotificationFilter = ({
  audience,
  setAudience,
  users,
  setUsers,
  allGroups,
  allCampaigns,
  form,
  allTopics,
  allCountries,
  from,
  setFrom,
  to,
  setTo,
}) => {
  const {Option} = Select;
  const width = 400;

  return (
    <div>
      <div className={styles['audience-grid']}>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Audience required',
            },
          ]}
          name="audience"
          label="Audience"
          initialValue={'ALL_USERS'}>
          <Select
            style={{width: 720}}
            name="audience"
            value={audience}
            placeholder="All Users"
            defaultValue="ALL_USERS"
            onChange={e => setAudience(e)}>
            {audienceTypes.map(data => (
              <Option key={data.value}>{data.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      {audience === 'USERS' && (
        <Form.Item
          style={{width: 720}}
          label="Search and Select User(s)"
          name="users">
          <UserSelectField users={users} setUsers={setUsers} />
        </Form.Item>
      )}
      {audience === 'USERS_BY_GROUP' && (
        <Form.Item
          rules={[{required: audience === 'USERS_BY_GROUP'}]}
          label="Groups"
          name="groups">
          <Select
            mode="tags"
            style={{width: 720}}
            placeholder="Select by Groups">
            {allGroups?.map(data => (
              <Option key={data?.id}>
                <div className={styles['name-data']}>
                  <img src={data?.bannerImg} alt="" />
                  <span>{data?.name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {audience === 'INVESTORS_IN_A_CAMPAIGN' && (
        <Form.Item label="Live Campaign" name="liveCampaignId">
          <Select style={{width: 720}} placeholder="Select by Campaign">
            {allCampaigns
              ?.filter(
                data =>
                  data?.campaignMode === 'LIVE' &&
                  data?.campaignStatus !== 'DRAFT',
              )
              ?.map(data => (
                <Option key={data?.id}>
                  <div className={styles['option-data']}>
                    <div className={styles['name-data']}>
                      <img src={data?.fundraisingCompany?.logoImgURL} alt="" />
                      <span>{data?.fundraisingCompany?.companyName}</span>
                    </div>
                    <div>--{data?.campaignStatus}</div>
                  </div>
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      {audience === 'INVESTORS_WITH_RESERVATIONS' && (
        <Form.Item label="Test Campaign" name="testCampaignId">
          <Select style={{width: 720}} placeholder="Select by Campaign">
            {allCampaigns
              ?.filter(
                data =>
                  data?.campaignMode === 'TEST' &&
                  data?.campaignStatus !== 'DRAFT',
              )
              ?.map(data => (
                <Option key={data?.id}>
                  <div className={styles['option-data']}>
                    <div className={styles['name-data']}>
                      <img src={data?.fundraisingCompany?.logoImgURL} alt="" />
                      <span>{data?.fundraisingCompany?.companyName}</span>
                    </div>
                    <div>--{data?.campaignStatus}</div>
                  </div>
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label="Topics" name="topics">
        <Select
          mode="multiple"
          style={{width: 720}}
          placeholder="Select by Topics">
          {allTopics?.map(item => (
            <Option key={`${item?.name};${item?.id}`}>
              <div className={styles['name-data']}>
                <img src={item?.icon} alt="" />
                <span>{item?.name}</span>
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>
      {audience !== 'USERS' && (
        <FadeEffect>
          <div className={styles.accredited}>
            <Form.Item
              className={styles['field-width']}
              label="Accredited"
              name="accredited">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Active Investors"
              name="activeInvestors">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Verified Investors"
              name="verifiedInvestors">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Latest App Visit"
              name="latestVisit">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationLastVisitOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="Profile Completion"
              name="profileCompletion">
              <Select defaultValue={'EITHER'} placeholder="Either">
                <Option key="EITHER">Either</Option>
                <Option key="EmailVerification">
                  Email Verification - Basics
                </Option>
                <Option key="PhoneVerification">
                  Phone Verification - Basics
                </Option>
                <Option key="Basics">Step One(Not Completed) - Basics</Option>
                <Option key="Preferences">
                  Step Two(Not Completed) - Preferences
                </Option>
                <Option key="Investments">
                  Step Three(Not Completed) - Investments
                </Option>
                <Option key="Customization">
                  Step Four(Not Completed) - Customization
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              label="KYC Approved"
              name="isKycApproved">
              <Select defaultValue={'EITHER'} placeholder="Either">
                {notificationFilterOptions.map(option => (
                  <Option key={option.value}>
                    <span>{option.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </FadeEffect>
      )}
      <Collapse>
        <Collapse.Panel
          key="orderFilter"
          header={
            <h1 className={styles['header-text']}>
              Filter By Users Investment
            </h1>
          }>
          <h3>Investment Timeline</h3>
          <NotificationOrderByFilter form={form} allCampaigns={allCampaigns} />
        </Collapse.Panel>
      </Collapse>
      <Collapse>
        <Collapse.Panel
          header={
            <h1 className={styles['header-text']}>
              Filter by Citizenship Details
            </h1>
          }
          key="1">
          <div className={styles['invest-form']}>
            <Form.Item label="Country of Residence" name="countryOfResidence">
              <Select mode="multiple" style={{width}} showSearch>
                {allCountries.map(data => (
                  <Option key={data?.name}>
                    <div className={styles['all-data']}>
                      <img src={data?.icon} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="State (Type state and click on Enter to type another input)"
              name="state">
              <Select mode="tags" style={{width}} showSearch>
                <Option disabled>Please Input State</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="City (Type city and click on Enter to type another input)"
              name="city">
              <Select mode="tags" style={{width}} showSearch>
                <Option disabled>Please Input City</Option>
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Country of Birth"
              name="countryOfBirth">
              <Select mode="multiple" style={{width}} showSearch>
                {allCountries.map(data => (
                  <Option key={`${data?.name};${data?.id}`}>
                    <div className={styles['all-data']}>
                      <img src={data?.icon} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Country of Citizenship"
              name="countryOfCitizenship">
              <Select mode="multiple" style={{width}} showSearch>
                {allCountries.map(data => (
                  <Option key={`${data?.name};${data?.id}`}>
                    <div className={styles['all-data']}>
                      <img src={data?.icon} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item style={{width}} label="U.S Visa Type" name="visaType">
              <Select mode="tags">
                {visaTypeEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Citizenship Status"
              name="citizenStatus">
              <Select mode="tags">
                {citizenStatusEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Collapse.Panel>
      </Collapse>
      <Collapse>
        <Collapse.Panel
          key="FinancialProfile"
          header={
            <h1 className={styles['header-text']}>
              Filter by Financial Profile
            </h1>
          }>
          <div className={styles['invest-form']}>
            <Form.Item
              style={{width}}
              label="Annual Net Income"
              name="netIncome">
              <Select mode="tags">
                {annualNetIncomeEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Tax Filling Status"
              name="taxFilingStatus">
              <Select mode="tags">
                {taxFilingStatusEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Employment Status"
              name="employmentStatus">
              <Select mode="tags">
                {employmentStatusEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Estimated Net Worth"
              name="estimatedNetWorth">
              <Select mode="tags">
                {estimatedNetWorthEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Collapse.Panel>
      </Collapse>
      <Collapse>
        <Collapse.Panel
          key="investorProfile"
          header={
            <h1 className={styles['header-text']}>
              Filter by Investor Profile
            </h1>
          }>
          <div className={styles['invest-form']}>
            <Form.Item
              style={{width}}
              name="investmentExperience"
              label="InvestmentExperience">
              <Select mode="tags">
                {InvestingExperienceEnum.map(({name, key}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Investment Preference"
              name="investmentPreference">
              <Select mode="tags">
                {investmentPreferenceEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Investment Horizon"
              name="investmentHorizon">
              <Select mode="tags">
                {investmentHorizonEnum.map(({name, key}) => (
                  <Option key={key}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Investment Affiliation"
              name="investmentAffiliation">
              <Select mode="tags">
                {investmentAffiliationEnum.map(({name, key}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Investment Goals"
              name="investmentGoals">
              <Select mode="tags">
                {investmentGoalsEnum.map(({name, key}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Dominant Investment Strategies"
              name="investmentStrategies">
              <Select mode="tags">
                {investmentStrategiesEnum.map(({name, key}) => (
                  <Option value={key} key={key}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Collapse.Panel>
      </Collapse>

      <div>
        <h1 className={styles['header-text']}>Signup Date</h1>
      </div>
      <div className={styles['schedule-grid']}>
        <Form.Item name="from" label="From">
          <DatePicker
            className={styles.date}
            name="From"
            format={'YYYY-MM-DD HH:mm:ss'}
            renderExtraFooter={() => (
              <div style={{textAlign: 'center'}}>
                <p>Your timezone is: {getUserTimezone()}</p>
              </div>
            )}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            value={from}
            onChange={e => setFrom(moment(e).format('YYYY-MM-DD HH:mm:ss'))}
          />
        </Form.Item>
        <Form.Item name="to" label="To">
          <DatePicker
            className={styles.date}
            name="To"
            format={'YYYY-MM-DD HH:mm:ss'}
            renderExtraFooter={() => (
              <div style={{textAlign: 'center'}}>
                <p>Your timezone is: {getUserTimezone()}</p>
              </div>
            )}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            value={to}
            onChange={e => setTo(moment(e).format('YYYY-MM-DD HH:mm:ss'))}
          />
        </Form.Item>
      </div>
    </div>
  );
};

const NotificationOrderByFilter = ({form, allCampaigns}) => {
  const [stocks, setStocks] = useState([]);
  const [instruments, setInstruments] = useState([]);
  const instrumentKey = Form.useWatch('assetClass', form);

  const [
    fetchInstruments,
    {refetch: refetchInstrument, loading: loadingInstruments},
  ] = useLazyQuery(GET_ASSET_CLASS_INSTRUMENTS);

  const [fetchStocks, {refetch: refetchStocks, loading: loadingStocks}] =
    useLazyQuery(GET_COMPANIES_IN_STOCK_EXCHANGE);

  useEffect(() => {
    if (['BOND', 'MUTUAL_FUND'].includes(instrumentKey)) {
      form.setFieldValue('assetId', undefined);
      fetchInstruments({
        variables: {
          input: {
            explore: false,
            instrumentKey,
            assetClassKey: instrumentKey === 'BOND' ? 'FIXED_INCOME' : 'FUNDS',
          },
        },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: error => toast.error(error?.message),
        onCompleted: data =>
          setInstruments(data?.getFinancialInstruments?.data || []),
      });
    } else if (instrumentKey === 'STOCK') {
      form.setFieldValue('assetId', undefined);
      fetchStocks({
        variables: {
          stockExchangeCode: 'BRVM',
          page: 1,
          limit: 100,
        },
        onCompleted: data =>
          setStocks(data?.adminGetStockExchangeListedCompanies?.companies),
        onError: error => toast.error(error?.message),
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
      });
    }
  }, [instrumentKey]);

  const handleSearchStocks = _.debounce(async value => {
    try {
      const {data} = await refetchStocks({
        searchTerm: value,
        page: 1,
        limit: 20,
      });
      setStocks(data?.adminGetStockExchangeListedCompanies?.companies || []);
    } catch (error) {
      console.error('Error fetching stocks:', error);
    }
  }, 1200);

  const handleSearch = _.debounce(async value => {
    try {
      const {data} = await refetchInstrument({
        input: {
          explore: false,
          instrumentKey,
          assetClassKey: instrumentKey === 'BOND' ? 'FIXED_INCOME' : 'FUNDS',
          search: value,
          page: 1,
          limit: 100,
        },
      });

      setInstruments(data.getFinancialInstruments?.data || []);
    } catch (error) {
      console.error('Error fetching instruments:', error);
    }
  }, 1200);

  return (
    <div className={styles['schedule-grid']}>
      <Form.Item name="orderFrom" label="Start Date">
        <DatePicker
          className={styles.date}
          format={'YYYY-MM-DD HH:mm:ss'}
          renderExtraFooter={() => (
            <div style={{textAlign: 'center'}}>
              <p>Your timezone is: {getUserTimezone()}</p>
            </div>
          )}
          showTime={{
            defaultValue: moment('00:00', 'HH:mm:ss'),
          }}
        />
      </Form.Item>
      <Form.Item name="orderTo" label="End Date">
        <DatePicker
          className={styles.date}
          format={'YYYY-MM-DD HH:mm:ss'}
          renderExtraFooter={() => (
            <div style={{textAlign: 'center'}}>
              <p>Your timezone is: {getUserTimezone()}</p>
            </div>
          )}
          showTime={{
            defaultValue: moment('00:00', 'HH:mm:ss'),
          }}
        />
      </Form.Item>
      <Form.Item
        className={styles.date}
        name="orderStatus"
        label="Order Status(es)">
        <Select mode="multiple" placeholder="Open" allowClear showSearch>
          {notificationOrderStatusOptions.map(option => (
            <Select.Option key={option.key}>{option.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.date}
        name="orderDirection"
        label="Order Direction">
        <Select placeholder="Buy" allowClear showSearch>
          <Select.Option key="BUY">Buy</Select.Option>
          <Select.Option key="SELL">Sell</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.date}
        name="isOrderOnHold"
        label="Order(s) On Hold">
        <Select defaultValue={'EITHER'} placeholder="Either">
          {notificationFilterOptions.map(option => (
            <Option key={option.value}>
              <span>{option.label}</span>
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className={styles.date} />
      <Form.Item className={styles.date} name="assetClass" label="Asset Class">
        <Select placeholder="Bond" allowClear>
          {notificationAssetClasses.map(option => (
            <Select.Option key={option.key}>{option.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {['VENTURE', 'IPO'].includes(form.getFieldValue('assetClass')) && (
        <Form.Item
          className={styles.date}
          name="assetId"
          label="Select Venture / IPO">
          <Select
            filterOption={true}
            optionFilterProp="filter"
            allowClear
            showSearch
            placeholder="Search and Select">
            {allCampaigns
              ?.filter(
                data =>
                  data?.campaignMode === 'LIVE' &&
                  data?.campaignStatus !== 'DRAFT',
              )
              ?.map(data => (
                <Option
                  filter={data?.fundraisingCompany?.companyName}
                  key={data?.id}>
                  <div className={styles['option-data']}>
                    <div className={styles['name-data']}>
                      <img src={data?.fundraisingCompany?.logoImgURL} alt="" />
                      <span>{data?.fundraisingCompany?.companyName}</span>
                    </div>
                    <div>--{data?.campaignStatus}</div>
                  </div>
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
      {['BOND', 'MUTUAL_FUND'].includes(form.getFieldValue('assetClass')) && (
        <Form.Item
          className={styles.date}
          name="assetId"
          label="Instrument based on Selected Asset">
          <Select
            showSearch
            onSearch={handleSearch}
            filterOption={false}
            placeholder="Search for Instrument"
            loading={loadingInstruments}
            allowClear>
            {instruments?.map(instrument => (
              <Select.Option key={instrument?.id}>
                {instrument?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {form.getFieldValue('assetClass') === 'STOCK' && (
        <Form.Item
          className={styles.date}
          name="assetId"
          label="Search & Select Stock">
          <Select
            onSearch={handleSearchStocks}
            loading={loadingStocks}
            allowClear
            showSearch
            filterOption={false}
            placeholder="Search for Stock">
            {stocks?.map(stock => (
              <Select.Option key={stock?.company?.id}>
                {stock?.company?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </div>
  );
};

export default NotificationFilter;
