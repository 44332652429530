import {PRE_SIGN_MEDIA_URL} from 'graphql/mutations/wallet';
import {client} from 'index';
import Resizer from 'react-image-file-resizer';
import {toast} from 'react-toastify';
import {formatFileName} from 'utils/helper';

export const resizeImageFile = file =>
  // eslint-disable-next-line no-undef
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      'PNG',
      90,
      0,
      uri => {
        resolve(uri);
      },
      'file',
    );
  });

export const UploadDocumentToBucket = async (
  payload,
  setProgress = () => {},
  bucket = 'campaign-bucket-images',
  isACL = false,
  folder,
) => {
  const name = formatFileName(payload.name);
  const filename = isACL ? `${folder}/${name}` : name;
  let imageUrl = null;

  try {
    // Function to make the GraphQL request
    const getPresignedUrl = async (isPublic = false) => {
      const {data, errors} = await client.mutate({
        mutation: PRE_SIGN_MEDIA_URL,
        variables: {
          input: {
            bucket,
            upload: true,
            filename,
            contentType: payload?.type,
            ...(isPublic ? {public: true} : {}), // Conditionally add public flag
          },
        },
      });

      if (errors) {
        toast.error(errors[0].message);
        return null; // Or throw the error, depending on your needs
      }
      if (data?.presignMediaURL?.statusCode === 400) {
        toast.error(data?.presignMediaURL?.message);
        return null;
      }
      return data?.presignMediaURL?.data?.url; // Return the URL, or null if there was an error
    };

    const uploadToS3 = async url => {
      if (!url) return null; // Exit if no URL

      try {
        const response = await fetch(url, {
          headers: {
            'Content-Type': payload?.type,
          },
          method: 'PUT',
          body: payload,
          redirect: 'follow',
        });

        if (!response.ok) {
          throw new Error('Something went wrong with Upload');
        }

        setProgress(0);
        return `https://${bucket}.s3.us-east-1.amazonaws.com/${filename}`; // Construct the final URL here
      } catch (error) {
        setProgress(0);
        toast.error(error);
        return null; // Indicate failure
      }
    };

    setProgress(50);

    const presignedUrl = await getPresignedUrl();
    if (presignedUrl) {
      imageUrl = await uploadToS3(presignedUrl);
    }
    if (isACL) {
      await getPresignedUrl(true);
    }
  } catch (error) {
    toast.error('Something went wrong uploading the image');
  }

  return imageUrl;
};

export const UploadVideoToBucket = async (payload, bucket, setProgress) => {
  let fileLink = null;
  const filename = formatFileName(payload.name);

  try {
    const {
      data: {presignMediaURL},
    } = await client.mutate({
      mutation: PRE_SIGN_MEDIA_URL,
      variables: {
        input: {
          bucket,
          upload: true,
          filename,
          contentType: payload?.type,
        },
      },
    });
    setProgress(50);
    if (presignMediaURL?.data?.url) {
      fileLink = fetch(presignMediaURL?.data?.url, {
        headers: {
          'Content-Type': payload?.type,
        },
        method: 'PUT',
        body: payload,
        redirect: 'follow',
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Something went wrong with Upload');
          } else {
            setProgress(0);
            return `https://${bucket}.s3.us-east-1.amazonaws.com/${filename}`;
          }
        })

        .catch(error => {
          setProgress(0);
          toast.error(error);
        });
    }
  } catch (error) {
    toast.error('Something went wrong uploading the image');
  }

  return fileLink;
};
