import {DatePicker, Form, Input, InputNumber, Select, Spin} from 'antd';
import styles from '../overview.module.scss';
import {mutualFundsTypes} from 'pages/AssetManagement/modules/constants';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';
import {getUserTimezone} from 'utils/helper';
import moment from 'moment';
import {InfoCircleOutlined} from '@ant-design/icons';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useDispatch, useSelector} from 'react-redux';
import {handleMutualFunds} from 'redux/store/assetClass';
import {useEffect, useState} from 'react';
import {createMutualFundsStepTwoRoute} from 'pages/AssetManagement/Bonds/routes';
import {useNavigate, useParams} from 'react-router-dom';
import {GET_MUTUAL_FUND_BY_LANGUAGE} from 'graphql/queries/assetManagement';
import {toast} from 'react-toastify';
import {UPDATE_MUTUAL_FUND} from 'graphql/mutations/assetManagement';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {handleUpdateMutualFund} from 'pages/AssetManagement/actions';

export const MutualFundsOverview = () => {
  const [form] = Form.useForm();
  const {assetId, lang, id} = useParams();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fieldValues = useSelector(state => state?.assetClass?.mutualFunds);
  const {data: getAllCountries, loading} = useQuery(GET_ALL_COUNTRIES, {
    errorPolicy: 'all',
  });

  const {
    data,
    refetch,
    loading: loadingInstrument,
  } = useQuery(GET_MUTUAL_FUND_BY_LANGUAGE, {
    variables: {
      getFinancialInstrumentId: id,
      language: lang,
    },
    skip: !id,
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const [updateMutualFund, {loading: loadingUpdate}] = useMutation(
    UPDATE_MUTUAL_FUND,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  useEffect(() => {
    if (data?.getFinancialInstrument?.data) {
      const {inceptionDate, currentNavDate, ...restOfFields} =
        data?.getFinancialInstrument?.data;
      form.setFieldsValue({
        currentNavDate: currentNavDate
          ? moment(currentNavDate, 'YYYY-MM-DD HH:mm:ss')
          : null,

        inceptionDate: inceptionDate
          ? moment(inceptionDate, 'YYYY-MM-DD HH:mm:ss')
          : null,

        ...restOfFields,
      });
    }
  }, [data?.getFinancialInstrument?.data]);

  useEffect(() => {
    if (fieldValues?.ticker) {
      const {currentNavDate, inceptionDate, ...restOfFields} = fieldValues;
      form.setFieldsValue({
        currentNavDate: currentNavDate
          ? moment(currentNavDate, 'YYYY-MM-DD HH:mm:ss')
          : null,

        inceptionDate: inceptionDate
          ? moment(inceptionDate, 'YYYY-MM-DD HH:mm:ss')
          : null,

        ...restOfFields,
      });
    }
  }, [fieldValues]);

  if (loadingInstrument) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <h2>Basic Information</h2>
      <Form
        form={form}
        onFinish={values => {
          const {currentNavDate, currentNetAssetValue, ...restValues} = values;
          if (id) {
            setOpenUpdateModal(true);
            setUpdatedValues({
              ...restValues,
            });
          } else {
            dispatch(
              handleMutualFunds({
                currentNavDate,
                currentNetAssetValue,
                ...values,
              }),
            );
            navigate(
              createMutualFundsStepTwoRoute({param: assetId, name: 'funds'}),
            );
          }
        }}
        layout="vertical">
        <div className={styles.form}>
          <Form.Item
            rules={[
              {required: true, message: 'Please provide a ticker'},
              {
                max: 6,
                message: 'Ticker Length cannot be more than 6 Characters',
              },
            ]}
            className={styles.smallWidth}
            name="ticker"
            label="Ticker">
            <Input placeholder="BRCVM" />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Please provide a type'}]}
            className={styles.smallWidth}
            name="type"
            label="Type">
            <Select>
              {mutualFundsTypes.map(type => (
                <Select.Option key={type.value}>{type.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          {id && (
            <Form.Item name="YTDReturn" label="YTD Return">
              <InputNumber
                className={styles['input-number']}
                min={0}
                placeholder="Enter year-to-date return"
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          )}
          <Form.Item name="lastYearReturn" label="Last Year Return">
            <InputNumber
              className={styles['input-number']}
              min={0}
              placeholder="Enter last year's return"
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item name="capitalizationRate" label="Capitalization Rate">
            <InputNumber
              className={styles['input-number']}
              min={0}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item name="marketCapitalization" label="Market Capitalization">
            <InputNumber
              className={styles['input-number']}
              min={0}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.largeWidth}
            label="Redemption Terms"
            name="redemptionTerms">
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item
            name="countries"
            className={styles.largeWidth}
            label="Countries">
            <Select
              loading={loading}
              allowClear
              disabled={loading}
              mode="multiple"
              showSearch
              optionFilterProp="search"
              filterOption>
              {getAllCountries?.getAllCountries?.map(country => (
                <Select.Option search={country?.name} key={country?.id}>
                  <div className={styles['all-data']}>
                    <img src={country?.icon} alt="" />
                    <span>{country?.name}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <h2>NAV Metrics</h2>
        <div className={styles.infoHeader}>
          <InfoCircleOutlined />
          <p>
            <span>NAV:</span> Net Asset Value
          </p>
        </div>
        <div className={styles.flexed}>
          <Form.Item
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="currentNetAssetValue"
            label="Current NAV">
            <InputNumber
              className={styles['input-number']}
              placeholder="10,000"
              disabled={id}
              min={0}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="currentNavDate"
            rules={[{required: true, message: 'This is  a Required Field'}]}
            label="Current NAV Date">
            <DatePicker
              className={styles.smallWidth}
              disabled={id}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="inceptionDate"
            label="Inception Date">
            <DatePicker
              className={styles.smallWidth}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="netAssetValueAtInception"
            label="NAV at Inception">
            <InputNumber
              className={styles['input-number']}
              placeholder="10,000"
              min={0}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <SubmitButton label="Save & Proceed" />
      </Form>
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        confirmText={'Are you sure you want to Update this Fields'}
        title="Update Mutual Fund"
        handleOperation={() =>
          handleUpdateMutualFund(
            updateMutualFund,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
            true,
          )
        }
      />
    </div>
  );
};
