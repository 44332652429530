import {gql} from '@apollo/client';

export const GET_ASSET_CLASSES = gql`
  query GetAssetClasses($input: AssetClassFilter) {
    getAssetClasses(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClasses {
        pagination {
          total
        }
        data {
          id
          name
          key
          description
          status
          author

          language
        }
      }
    }
  }
`;

export const GET_ASSET_CLASS_LANGUAGES = gql`
  query GetAssetClassLanguageVersions($getAssetClassLanguageVersionsId: ID!) {
    getAssetClassLanguageVersions(id: $getAssetClassLanguageVersionsId) {
      ... on ResponseWithAssetClassLanguageVersions {
        data {
          description
          id
          languageVersions
          name
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_ASSET_CLASS = gql`
  query GetAssetClass($getAssetClassId: ID!, $language: LanguageEnum!) {
    getAssetClass(id: $getAssetClassId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClass {
        message
        data {
          id
          name
          key
          description
          status
          author
          instruments {
            name
            key
            description
            status
          }
          language
        }
      }
    }
  }
`;

export const GET_ASSET_CLASS_INSTRUMENTS = gql`
  query Bond($input: FinancialInstrumentFilter!) {
    getFinancialInstruments(input: $input) {
      ... on ResponseWithFinancialInstruments {
        data {
          ... on Instruments {
            title
            instrumentKey
            exclusive
            banner
            lang: language
            assetClassKey
            investmentRisk
            groupId: id
            instruments {
              ... on Bond {
                id
              }
            }
            instruments {
              ... on MutualFund {
                id
              }
            }
            description
            multilang {
              title
              language
              description
            }
          }
          ... on Bond {
            name
            key
            language
            ticker
            listingType
            currentMarketPrice
            fractionable
            exchange
            id
            issuer {
              id
            }
            status
            currency {
              currencyCode
            }
          }
          ... on MutualFund {
            name
            key
            id
            language
            previousNavDate
            currentNavDate
            ticker
            currentNetAssetValue
            netAssetValueAtInception
            inceptionDate
            issuer {
              id
            }
            status
            currency {
              currencyCode
            }
            exchange
            listingType
            listingType
          }
        }
        pagination {
          total
        }
      }
    }
  }
`;

export const INSTRUMENTS_DETAILS_QUERIES = gql`
  query GetAllInstrumentsQueries {
    getAllCompanies {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        id
        name
        legalName
        ticker
        stage
        isTrending
        logoImgURL
        totalCapitalRaised
      }
    }
    getAllTopics {
      ... on Error {
        message
        statusCode
      }
      ... on Topic {
        id
        name
        icon
      }
    }
    getAllIndustries {
      ... on Error {
        message
        statusCode
      }
      ... on Industry {
        id
        name
        icon
      }
    }
  }
`;

export const GET_ALL_RATINGS = gql`
  query ResponseWithRatings($page: Int!, $limit: Int!) {
    getAllRatings(page: $page, limit: $limit) {
      ... on ResponseWithRatings {
        message
        data {
          id
          agencyName
          agencyLogo
          rating
          document
          type
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_RATING_BY_ID = gql`
  query GetRatingById($getRatingByIdId: ID!) {
    getRatingById(id: $getRatingByIdId) {
      ... on ResponseWithRating {
        data {
          id
          agencyName
          agencyLogo
          rating
          document
          type
        }
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENT_LANGUAGE_VERSIONS = gql`
  query GetFinancialInstrumentLanguageVersions(
    $getFinancialInstrumentLanguageVersionsId: ID!
    $assetClassKey: AssetClassKey!
  ) {
    getFinancialInstrumentLanguageVersions(
      id: $getFinancialInstrumentLanguageVersionsId
      assetClassKey: $assetClassKey
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithFinancialInstrumentLanguageVersions {
        data {
          id
          name
          description
          languageVersions
        }
      }
    }
  }
`;

export const GET_BOND_INSTRUMENT_BY_LANGUAGE = gql`
  query GetFinancialInstrument(
    $getFinancialInstrumentId: ID!
    $language: LanguageEnum
  ) {
    getFinancialInstrument(id: $getFinancialInstrumentId, language: $language) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        data {
          ... on Bond {
            issuer {
              id
              issuerType
            }
            name
            icon
            description
            tradeable
            currency {
              currencyCode
            }
            endDate
            startDate
            settings {
              isPaused
              isVisible
              shouldNotifyUsersOnLaunch
              publicAudience
            }
            templateId
            key
            language
            topics {
              id
            }
            status
            minimumInvestmentAmount
            managementFee
            allocatedUnits
            availableUnits
            gracePeriodPrincipal
            gracePeriodInterest
            assetClassKey
            sectorIndustry {
              id
            }
            investmentContractSettings {
              spvNamePrefix
              investmentTargetEntityLegalName
              managementFeeTerms
              carryPercentageTerms
              minimumInvestmentPerMember
              spvManagerRepresentativeName
              investmentContractType
            }
            listingType
            isShariaCompliant
            investmentRisk
            liquidity
            pitchDeck {
              fileLink
              coverImage
              linkType
              externalViewLink
              externalShareLink
            }
            coInvestors {
              name
              imageUrl
              title
              description
              otherInvestments {
                companyId
                imageUrl
              }
            }
            competitors {
              companyName
              companyLogo
              companyId
            }
            documents {
              name
              fileLink
            }
            dabaScore {
              score
              pdfReport
              business
              industry
              market
              team
              strategyProduct
              accelerator
              riskChallenges
              analogus
              economicsAndFinance
              legalCompliance
              returnOutlook
              socialImpact
            }
            faq {
              question
              answer
            }
            riskAndEsg {
              title
              answer
            }
            impactAndEsg {
              title
              answer
            }
            missionAndVision
            solutions
            invitedUsers {
              id
              firstName
              lastName
              email
            }
            invitedGroups {
              id
            }
            disclosure
            additionalDisclosures {
              name
              value
            }
            recommendedPortfolioStrategy
            opportunityVoiceRecording
            opportunityVoiceRecordingThumbnail
            opportunityDemoVideo
            opportunityDemoVideoThumbnail
            level1AnalystVideo
            level1AnalystVideoThumbnail
            level2AnalystVideo
            level2AnalystVideoThumbnail
            externalVideoUrl
            externalVideoThumbnail
            totalExternallyFundedAmount
            externallyFundedAmountBreakDown {
              name
              amount
            }
            analog {
              logo
              name
              description
              companyType
              valuation
            }
            totalInvestedAmount
            totalInvestment
            totalPayments
            exchange
            feeSchedule {
              feeName
              feeType
              fixedFeeAmount
              variableFeeAmount
            }
            tradingVolume
            fractionable
            minTradeableUnits
            maxTradeableUnits
            minTradeableAmount
            maxTradeableAmount
            orderExpirationLimit

            minimumFundingTarget
            maximumFundingTarget
            fundingTarget
            companyValuation
            additionalInvestmentTerms {
              name
              value
            }
            id
            maturityDate
            duration
            couponRate
            isCallable
            currentMarketPrice
            type
            yieldToMaturity
            issuanceDate
            useOfProceeds {
              description
              allocations {
                allocationCategory
                percentageValue
              }
            }
            interestPaymentFrequency
            projectFinancingDetails
            legalChallenges
            nextCouponDate
            nextCallDate
            nextCallPrice
            outlookAsOfDate
            creditWatchAsOfDate
            CUSIP
            ticker
            issuanceVolume
            isn
            rating {
              id
            }
            primaryBrokerName
            primaryBrokerLogo
            primaryBrokerDescription
            primaryBrokerWebsite
            primaryBrokerOperatingCountries
            marketCapitalization
          }
        }
      }
    }
  }
`;

export const GET_MUTUAL_FUND_BY_LANGUAGE = gql`
  query GetFinancialInstrument(
    $getFinancialInstrumentId: ID!
    $language: LanguageEnum
  ) {
    getFinancialInstrument(id: $getFinancialInstrumentId, language: $language) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        data {
          ... on MutualFund {
            id
            name
            description
            endDate
            startDate
            icon
            templateId
            key
            language
            status
            minimumInvestmentAmount
            assetClassKey
            listingType
            isShariaCompliant
            bestSuitedFor
            investmentRisk
            tradeable
            YTDReturn
            lastYearReturn
            isForDabaPro
            liquidity
            missionAndVision
            solutions
            disclosure
            recommendedPortfolioStrategy
            totalExternallyFundedAmount
            totalInvestedAmount
            totalInvestment
            totalPayments
            exchange
            ticker
            type
            benefits
            marketCapitalization
            capitalizationRate
            tradingVolume
            fractionable
            minTradeableUnits
            maxTradeableUnits
            minTradeableAmount
            maxTradeableAmount
            orderExpirationLimit
            minimumFundingTarget
            currentNavDate
            currentNetAssetValue
            maximumFundingTarget
            fundingTarget
            useOfProceeds {
              description
              allocations {
                allocationCategory
                percentageValue
              }
            }
            companyValuation
            netAssetValueAtInception
            previousNavDate
            projectedAnnualReturn
            fundGeographicArea
            countries
            investmentSteps
            redemptionTerms
            capitalizationRate
            investmentHorizon
            inceptionDate
            fundAssetMixFund
            performanceUpdateFrequency
            fundDisclaimer
            fundObjective
            fundVolatilityInformation
            fundApprovalNumber
            fundComplianceInformation
            fundLastUpdatedDate
            historicalPerformance {
              year
              performance
            }
            faq {
              question
              answer
            }
            settings {
              isPaused
              isVisible
              shouldNotifyUsersOnLaunch
              publicAudience
            }
            sectorIndustry {
              id
            }
            topics {
              id
            }
            riskAndEsg {
              title
              answer
            }
            stockExchange {
              id
            }
            pitchDeck {
              fileLink
              coverImage
              linkType
              externalViewLink
              externalShareLink
            }
            invitedUsers {
              id
            }
            issuer {
              issuerType
              id
            }
            currency {
              currencyCode
            }
            impactAndEsg {
              title
              answer
            }
            additionalInvestmentTerms {
              name
              value
            }
            documents {
              name
              fileLink
            }
            fundManager {
              name
              icon
              foundingDate
              website
            }
            feeSchedule {
              scalable
              origination
              frequency
              feeName
              feeType
              fixedFeeAmount
              variableFeeAmount
              level
              vector {
                magnitude {
                  feeType
                  value
                }
                duration
              }
            }
            keyFacts {
              name
              value
            }
            keyStatistics {
              name
              value
            }
            dabaScore {
              score
              pdfReport
              business
              industry
              market
              team
              strategyProduct
              accelerator
              riskChallenges
              analogus
              economicsAndFinance
              legalCompliance
              returnOutlook
              socialImpact
            }
            investmentContractSettings {
              spvNamePrefix
              investmentTargetEntityLegalName
              managementFeeTerms
              carryPercentageTerms
              minimumInvestmentPerMember
              spvManagerRepresentativeName
              investmentContractType
            }
            investmentCalculatorSlider {
              min
              max
            }
            invitedGroups {
              id
            }
          }
        }
      }
    }
  }
`;
