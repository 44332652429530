import {gql} from '@apollo/client';

export const GET_ALL_STOCKS = gql`
  query GetAllStocksExchange {
    getAllStocksExchange {
      ... on Error {
        statusCode
      }
      ... on StockExchange {
        id
        stockExchangeName
        logoImage
        description
        countriesOfOperation {
          id
          name
          countryCode
          continentName
          capital
          region
          subRegion
          icon
          createdAt
          updatedAt
        }
        baseCurrencyCode
        foundedDate
        code
        website
        CEO
        headquarters
        numberOfListings
        totalMarketCapitalization
      }
    }
  }
`;

export const GET_ALL_STOCKS_WITH_INSTRUMENT_KEY = gql`
  query GetAllStocksExchange {
    getAllStocksExchange {
      ... on Error {
        statusCode
      }
      ... on StockExchange {
        id
        stockExchangeName
        instrument
      }
    }
  }
`;

export const SEARCH_SEEDED_COMPANY_DOCUMENTS = gql`
  query GetSeededStockExchangeDocuments(
    $filters: SeededStockExchangeDocumentsFilters
  ) {
    getSeededStockExchangeDocuments(filters: $filters) {
      ... on Error {
        message
        statusCode
      }
      ... on SeededStockExchangeDocumentsList {
        totalDocuments
        pagination {
          total
        }
        documents {
          documentCategory
          documentLink
          documentName
          id
          dabaCategory
          isAddedToDocumentCenter
          language
          company {
            id
            ticker
            legalName
          }
        }
      }
    }
  }
`;

export const GET_SEEDED_STOCK_EXCHANGE_DOCUMENT_BY_ID = gql`
  query GetSeededStockExchangeDocumentById(
    $getSeededStockExchangeDocumentByIdId: ID!
  ) {
    getSeededStockExchangeDocumentById(
      id: $getSeededStockExchangeDocumentByIdId
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on SingleSeededStockExchangeDocument {
        id
        isAddedToDocumentCenter
        documentName
        originalDocumentName
        documentLink
        language
        company {
          id
          legalName
        }
        documentCategory
        dabaCategory
        multiLang {
          originalDocumentName
          language
          documentLink
        }
      }
    }
  }
`;

export const SEARCH_STOCK = gql`
  query Search($queryString: String) {
    search(queryString: $queryString) {
      ... on Error {
        message
        statusCode
      }
      ... on Search {
        stocks {
          lastPrice
          symbol
          company {
            name
            StockExchange {
              code
              id
            }
            id
          }
          baseCurrencyCode
          date
        }
      }
    }
  }
`;

export const GET_STOCK_BY_ID = gql`
  query GetStockExchangeById($getStockExchangeByIdId: ID!) {
    getStockExchangeById(id: $getStockExchangeByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchange {
        id
        stockExchangeName
        logoImage
        language
        description
        baseCurrencyCode
        foundedDate
        fractionalShares
        defaultClosureMessage
        defaultClosureTitle
        tradeable
        autoSettlementConfig {
          autoSettlement
          settlementTime
          daysAfterFill
        }
        orderExpirationLimit
        feeSchedule {
          feeName
          feeType
          fixedFeeAmount
          variableFeeAmount
        }
        code
        website
        CEO
        headquarters
        numberOfListings
        multiLang {
          description
          language
          defaultClosureMessage
          defaultClosureTitle
        }
        totalMarketCapitalization
        countriesOfOperation {
          id
          icon
          name
        }
      }
    }
  }
`;

export const GET_ORDER_BOOK_STATUS = gql`
  query GetOrderBookStatus($input: GetOrderBookStatusInput!) {
    getOrderBookStatus(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAnything {
        data
        message
      }
    }
  }
`;

export const GET_ALL_ORDERS = gql`
  query GetOrders($input: OrderFilter) {
    getOrders(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrders {
        pagination {
          hasNextPage
          hasPrevPage
          limit
          page
          total
          pages
        }
        data {
          company {
            ticker
            stock {
              lastPrice
            }
          }
          direction
          collection {
            name
            bannerUrl
          }
          createdAt
          broker {
            id
            name
          }
          brokerOrderId
          updatedAt
          instrumentKey
          symbol
          hold
          expiresAt
          settlementDate
          limitPrice
          lastFillDate
          staticFee
          floatingFee
          entryFee
          commissionFee
          exitFee
          totalFee
          recurringFee
          feeCharged
          feeCharged
          avgFillPrice
          feeSchedule {
            feeName
            feeType
            fixedFeeAmount
            variableFeeAmount
          }
          type
          notes {
            id
            note
            createdAt
            createdById
            createdBy {
              email
              firstName
              lastName
            }
            updatedAt
            updatedById
            updatedBy {
              email
              firstName
              lastName
            }
          }
          fillQuantity
          history {
            status
            matchOrder
            description
            createdAt
          }
          type
          price
          quantity
          user {
            id
            imageUrl
            firstName
            lastName
          }
          status
          assetCurrency
          userId
          id
        }
      }
    }
  }
`;

export const GET_STOCKS_ORDER_BY_ID = gql`
  query GetOrderById($orderId: ID!) {
    getOrderById(orderId: $orderId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        data {
          assetCurrency
          company {
            ticker
            stock {
              lastPrice
            }
          }
          direction
          fillValue
          fillQuantity
          price
          instrument {
            ... on Bond {
              currentMarketPrice
            }
            ... on MutualFund {
              currentNetAssetValue
            }
          }
          staticFee
          symbol
          floatingFee
          commissionFee
          exitFee
          recurringFee
          instrumentKey
          feeCharged
          entryFee
          totalFee
          broker {
            id
            name
          }
          lastFillDate

          id
          notes {
            id
            note
            createdAt
            createdById
            createdBy {
              email
              firstName
              lastName
            }
            updatedAt
            updatedById
            updatedBy {
              email
              firstName
              lastName
            }
          }
          quantity
          status
          userId
          type
          limitPrice
          price
        }
      }
    }
  }
`;

export const GET_COMPANIES_IN_STOCK_EXCHANGE = gql`
  query AdminGetStockExchangeListedCompanies(
    $stockExchangeCode: STOCK_EXCHANGE_CODE!
    $page: Int
    $limit: Int
    $sortInput: CompanyStockSortInput
    $searchTerm: String
  ) {
    adminGetStockExchangeListedCompanies(
      stockExchangeCode: $stockExchangeCode
      page: $page
      limit: $limit
      sortInput: $sortInput
      searchTerm: $searchTerm
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeCompanyList {
        companies {
          exchangeId
          symbol
          baseCurrencyCode
          description
          percentChange
          volume
          highPrice
          lowPrice
          lastPrice
          closingPrice
          openingPrice
          marketCapitalization
          date
          company {
            id
            name
          }
        }
        hasNextPage
        hasPrevPage
        total
      }
    }
  }
`;

export const GET_ALL_STOCK_CLOSURES = gql`
  query GetStockExchangeClosures($stockExchangeId: ID!) {
    getStockExchangeClosures(stockExchangeId: $stockExchangeId) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeClosureList {
        closures {
          id
          stockExchange {
            id
          }
          date
          title
          isActive
          closureMessage
          language
        }
      }
    }
  }
`;

export const GET_STOCK_CLOSURE = gql`
  query GetStockExchangeClosureById($getStockExchangeClosureByIdId: ID!) {
    getStockExchangeClosureById(id: $getStockExchangeClosureByIdId) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeClosure {
        id
        stockExchange {
          id
          code
          logoImage
        }
        date
        title
        isActive
        closureMessage
        language
        multiLang {
          title
          closureMessage
          language
          parent
        }
      }
    }
  }
`;

export const BROKER_EXPORT = gql`
  query ExportOrdersForBroker($input: OrderFilter!) {
    exportOrdersForBroker(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const GET_ALL_BROKERS = gql`
  query GetBrokers {
    getBrokers {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithBrokers {
        data {
          id
          name
          assetClass
        }
      }
    }
  }
`;

export const GET_STOCK_EXCHANGE_DOCUMENTS = gql`
  query GetStockExchangeDocument($filter: StockExchangeDocumentFilter) {
    getStockExchangeDocument(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocuments {
        pagination {
          hasNextPage
          hasPrevPage
          limit
          total
        }
        data {
          id
          documentName
          documentUrl
          documentCategory
          issuedDate
          restrictLanguage
          language
        }
      }
    }
  }
`;

export const GET_DOCUMENT_CATEGORY = gql`
  query GetStockExchangeDocumentQueries(
    $filter: CompanyDocumentCategoryFilter
  ) {
    getStockExchangeDocumentCategory {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocumentCategories {
        data {
          name
          label
        }
      }
    }
    getCompanyDocumentCategories(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCompanyDocumentCategories {
        data {
          id
          name
        }
      }
    }
    getAllStocksExchange {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchange {
        id
        stockExchangeName
      }
    }
  }
`;

export const GET_STOCK_EXCHANGE_DOCUMENT_QUERIES = gql`
  query GetStockExchangeDocumentQueries {
    getStockExchangeDocumentCategory {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocumentCategories {
        data {
          name
          label
        }
      }
    }
    getAllStocksExchange {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchange {
        id
        stockExchangeName
      }
    }
  }
`;

export const GET_STOCK_EXCHANGE_DOCUMENT_BY_ID = gql`
  query GetOneStockExchangeDocument($documentId: ID!) {
    getOneStockExchangeDocument(documentId: $documentId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocument {
        data {
          id
          documentName
          documentUrl
          documentCategory
          issuedDate
          restrictLanguage
          language
          stockExchange {
            id
          }
          multilang {
            language
            documentName
            documentUrl
            documentUrlSource
          }
          documentUrlSource
        }
      }
    }
  }
`;
