import {useLazyQuery, useQuery} from '@apollo/client';
import {Button, Form, Input, Select, Tooltip} from 'antd';
import {GET_ORDER_BOOK_STATUS} from 'graphql/queries/stocks';
import moment from 'moment';
import {useState} from 'react';
import styles from './orderBook.module.scss';
import {ConfirmModal} from './HandleOrders';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {RetweetOutlined} from '@ant-design/icons';

export const OrderBookModal = () => {
  const [triggerReset, setTriggerReset] = useState(false);
  // const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [form] = Form.useForm();
  const {
    data: getMatchEngine,
    refetch,
    loading: loadingMatchEngine,
  } = useQuery(GET_ORDER_BOOK_STATUS, {
    variables: {
      input: {
        key: 'matchengine',
        reset: false,
      },
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });
  const [handleReset, {loading: loadingReset}] = useLazyQuery(
    GET_ORDER_BOOK_STATUS,
    {
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    },
  );

  const downloadCSV = data => {
    if (!data || !data.length) return;

    // Dynamically get headers from the keys of the first object
    const headers = Object.keys(data[0]);

    // Convert array of objects to CSV format
    const csvRows = data.map(
      row => headers.map(field => `"${row[field] || ''}"`).join(','), // Ensures proper CSV formatting
    );

    // Combine headers and rows
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...csvRows].join('\n');

    // Encode URI and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'orderbook_status.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <h2>Order Book Status</h2>
      <Form
        form={form}
        onFinish={values => {
          handleReset({
            variables: {
              input: {
                key: values?.key,

                ...(values?.key === 'YES' && {reset: true}),
              },
            },
          })
            .then(({data: {getOrderBookStatus}}) => {
              if (
                getOrderBookStatus.statusCode === 400 ||
                getOrderBookStatus.__typename === 'Error'
              ) {
                return toast.error(getOrderBookStatus.message);
              } else {
                toast.success(getOrderBookStatus?.message);
                if (getOrderBookStatus?.data?.length) {
                  downloadCSV(getOrderBookStatus.data);
                }
                form.resetFields();
              }
            })
            .catch(error => toast.error(error.message));
        }}
        layout="vertical">
        <div className={styles.matchEngine}>
          <Form.Item
            label="Match Engine"
            className={styles.resetEngine}
            tooltip={
              getMatchEngine?.getOrderBookStatus?.data?.locked
                ? 'when locked the match engine is possibly active but might have been hooked, check the last updated time'
                : 'When unlocked the match engine is inactive and waiting for the next runtime'
            }>
            <div className={styles.resetEngine}>
              <Button
                disabled={!getMatchEngine?.getOrderBookStatus?.data?.locked}
                onClick={() => setTriggerReset(true)}
                loading={loadingMatchEngine}>
                Reset
              </Button>
              <Tooltip title="Refresh">
                <RetweetOutlined
                  className={styles.refresh}
                  size={500}
                  onClick={() => refetch()}
                />
              </Tooltip>
            </div>
          </Form.Item>
          <div>
            <p>Last Updated Time</p>
            <span>
              {loadingMatchEngine
                ? 'Loading...'
                : getMatchEngine?.getOrderBookStatus?.data?.updatedAt
                ? moment(
                    getMatchEngine.getOrderBookStatus.data.updatedAt,
                  ).format('DD MMM, YYYY HH:mm:ss A')
                : 'No update time available'}
            </span>
          </div>
        </div>
        <div>
          <h2>Check Bulk Order Status</h2>
          <Form.Item
            name="key"
            rules={[{required: true, message: 'This is Required'}]}
            label="Bulk Order Key"
            tooltip="Note: Use the key copied from the bulk order transaction to check the Status">
            <Input placeholder="Insert Key" />
          </Form.Item>
          <Form.Item name="reset" label="Cancel Process">
            <Select allowClear>
              <Select.Option key="YES">Yes</Select.Option>
              <Select.Option key="NO">No</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <SubmitButton disabled={loadingReset} label="Check Progress" />
      </Form>
      <ConfirmModal
        openModal={triggerReset}
        setOpenModal={() => setTriggerReset(false)}
        confirmText="Are you sure you want to trigger a reset for Match Engine"
        title="Confirm Action"
        loading={loadingReset}
        handleOperation={() => {
          handleReset({
            variables: {
              input: {
                key: 'matchengine',
                reset: true,
              },
            },
          })
            .then(({data: {getOrderBookStatus}}) => {
              if (
                getOrderBookStatus.statusCode === 400 ||
                getOrderBookStatus.__typename === 'Error'
              ) {
                return toast.error(getOrderBookStatus.message);
              } else {
                toast.success(getOrderBookStatus?.message);
                refetch();
                setTriggerReset(false);
              }
            })
            .catch(error => toast.error(error.message));
        }}
      />
    </div>
  );
};

// When unlocked the match engine is inactive and waiting for the next runtime
// when locked the match engine is possibly active but might have been hooked, check the last updated time
