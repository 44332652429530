import {gql} from '@apollo/client';

export const CREATE_ASSET_CLASS = gql`
  mutation CreateAssetClass($input: AssetClassInput!) {
    createAssetClass(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClass {
        message
      }
    }
  }
`;

export const DELETE_ASSET_CLASS = gql`
  mutation DeleteAssetClass($deleteAssetClassId: ID!) {
    deleteAssetClass(id: $deleteAssetClassId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClass {
        message
      }
    }
  }
`;

export const TRANSLATE_ASSET_CLASS = gql`
  mutation TranslateAssetClass(
    $translateAssetClassId: ID!
    $language: LanguageEnum!
  ) {
    translateAssetClass(id: $translateAssetClassId, language: $language) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClass {
        message
      }
    }
  }
`;

export const UPDATE_ASSET_CLASS = gql`
  mutation UpdateAssetClass(
    $updateAssetClassId: ID!
    $input: AssetClassInput!
  ) {
    updateAssetClass(id: $updateAssetClassId, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAssetClass {
        message
      }
    }
  }
`;

export const UPDATE_RATING = gql`
  mutation UpdateRating($updateRatingId: ID!, $input: RatingInput!) {
    updateRating(id: $updateRatingId, input: $input) {
      ... on ResponseWithRating {
        message
      }
      ... on Error {
        statusCode
        message
      }
    }
  }
`;

export const CREATE_RATING = gql`
  mutation CreateRating($input: RatingInput!) {
    createRating(input: $input) {
      ... on ResponseWithRating {
        message
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const CREATE_BOND = gql`
  mutation CreateBond($input: BondInput!) {
    createBond(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        message
      }
    }
  }
`;

export const CREATE_MUTUAL_FUND = gql`
  mutation CreateMutualFund($input: MutualFundInput!) {
    createMutualFund(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        message
      }
    }
  }
`;

export const TRANSLATE_BOND = gql`
  mutation TranslateBond($translateBondId: ID!, $language: LanguageEnum!) {
    translateBond(id: $translateBondId, language: $language) {
      ... on Error {
        statusCode
        message
      }
      ... on Response {
        message
      }
    }
  }
`;

export const DELETE_FINANCIAL_INSTRUMENT = gql`
  mutation DeleteFinancialInstrument($deleteFinancialInstrumentId: ID!) {
    deleteFinancialInstrument(id: $deleteFinancialInstrumentId) {
      ... on Error {
        statusCode
        message
      }
      ... on Response {
        message
      }
    }
  }
`;

export const UPDATE_BOND = gql`
  mutation UpdateBond(
    $updateBondId: ID!
    $language: LanguageEnum!
    $input: UpdateBondInput!
  ) {
    updateBond(id: $updateBondId, language: $language, input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithFinancialInstrument {
        message
      }
    }
  }
`;

export const UPDATE_FINANCIAL_INSTRUMENT_STATUS = gql`
  mutation UpdateFinancialInstrumentStatus(
    $updateFinancialInstrumentStatusId: ID
    $status: AssetStatus
  ) {
    updateFinancialInstrumentStatus(
      id: $updateFinancialInstrumentStatusId
      status: $status
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const GROUP_FINANCIAL_INSTRUMENTS = gql`
  mutation GroupFinancialInstruments($input: FinancialInstrumentGroupInput!) {
    groupFinancialInstruments(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        message
      }
    }
  }
`;

export const DELETE_GROUPED_FINANCIAL_INSTRUMENT = gql`
  mutation UngroupFinancialInstruments($input: FinancialInstrumentGroupInput!) {
    ungroupFinancialInstruments(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on Response {
        message
      }
    }
  }
`;

export const UPDATE_MUTUAL_FUND = gql`
  mutation UpdateMutualFund(
    $updateMutualFundId: ID!
    $language: LanguageEnum!
    $input: UpdateMutualFundInput!
  ) {
    updateMutualFund(
      id: $updateMutualFundId
      language: $language
      input: $input
    ) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithFinancialInstrument {
        message
      }
    }
  }
`;

export const TRANSLATE_MUTUAL_FUND = gql`
  mutation TranslateMutualFund(
    $translateMutualFundId: ID!
    $language: LanguageEnum!
  ) {
    translateMutualFund(id: $translateMutualFundId, language: $language) {
      ... on Error {
        statusCode
        message
      }
      ... on Response {
        message
      }
    }
  }
`;

export const UPDATE_INSTRUMENT_VALUATION = gql`
  mutation UpdateMutualFundCompanyValuationHistory(
    $input: mutualFundValuationHistoryInput!
  ) {
    updateMutualFundCompanyValuationHistory(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithMutualFundCompanyValuation {
        message
      }
    }
  }
`;
