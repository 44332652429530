import {useState, useEffect} from 'react';
import styles from './profileDetails.module.scss';
import {Button, UserThumbnail} from '@dabafinance/react-components';
import {
  thumbnails,
  visaTypeEnum,
  citizenStatusEnum,
  annualNetIncomeEnum,
  taxFilingStatusEnum,
  employmentStatusEnum,
  estimatedNetWorthEnum,
  retirementSavingsEnum,
} from 'utils/mock';
import {DatePicker, Form, Input, Select, Spin, Switch} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_USER_BY_ID,
  GET_ALL_COUNTRIES,
  GET_USER_COUNTRY_DETAILS,
} from 'graphql/queries/users';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_PROFILE} from 'graphql/mutations/user';
import {toast} from 'react-toastify';
import {NavTabsEnum, supportedLanguages} from 'utils/constants';
import {GENERATE_USER_REFERRAL_CODE} from 'graphql/mutations/users';
import DBModal from 'components/modal/modal';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';

const ProfileDetails = () => {
  const {id} = useParams();
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [complyCubeClientId, setComplyCubeClientId] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [countryOfResidence, setCountryOfResidence] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [apartment, setApartment] = useState('');
  const [visaType, setVisaType] = useState('');
  const [citizenStatus, setCitizenStatus] = useState('');
  const [teamMember, setTeamMember] = useState('');
  const [ssn, setSsn] = useState('');
  const [netIncome, setNetIncome] = useState('');
  const [taxFilingStatus, setTaxFilingStatus] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [estimatedNetWorth, setEstimatedNetWorth] = useState('');
  const [retirementSavings, setRetirementSavings] = useState('');
  const [dabaSubscription, setDabaSubscription] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [citizenForm] = Form.useForm();
  const {Option} = Select;

  const {data: getCitizenshipDetails, loading: loadingCitizenship} = useQuery(
    GET_USER_COUNTRY_DETAILS,
    {
      variables: {
        getUserByIdId: id,
      },
    },
  );

  const {data, refetch, loading} = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: id,
    },
  });

  const pageOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const {data: getAllCountries, loading: loadingCountries} = useQuery(
    GET_ALL_COUNTRIES,
    {
      fetchPolicy: 'cache-network',
    },
  );
  const [updateProfile, {loading: loadingUpdate}] = useMutation(UPDATE_PROFILE);

  useEffect(() => {
    console.log(getCitizenshipDetails?.getUserById?.user);
    if (getCitizenshipDetails?.getUserById?.user) {
      citizenForm.setFieldsValue({
        countryOfBirth:
          getCitizenshipDetails?.getUserById?.user?.countryOfBirth?.id,
        countryOfCitizenship:
          getCitizenshipDetails?.getUserById?.user?.countryOfCitizenship?.id,
      });
    }
  }, [getCitizenshipDetails?.getUserById?.user]);

  useEffect(() => {
    const taskfiling =
      data?.getUserById?.user?.investorProfile?.riskCapacity?.taxFilingStatus;

    setFirstName(data?.getUserById?.user?.firstName);
    setLastName(data?.getUserById?.user?.lastName);
    setEmail(data?.getUserById?.user?.email);
    setDabaSubscription(data?.getUserById?.user?.dabaSubscriptionAccess);
    setDob(data?.getUserById?.user?.dataOfBirth);
    setTeamMember(data?.getUserById?.user?.internalTeamMember);
    setComplyCubeClientId(data?.getUserById?.complyCubeClientId);
    setPhone(data?.getUserById?.phoneNumber);
    setCountryCode(data?.getUserById?.countryCode);
    setCountryOfResidence(data?.getUserById?.user?.country);
    setState(data?.getUserById?.user?.state);
    setMiddleName(data?.getUserById?.user?.middleName);
    setAddress(data?.getUserById?.user?.homeAddress);
    setApartment(data?.getUserById?.user?.homeAddressNumber);
    setDob(data?.getUserById?.user?.dateOfBirth);
    setVisaType(data?.getUserById?.user?.visaType);
    setSsn(data?.getUserById?.user?.socialSecurityNumber);
    setCitizenStatus(data?.getUserById?.user?.citizenStatus);
    setReferralCode(data?.getUserById?.user?.referralCode);
    setTaxFilingStatus(
      data?.getUserById?.user?.investorProfile?.riskCapacity?.taxFilingStatus,
    );
    if (taskfiling === 'SINGLE' || taskfiling === 'WIDOWED') {
      setNetIncome(
        data?.getUserById?.user?.investorProfile?.riskCapacity
          ?.singleAnnualIncome,
      );
    } else {
      setNetIncome(
        data?.getUserById?.user?.investorProfile?.riskCapacity
          ?.jointAnnualNetIncome,
      );
    }
    setEmploymentStatus(
      data?.getUserById?.user?.investorProfile?.riskCapacity?.employmentStatus,
    );
    setEstimatedNetWorth(
      data?.getUserById?.user?.investorProfile?.riskCapacity?.estimatedNetWorth,
    );
    setRetirementSavings(
      data?.getUserById?.user?.investorProfile?.riskCapacity?.retirementSavings,
    );
  }, [data?.getUserById?.user]);

  const width = 320;

  const handleSavePersonalInfo = () => {
    updateProfile({
      variables: {
        data: {
          id,
          firstName,
          lastName,
          email,
          middleName,
          complyCubeClientId,
          phoneNumber: phone,
          countryCode,
          internalTeamMember: teamMember,
          country: countryOfResidence,
          dateOfBirth: new Date(dob).toISOString(),
          state,
          homeAddressNumber: apartment,
          homeAddress: address,
        },
      },
    }).then(({data: updateProfile}) => {
      if (updateProfile.__typename === 'Error') {
        toast.error(updateProfile.message);
      } else {
        toast.success('Updated Successfully');
      }
    });
  };

  const [adminGenerateReferralCode] = useMutation(GENERATE_USER_REFERRAL_CODE);

  const handleGenerate = () => {
    adminGenerateReferralCode({
      variables: {
        data: {
          userId: id,
          referralCode,
        },
      },
    }).then(({data: {adminGenerateReferralCode}}) => {
      if (adminGenerateReferralCode.__typename === 'Error') {
        toast.error(adminGenerateReferralCode.message);
      } else {
        toast.success(adminGenerateReferralCode.message);
        refetch();
        setOpenModal(false);
        setOpenConfirmationModal(false);
      }
    });
  };

  const handleSaveCitizenship = values => {
    updateProfile({
      variables: {
        data: {
          id,
          countryOfBirth: values?.countryOfBirth,
          countryOfCitizenship: values?.countryOfCitizenship,
          visaType,
          citizenStatus,
        },
      },
    }).then(({data: updateProfile}) => {
      if (updateProfile.__typename === 'Error') {
        toast.error(updateProfile.message);
      } else {
        toast.success('Updated Successfully');
      }
    });
  };

  const handleSaveFinancials = () => {
    /**
     * To be inplmented, awaiting backend functionality
     */
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {data?.getUserById?.user?.imageUrl ? (
          <div className={styles.image}>
            <img src={data?.getUserById?.user?.imageUrl} alt="" />
          </div>
        ) : (
          <div className={styles.dummyAvatar}>
            {data?.getUserById?.user?.firstName?.charAt(0)}
            {data?.getUserById?.user?.lastName?.charAt(0)}
          </div>
        )}
        <h1>
          {dabaSubscription === 'NONE'
            ? 'Free Plan'
            : dabaSubscription?.replace('_', ' ')}
        </h1>
        <div className={styles['user-premium']}>
          {data?.getUserById?.user?.numOfFollowers >= 1 ? (
            <div className={styles.followers}>
              <UserThumbnail
                counts={data?.getUserById?.user?.numOfFollowers}
                label="Followers"
                type="followers"
                thumbnails={thumbnails}
              />
            </div>
          ) : (
            <span className={styles['no-follow']}>No Followers</span>
          )}
          {data?.getUserById?.user?.numOfFollowing >= 1 ? (
            <div className={styles.followers}>
              <UserThumbnail
                counts={data?.getUserById?.user?.numOfFollowing}
                label="Following"
                type="followers"
                thumbnails={thumbnails}
              />
            </div>
          ) : (
            <span className={styles['no-follow']}>No Following</span>
          )}
          <div className={styles.session}>
            <h3>App Session Details</h3>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}>Device</span>
              <span>
                {data?.getUserById?.user?.currentSessionDetails?.currentDevice?.toUpperCase() ??
                  '--'}
              </span>
            </div>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}> Current Version</span>
              <span>
                {data?.getUserById?.user?.currentSessionDetails
                  ?.currentAppVersion ?? '--'}
              </span>
            </div>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}>
                {' '}
                Last Session Date Time
              </span>
              <span className={styles.time}>
                (
                {data?.getUserById?.user?.currentSessionDetails
                  ?.currentTimeZone ?? '--'}
                ){' '}
              </span>
              <span>
                {data?.getUserById?.user?.currentSessionDetails
                  ?.currentDateTime &&
                  moment(
                    data?.getUserById?.user?.currentSessionDetails
                      ?.currentDateTime,
                  ).format('MMM Do YYYY h:mm:ss a')}
              </span>
            </div>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}>Preferred Currency</span>
              <span className={styles.time}>
                {data?.getUserById?.user?.preferredDisplayCurrency ?? '--'}{' '}
              </span>
            </div>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}>App Language</span>
              <span className={styles.time}>
                {supportedLanguages.find(
                  lang =>
                    lang?.value === (data?.getUserById?.user?.language || 'EN'),
                ).label ?? '--'}{' '}
              </span>
            </div>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}>Referred By</span>
              {data?.getUserById?.user?.referredBy?.firstName ? (
                <span
                  onClick={() =>
                    navigate(
                      `/dashboard/user/${data?.getUserById?.user?.referredBy?.id}`,
                    )
                  }
                  className={styles.referredBy}>
                  {data?.getUserById?.user?.referredBy?.firstName ?? '--'}{' '}
                  {data?.getUserById?.user?.referredBy?.lastName ?? '--'}
                </span>
              ) : (
                <span className={styles.time}>--</span>
              )}
            </div>
            <div className={styles['session-details']}>
              <span className={styles['session-name']}>ReferralCode</span>
              <span className={styles.time}>{referralCode} </span>
            </div>
          </div>
          <div>
            <Button
              label="Generate Referral Code"
              type="secondary"
              className={styles.button}
              onClick={() => setOpenModal(true)}
            />
          </div>
          {/* <div>
            <Button
              disabled={true}
              label="Disable Account"
              className={styles['button-red']}
              type="secondary"
            />
          </div> */}
        </div>
      </div>
      <div className={styles.right}>
        <div>
          <h1>Personal Details</h1>
          <Form
            onFinish={handleSavePersonalInfo}
            className={styles['form-style']}
            layout="vertical">
            <Form.Item
              rules={[{required: true}]}
              style={{width}}
              label="First Name"
              initialValue={data?.getUserById?.user?.firstName}
              name="firstName">
              <Input
                placeholder="Jane"
                onChange={e => setFirstName(e.target.value)}
                defaultValue={data?.getUserById?.user?.firstName}
                value={firstName}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true}]}
              style={{width}}
              label="Last Name"
              name="lastName"
              initialValue={data?.getUserById?.user?.lastName}>
              <Input
                placeholder="Steve"
                defaultValue={data?.getUserById?.user?.lastName}
                onChange={e => setLastName(e.target.value)}
                value={lastName}
              />
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Middle Name"
              name="middleName"
              initialValue={data?.getUserById?.user?.middleName}>
              <Input
                placeholder="Helen"
                defaultValue={data?.getUserById?.user?.middleName}
                onChange={e => setMiddleName(e.target.value)}
                value={middleName}
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Please provide a valid email',
                },
                {
                  required: true,
                },
              ]}
              style={{width}}
              label="Email"
              name="email"
              initialValue={data?.getUserById?.user?.email}>
              <Input
                placeholder="alma.lawson@example.com"
                onChange={e => setEmail(e.target.value)}
                defaultValue={data?.getUserById?.user?.email}
                value={email}
              />
            </Form.Item>
            <div className={styles.phone}>
              <Form.Item
                label="Code"
                name="code"
                initialValue={data?.getUserById?.user?.countryCode}>
                <Input
                  placeholder="+234"
                  value={countryCode}
                  style={{width: 70}}
                  onChange={e => setCountryCode(e.target.value)}
                  defaultValue={data?.getUserById?.user?.countryCode}
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    max: 10,
                  },
                ]}
                label="Phone"
                name="phone"
                className={styles.phone}>
                <Input
                  placeholder="+2347038098360"
                  value={phone}
                  style={{width: 250}}
                  onChange={e => setPhone(e.target.value)}
                  defaultValue={data?.getUserById?.user?.phoneNumber}
                />
              </Form.Item>
            </div>
            <Form.Item
              style={{width}}
              label="Date of Birth"
              name="dob"
              initialValue={
                data?.getUserById?.user?.dateOfBirth
                  ? moment(data?.getUserById?.user?.dateOfBirth, 'YYYY-MM-DD')
                  : ''
              }>
              <DatePicker
                value={dob}
                format={'YYYY-MM-DD'}
                onChange={e => setDob(moment(e).format('YYYY-MM-DD'))}
                defaultValue={
                  data?.getUserById?.user?.dateOfBirth
                    ? moment(data?.getUserById?.user?.dateOfBirth, 'YYYY-MM-DD')
                    : ''
                }
                style={{width}}
              />
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Country of Residence"
              name="residence">
              <Select
                style={{width}}
                value={countryOfResidence}
                onChange={e => setCountryOfResidence(e)}
                defaultValue={data?.getUserById?.user?.country}
                showSearch>
                {getAllCountries?.getAllCountries.map(data => (
                  <Option key={data?.name}>
                    <div className={styles['all-data']}>
                      <img src={data?.icon} alt="" />
                      <span>{data?.name}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={data?.getUserById?.user?.state}
              style={{width}}
              label="State"
              name="state">
              <Input
                placeholder="Houston"
                defaultValue={data?.getUserById?.user?.state}
                value={state}
                onChange={e => setState(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              initialValue={data?.getUserById?.user?.homeAddress}
              style={{width}}
              label="Address"
              name="address">
              <Input
                placeholder="3517 W. Gray St. Utica, Pennsylvania 57867"
                defaultValue={data?.getUserById?.user?.homeAddress}
                onChange={e => setAddress(e.target.value)}
                value={address}
              />
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Apartment / Suite"
              name="apartment"
              initialValue={data?.getUserById?.user?.homeAddressNumber}>
              <Input
                placeholder="12"
                defaultValue={data?.getUserById?.user?.homeAddressNumber}
                value={apartment}
                onChange={e => setApartment(e.target.value)}
              />
            </Form.Item>
            <Form.Item name="teamMember" label="Internal Team Member">
              <Switch
                defaultChecked={data?.getUserById?.user?.internalTeamMember}
                onChange={e => setTeamMember(e)}
              />
            </Form.Item>
            <Form.Item n label="User is a Member of Daba Pro">
              <Switch
                disabled
                defaultChecked={
                  data?.getUserById?.user?.proSubscription?.hasProSubscription
                }
                onChange={e => setTeamMember(e)}
              />
            </Form.Item>
            <Form.Item
              initialValue={data?.getUserById?.user?.complyCubeClientId}
              style={{width}}
              label="Comply Cube Client Id"
              name="complyCubeClientId">
              <Input
                defaultValue={data?.getUserById?.user?.complyCubeClientId}
                value={complyCubeClientId}
                onChange={e => setComplyCubeClientId(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              initialValue={data?.getUserById?.user?.accountId}
              style={{width}}
              label="Account ID"
              name="AccountId">
              <Input
                disabled
                defaultValue={data?.getUserById?.user?.accountId}
              />
            </Form.Item>

            <div className={styles['submit-button']}>
              <SubmitButton
                disabled={loadingUpdate || !pageOperations?.update}
                label="Save Changes"
              />
            </div>
          </Form>
        </div>
        <div>
          {loadingCountries && loadingCitizenship ? (
            <div className="small-query-loader">
              <Spin />
              <h1>Loading Countries</h1>
            </div>
          ) : (
            <div className={styles.details}>
              <h1>Citizenship Details</h1>
              <Form
                onFinish={handleSaveCitizenship}
                form={citizenForm}
                className={styles['form-style']}
                layout="vertical">
                <Form.Item
                  style={{width}}
                  label="Country of Birth"
                  name="countryOfBirth">
                  <Select
                    allowClear
                    optionFilterProp="filter"
                    style={{width}}
                    filterOption={true}
                    showSearch>
                    {getAllCountries?.getAllCountries.map(data => (
                      <Option filter={data?.name} key={data?.id}>
                        <div className={styles['all-data']}>
                          <img src={data?.icon} alt="" />
                          <span>{data?.name}</span>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{width}}
                  label="Country of Citizenship"
                  name="countryOfCitizenship">
                  <Select
                    style={{width}}
                    allowClear
                    showSearch
                    filterOption={true}
                    optionFilterProp="filter">
                    {getAllCountries?.getAllCountries.map(data => (
                      <Option filter={data?.name} key={data?.id}>
                        <div className={styles['all-data']}>
                          <img src={data?.icon} alt="" />
                          <span>{data?.name}</span>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item style={{width}} label="U.S Visa Type" name="visa">
                  <Select
                    defaultValue={data?.getUserById?.user?.visaType}
                    value={visaType}
                    onChange={e => setVisaType(e)}>
                    {visaTypeEnum.map(({name, key}) => (
                      <Option key={key}>{name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  style={{width}}
                  label="Citizenship Status"
                  name="citizen-status">
                  <Select
                    defaultValue={data?.getUserById?.user?.citizenStatus}
                    value={citizenStatus}
                    onChange={e => setCitizenStatus(e)}>
                    {citizenStatusEnum.map(({name, key}) => (
                      <Option key={key}>{name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item style={{width}} label="SSN Number" name="ssn">
                  <Input
                    type="password"
                    placeholder="123-45-4564"
                    defaultValue={data?.getUserById?.user?.socialSecurityNumber}
                    value={ssn}
                    disabled
                    readOnly
                  />
                </Form.Item>
                <div className={styles['submit-button']}>
                  <SubmitButton
                    disabled={loadingUpdate || !pageOperations?.update}
                    label="Save Changes"
                  />
                </div>
              </Form>
            </div>
          )}
        </div>
        <div>
          <div className={styles.details}>
            <h1>Financial Details</h1>
            <Form
              onFinish={handleSaveFinancials}
              className={styles['form-style']}
              layout="vertical">
              <Form.Item
                style={{width}}
                label="Annual Net Income"
                name="pre-tax">
                <Select
                  defaultValue={
                    data?.getUserById?.user?.investorProfile?.riskCapacity
                      ?.jointAnnualNetIncome
                  }
                  value={netIncome}
                  onChange={e => setNetIncome(e)}>
                  {annualNetIncomeEnum.map(({name, key}) => (
                    <Option key={key}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{width}}
                label="Tax Filling Status For The Year"
                name="tax">
                <Select
                  defaultValue={
                    data?.getUserById?.user?.investorProfile?.riskCapacity
                      ?.taxFilingStatus
                  }
                  value={taxFilingStatus}
                  onChange={e => setTaxFilingStatus(e)}>
                  {taxFilingStatusEnum.map(({name, key}) => (
                    <Option key={key}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{width}}
                label="Employment Status"
                name="employment">
                <Select
                  defaultValue={
                    data?.getUserById?.user?.investorProfile?.riskCapacity
                      ?.employmentStatus
                  }
                  value={employmentStatus}
                  onChange={e => setEmploymentStatus(e)}>
                  {employmentStatusEnum.map(({name, key}) => (
                    <Option key={key}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{width}}
                label="Estimated net worth"
                name="worth">
                <Select
                  defaultValue={
                    data?.getUserById?.user?.investorProfile?.riskCapacity
                      ?.estimatedNetWorth
                  }
                  value={estimatedNetWorth}
                  onChange={e => setEstimatedNetWorth(e)}>
                  {estimatedNetWorthEnum.map(({name, key}) => (
                    <Option key={key}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{width}}
                label="Are you currently saving for retirement"
                name="save">
                <Select
                  defaultValue={
                    data?.getUserById?.user?.investorProfile?.riskCapacity
                      ?.retirementSavings
                  }
                  value={retirementSavings}
                  onChange={e => setRetirementSavings(e)}>
                  {retirementSavingsEnum.map(({name, key}) => (
                    <Option key={key}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            <SubmitButton
              disabled={true}
              label="Save Changes"
              className={styles.button}
            />
          </div>
        </div>
      </div>

      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        width={500}
        content={
          <div>
            <h2>Generate Custom Referral Code</h2>
            <Form
              layout="vertical"
              onFinish={() => setOpenConfirmationModal(true)}>
              <Form.Item
                rules={[{required: true}]}
                style={{width}}
                label="Referral Code"
                initialValue={data?.getUserById?.user?.referralCode}
                name="referralCode">
                <Input
                  placeholder="Jane000"
                  onChange={e => setReferralCode(e.target.value)}
                  defaultValue={data?.getUserById?.user?.referralCode}
                  value={referralCode}
                />
              </Form.Item>
              <SubmitButton disabled={!pageOperations?.update} label={'Save'} />
            </Form>
          </div>
        }
      />

      <ConfirmModal
        title={'Confirm The Referral Code'}
        confirmText={`Are you sure you want to change ${firstName} REFERRAL CODE?`}
        confirmation
        handleOperation={handleGenerate}
        openModal={openConfirmationModal}
        setOpenModal={setOpenConfirmationModal}
      />
    </div>
  );
};

export default ProfileDetails;
