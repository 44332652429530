import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {BaseKeyStatistics} from '../BaseFinancialInstruments/fundsAllocation/BaseKeyStatistics';
import {useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {Spin} from 'antd';
import {
  currentInstrumentKey,
  financialInstrumentConfig,
} from '../modules/financialInstrumentConfig';

export const BondsKeyStatistics = () => {
  const location = useLocation();
  const {
    mutation,
    handleUpdate,
    route,
    handleDispatch,
    title,
    overviewSelector,
    QUERY,
  } = financialInstrumentConfig[currentInstrumentKey(location.pathname)];

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});
  const navigate = useNavigate();
  const {assetId, lang, id} = useParams();
  const dispatch = useDispatch();
  const data = useSelector(overviewSelector);

  const [updateBond, {loading: loadingUpdate}] = useMutation(mutation);
  const {
    data: updateData,
    refetch,
    loading,
  } = useQuery(QUERY, {
    variables: {
      getFinancialInstrumentId: id,
      language: lang,
    },
    skip: !id,
  });

  const handleSubmit = values => {
    if (id) {
      setOpenUpdateModal(true);
      setUpdatedValues(values);
    } else {
      dispatch(handleDispatch({...values}));
      navigate(route({param: assetId, name: 'documents'}));
    }
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <BaseKeyStatistics
        onSubmit={handleSubmit}
        existingValues={
          id ? updateData?.getFinancialInstrument?.data || {} : data
        }
      />
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        title={`Update ${title}`}
        confirmText={`Are you sure you want update to this ${title}?`}
        handleOperation={() =>
          handleUpdate(
            updateBond,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
          )
        }
      />
    </div>
  );
};
