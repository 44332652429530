import {gql} from '@apollo/client';

export const GET_ALL_TRENDING_COUNTRIES = gql`
  query GetAllTrendingCountries {
    getAllTrendingCountries {
      id
      trendingCountries {
        id
        country {
          id
          name
        }
        companies {
          id
          name
        }
        sequence
        totalAmountReceived
        totalNumOfDeals
      }
      month
      year
    }
  }
`;

export const GET_TRENDING_COUNTRIES = gql`
  query Error {
    getTrendingCountries {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          id
          country {
            id
            name
            icon
          }
          companies {
            id
            name
            logoImgURL
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_COMPANY_BY_COUNTRY_ID = gql`
  query GetCompaniesByCountryId($countryId: ID!) {
    getCompaniesByCountryId(countryId: $countryId) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          id
          country {
            id
            name
          }
          companies {
            id
            name
            logoImgURL
            isRaising
            fundraisingHistory {
              fundingHistory {
                fundingAmount
                round
              }
            }
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        month
        year
        isTrending
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TRENDING_COUNTRIES_BY_MONTH = gql`
  query GetTrendingCountriesByMonth($month: String!, $year: String) {
    getTrendingCountriesByMonth(month: $month, year: $year) {
      ... on Error {
        message
        statusCode
      }
      ... on TrendingCountriesType {
        id
        trendingCountries {
          id
          country {
            id
            name
          }
          companies {
            id
            name
            logoImgURL
          }
          sequence
          totalAmountReceived
          totalNumOfDeals
        }
        isTrending
        month
        year
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_CITIES = gql`
  query GetAllCities($filter: getCitiesFilterInput) {
    getAllCities(filter: $filter) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithCities {
        data {
          city
        }
      }
    }
  }
`;

export const countryISOs = [
  {value: 'AF', label: 'AF - Afghanistan'},
  {value: 'AX', label: 'AX - Åland Islands'},
  {value: 'AL', label: 'AL - Albania'},
  {value: 'DZ', label: 'DZ - Algeria'},
  {value: 'AS', label: 'AS - American Samoa'},
  {value: 'AD', label: 'AD - Andorra'},
  {value: 'AO', label: 'AO - Angola'},
  {value: 'AI', label: 'AI - Anguilla'},
  {value: 'AQ', label: 'AQ - Antarctica'},
  {value: 'AG', label: 'AG - Antigua and Barbuda'},
  {value: 'AR', label: 'AR - Argentina'},
  {value: 'AM', label: 'AM - Armenia'},
  {value: 'AW', label: 'AW - Aruba'},
  {value: 'AU', label: 'AU - Australia'},
  {value: 'AT', label: 'AT - Austria'},
  {value: 'AZ', label: 'AZ - Azerbaijan'},
  {value: 'BS', label: 'BS - Bahamas'},
  {value: 'BH', label: 'BH - Bahrain'},
  {value: 'BD', label: 'BD - Bangladesh'},
  {value: 'BB', label: 'BB - Barbados'},
  {value: 'BY', label: 'BY - Belarus'},
  {value: 'BE', label: 'BE - Belgium'},
  {value: 'BZ', label: 'BZ - Belize'},
  {value: 'BJ', label: 'BJ - Benin'},
  {value: 'BM', label: 'BM - Bermuda'},
  {value: 'BT', label: 'BT - Bhutan'},
  {value: 'BO', label: 'BO - Bolivia'},
  {value: 'BQ', label: 'BQ - Bonaire, Sint Eustatius and Saba'},
  {value: 'BA', label: 'BA - Bosnia and Herzegovina'},
  {value: 'BW', label: 'BW - Botswana'},
  {value: 'BV', label: 'BV - Bouvet Island'},
  {value: 'BR', label: 'BR - Brazil'},
  {value: 'IO', label: 'IO - British Indian Ocean Territory'},
  {value: 'BN', label: 'BN - Brunei Darussalam'},
  {value: 'BG', label: 'BG - Bulgaria'},
  {value: 'BF', label: 'BF - Burkina Faso'},
  {value: 'BI', label: 'BI - Burundi'},
  {value: 'CV', label: 'CV - Cabo Verde'},
  {value: 'KH', label: 'KH - Cambodia'},
  {value: 'CM', label: 'CM - Cameroon'},
  {value: 'CA', label: 'CA - Canada'},
  {value: 'KY', label: 'KY - Cayman Islands'},
  {value: 'CF', label: 'CF - Central African Republic'},
  {value: 'TD', label: 'TD - Chad'},
  {value: 'CL', label: 'CL - Chile'},
  {value: 'CN', label: 'CN - China'},
  {value: 'CX', label: 'CX - Christmas Island'},
  {value: 'CC', label: 'CC - Cocos (Keeling) Islands'},
  {value: 'CO', label: 'CO - Colombia'},
  {value: 'KM', label: 'KM - Comoros'},
  {value: 'CG', label: 'CG - Congo'},
  {value: 'CD', label: 'CD - Congo, Democratic Republic of the'},
  {value: 'CK', label: 'CK - Cook Islands'},
  {value: 'CR', label: 'CR - Costa Rica'},
  {value: 'CI', label: "CI - Côte d'Ivoire"},
  {value: 'HR', label: 'HR - Croatia'},
  {value: 'CU', label: 'CU - Cuba'},
  {value: 'CW', label: 'CW - Curaçao'},
  {value: 'CY', label: 'CY - Cyprus'},
  {value: 'CZ', label: 'CZ - Czechia'},
  {value: 'DK', label: 'DK - Denmark'},
  {value: 'DJ', label: 'DJ - Djibouti'},
  {value: 'DM', label: 'DM - Dominica'},
  {value: 'DO', label: 'DO - Dominican Republic'},
  {value: 'EC', label: 'EC - Ecuador'},
  {value: 'EG', label: 'EG - Egypt'},
  {value: 'SV', label: 'SV - El Salvador'},
  {value: 'GQ', label: 'GQ - Equatorial Guinea'},
  {value: 'ER', label: 'ER - Eritrea'},
  {value: 'EE', label: 'EE - Estonia'},
  {value: 'SZ', label: 'SZ - Eswatini'},
  {value: 'ET', label: 'ET - Ethiopia'},
  {value: 'FK', label: 'FK - Falkland Islands (Malvinas)'},
  {value: 'FO', label: 'FO - Faroe Islands'},
  {value: 'FJ', label: 'FJ - Fiji'},
  {value: 'FI', label: 'FI - Finland'},
  {value: 'FR', label: 'FR - France'},
  {value: 'GF', label: 'GF - French Guiana'},
  {value: 'PF', label: 'PF - French Polynesia'},
  {value: 'TF', label: 'TF - French Southern Territories'},
  {value: 'GA', label: 'GA - Gabon'},
  {value: 'GM', label: 'GM - Gambia'},
  {value: 'GE', label: 'GE - Georgia'},
  {value: 'DE', label: 'DE - Germany'},
  {value: 'GH', label: 'GH - Ghana'},
  {value: 'GI', label: 'GI - Gibraltar'},
  {value: 'GR', label: 'GR - Greece'},
  {value: 'GL', label: 'GL - Greenland'},
  {value: 'GD', label: 'GD - Grenada'},
  {value: 'GP', label: 'GP - Guadeloupe'},
  {value: 'GU', label: 'GU - Guam'},
  {value: 'GT', label: 'GT - Guatemala'},
  {value: 'GG', label: 'GG - Guernsey'},
  {value: 'GN', label: 'GN - Guinea'},
  {value: 'GW', label: 'GW - Guinea-Bissau'},
  {value: 'GY', label: 'GY - Guyana'},
  {value: 'HT', label: 'HT - Haiti'},
  {value: 'HM', label: 'HM - Heard Island and McDonald Islands'},
  {value: 'VA', label: 'VA - Holy See'},
  {value: 'HN', label: 'HN - Honduras'},
  {value: 'HK', label: 'HK - Hong Kong'},
  {value: 'HU', label: 'HU - Hungary'},
  {value: 'IS', label: 'IS - Iceland'},
  {value: 'IN', label: 'IN - India'},
  {value: 'ID', label: 'ID - Indonesia'},
  {value: 'IR', label: 'IR - Iran (Islamic Republic of)'},
  {value: 'IQ', label: 'IQ - Iraq'},
  {value: 'IE', label: 'IE - Ireland'},
  {value: 'IM', label: 'IM - Isle of Man'},
  {value: 'IL', label: 'IL - Israel'},
  {value: 'IT', label: 'IT - Italy'},
  {value: 'JM', label: 'JM - Jamaica'},
  {value: 'JP', label: 'JP - Japan'},
  {value: 'JE', label: 'JE - Jersey'},
  {value: 'JO', label: 'JO - Jordan'},
  {value: 'KZ', label: 'KZ - Kazakhstan'},
  {value: 'KE', label: 'KE - Kenya'},
  {value: 'KI', label: 'KI - Kiribati'},
  {value: 'KP', label: "KP - Korea (Democratic People's Republic of)"},
  {value: 'KR', label: 'KR - Korea (Republic of)'},
  {value: 'KW', label: 'KW - Kuwait'},
  {value: 'KG', label: 'KG - Kyrgyzstan'},
  {value: 'LA', label: "LA - Lao People's Democratic Republic"},
  {value: 'LV', label: 'LV - Latvia'},
  {value: 'LB', label: 'LB - Lebanon'},
  {value: 'LS', label: 'LS - Lesotho'},
  {value: 'LR', label: 'LR - Liberia'},
  {value: 'LY', label: 'LY - Libya'},
  {value: 'LI', label: 'LI - Liechtenstein'},
  {value: 'LT', label: 'LT - Lithuania'},
  {value: 'LU', label: 'LU - Luxembourg'},
  {value: 'MO', label: 'MO - Macao'},
  {value: 'MG', label: 'MG - Madagascar'},
  {value: 'MW', label: 'MW - Malawi'},
  {value: 'MY', label: 'MY - Malaysia'},
  {value: 'MV', label: 'MV - Maldives'},
  {value: 'ML', label: 'ML - Mali'},
  {value: 'MT', label: 'MT - Malta'},
  {value: 'MH', label: 'MH - Marshall Islands'},
  {value: 'MQ', label: 'MQ - Martinique'},
  {value: 'MR', label: 'MR - Mauritania'},
  {value: 'MU', label: 'MU - Mauritius'},
  {value: 'YT', label: 'YT - Mayotte'},
  {value: 'MX', label: 'MX - Mexico'},
  {value: 'FM', label: 'FM - Micronesia (Federated States of)'},
  {value: 'MD', label: 'MD - Moldova (Republic of)'},
  {value: 'MC', label: 'MC - Monaco'},
  {value: 'MN', label: 'MN - Mongolia'},
  {value: 'ME', label: 'ME - Montenegro'},
  {value: 'MS', label: 'MS - Montserrat'},
  {value: 'MA', label: 'MA - Morocco'},
  {value: 'MZ', label: 'MZ - Mozambique'},
  {value: 'MM', label: 'MM - Myanmar'},
  {value: 'NA', label: 'NA - Namibia'},
  {value: 'NR', label: 'NR - Nauru'},
  {value: 'NP', label: 'NP - Nepal'},
  {value: 'NL', label: 'NL - Netherlands'},
  {value: 'NC', label: 'NC - New Caledonia'},
  {value: 'NZ', label: 'NZ - New Zealand'},
  {value: 'NI', label: 'NI - Nicaragua'},
  {value: 'NE', label: 'NE - Niger'},
  {value: 'NG', label: 'NG - Nigeria'},
  {value: 'NU', label: 'NU - Niue'},
  {value: 'NF', label: 'NF - Norfolk Island'},
  {value: 'MK', label: 'MK - North Macedonia'},
  {value: 'MP', label: 'MP - Northern Mariana Islands'},
  {value: 'NO', label: 'NO - Norway'},
  {value: 'OM', label: 'OM - Oman'},
  {value: 'PK', label: 'PK - Pakistan'},
  {value: 'PW', label: 'PW - Palau'},
  {value: 'PS', label: 'PS - Palestine, State of'},
  {value: 'PA', label: 'PA - Panama'},
  {value: 'PG', label: 'PG - Papua New Guinea'},
  {value: 'PY', label: 'PY - Paraguay'},
  {value: 'PE', label: 'PE - Peru'},
  {value: 'PH', label: 'PH - Philippines'},
  {value: 'PN', label: 'PN - Pitcairn'},
  {value: 'PL', label: 'PL - Poland'},
  {value: 'PT', label: 'PT - Portugal'},
  {value: 'PR', label: 'PR - Puerto Rico'},
  {value: 'QA', label: 'QA - Qatar'},
  {value: 'RO', label: 'RO - Romania'},
  {value: 'RU', label: 'RU - Russian Federation'},
  {value: 'RW', label: 'RW - Rwanda'},
  {value: 'RE', label: 'RE - Réunion'},
  {value: 'BL', label: 'BL - Saint Barthélemy'},
  {value: 'SH', label: 'SH - Saint Helena, Ascension and Tristan da Cunha'},
  {value: 'KN', label: 'KN - Saint Kitts and Nevis'},
  {value: 'LC', label: 'LC - Saint Lucia'},
  {value: 'MF', label: 'MF - Saint Martin (French part)'},
  {value: 'PM', label: 'PM - Saint Pierre and Miquelon'},
  {value: 'VC', label: 'VC - Saint Vincent and the Grenadines'},
  {value: 'WS', label: 'WS - Samoa'},
  {value: 'SM', label: 'SM - San Marino'},
  {value: 'ST', label: 'ST - Sao Tome and Principe'},
  {value: 'SA', label: 'SA - Saudi Arabia'},
  {value: 'SN', label: 'SN - Senegal'},
  {value: 'RS', label: 'RS - Serbia'},
  {value: 'SC', label: 'SC - Seychelles'},
  {value: 'SL', label: 'SL - Sierra Leone'},
  {value: 'SG', label: 'SG - Singapore'},
  {value: 'SX', label: 'SX - Sint Maarten (Dutch part)'},
  {value: 'SK', label: 'SK - Slovakia'},
  {value: 'SI', label: 'SI - Slovenia'},
  {value: 'SB', label: 'SB - Solomon Islands'},
  {value: 'SO', label: 'SO - Somalia'},
  {value: 'ZA', label: 'ZA - South Africa'},
  {value: 'GS', label: 'GS - South Georgia and the South Sandwich Islands'},
  {value: 'SS', label: 'SS - South Sudan'},
  {value: 'ES', label: 'ES - Spain'},
  {value: 'LK', label: 'LK - Sri Lanka'},
  {value: 'SD', label: 'SD - Sudan'},
  {value: 'SR', label: 'SR - Suriname'},
  {value: 'SJ', label: 'SJ - Svalbard and Jan Mayen'},
  {value: 'SE', label: 'SE - Sweden'},
  {value: 'CH', label: 'CH - Switzerland'},
  {value: 'SY', label: 'SY - Syrian Arab Republic'},
  {value: 'TW', label: 'TW - Taiwan, Province of China'},
  {value: 'TJ', label: 'TJ - Tajikistan'},
  {value: 'TZ', label: 'TZ - Tanzania, United Republic of'},
  {value: 'TH', label: 'TH - Thailand'},
  {value: 'TL', label: 'TL - Timor-Leste'},
  {value: 'TG', label: 'TG - Togo'},
  {value: 'TK', label: 'TK - Tokelau'},
  {value: 'TO', label: 'TO - Tonga'},
  {value: 'TT', label: 'TT - Trinidad and Tobago'},
  {value: 'TN', label: 'TN - Tunisia'},
  {value: 'TR', label: 'TR - Turkey'},
  {value: 'TM', label: 'TM - Turkmenistan'},
  {value: 'TC', label: 'TC - Turks and Caicos Islands'},
  {value: 'TV', label: 'TV - Tuvalu'},
  {value: 'UG', label: 'UG - Uganda'},
  {value: 'UA', label: 'UA - Ukraine'},
  {value: 'AE', label: 'AE - United Arab Emirates'},
  {
    value: 'GB',
    label: 'GB - United Kingdom of Great Britain and Northern Ireland',
  },
  {value: 'US', label: 'US - United States of America'},
  {value: 'UM', label: 'UM - United States Minor Outlying Islands'},
  {value: 'UY', label: 'UY - Uruguay'},
  {value: 'UZ', label: 'UZ - Uzbekistan'},
  {value: 'VU', label: 'VU - Vanuatu'},
  {value: 'VE', label: 'VE - Venezuela (Bolivarian Republic of)'},
  {value: 'VN', label: 'VN - Viet Nam'},
  {value: 'VG', label: 'VG - Virgin Islands (British)'},
  {value: 'VI', label: 'VI - Virgin Islands (U.S.)'},
  {value: 'WF', label: 'WF - Wallis and Futuna'},
  {value: 'EH', label: 'EH - Western Sahara'},
  {value: 'YE', label: 'YE - Yemen'},
  {value: 'ZM', label: 'ZM - Zambia'},
  {value: 'ZW', label: 'ZW - Zimbabwe'},
];

export const GET_ALL_COUNTRY_CODES = gql`
  query Countries {
    getAllCountryCodes {
      ... on AllCountryCodes {
        countries {
          id
          name
          code
        }
      }
    }
  }
`;
