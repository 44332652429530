export const securityTypeOptions = [
  {label: 'Equity', value: 'EQUITY'},
  {label: 'Debt', value: 'DEBT'},
  {label: 'Hybrid', value: 'HYBRID'},
  {label: 'Fund', value: 'FUND'},
];

export const companyStageData = [
  {value: 'PRE_SEED', label: 'Pre Seed'},
  {value: 'SEED', label: 'Seed'},
  {value: 'PRE_SERIES_A', label: 'Pre Series A'},
  {value: 'SERIES_A', label: 'Series A'},
  {value: 'PRE_SERIES_B', label: 'Pre Series B'},
  {value: 'SERIES_B', label: 'Series B'},
  {value: 'SERIES_C', label: 'Series_C'},
  {value: 'SERIES_D', label: 'Series D'},
  {value: 'SERIES_E', label: 'Series E'},
  {value: 'ACQUIRED', label: 'Acquired'},
  {value: 'IPO', label: 'Ipo'},
];

export const currencyData = [
  {name: 'Euro', symbol: '€', currencyCode: 'EUR', key: 0},
  {name: 'West African CFA franc', symbol: 'CFA', currencyCode: 'XOF', key: 1},
  {
    name: 'Central African CFA franc',
    symbol: 'CFA',
    currencyCode: 'XAF',
    key: 2,
  },
  {name: 'Canadian Dollar', symbol: '$', currencyCode: 'CAD', key: 3},
  {name: 'Nigerian Naira', symbol: '₦', currencyCode: 'NGN', key: 4},
  {name: 'United States Dollar', symbol: '$', currencyCode: 'USD', key: 5},
];

export const currencyMatch = {
  'EUR': {
    name: 'Euro',
    symbol: '€',
    currencyCode: 'EUR',
    key: 0,
  },
  'XOF': {
    name: 'West African CFA franc',
    symbol: 'CFA',
    currencyCode: 'XOF',
    key: 1,
  },
  'XAF': {
    name: 'Central African CFA franc',
    symbol: 'CFA',
    currencyCode: 'XAF',
    key: 2,
  },
  'CAD': {
    name: 'Canadian Dollar',
    symbol: '$',
    currencyCode: 'CAD',
    key: 3,
  },
  'NGN': {
    name: 'Nigerian Naira',
    symbol: '₦',
    currencyCode: 'NGN',
    key: 4,
  },
  'USD': {
    name: 'United States Dollar',
    symbol: '$',
    currencyCode: 'USD',
    key: 5,
  },
};

export const offeringTypeData = [
  {value: 'REG_D_506B', label: 'REG_D_506B'},
  {value: 'REG_D_506C', label: 'REG_D_506C'},
  // { value: 'REG_A', label: 'REG_A' },
  // { value: 'REG_S', label: 'REG_S' },
  // { value: 'REG_CF', label: 'REG_CF' },
];

export const portfolioStrategyData = [
  {
    key: 'P1',
    name: 'P1 - Pure Conservative',
  },
  {
    key: 'P2',
    name: 'P2 - Conservative Titled Moderate',
  },
  {
    key: 'P3',
    name: 'P3 - Moderate Titled Conservative',
  },
  {
    key: 'P4',
    name: 'P4 - Moderate',
  },
  {
    key: 'P5',
    name: 'P5 - Moderate Titled Aggressive',
  },
  {
    key: 'P6',
    name: 'P6 - Aggressive',
  },
  {
    key: 'P7',
    name: 'P7 - Very Aggressive',
  },
];

export const initialDisclaimer =
  "Investing in startups is risky. Capital at risk. We are 'testing the waters' to gauge investor interest. No money or other consideration is being solicited. If sent, it will not be accepted. No offer to buy securities will be accepted. Any indication of interest involves no obligation or commitment of any kind. Expressing interest does not guarantee you will be given a spot. All applicable laws and regulations will be followed";
