import styles from './percentageCard.module.scss';
import campaignImage from 'images/campaign.svg';
import wiflix from 'images/wiflix.png';
import editIcon from 'images/edit.svg';
import deleteIcon from 'images/delete.svg';
import userIcon from 'images/user.svg';
import checkIcon from 'images/check-icon.svg';
import dotsIcon from 'images/dots.svg';
import eyesIcon from 'images/eye-opened.svg';
import {UserThumbnail} from '@dabafinance/react-components';
import {thumbnails} from 'utils/mock';
import {Dropdown, Menu, Progress, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import {ArrowUpOutlined} from '@ant-design/icons';

const PercentageCard = ({
  amount,
  label,
  perecentage,
  active,
  chart,
  postive,
  handleClick,
}) => {
  return (
    <div
      onClick={handleClick}
      className={active ? styles['active-card'] : styles['card-container']}>
      <div className={styles.top}>
        <div className={styles['top-control']}>
          <h1>{amount ? new Intl.NumberFormat().format(amount) : '- -'}</h1>
          {chart && (
            <Tooltip placement="topLeft" title="View Chart">
              <ArrowUpOutlined
                onClick={handleClick}
                style={{fontSize: '30px'}}
                className={styles['chart-icon']}
              />
            </Tooltip>
          )}
        </div>
        <span>{label}</span>
      </div>
      {perecentage && (
        <div className={styles.bottom}>
          <h2>Last month</h2>
          <span className={postive ? styles.positive : styles.negative}>
            {perecentage}
          </span>
        </div>
      )}
    </div>
  );
};

export const CampaignCard = ({amount, label, value, footerTitle}) => {
  return (
    <div className={styles['card-container']}>
      <div className={styles.top}>
        <h1>{new Intl.NumberFormat().format(amount)}</h1>
        <span>{label}</span>
      </div>
      <div className={styles.footer}>
        <h2>{footerTitle}</h2>
        <span className={styles.amount}>{value}</span>
      </div>
    </div>
  );
};

export const ActiveCampaignCard = ({draft, live, test}) => {
  const navigate = useNavigate();

  const draftMenu = (
    <Menu>
      <Menu.Item key="0">
        <div className={styles['menu-items']}>
          <img src={eyesIcon} alt="" />
          <span>View Campaign</span>
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div className={styles['menu-items']}>
          <img src={checkIcon} alt="" />
          <span>Go Live</span>
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <div className={styles['menu-items']}>
          <img src={deleteIcon} alt="" />
          <span>Delete Campaign</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const liveMenu = (
    <Menu>
      <Menu.Item key="0">
        <div className={styles['menu-items']}>
          <img src={userIcon} alt="" />
          <span>View Profile</span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={() => navigate('/dashboard/campaign/edit')} key="1">
        <div className={styles['menu-items']}>
          <img src={eyesIcon} alt="" />
          <span>Preview Campaign</span>
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <div className={styles['menu-items']}>
          <img src={editIcon} alt="" />
          <span>Edit Campaign</span>
        </div>
      </Menu.Item>
      <Menu.Item key="3">
        <div className={styles['menu-items']}>
          <img src={deleteIcon} alt="" />
          <span>Delete Campaign</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles['ac-container']}>
      <div className={styles['ac-image']}>
        <img src={campaignImage} alt="" />
      </div>
      <div className={styles['ac-body']}>
        <div className={styles['ac-title']}>
          <div>
            <img className={styles['ac-logo']} src={wiflix} alt="" />
          </div>
          <div className={styles['ac-icons']}>
            <Dropdown
              overlayStyle={{width: 200}}
              trigger="click"
              overlay={draft ? draftMenu : liveMenu}>
              <img src={dotsIcon} alt="" />
            </Dropdown>
          </div>
        </div>
        <div className={styles['bottom-grid']}>
          <div className={styles['ac-header']}>
            <h1>Wi-Flix</h1>
            <div className={styles['ac-series']}>
              <span className={styles['ac-f-series']}>REG CF</span>
              <span className={styles['ac-s-series']}>Series A</span>
            </div>
          </div>
          <div className={styles.description}>
            <span>
              Lorem ipsum dolor sit amet, amet, consectetur adipiscing
              elconsectetur.
            </span>
          </div>
          {live && (
            <div className={styles.thumbnails}>
              <UserThumbnail
                counts={1200}
                label="Investors"
                type="followers"
                thumbnails={thumbnails}
              />
            </div>
          )}
          <div className={styles.capital}>
            <h1>$10,000,000.00</h1>
            <span>{draft && 'Goal'}</span>
            <span>{live && 'raised'}</span>
            <span>{test && 'interested'}</span>
          </div>
          {live && (
            <div>
              <Progress strokeColor={'#8D6CFA'} percent={60} size="medium" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PercentageCard;
