/* eslint-disable no-debugger */
import styles from '../walletDetails.module.scss';
import {Switch, Form, InputNumber, Tooltip, Collapse} from 'antd';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {ProviderFeeSchedule} from './ProviderFeeSchedule';

const DepositWalletFields = ({
  selectedCurrency,
  currencyDetails = {},
  form,
  currencyLimits,
}) => {
  const [enableProvider, setEnableProvider] = useState();

  const [enableDeposit, setEnableDeposit] = useState(false);
  const [minDepositAmount, setMinDepositAmount] = useState(0);
  const [maxDepositAmount, setMaxDepositAmount] = useState(0);
  const [maxNumOfDepositsPerDay, setMaxNumOfDepositsPerDay] = useState(0);
  const [dailyLimit, setDailyLimit] = useState(0);
  const [weeklyLimit, setWeeklyLimit] = useState(0);
  const [monthlyLimit, setMonthlyLimit] = useState(0);
  const {provider} = useParams();

  useEffect(() => {
    form.resetFields();
  }, [selectedCurrency]);
  const width = 190;

  useEffect(() => {
    setEnableDeposit(currencyDetails[selectedCurrency]?.deposit?.enabled);
    setMinDepositAmount(
      currencyDetails[selectedCurrency]?.deposit?.minDepositAmount,
    );
    setMaxDepositAmount(
      currencyDetails[selectedCurrency]?.deposit?.maxDepositAmount,
    );
    setMaxNumOfDepositsPerDay(
      currencyDetails[selectedCurrency]?.deposit?.maxNumberOfDepositPerDay,
    );
    setDailyLimit(currencyDetails[selectedCurrency]?.deposit?.limit?.daily);
    setWeeklyLimit(currencyDetails[selectedCurrency]?.deposit?.limit?.weekly);
    setMonthlyLimit(currencyDetails[selectedCurrency]?.deposit?.limit?.monthly);
  }, [currencyDetails, selectedCurrency]);

  const handleMaxDepositAmountChange = value => {
    setMaxDepositAmount(value);
    updateDailyLimit(value, maxNumOfDepositsPerDay);
  };

  const handleMaxNumOfDepositsChange = value => {
    setMaxNumOfDepositsPerDay(value);
    updateDailyLimit(maxDepositAmount, value);
  };

  const updateDailyLimit = (maxDepositAmount, maxNumOfDepositsPerDay) => {
    // Update dailyLimit based on maxDepositAmount and maxNumOfDepositsPerDay
    const newDailyLimit = maxDepositAmount * maxNumOfDepositsPerDay;
    setDailyLimit(newDailyLimit);

    // Update weeklyLimit and monthlyLimit accordingly
    form.setFieldsValue({
      dailyLimit: newDailyLimit,
      weeklyLimit: newDailyLimit * 7,
      monthlyLimit: newDailyLimit * 30,
    });
    setWeeklyLimit(newDailyLimit * 7);
    setMonthlyLimit(newDailyLimit * 30); // Assuming an average of 30 days in a month
  };

  return (
    <div className={styles.section}>
      <Form.Item
        name="enableProvider"
        label={`Enable ${provider ? 'Provider' : 'Currency'}`}>
        <Switch
          value={enableProvider}
          onChange={e => setEnableProvider(e)}
          defaultChecked={
            currencyDetails[selectedCurrency]?.deposit?.enabled || false
          }
        />
      </Form.Item>
      <h1>Deposit Method</h1>
      <div className={styles.sectionType}>
        <p>Allow Deposit in {`${selectedCurrency}`}</p>
        <Form.Item
          initialValue={currencyDetails[selectedCurrency]?.deposit?.enabled}
          name="allowedDeposit">
          <Switch
            value={enableDeposit}
            onChange={e => setEnableDeposit(e)}
            defaultChecked={currencyDetails[selectedCurrency]?.deposit?.enabled}
          />
        </Form.Item>
      </div>

      <div className={styles.breakdown}>
        <Form.Item
          initialValue={
            currencyDetails[selectedCurrency]?.deposit?.minDepositAmount
          }
          rules={[{required: true, message: 'This field is required'}]}
          help={
            minDepositAmount > maxDepositAmount && (
              <small style={{color: 'grey'}}>
                This must be LESS than the Maximum Deposit Amount
              </small>
            )
          }
          name="minDepositAmount"
          label="Minimum Deposit Amount">
          <InputNumber
            value={minDepositAmount}
            onChange={e => setMinDepositAmount(e)}
            style={{width}}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            defaultValue={
              currencyDetails[selectedCurrency]?.deposit?.minDepositAmount
            }
          />
        </Form.Item>
        <Form.Item
          initialValue={
            currencyDetails[selectedCurrency]?.deposit?.maxDepositAmount
          }
          help={
            maxDepositAmount < minDepositAmount && (
              <small style={{color: 'grey'}}>
                This must be Greater than the Minimum Deposit AMount
              </small>
            )
          }
          name="maxDepositAmount"
          rules={[{required: true, message: 'This field is required'}]}
          label="Maximum Deposit Amount">
          <InputNumber
            value={maxDepositAmount}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            defaultValue={
              currencyDetails[selectedCurrency]?.deposit?.maxDepositAmount
            }
            onChange={handleMaxDepositAmountChange}
            style={{width}}
          />
        </Form.Item>
        <Form.Item
          initialValue={
            currencyDetails[selectedCurrency]?.deposit?.maxNumberOfDepositPerDay
          }
          name="depositADay"
          rules={[{required: true, message: 'This field is required'}]}
          label="Maximum Number of Deposits in a Day">
          <InputNumber
            defaultValue={
              currencyDetails[selectedCurrency]?.deposit
                ?.maxNumberOfDepositPerDay
            }
            value={maxNumOfDepositsPerDay}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={handleMaxNumOfDepositsChange}
            style={{width}}
          />
        </Form.Item>
      </div>

      <div>
        <h2>Limits</h2>
        <div className={styles.breakdown}>
          <Form.Item
            initialValue={
              currencyDetails[selectedCurrency]?.deposit?.limit?.daily
            }
            name="dailyLimit"
            rules={[{required: true, message: 'This field is required'}]}
            help={
              (dailyLimit >= weeklyLimit ||
                dailyLimit >= monthlyLimit ||
                minDepositAmount >= dailyLimit ||
                maxDepositAmount * maxNumOfDepositsPerDay > dailyLimit) && (
                <>
                  <small style={{color: 'grey', display: 'block'}}>
                    1. Daily Limit Cannot be Greater than Weekly and/or Monthly
                    Limit
                  </small>
                  <small style={{color: 'grey', display: 'block'}}>
                    2. It also cannot be Less than Minimum Deposit Amount
                  </small>
                  <small style={{color: 'grey', display: 'block'}}>
                    3. It cannot be less than the Max-Deposit by Total No of
                    deposits
                  </small>
                </>
              )
            }
            label={
              <span>
                Daily Limit{' '}
                <Tooltip
                  title={`Platform Daily Limit: ${
                    currencyLimits?.daily?.toLocaleString() ?? 'Not Created'
                  }`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <InputNumber
              value={dailyLimit}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              defaultValue={
                currencyDetails[selectedCurrency]?.deposit?.limit?.daily
              }
              onChange={e => setDailyLimit(e)}
              style={{width}}
            />
          </Form.Item>
          <Form.Item
            initialValue={
              currencyDetails[selectedCurrency]?.deposit?.limit?.weekly
            }
            name="weeklyLimit"
            help={
              (weeklyLimit <= dailyLimit || weeklyLimit >= monthlyLimit) && (
                <small style={{color: 'grey'}}>
                  Weekly Limit Cannot be Greater than Monthly Limit or Less than
                  Daily Limit
                </small>
              )
            }
            rules={[{required: true, message: 'This field is required'}]}
            label={
              <span>
                Weekly Limit{' '}
                <Tooltip
                  title={`Platform Weekly Limit: ${
                    currencyLimits?.weekly?.toLocaleString() ?? 'Not Created'
                  }`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <InputNumber
              value={weeklyLimit}
              defaultValue={
                currencyDetails[selectedCurrency]?.deposit?.limit?.weekly
              }
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={e => setWeeklyLimit(e)}
              style={{width}}
            />
          </Form.Item>
          <Form.Item
            initialValue={
              currencyDetails[selectedCurrency]?.deposit?.limit?.monthly
            }
            help={
              (monthlyLimit <= dailyLimit || monthlyLimit <= weeklyLimit) && (
                <small style={{color: 'grey'}}>
                  Monthly Limit Cannot be less than Weekly & Daily Limit
                </small>
              )
            }
            rules={[{required: true, message: 'This field is required'}]}
            name="monthlyLimit"
            label={
              <span>
                Monthly Limit{' '}
                <Tooltip
                  title={`Platform Monthly Limit: ${
                    currencyLimits?.monthly?.toLocaleString() ?? 'Not Created'
                  }`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <InputNumber
              value={monthlyLimit}
              defaultValue={
                currencyDetails[selectedCurrency]?.deposit?.limit?.monthly
              }
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={e => setMonthlyLimit(e)}
              style={{width}}
            />
          </Form.Item>
        </div>
      </div>
      <Collapse defaultActiveKey={['depositFee']}>
        <Collapse.Panel header={<h2>Deposit Fee Schedule</h2>} key="depositFee">
          <div className={styles.collapse}>
            <ProviderFeeSchedule
              feeName="depositFees"
              customAddLabel="Add Deposit Fee"
              form={form}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={['depositDiscount']}>
        <Collapse.Panel
          header={<h2>Deposit Discount</h2>}
          key="depositDiscount">
          <div className={styles.collapse}>
            <ProviderFeeSchedule
              feeName="depositDiscount"
              customAddLabel="Add Deposit Discount"
              form={form}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default DepositWalletFields;
