import FadeEffect from 'components/animation/fadeEffect';
import {toast} from 'react-toastify';
import {Form, InputNumber, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import styles from './baseDabaScore.module.scss';
import {useNavigate, useParams} from 'react-router-dom';

export const BaseDabaScore = ({onSubmit, route, existingData}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [reportName, setReportName] = useState('Upload PDF Report');
  const [viewPdf, setViewPdf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfReport, setPdfReport] = useState(null);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {assetId, id} = useParams();

  const isListed = existingData?.listingType !== 'LISTED';

  const isDev = process.env.REACT_APP_API_BASE_URL.includes('.daba-dev.');

  useEffect(() => {
    if (existingData && existingData.dabaScore) {
      form.setFieldsValue({...existingData.dabaScore});
      setPdfReport(existingData?.dabaScore?.pdfReport);
      setReportName(existingData?.dabaScore?.reportName || 'Upload PDF Report');
    }
  }, [existingData]);

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    const fileType = ['application/pdf'];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        // Upload file to S3 bucket
        setLoading(true);
        setPdfLoading(true);
        const res = await UploadDocumentToBucket(
          selectedFile,
          setProgress,
          isDev
            ? 'dabafinancialinstruments-nonprod'
            : 'dabafinancialinstruments-prod',
          true,
          'bonds',
        );
        if (res) {
          setPdfReport(res);
          setReportName(selectedFile.name);
          setLoading(false);
          setPdfLoading(false);
        }
      } else {
        setPdfReport(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfReport(null);
    setReportName('Supported formats pdf, docs');
    setViewPdf(null);
  };

  const handlePdfPreview = e => {
    e.preventDefault();
    if (pdfReport !== null) {
      setViewPdf(pdfReport);
    } else {
      setViewPdf(null);
    }
  };

  const validateScore = (_, value) => {
    if (value == undefined && isListed) {
      return Promise.reject(new Error('Required Field'));
    }
    if (value < 0 || value > 10) {
      return Promise.reject(new Error('Score must be between 0 and 10'));
    }
    return Promise.resolve();
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>
          Enter the values for the parameters below and click calculate and the
          organzations daba score would be calculated automatically
        </span>
      </div>
      <Form
        form={form}
        onFinish={values => {
          onSubmit({dabaScore: {...values, pdfReport, reportName}});
        }}
        layout="vertical">
        <div className={styles.form}>
          <Form.Item
            name="score"
            className={styles.formField}
            label="Daba Score"
            rules={[{validator: validateScore}]}>
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="business"
            className={styles.formField}
            label="Business">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="industry"
            className={styles.formField}
            label="Industry">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="market"
            className={styles.formField}
            label="Market">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="team"
            className={styles.formField}
            label="Team">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            name="strategyProduct"
            className={styles.formField}
            label="Strategy, Product GTM"
            rules={[{validator: validateScore}]}>
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            name="accelerator"
            className={styles.formField}
            label="Accelerator"
            rules={[{validator: validateScore}]}>
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            name="riskChallenges"
            className={styles.formField}
            label="Risks + Challenges"
            rules={[{validator: validateScore}]}>
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="analogus"
            className={styles.formField}
            label="Analogue">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="economicsAndFinance"
            className={styles.formField}
            label="Economics / Financials">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            className={styles.formField}
            label="Legal & Compliance"
            name="legalCompliance">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="returnOutlook"
            className={styles.formField}
            label="Returns Outlook">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
          <Form.Item
            rules={[{validator: validateScore}]}
            name="socialImpact"
            className={styles.formField}
            label="Social Impact">
            <InputNumber
              className={styles.number}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="Enter number"
            />
          </Form.Item>
        </div>
        <div>
          <h1 className={styles['pdf-title']}>Full Report PDF</h1>
          {!viewPdf && (
            <div className={styles.button}>
              <SubmitButton
                disabled={pdfReport === ''}
                label="Preview Pdf"
                handleClick={handlePdfPreview}
              />
            </div>
          )}
          {!viewPdf && (
            <form>
              <label className={styles.upload} htmlFor="file-upload">
                {pdfLoading ? (
                  <>
                    <Spin size="large" />
                    <h1>{progress}%</h1>
                  </>
                ) : (
                  <span>{reportName}</span>
                )}
                <input
                  type="file"
                  id="file-upload"
                  accept="application/pdf"
                  className={styles['input-file']}
                  required
                  onChange={handlePdfFileChange}
                />
              </label>
            </form>
          )}
          {viewPdf && (
            <FadeEffect>
              <div className={styles.button}>
                <SubmitButton
                  label="Delete File"
                  handleClick={handleDeleteFile}
                />
              </div>
              <div className={styles['pdf-height']}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={viewPdf}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </Worker>
              </div>
            </FadeEffect>
          )}
        </div>
        <div className={styles['bottom-buttons']}>
          <SubmitButton disabled={loading} label="Save Changes" />
          {!isListed && !id && (
            <SubmitButton
              secondary
              handleClick={() => navigate(route({param: assetId, name: ''}))}
              label="Skip to Next Step"
            />
          )}
        </div>
      </Form>
    </div>
  );
};
