import {gql} from '@apollo/client';

export const UPDATE_STOCK = gql`
  mutation UpdateStockExchange(
    $updateStockExchangeId: ID!
    $addStockExchangeInput: StockExchangeInput!
  ) {
    updateStockExchange(
      id: $updateStockExchangeId
      addStockExchangeInput: $addStockExchangeInput
    ) {
      ... on Error {
        message
        statusCode
        type
      }
      ... on StockExchange {
        id
        baseCurrencyCode
      }
    }
  }
`;

export const CREATE_STOCK = gql`
  mutation AddStockExchange($addStockExchangeInput: StockExchangeInput!) {
    addStockExchange(addStockExchangeInput: $addStockExchangeInput) {
      ... on Error {
        message
        statusCode
        type
      }
      ... on StockExchange {
        baseCurrencyCode
        id
      }
    }
  }
`;

export const ADD_COMPANY_TO_STOCK_EXCHANGE = gql`
  mutation AddCompanyToStockExchange(
    $stockExchangeId: ID!
    $companyId: ID!
    $ticker: String!
  ) {
    addCompanyToStockExchange(
      stockExchangeId: $stockExchangeId
      companyId: $companyId
      ticker: $ticker
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on Company {
        StockExchange {
          id
          stockExchangeName
        }
      }
    }
  }
`;

export const DELETE_STOCK_EXCHANGE = gql`
  mutation RemoveStockExchange($removeStockExchangeId: ID!) {
    removeStockExchange(id: $removeStockExchangeId) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchange {
        id
      }
    }
  }
`;

export const MATCHING_BUY_AND_SELL_ORDER = gql`
  mutation MatchAndSettleStockOrders($input: MatchStockOrder!) {
    matchAndSettleStockOrders(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrders {
        message
      }
    }
  }
`;

export const SETTLE_STOCK = gql`
  mutation SettleStockOrder($input: SettleStockOrder!) {
    settleStockOrder(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const REJECT_ORDER = gql`
  mutation RejectOrder($orderId: ID!) {
    rejectOrder(orderId: $orderId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const SETTLE_ORDER = gql`
  mutation SettleOrder($input: SettleOrderInput!) {
    settleOrder(input: $input) {
      ... on ResponseWithAnything {
        data
        message
      }
      ... on Error {
        message
        statusCode
      }
    }
  }
`;

export const OPEN_ORDER = gql`
  mutation OpenOrder($orderId: ID, $orderIds: [ID!]) {
    openOrder(orderId: $orderId, orderIds: $orderIds) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAnything {
        data
        message
      }
    }
  }
`;

export const REVERT_OPEN_ORDER = gql`
  mutation PendOrder($orderId: ID, $orderIds: [ID!]) {
    pendOrder(orderId: $orderId, orderIds: $orderIds) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const EXPIRE_ORDER = gql`
  mutation ExpireOrder($orderId: ID, $orderIds: [ID!]) {
    expireOrder(orderId: $orderId, orderIds: $orderIds) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const CREATE_STOCK_CLOSURE = gql`
  mutation CreateStockExchangeClosure($input: StockExchangeClosureInput!) {
    createStockExchangeClosure(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeClosure {
        id
        title
        isActive
        closureMessage
        language
      }
    }
  }
`;

export const HOLD_ORDER = gql`
  mutation HoldOrder($input: HoldOrderInput!) {
    holdOrder(input: $input) {
      ... on Error {
        statusCode
        message
      }
      ... on Response {
        message
      }
    }
  }
`;

export const CREATE_ORDER_NOTE = gql`
  mutation AddOrderNote($orderId: ID!, $note: String!) {
    addOrderNote(orderId: $orderId, note: $note) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const SET_STOCK_CLOSURE_STATUS = gql`
  mutation SetStockExchangeClosureStatus(
    $setStockExchangeClosureStatusId: ID!
    $status: StockExchangeClosureStatus!
  ) {
    setStockExchangeClosureStatus(
      id: $setStockExchangeClosureStatusId
      status: $status
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeClosure {
        id
        title
        isActive
      }
    }
  }
`;

export const UPDATE_ORDER_NOTE = gql`
  mutation UpdateOrderNote($input: UpdateOrderNoteInput!) {
    updateOrderNote(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithOrder {
        message
      }
    }
  }
`;

export const UPDATE_STOCK_CLOSURE = gql`
  mutation UpdateStockExchangeClosure(
    $updateStockExchangeClosureId: ID!
    $input: StockExchangeClosureInput!
  ) {
    updateStockExchangeClosure(
      id: $updateStockExchangeClosureId
      input: $input
    ) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeClosure {
        id
        title
        isActive
      }
    }
  }
`;

export const DELETE_STOCK_CLOSURE = gql`
  mutation DeleteStockExchangeClosure($deleteStockExchangeClosureId: ID!) {
    deleteStockExchangeClosure(id: $deleteStockExchangeClosureId) {
      ... on Error {
        message
        statusCode
      }
      ... on StockExchangeClosure {
        id
        title
      }
    }
  }
`;

export const CREATE_STOCK_EXCHANGE_DOCUMENT = gql`
  mutation AddStockExchangeDocument($data: AddStockExchangeDocument!) {
    addStockExchangeDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocument {
        message
      }
    }
  }
`;

export const UPDATE_STOCK_EXCHANGE_DOCUMENT = gql`
  mutation UpdateStockExchangeDocument($data: UpdateStockExchangeDocument!) {
    updateStockExchangeDocument(data: $data) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocument {
        message
      }
    }
  }
`;

export const DELETE_STOCK_EXCHANGE_DOCUMENT = gql`
  mutation DeleteOneStockExchangeDocument($documentId: ID!) {
    deleteOneStockExchangeDocument(documentId: $documentId) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithStockExchangeDocument {
        message
      }
    }
  }
`;

export const SEND_EXPIRING_ORDERS = gql`
  mutation SendExpiringOrderEmail($emails: [String!]!) {
    sendExpiringOrderEmail(emails: $emails) {
      ... on Error {
        message
        statusCode
      }
      ... on Response {
        message
      }
    }
  }
`;

export const BULK_ORDER_OPERATIONS = gql`
  mutation BulkOrderOperation($input: SettleOrderInput!) {
    bulkOrderOperation(input: $input) {
      ... on Error {
        message
        statusCode
      }
      ... on ResponseWithAnything {
        message
        data
      }
    }
  }
`;

export const REWRITE_ORDER = gql`
  mutation Rewrite($orderId: ID!) {
    rewrite(orderId: $orderId) {
      ... on Error {
        statusCode
        message
        type
      }
      ... on Response {
        message
      }
    }
  }
`;
