import {gql} from '@apollo/client';

export const GET_ALL_GROUPS = gql`
  query GetAllGroups {
    getAllGroups {
      ... on Error {
        message
        statusCode
      }
      ... on GroupList {
        groups {
          id
          name
          description
          bannerImg
          status
          isPrivate
          memberCount
          welcomeMessage
          # topics {
          #   id
          #   name
          #   icon
          #   type
          # }
          createdAt
        }
      }
    }
  }
`;

export const GET_ALL_GROUP_IDS = gql`
  query GetAllGroups {
    getAllGroups {
      ... on Error {
        message
        statusCode
      }
      ... on GroupList {
        groups {
          id
          name
        }
      }
    }
  }
`;

export const GET_GROUP_MEMBERS_BY_ID = gql`
  query AdminGetGroupMembers($filters: GroupMembersFilter) {
    adminGetGroupMembers(filters: $filters) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithGroupMembers {
        data {
          membershipDuration {
            endDate
            isLifetime
            startDate
          }
          user {
            imageUrl
            id
            username
            email
            firstName
            lastName
            createdAt
            restrictedCircles
            phoneNumber
            countryCode
            investorProfile {
              isAccredited
            }
            profileCompletion {
              completionPercentage
            }
          }
        }
        group {
          settings {
            timeBasedMembership {
              enabled
            }
          }
        }
        pagination {
          total
        }
      }
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GetGroupById($groupId: ID!, $page: Int, $limit: Int) {
    getGroupById(groupId: $groupId) {
      ... on Error {
        message
        statusCode
      }
      ... on SingleReturnedGroup {
        group {
          id
          name
          description
          bannerImg
          isPrivate
          status
          admins {
            id
            firstName
            email
            lastName
          }
          settings {
            timeBasedMembership {
              enabled
              defaultDuration {
                value
                unit
              }
            }
            allowDocumentUpload {
              admins
              nonAdmins
            }
            allowSendMessages {
              admins
              nonAdmins
            }
            allowDownload {
              admins
              nonAdmins
            }
            allowScreenRecord {
              admins
              nonAdmins
            }
          }
          memberCount
          welcomeMessage
          members(page: $page, limit: $limit) {
            imageUrl
            id
            username
            email
            firstName
            lastName
            phoneNumber
            countryCode
            investorProfile {
              isAccredited
            }
            profileCompletion {
              completionPercentage
            }
          }
          topics {
            id
            name
            icon
            type
          }
          createdAt
        }
      }
    }
  }
`;
