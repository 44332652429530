import styles from './assetClassOverview.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ASSET_CLASSES} from 'graphql/queries/assetManagement';
import {Dropdown, Table, Tag} from 'antd';
import {useNavigate} from 'react-router-dom';
import dotsIcon from 'images/dots.svg';
import {useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {DELETE_ASSET_CLASS} from 'graphql/mutations/assetManagement';
import {handleDeleteAssetClass} from '../actions';
import {Button} from '@dabafinance/react-components';

const AssetClassesOverview = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const navigate = useNavigate();
  const {data, loading, refetch} = useQuery(GET_ASSET_CLASSES);
  const [deleteAssetClass, {loading: loadingDelete}] =
    useMutation(DELETE_ASSET_CLASS);

  const columns = [
    {
      dataIndex: 'name',
      key: 'name',
      title: 'Name',
    },
    {
      dataIndex: 'key',
      key: 'key',
      title: 'Key',
      render: (text, render) => <Tag color="default">{render?.key}</Tag>,
    },
    {
      dataIndex: 'language',
      key: 'language',
      title: 'language',
    },
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, record) => (
        <Tag
          color={
            {
              'PENDING': 'yellow',
              'ENABLED': 'green',
              'DISABLED': 'red',
            }[record?.status]
          }>
          {record?.status}
        </Tag>
      ),
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      title: 'Actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: 'View Language Versions',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  navigate(
                    `/dashboard/asset-management/classes/languages/${record?.id}`,
                  );
                },
                key: 0,
              },
              {
                key: 1,
                label: 'Delete Class',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  setOpenDeleteModal({isOpen: true, id: record?.id});
                },
              },
              {
                key: 2,
                label: 'View Instruments',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  navigate(
                    `/dashboard/asset-management/class/${record?.key}/instruments`,
                  );
                },
              },
            ],
          }}>
          <img
            role="button"
            onClick={e => e.stopPropagation()}
            src={dotsIcon}
            alt=""
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <div className={styles.top}>
        <h1>Asset Classes</h1>
        <div>
          <Button
            onClick={() =>
              navigate(`/dashboard/asset-management/classes/create`)
            }
            label="Add Asset Class"
          />

          <Button
            onClick={() =>
              navigate(`/dashboard/asset-management/classes/ratings`)
            }
            label="Ratings"
            type="secondary"
          />
        </div>
      </div>
      <div className={styles.content}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={data?.getAssetClasses?.data}
          onRow={record => ({
            onClick: () =>
              navigate(
                `/dashboard/asset-management/class/${record?.key}/instruments`,
              ),
            className: 'on-row',
          })}
        />
      </div>
      <ConfirmModal
        openModal={openDeleteModal.isOpen}
        title="Delete Asset Class"
        confirmText="Are you sure you want to delete this Asset Class? this action cannot be undone."
        setOpenModal={() => setOpenDeleteModal(false)}
        loading={loadingDelete}
        handleOperation={() =>
          handleDeleteAssetClass(
            deleteAssetClass,
            openDeleteModal.id,
            refetch,
            setOpenDeleteModal,
          )
        }
      />
    </div>
  );
};

export default AssetClassesOverview;
