import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import DBModal from 'components/modal/modal';
import {
  BULK_ORDER_OPERATIONS,
  CREATE_ORDER_NOTE,
  EXPIRE_ORDER,
  OPEN_ORDER,
  REJECT_ORDER,
  REVERT_OPEN_ORDER,
  REWRITE_ORDER,
  SETTLE_ORDER,
  UPDATE_ORDER_NOTE,
} from 'graphql/mutations/stocks';
import {toast} from 'react-toastify';
import styles from './viewOrders.module.scss';
import {Button} from '@dabafinance/react-components';
import {
  DatePicker,
  Form,
  Input,
  Select,
  InputNumber,
  Table,
  Tag,
  Typography,
  Button as AntButton,
  Spin,
} from 'antd';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import backIcon from 'images/back.png';
import {BROKER_EXPORT, GET_ALL_BROKERS} from 'graphql/queries/stocks';
import {stockOrderStatus} from 'utils/mock';
import {extractCompanyId, extractUserId, formatValue} from 'utils/helper';
import {OrderFilter} from './OrderFilter';
import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {handleOrderRewrite} from './OrderActions';

export const BulkFillCSVOrders = ({orderModal, setOrderModal}) => {
  const [loadingCSVUpload, setLoadingCSVUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [csvName, setCsvName] = useState('');
  const [csvUrl, setCsvUrl] = useState('');
  const [openFinishModal, setOpenFinishModal] = useState(false);

  const isFilledBulk = orderModal === 'FILL_CSV';
  const [bulkOrderOperation, {loading}] = useMutation(BULK_ORDER_OPERATIONS);

  const handleCSVUPload = async e => {
    setProgress(0);
    const selectedFile = e.target.files[0];
    setCsvName(selectedFile.name);
    setLoadingCSVUpload(true);
    const response = await UploadVideoToBucket(
      selectedFile,
      'campaign-bucket-images',
      setProgress,
    );
    setCsvUrl(response);
    setLoadingCSVUpload(false);
  };

  const handleSubmit = () => {
    bulkOrderOperation({
      variables: {
        input: {
          csv: csvUrl,
          status: isFilledBulk ? 'FILLED' : 'SETTLED',
        },
      },
    })
      .then(({data: {bulkOrderOperation}}) => {
        if (bulkOrderOperation.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${bulkOrderOperation.message} `,
          );
        } else {
          toast.success('CSV Uploaded Successfully');
          if (bulkOrderOperation?.data?.key) {
            navigator.clipboard.writeText(bulkOrderOperation?.data?.key);
            toast.info('Order Book Key Copied to Clipboard Successfully');
          }
          setOrderModal(false);
          setCsvUrl('');
          setOpenFinishModal(false);
          setCsvName('');
          setProgress(0);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  return (
    <DBModal
      isOpen={orderModal === 'FILL_CSV' || orderModal === 'SETTLE_CSV'}
      handleClose={() => {
        setOrderModal(false);
        setCsvUrl('');
        setCsvName('');
        setProgress(0);
        setOpenFinishModal(false);
      }}
      content={
        <div className={styles.modal}>
          <h1>{isFilledBulk ? 'Fill' : 'Settle'} Uploaded CSV Orders</h1>
          {openFinishModal ? (
            <div className={styles.modal}>
              <h2>Are you sure you want to Proceed?</h2>
              <div className="buttons">
                <Button
                  onClick={handleSubmit}
                  disabled={loading}
                  label="Yes, Finish"
                />
                <Button
                  onClick={() => setOpenFinishModal(false)}
                  label="No, Cancel"
                  type="secondary"
                />
              </div>
            </div>
          ) : (
            <Form onFinish={() => setOpenFinishModal(true)} layout="vertical">
              <div className={styles.uploadSec}>
                <label className={styles.upload} htmlFor="file-upload">
                  {loadingCSVUpload ? (
                    <div className={styles.uploading}>
                      <Spin />
                      <h3>Uploading, Please wait...</h3>
                      <span>{progress}%</span>
                    </div>
                  ) : csvName && csvUrl ? (
                    <div>{csvName}</div>
                  ) : (
                    <h2>Upload CSV File</h2>
                  )}
                  <input
                    type="file"
                    id="file-upload"
                    accept=".csv"
                    className={styles['input-file']}
                    required
                    onChange={handleCSVUPload}
                  />
                </label>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://campaign-bucket-images.s3.amazonaws.com/1724135082567-bulk-settle-sample.csv">
                    Sample CSV
                  </a>
                </div>
              </div>
              <SubmitButton
                disabled={!csvUrl || loading}
                label="Proceed to Upload"
              />
            </Form>
          )}
        </div>
      }
    />
  );
};

export const RejectOrder = ({
  orderModal,
  setOrderModal,
  orderId,
  refetch,
  orderIds,
  setType,
  setOrder,
  type,
  isReject,
  setSelected,
}) => {
  const [rejectOrder, {loading: loadingReject}] = useMutation(REJECT_ORDER);
  const [rewriteOrder, {loading: loadingRewrite}] = useMutation(REWRITE_ORDER);

  const [bulkOrderOperation, {loading: loadingBulkReject}] = useMutation(
    BULK_ORDER_OPERATIONS,
  );

  const handleUpdate = () => {
    if (!isReject)
      return handleOrderRewrite({
        rewriteOrder,
        refetch,
        setOrder,
        setOrderModal,
        orderId,
      });
    let resolver;
    if (type === 'MULTIPLE') {
      resolver = bulkOrderOperation({
        variables: {
          input: {
            orderIds,
            status: 'REJECTED',
          },
        },
      });
    } else {
      resolver = rejectOrder({
        variables: {
          orderId,
        },
      });
    }

    resolver
      .then(({data}) => {
        const resolverType =
          data?.[type === 'MULTIPLE' ? 'bulkOrderOperation' : 'rejectOrder'];
        if (resolverType.__typename === 'Error') {
          toast.error(`Something went wrong!!! - ${resolverType.message} `);
        } else {
          toast.success('Rejected Successfully');
          if (resolverType?.data?.key && type === 'MULTIPLE') {
            navigator.clipboard.writeText(resolverType?.data?.key);
            toast.info('Order Book Key Copied to Clipboard Successfully');
          }
          setOrderModal(false);
          refetch();
          setSelected([]);
          setOrder('');
          setType('SINGLE');
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  return (
    <DBModal
      isOpen={orderModal}
      handleClose={() => {
        setOrderModal(false);
        setType('SINGLE');
        setOrder();
      }}
      content={
        <div className={styles.modal}>
          <h1>{isReject ? 'Update' : 'Rewrite'} Order</h1>
          {type === 'MULTIPLE' ? (
            <span>
              Are you sure you want to{' '}
              <span className={styles.type}>REJECT {orderIds?.length}</span>{' '}
              ORDER(S)
            </span>
          ) : isReject ? (
            <span>
              Are you sure you want to{' '}
              <span className={styles.type}>REJECT</span> this Order?
            </span>
          ) : (
            <span>
              Are you sure you want to{' '}
              <span className={styles.type}>REWRITE</span> this Order? NOTE that
              this will return the order to the previous state
            </span>
          )}

          <div className={styles.buttons}>
            <Button
              disabled={loadingReject || loadingBulkReject || loadingRewrite}
              label="Yes"
              onClick={handleUpdate}
            />
            <Button
              type="secondary"
              label="No"
              onClick={() => setOrderModal(false)}
            />
          </div>
        </div>
      }
    />
  );
};

export const FilledOrder = ({
  orderModal,
  setOrderModal,
  orderDetails,
  refetch,
  operationType,
  orderIds,
  setType = () => {},
  setSelected = () => {},
}) => {
  const [settleOrder, {loading}] = useMutation(SETTLE_ORDER);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [failedIds, setFailedIds] = useState([]);
  const [form] = Form.useForm();
  const {
    company,
    symbol,
    id,
    direction,
    type,
    assetCurrency,
    quantity,
    fillQuantity,
  } = orderDetails;
  const handleUpdate = values => {
    settleOrder({
      variables: {
        input: {
          status: 'SETTLED',
          orderId: id,
          direction,
          ...(values.settlementDate && {
            settlementDate: moment(values?.settlementDate).format(),
          }),
          fillQuantity: Number(quantity) - Number(fillQuantity),
          type,
          assetPrice: Number(company?.stock?.lastPrice),
          ticker: symbol,
          currency: assetCurrency,
        },
      },
    })
      .then(({data: {settleOrder}}) => {
        if (settleOrder.__typename === 'Error') {
          toast.error(`Something went wrong!!! - ${settleOrder.message} `);
        } else {
          if (settleOrder.data?.length) {
            setFailedIds(settleOrder.data);
            setOpenFailedModal(true);
          }
          toast.success('Settled Successfully');
          setOrderModal(false);
          setSelected([]);
          form.resetFields();
          setType('SINGLE');
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  return (
    <>
      <DBModal
        isOpen={orderModal}
        handleClose={() => {
          setOrderModal(false);
          setType('SINGLE');
        }}
        content={
          <div className={styles.modal}>
            <h1>Settle Order</h1>
            <span>
              Are you sure you want to{' '}
              <span className={styles.type}>
                SETTLED {operationType === 'MULTIPLE' && orderIds.length}
              </span>{' '}
              {operationType === 'MULTIPLE' ? 'Orders?' : 'this Order?'}
            </span>
            <Form form={form} onFinish={handleUpdate} layout="vertical">
              <div className={styles.settleDate}>
                <Form.Item name="settlementDate">
                  <DatePicker
                    showTime={{
                      defaultValue: moment('00:00', 'HH:mm:ss'),
                    }}
                    format={'YYYY-MM-DD HH:mm:ss'}
                  />
                </Form.Item>
              </div>
              <div className={styles.buttons}>
                <SubmitButton disabled={loading} label="Yes" />
                <Button
                  type="secondary"
                  label="No"
                  onClick={() => setOrderModal(false)}
                />
              </div>
            </Form>
          </div>
        }
      />

      {/* Failed Order Modal */}
      <ConfirmModal
        title={'Order Failed'}
        openModal={openFailedModal}
        setOpenModal={setOpenFailedModal}
        confirmText={<span>These orders failed: {failedIds.join(', ')}</span>}
        loading={loading}
        confirmation={false}
      />
    </>
  );
};

export const OpenOrder = ({
  orderModal,
  setOrderModal,
  orderId,
  refetch,
  type,
  orderIds,
  setType = () => {},
  setSelected = () => {},
}) => {
  const [openOrder, {loading}] = useMutation(OPEN_ORDER);
  const handleUpdate = () => {
    let variables = {};
    if (type === 'MULTIPLE') {
      variables = {
        orderIds,
      };
    } else {
      variables = {
        orderId,
      };
    }
    openOrder({
      variables,
    })
      .then(({data: {openOrder}}) => {
        if (openOrder.__typename === 'Error') {
          toast.error(`Something went wrong!!! - ${openOrder.message} `);
        } else {
          toast.success('Opened Successfully');
          setOrderModal(false);
          setSelected([]);
          setType('SINGLE');
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };
  return (
    <DBModal
      isOpen={orderModal}
      handleClose={() => {
        setOrderModal(false);
        setType('SINGLE');
      }}
      content={
        <div className={styles.modal}>
          <h1>Open Order</h1>
          <span>
            Are you sure you want to{' '}
            <span className={styles.type}>
              OPEN {type === 'MULTIPLE' && orderIds.length}
            </span>{' '}
            {type === 'MULTIPLE' ? 'Orders?' : 'this Order?'}
          </span>

          <div className={styles.buttons}>
            <Button disabled={loading} label="Yes" onClick={handleUpdate} />
            <Button
              type="secondary"
              label="No"
              onClick={() => setOrderModal(false)}
            />
          </div>
        </div>
      }
    />
  );
};

export const RevertOpenOrder = ({
  orderModal,
  setOrderModal,
  order,
  refetch,
  type,
  orderIds,
  setType,
  setOrder,
  setSelected,
}) => {
  const isExpire = order?.type === 'EXPIRE';
  const [pendOrder, {loading}] = useMutation(REVERT_OPEN_ORDER);
  const [expireOrder, {loading: loadingExpire}] = useMutation(EXPIRE_ORDER);
  const resolver = isExpire ? expireOrder : pendOrder;

  const handleUpdate = () => {
    let variables = {};
    if (type === 'MULTIPLE') {
      variables = {
        orderIds,
      };
    } else {
      variables = {
        orderId: isExpire ? order?.id : order,
      };
    }

    resolver({
      variables,
    })
      .then(({data}) => {
        const type = data?.[isExpire ? 'expireOrder' : 'pendOrder'];
        if (type.__typename === 'Error') {
          toast.error(`Something went wrong!!! - ${type.message} `);
        } else {
          toast.success(
            isExpire
              ? 'Order(s) Expired Successfully'
              : 'Reverted to Pending Successfully',
          );
          setOrderModal(false);
          setSelected([]);
          setOrder('');
          setType('SINGLE');
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };
  return (
    <DBModal
      isOpen={orderModal}
      handleClose={() => {
        setOrderModal(false);
        setType('SINGLE');
        setOrder('');
      }}
      content={
        <div className={styles.modal}>
          {isExpire ? (
            <>
              <h1>Expire Order(s)</h1>
              <span>
                Are you sure you want to Expire{' '}
                {type === 'MULTIPLE' ? 'these' : 'this'}{' '}
                <span className={styles.type}>
                  {type === 'MULTIPLE' && orderIds.length}
                </span>{' '}
                Order(s)?
              </span>
            </>
          ) : (
            <>
              <h1>REVERT TO PENDING</h1>
              <span>
                Are you sure you want to revert{' '}
                {type === 'MULTIPLE' ? 'these' : 'this'}{' '}
                <span className={styles.type}>
                  {type === 'MULTIPLE' && orderIds.length} OPEN
                </span>{' '}
                Order to Pending?
              </span>
            </>
          )}

          <div className={styles.buttons}>
            <Button
              disabled={loading || loadingExpire}
              label="Yes"
              onClick={handleUpdate}
            />
            <Button
              type="secondary"
              label="No"
              onClick={() => setOrderModal(false)}
            />
          </div>
        </div>
      }
    />
  );
};

export const OrderHistory = ({orderModal, setOrderModal, history}) => {
  const navigate = useNavigate();
  const columns = [
    {
      dataIndex: 'status',
      key: 'status',
      title: 'Status',
      render: (text, render) => (
        <Tag
          color={
            {
              'FILLED': 'green',
              'PENDING': 'orange',
              'ERROR': 'red',
              'REJECTED': 'red',
              'CANCELLED': 'red',
              'SETTLED': 'cyan',
              'PARTIALLY_FILLED': 'yellow',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {dataIndex: 'description', key: 'description', title: 'Description'},
    {
      dataIndex: 'matchOrder',
      key: 'matchOrder',
      title: 'Match Order',
      render: (text, record) =>
        record?.matchOrder && (
          <span
            className={styles.matchOrder}
            onClick={() =>
              navigate(
                `/dashboard/stocks/settle-order/${record.matchOrder}?type=match`,
              )
            }>
            View Order
          </span>
        ),
    },
    {
      dataIndex: 'Date',
      key: 'Date',
      title: 'Date',
      render: (text, record) => (
        <span>{moment(record?.createdAt).format('DD MMM, YYYY h:mmA')}</span>
      ),
    },
  ];
  return (
    <DBModal
      isOpen={orderModal}
      handleClose={() => setOrderModal(false)}
      width={1000}
      content={
        <>
          <div className={styles.historyContent}>
            <h1>Order History</h1>
          </div>
          <Table columns={columns} dataSource={history} />
        </>
      }
    />
  );
};

export const OrderNotes = ({orderModal, setOrderModal, notes, refetch}) => {
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [finalizeState, setFinalizeState] = useState({});

  const [addOrderNote, {loading}] = useMutation(CREATE_ORDER_NOTE);
  const [updateOrderNote, {loading: loadingUpdate}] =
    useMutation(UPDATE_ORDER_NOTE);

  const handleCreateNote = () => {
    if (finalizeState?.noteId) {
      updateOrderNote({
        variables: {
          input: {
            orderId: notes?.id,
            note: finalizeState.note,
            noteId: finalizeState?.noteId,
          },
        },
      })
        .then(({data: updateOrderNote}) => {
          if (updateOrderNote.__typename === 'Error') {
            toast.error(
              `Something went wrong!!! - ${updateOrderNote.message} `,
            );
          } else {
            toast.success('Order Note Updated Successfully');
            setFinalizeState({open: false});
            setCreateNoteModal(false);
            setOrderModal(false);
            refetch();
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err}`));
    } else {
      addOrderNote({
        variables: {
          orderId: notes?.id,
          note: finalizeState.note,
        },
      })
        .then(({data: addOrderNote}) => {
          if (addOrderNote.__typename === 'Error') {
            toast.error(`Something went wrong!!! - ${addOrderNote.message} `);
          } else {
            toast.success('Order Note Added Successfully');
            setFinalizeState({open: false});
            setCreateNoteModal(false);
            setOrderModal(false);
            refetch();
          }
        })
        .catch(err => toast.error(`Something went wrong!! - ${err}`));
    }
  };

  const columns = [
    {
      dataIndex: 'createdBy',
      key: 'createdBy',
      title: 'Created By',
      render: (text, render) => <div>{render?.createdBy?.email}</div>,
    },
    {
      dataIndex: 'updatedBy',
      key: 'updatedBy',
      title: 'Updated By',
      render: (text, render) => <div>{render?.updatedBy?.email}</div>,
    },
    {dataIndex: 'note', key: 'note', title: 'Note'},
    {
      dataIndex: 'createdAt',
      key: 'createdAt',
      title: 'Created At',
      render: (text, record) => (
        <span>
          {record?.createdAt &&
            moment(record?.createdAt).format('DD MMM, YYYY h:mmA')}
        </span>
      ),
    },
    {
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      title: 'Updated At',
      render: (text, record) => (
        <span>
          {record?.updatedAt &&
            moment(record?.updatedAt).format('DD MMM, YYYY h:mmA')}
        </span>
      ),
    },
    {
      dataIndex: 'action',
      key: 'action',
      title: 'Action',
      render: (text, render) => (
        <Button
          label="Update"
          type="secondary"
          onClick={() => {
            setFinalizeState({note: render?.note, noteId: render?.id});
            setCreateNoteModal(true);
          }}
        />
      ),
    },
  ];
  return (
    <DBModal
      isOpen={orderModal}
      handleClose={() => {
        setCreateNoteModal(false);
        setOrderModal(false);
        setFinalizeState({open: false});
      }}
      width={1500}
      content={
        createNoteModal ? (
          <div className={styles.createNote}>
            <div className={styles.createTop}>
              {!finalizeState.open && (
                <img
                  onClick={() => setCreateNoteModal(false)}
                  src={backIcon}
                  alt=""
                />
              )}
              <h1>
                {' '}
                {finalizeState?.noteId ? 'Update' : 'Create'} Order Note{' '}
              </h1>
            </div>
            {finalizeState?.open ? (
              <div className={styles.buttons}>
                <Button
                  disabled={loading || loadingUpdate}
                  label="Yes"
                  onClick={handleCreateNote}
                />
                <Button
                  type="secondary"
                  label="No"
                  onClick={() =>
                    setFinalizeState(prev => ({...prev, open: false}))
                  }
                />
              </div>
            ) : (
              <Form
                onFinish={values =>
                  setFinalizeState(prev => ({
                    ...prev,
                    note: values.note,
                    open: true,
                  }))
                }
                layout="vertical">
                <Form.Item
                  className={styles.width}
                  rules={[
                    {required: true, message: 'This is a required field'},
                  ]}
                  name="note"
                  initialValue={finalizeState?.note}
                  label="Note">
                  <Input.TextArea rows={6} />
                </Form.Item>
                <SubmitButton
                  label={finalizeState?.noteId ? 'Update' : 'Create'}
                />
              </Form>
            )}
          </div>
        ) : (
          <>
            <div>
              <div className={styles.historyContent}>
                <h1>Order Notes</h1>
              </div>
              {notes?.notes && (
                <div className={styles.topCreate}>
                  <Button
                    label="Create Note"
                    onClick={() => setCreateNoteModal(true)}
                    type="secondary"
                  />
                </div>
              )}
            </div>
            {notes.notes ? (
              <Table columns={columns} dataSource={notes?.notes} />
            ) : (
              <div className={styles.noNote}>
                <h2>No Note Exists for this Order</h2>
                <Button
                  label="Create Note"
                  onClick={() => setCreateNoteModal(true)}
                  type="secondary"
                />
              </div>
            )}
          </>
        )
      }
    />
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  select,
  date,
  options,
  children,
  ...restProps
}) => {
  const inputNode = select ? (
    <Select>
      {options?.map(data => (
        <Select.Option key={data.key}>{data.name}</Select.Option>
      ))}
    </Select>
  ) : inputType === 'number' ? (
    <InputNumber />
  ) : inputType === 'text' ? (
    <Input />
  ) : (
    <DatePicker
      defaultValue={
        date ? moment(date || '').format('YYYY-MM-DD HH:mm:ss') : ''
      }
      showTime={{
        defaultValue: moment('00:00', 'HH:mm:ss'),
      }}
      format={'YYYY-MM-DD HH:mm:ss'}
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: false,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const BulkFillOrder = ({
  orderModal,
  setOrderModal,
  refetch,
  orders,
  setOrders,
  setSelected,
}) => {
  const [bulkOrderOperation, {loading}] = useMutation(BULK_ORDER_OPERATIONS, {
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });
  const [editingKey, setEditingKey] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openFailedModal, setOpenFailedModal] = useState(false);
  const [failedIds, setFailedIds] = useState([]);
  const [form] = Form.useForm();
  const [updateAllForm] = Form.useForm();
  const isEditing = record => record.key === editingKey;
  const isOrderOpen = orders?.some(data => data?.status === 'OPEN');
  const isFilledModal = orderModal?.type === 'FILLED';
  const [selectedRows, setSelectedRows] = useState([]);
  const quantityColumnValue = Form.useWatch('fillQuantityLeft', updateAllForm);
  const assetPriceColumnValue = Form.useWatch('limitPrice', updateAllForm);
  const statusColumnValue = Form.useWatch('status', updateAllForm);
  const lastFillDateColumn = Form.useWatch('lastFillDate', updateAllForm);
  const settlementDateColumn = Form.useWatch('settlementDate', updateAllForm);

  const handleUpdate = () => {
    let variables = {};
    if (isFilledModal) {
      variables = {
        input: {
          settlements: orders.map(data => ({
            orderId: data?.id,
            direction: data?.direction,
            fillQuantity: Number(data?.fillQuantity),
            type: data?.type,
            assetPrice: Number(data?.limitPrice),
            ticker: data?.symbol,
            currency: data?.assetCurrency,
            ...(data?.settlementDate && {
              settlementDate: moment(data?.settlementDate).format(),
            }),
            status: 'SETTLED',
          })),
        },
      };
    } else {
      variables = {
        input: {
          status: 'FILLED',
          settlements: orders.map(data => ({
            orderId: data?.id,
            direction: data?.direction,
            fillQuantity: data?.fillQuantityLeft
              ? Number(data?.fillQuantityLeft)
              : data?.quantity - data?.fillQuantity,
            type: data?.type,
            assetPrice: Number(data?.limitPrice),
            ticker: data?.symbol,
            currency: data?.assetCurrency,
            lastFillDate: data?.lastFillDate === '' ? null : data?.lastFillDate,
            status: data?.status,
          })),
        },
      };
    }
    bulkOrderOperation({
      variables,
    })
      .then(({data: {bulkOrderOperation}}) => {
        if (bulkOrderOperation.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${bulkOrderOperation.message} `,
          );
        } else {
          toast.success(bulkOrderOperation?.message);
          if (Array.isArray(bulkOrderOperation?.data)) {
            setFailedIds(bulkOrderOperation.data);
            setOpenFailedModal(true);
          }
          if (bulkOrderOperation?.data?.key) {
            navigator.clipboard.writeText(bulkOrderOperation?.data?.key);
            toast.info('Order Book Key Copied to Clipboard Successfully');
          }
          setOrderModal(false);
          setOrders([]);
          updateAllForm.resetFields();
          setOpenConfirmation(false);
          setSelected([]);
          refetch();
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const edit = record => {
    const fillQuantity = record?.quantity - record?.fillQuantity;
    form.setFieldsValue({
      limitPrice: record?.limitPrice,
      fillQuantityLeft: record?.fillQuantityLeft
        ? record?.fillQuantityLeft
        : fillQuantity,
      status: record?.status,
      lastFillDate: record?.lastFillDate ? moment(record?.lastFillDate) : '',
      settlementDate: record?.settlementDate
        ? moment(record?.settlementDate)
        : '',
      ticker: record?.symbol,
      direction: record?.direction,
      type: record?.type,
      assetCurrency: record?.assetCurrency,
    });
    setEditingKey(record.key);
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...orders];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setOrders(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setOrders(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      width: '10%',
      editable: false,
      render: (text, record) => <span>{record?.symbol}</span>,
    },
    {
      title: 'Direction',
      dataIndex: 'direction',
      width: '10%',
      editable: false,
    },
    {
      title: 'Order Type',
      dataIndex: 'type',
      width: '10%',
      editable: false,
    },
    {
      title: 'Currency',
      dataIndex: 'assetCurrency',
      width: '10%',
      editable: false,
    },
    {
      title: `Order Asset Price`,
      dataIndex: 'limitPrice',
      width: '15%',
      editable: orderModal?.type !== 'FILLED',
      render: (text, record) => <span>{record?.limitPrice}</span>,
    },
    {
      title: isFilledModal ? 'Fill Quantity' : 'Fill Quantity Left',
      dataIndex: 'fillQuantityLeft',
      width: '10%',
      editable: !isFilledModal,
      render: (text, record) =>
        isFilledModal
          ? record?.fillQuantity
          : record?.fillQuantityLeft
          ? record?.fillQuantityLeft
          : Number(record?.quantity) - Number(record?.fillQuantity),
    },
    {
      title: `Order Status`,
      dataIndex: 'status',
      width: '15%',
      editable: orderModal?.type !== 'FILLED',
    },
    {
      title: isFilledModal ? `Settlement Date` : `Filled Date`,
      dataIndex: isFilledModal ? `settlementDate` : `lastFillDate`,
      width: '20%',
      editable: true,
      render: (text, record) =>
        isFilledModal ? (
          <span>
            {record?.settlementDate
              ? moment(record?.settlementDate).format('YYYY-MM-DD HH:mm:ss')
              : ''}
          </span>
        ) : (
          <span>
            {record?.lastFillDate
              ? moment(record?.lastFillDate).format('YYYY-MM-DD HH:mm:ss')
              : ''}
          </span>
        ),
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8,
              }}>
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        );
      },
    },
  ];

  const disableBulkButton = editingKey.length || isOrderOpen || !orders?.length;

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: record => ({
        record,
        inputType:
          col.dataIndex === 'limitPrice' || col.dataIndex === 'fillQuantity'
            ? 'number'
            : ['lastFillDate', 'settlementDate'].includes(col.dataIndex)
            ? 'date'
            : 'text',
        select: col.dataIndex === 'status',
        options:
          col.dataIndex === 'status' &&
          stockOrderStatus.filter(data =>
            ['PARTIALLY_FILLED', 'FILLED'].includes(data.key),
          ),
        dataIndex: col.dataIndex,
        title: col.title,
        date: moment(record?.settlementDate || record?.lastFillDate || ''),
        editing: isEditing(record),
      }),
    };
  });

  const handleRemoveSelectedOrders = () => {
    const filteredOrders = orders.filter(data =>
      selectedRows.every(row => row.id !== data.id),
    );
    setSelected(filteredOrders?.map(data => data?.id));
    setOrders(filteredOrders);
  };

  return (
    <>
      <DBModal
        isOpen={orderModal?.isOpen}
        handleClose={() => {
          setOrderModal(false);
          setSelectedRows([]);
          updateAllForm.resetFields();
        }}
        width={'auto'}
        content={
          <div className={styles.modal}>
            {isFilledModal ? (
              <h1>Settle Bulk Orders</h1>
            ) : (
              <h1>Fill Bulk Orders</h1>
            )}
            <div className={styles.bulkTop}>
              <div className={styles.selectedRow}>
                <Button
                  label="Remove Selected Row(s)"
                  disabled={!selectedRows?.length}
                  onClick={handleRemoveSelectedOrders}
                />
              </div>
              <Form
                form={updateAllForm}
                className={styles.allUpdate}
                layout="vertical">
                {!isFilledModal ? (
                  <>
                    <div className={styles.allField}>
                      <Form.Item
                        className={styles.updateAllFields}
                        name="limitPrice"
                        label="Asset Price">
                        <Input type="number" />
                      </Form.Item>
                      <AntButton
                        onClick={() => {
                          const updatedOrders = orders.map(order => {
                            return {
                              ...order,
                              limitPrice: assetPriceColumnValue
                                ? Number(assetPriceColumnValue)
                                : order?.limitPrice,
                            };
                          });
                          setOrders(updatedOrders);
                        }}
                        className={styles.allButton}>
                        Update
                      </AntButton>
                    </div>
                    <div className={styles.allField}>
                      <Form.Item
                        className={styles.updateAllFields}
                        name="fillQuantityLeft"
                        label="Fill Quantity">
                        <Input type="number" />
                      </Form.Item>
                      <AntButton
                        onClick={() => {
                          const updatedOrders = orders.map(order => {
                            const fillQuantity =
                              order?.quantity - order?.fillQuantity;
                            return {
                              ...order,
                              fillQuantityLeft: quantityColumnValue
                                ? Number(quantityColumnValue)
                                : fillQuantity,
                            };
                          });
                          setOrders(updatedOrders);
                        }}
                        className={styles.allButton}>
                        Update
                      </AntButton>
                    </div>
                    <div className={styles.allField}>
                      <Form.Item
                        className={styles.updateAllFields}
                        name="status"
                        label="Status">
                        <Select allowClear>
                          {stockOrderStatus
                            .filter(status =>
                              ['PARTIALLY_FILLED', 'FILLED'].includes(
                                status.key,
                              ),
                            )
                            .map(data => (
                              <Select.Option key={data.key}>
                                {data.name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <AntButton
                        onClick={() => {
                          const updatedOrders = orders.map(order => ({
                            ...order,
                            status: statusColumnValue
                              ? statusColumnValue
                              : order?.status,
                          }));
                          setOrders(updatedOrders);
                        }}
                        className={styles.allButton}>
                        Update
                      </AntButton>
                    </div>
                    <div className={styles.allField}>
                      <Form.Item
                        className={styles.updateAllFields}
                        name="lastFillDate"
                        label="Fill Date">
                        <DatePicker
                          showTime={{
                            defaultValue: moment('00:00', 'HH:mm:ss'),
                          }}
                          format={'YYYY-MM-DD HH:mm:ss'}
                        />
                      </Form.Item>
                      <AntButton
                        onClick={() => {
                          const updatedOrders = orders.map(order => ({
                            ...order,
                            ...(lastFillDateColumn && {
                              lastFillDate: moment(lastFillDateColumn),
                            }),
                          }));
                          setOrders(updatedOrders);
                        }}
                        className={styles.allButton}>
                        Update
                      </AntButton>
                    </div>
                  </>
                ) : (
                  <div className={styles.allField}>
                    <Form.Item
                      className={styles.updateAllFields}
                      name="settlementDate"
                      label="Settlement Date">
                      <DatePicker
                        showTime={{
                          defaultValue: moment('00:00', 'HH:mm:ss'),
                        }}
                        format={'YYYY-MM-DD HH:mm:ss'}
                      />
                    </Form.Item>
                    <AntButton
                      onClick={() => {
                        const updatedOrders = orders.map(order => ({
                          ...order,
                          ...(settlementDateColumn && {
                            settlementDate: moment(settlementDateColumn),
                          }),
                        }));
                        setOrders(updatedOrders);
                      }}
                      className={styles.allButton}>
                      Update
                    </AntButton>
                  </div>
                )}
              </Form>
            </div>

            <Form
              form={form}
              component={false}
              className={styles.modalForm}
              onFinish={() => setOpenConfirmation(true)}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys: selectedRows?.map(data => data?.id),
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRows(selectedRows);
                  },
                }}
                dataSource={orders}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
              <Button
                type="primary"
                onClick={() => setOpenConfirmation(true)}
                label={
                  isFilledModal ? 'Complete Bulk Settle' : 'Complete Bulk Fill'
                }
                loading={loading}
                disabled={disableBulkButton}
              />
              {isOrderOpen && (
                <p style={{fontSize: '14px', color: 'red'}}>
                  Please note that an Order cannot be Open
                </p>
              )}
            </Form>
          </div>
        }
      />

      {/* Confirmation modal */}
      <ConfirmModal
        title={isFilledModal ? 'COFIRM BULK SETTLED' : 'CONFIRM BULK FILL'}
        openModal={openConfirmation}
        setOpenModal={() => {
          setOpenConfirmation(false);
          updateAllForm.resetFields();
        }}
        confirmText={
          <span>
            Are you sure you want to{' '}
            <span className={styles.type}>
              BULK {isFilledModal ? 'SETTLED' : 'FILL'}
            </span>{' '}
            these <span className={styles.type}>{orders.length}</span> orders?
          </span>
        }
        loading={loading}
        handleOperation={handleUpdate}
      />

      {/* Failed Order Modal */}
      <ConfirmModal
        title={'Order Failed'}
        openModal={openFailedModal}
        setOpenModal={() => {
          setOpenConfirmation(false);
          updateAllForm.resetFields();
        }}
        confirmText={<span>These orders failed: {failedIds.join(', ')}</span>}
        loading={loading}
        confirmation={false}
      />
    </>
  );
};

export const ExportBroker = ({orderModal, activeTab, setOrderModal}) => {
  const {data, loading: brokerLoading} = useQuery(GET_ALL_BROKERS, {
    skip: !orderModal,
  });
  const [exportOrdersForBroker, {loading}] = useLazyQuery(BROKER_EXPORT);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [broker, setBroker] = useState('');
  const [recipient, setRecipient] = useState('');
  const [user, setUser] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [form] = Form.useForm();

  const {Option} = Select;

  useEffect(() => {
    form.resetFields();
    setUser(null);
  }, [activeTab]);

  const {
    companyId,
    startDate,
    endDate,
    collection,
    collectionId,
    startExpiryDate,
    endExpiryDate,
    type,
    direction,
    status,
    startSettlementDate,
    endSettlementDate,
    startFillDate,
    hold,
    endFillDate,
    affiliateOrder,
    stockExchange,
    instrumentKey,
    instrumentId,
  } = filterValues;

  const formattedValues = {
    companyId: extractCompanyId(companyId),
    ...(user && {userId: extractUserId(user)}),
    startDate: formatValue(startDate, d => moment(d).format()),
    endDate: formatValue(endDate, d => moment(d).format()),
    startExpiryDate: formatValue(startExpiryDate, d => moment(d).format()),
    endExpiryDate: formatValue(endExpiryDate, d => moment(d).format()),
    startSettlementDate: formatValue(startSettlementDate, d =>
      moment(d).format(),
    ),
    endSettlementDate: formatValue(endSettlementDate, d => moment(d).format()),
    startFillDate: formatValue(startFillDate, d => moment(d).format()),
    endFillDate: formatValue(endFillDate, d => moment(d).format()),
  };

  const handleUpdate = () => {
    exportOrdersForBroker({
      variables: {
        input: {
          brokerId: broker,
          recipients: recipient,
          status: status?.length >= 1 ? status : null,
          ...formattedValues,
          collection,
          affiliateOrder,
          stockExchange,
          instrumentKey,
          instrumentId,
          collectionId,
          type,
          direction,
          hold,
        },
      },
    })
      .then(({data: {exportOrdersForBroker}}) => {
        if (exportOrdersForBroker.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${exportOrdersForBroker.message} `,
          );
        } else {
          toast.success('Export has been processed');
          setOrderModal(false);
          setBroker('');
          setRecipient('');
          form.resetFields();
          setUser('');
          setFilterValues({});
          setOpenConfirmModal(false);
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  const validateEmail = (rule, value, callback) => {
    if (!value) {
      callback();
    } else {
      const emailRegex = /^(.+)@(.*\.)?(dabafinance\.com|joindaba\.com)$/i;
      if (!emailRegex.test(value)) {
        callback('Please use an official email');
      } else {
        callback();
      }
    }
  };

  return (
    <>
      <DBModal
        isOpen={orderModal}
        handleClose={() => {
          setOrderModal(false);
        }}
        content={
          <div className={styles.modall}>
            <h1>Broker Export</h1>
            <Form
              form={form}
              onFinish={values => {
                setOpenConfirmModal(true);
                setFilterValues(values);
              }}
              layout="vertical">
              <div className={styles.price}>
                <Form.Item
                  name="broker"
                  className={styles.smallWidth}
                  label="Broker"
                  style={{marginBottom: '20px'}}
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Select
                    loading={brokerLoading}
                    value={broker}
                    onChange={e => setBroker(e)}>
                    {data?.getBrokers?.data.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="recipient"
                  label="Official Email Address"
                  style={{marginBottom: '20px'}}
                  className={styles.smallWidth}
                  rules={[
                    {
                      required: true,
                      message: 'Please input email address',
                    },
                    {validator: validateEmail},
                  ]}>
                  <Input
                    value={recipient}
                    onChange={e => setRecipient(e.target.value)}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="status"
                style={{marginBottom: '20px'}}
                label="Status(es)">
                <Select value={status} mode="tags">
                  {stockOrderStatus.map(data => (
                    <Select.Option key={data.key}>{data.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <OrderFilter
                isFilterModalOpen={orderModal}
                isExport={true}
                user={user}
                exportForm={form}
                setUser={setUser}
              />
              <SubmitButton loading={loading} label={'Proceed to Export'} />
            </Form>
          </div>
        }
      />
      <ConfirmModal
        title={'CONFIRM EXPORT'}
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmText={<span>Are you sure you want to EXPORT these orders?</span>}
        loading={loading}
        handleOperation={handleUpdate}
      />
    </>
  );
};

export const ConfirmModal = ({
  title,
  openModal,
  setOpenModal,
  confirmText,
  loading,
  handleOperation,
  confirmation = true,
}) => {
  return (
    <>
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.modal}>
            <h1>{title}</h1>
            {confirmText}
            {confirmation ? (
              <div className={styles.buttons}>
                <Button
                  onClick={() => setOpenModal(false)}
                  type="secondary"
                  label="NO"
                />
                <Button
                  disabled={loading}
                  onClick={handleOperation}
                  label="YES"
                />
              </div>
            ) : (
              <div className={styles.buttons}>
                <Button
                  onClick={() => setOpenModal(false)}
                  type="primary"
                  label="Okay"
                />
              </div>
            )}
          </div>
        }
      />
    </>
  );
};
