import {DatePicker, Form, InputNumber} from 'antd';
import styles from './assetValuation.module.scss';
import {getUserTimezone} from 'utils/helper';
import moment from 'moment';
import {InfoCircleOutlined} from '@ant-design/icons';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {useMutation} from '@apollo/client';
import {UPDATE_INSTRUMENT_VALUATION} from 'graphql/mutations/assetManagement';
import {toast} from 'react-toastify';

export const AssetValuationModal = ({content, refetch, onClose}) => {
  const [formModal, setFormModal] = useState({open: false, content: null});
  const [form] = Form.useForm();

  useEffect(() => {
    if (content) {
      const {currentNavDate, previousNavDate, ...restOfFields} = content;
      form.setFieldsValue({
        currentNavDate: currentNavDate
          ? moment(currentNavDate, 'YYYY-MM-DD HH:mm:ss')
          : null,
        previousNavDate: previousNavDate
          ? moment(previousNavDate, 'YYYY-MM-DD HH:mm:ss')
          : null,

        ...restOfFields,
      });
    }
  }, [content]);

  const [updateMutualFundCompanyValuationHistory, {loading}] = useMutation(
    UPDATE_INSTRUMENT_VALUATION,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h1>Instrument Valuation Update </h1>
        <p>
          {' '}
          This form will update the valuation metrics across all related
          instruments. Please review all values carefully before submission.{' '}
        </p>
      </div>
      <div className={styles.infoHeader}>
        <InfoCircleOutlined />
        <p>
          <span>NAV:</span> Net Asset Value
        </p>
      </div>
      <Form
        onFinish={values => setFormModal({open: true, content: values})}
        form={form}
        className={styles.form}
        layout="vertical">
        <Form.Item
          tooltip="The date when the current NAV was calculated"
          name="currentNetAssetValue"
          rules={[{required: true, message: 'This is a Required Field'}]}
          className={styles.smallWidth}
          label="Current NAV">
          <InputNumber
            className={styles['input-number']}
            placeholder="10,000"
            min={0}
            formatter={value => {
              if (!value) return '';
              // Split number into integer and decimal parts
              const [int, dec] = value.toString().split('.');
              // Format integer part with commas
              const formattedInt = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              // Return formatted number with decimal if it exists
              return dec ? `${formattedInt}.${dec}` : formattedInt;
            }}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          className={styles.smallWidth}
          name="currentNavDate"
          rules={[{required: true, message: 'This is a Required Field'}]}
          tooltip="The last recorded NAV before the current valuation"
          label="Current NAV Date">
          <DatePicker
            className={styles.smallWidth}
            disabledDate={current => current && current > moment().endOf('day')}
            disabledTime={selectedDate => {
              if (!selectedDate) {
                return {
                  disabledHours: () => [],
                  disabledMinutes: () => [],
                  disabledSeconds: () => [],
                };
              }

              const isSameDay = moment().isSame(selectedDate, 'day');
              const isFutureDay = selectedDate.isAfter(moment(), 'day');

              return {
                disabledHours: () => {
                  if (isSameDay) {
                    const currentHour = moment().hour();
                    const hours = [];
                    for (let i = currentHour + 1; i < 24; i++) {
                      hours.push(i);
                    }
                    return hours;
                  }
                  return isFutureDay ? [...Array(24).keys()] : [];
                },
                disabledMinutes: selectedHour => {
                  if (isSameDay && selectedHour === moment().hour()) {
                    const currentMinute = moment().minute();
                    const minutes = [];
                    for (let i = currentMinute + 1; i < 60; i++) {
                      minutes.push(i);
                    }
                    return minutes;
                  }
                  return (isSameDay && selectedHour > moment().hour()) ||
                    isFutureDay
                    ? [...Array(60).keys()]
                    : [];
                },
                disabledSeconds: (selectedHour, selectedMinute) => {
                  if (
                    isSameDay &&
                    selectedHour === moment().hour() &&
                    selectedMinute === moment().minute()
                  ) {
                    const currentSecond = moment().second();
                    const seconds = [];
                    for (let i = currentSecond + 1; i < 60; i++) {
                      seconds.push(i);
                    }
                    return seconds;
                  }
                  return (isSameDay && selectedHour > moment().hour()) ||
                    (isSameDay &&
                      selectedHour === moment().hour() &&
                      selectedMinute > moment().minute()) ||
                    isFutureDay
                    ? [...Array(60).keys()]
                    : [];
                },
              };
            }}
            renderExtraFooter={() => (
              <div style={{textAlign: 'center'}}>
                <p>Your timezone is: {getUserTimezone()}</p>
              </div>
            )}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
        <Form.Item
          className={styles.date}
          name="previousNavDate"
          tooltip="The date when the previous NAV was recorded"
          label="Previous NAV Date">
          <DatePicker
            className={styles.smallWidth}
            disabled
            renderExtraFooter={() => (
              <div style={{textAlign: 'center'}}>
                <p>Your timezone is: {getUserTimezone()}</p>
              </div>
            )}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
        <div className={styles.smallWidth} />
        <SubmitButton label="Proceed" />
      </Form>
      <ConfirmModal
        openModal={formModal.open}
        setOpenModal={() => setFormModal({open: false, content: null})}
        title="Confirm Changes"
        loading={loading}
        confirmText="Are you sure you want to update this data"
        handleOperation={() => {
          updateMutualFundCompanyValuationHistory({
            variables: {
              input: {
                exchangeId: content?.exchange,
                instrumentId: content?.id,
                exchangeCode: 'DABA',
                currentNetAssetValue: formModal?.content?.currentNetAssetValue,
                currentNavDate: moment(
                  formModal?.content?.currentNavDate,
                ).format(),
              },
            },
          })
            .then(({data: {updateMutualFundCompanyValuationHistory}}) => {
              if (
                updateMutualFundCompanyValuationHistory.__typename ===
                  'Error' ||
                updateMutualFundCompanyValuationHistory.statusCode === 400
              ) {
                return toast.error(
                  updateMutualFundCompanyValuationHistory.message,
                );
              } else {
                toast.success(updateMutualFundCompanyValuationHistory.message);
                setFormModal({open: false, content: null});
                refetch();
                onClose();
                form.resetFields();
              }
            })
            .catch(err => toast.error(err.message));
        }}
      />
    </div>
  );
};
