import {useQuery} from '@apollo/client';
import {Form, Input, Select, Switch, Tooltip} from 'antd';
import styles from './generalDetails.module.scss';
import {isDev, supportedLanguages} from 'utils/constants';
import {currencyCodes} from 'pages/adminOpportunities/stocksListings/create/CreateStock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {INSTRUMENTS_DETAILS_QUERIES} from 'graphql/queries/assetManagement';
import {useEffect, useState} from 'react';
import {issuerTypes} from 'pages/AssetManagement/modules/constants';
import {GET_ALL_STOCKS_WITH_INSTRUMENT_KEY} from 'graphql/queries/stocks';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import UploadImage from 'components/Startup/profile/companyImage';

export const BaseGeneralDetails = ({onSubmit, title, existingValues}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [icon, setIcon] = useState(null);
  const [form] = Form.useForm();
  const {id, assetId} = useParams();
  const {data: getAllQueries, loading} = useQuery(INSTRUMENTS_DETAILS_QUERIES);
  const {data: stockExchange, loading: stockLoading} = useQuery(
    GET_ALL_STOCKS_WITH_INSTRUMENT_KEY,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error),
    },
  );

  useEffect(() => {
    if (id) {
      setIcon(existingValues?.icon);
      setPreviewUrl(existingValues?.icon);
      form.setFieldsValue({
        ...existingValues,
        sectorIndustry: existingValues?.sectorIndustry?.id,
        topics:
          existingValues?.topics
            ?.filter(topic => topic?.id != null)
            .map(topic => topic?.id) || [],
        issuer: existingValues?.issuer?.id,
        issuerType: existingValues?.issuer?.issuerType,
        exchange: existingValues?.exchange,
        currency: existingValues?.currency?.currencyCode,
      });
    } else {
      setIcon(existingValues?.icon);
      setPreviewUrl(existingValues?.icon);
      form.setFieldsValue({...existingValues});
    }
  }, [existingValues]);

  return (
    <div className={styles.form}>
      <h1>General Details</h1>
      <Form
        onFinish={values => {
          if (!icon && assetId === 'FUNDS')
            return toast.error('Icon is Required');
          onSubmit({...values, icon});
        }}
        form={form}
        layout="vertical">
        <UploadImage
          label="Upload Icon"
          isACL
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
          setImgUrl={setIcon}
          folder={'bonds'}
          bucket={
            isDev
              ? 'dabafinancialinstruments-nonprod'
              : 'dabafinancialinstruments-prod'
          }
        />
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          className={styles.field}
          name="issuer"
          label="Issuer">
          <Select
            showSearch
            allowClear
            disabled={loading}
            filterOption={true}
            optionFilterProp="filter"
            loading={loading}>
            {loading ? (
              <Select.Option disabled key="Loading...">
                Loading Issuers...
              </Select.Option>
            ) : (
              getAllQueries?.getAllCompanies?.map(company => (
                <Select.Option
                  filter={`${company?.name}${company?.ticker}`}
                  key={company?.id}>
                  <div className="all-data">
                    <img src={company?.logoImgURL} alt="" />
                    <span>{company?.name}</span>
                  </div>
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.field}
          rules={[{required: true, message: 'This is a Required Field'}]}
          name="issuerType"
          label="Issuer Type">
          <Select>
            {issuerTypes.map(data => (
              <Select.Option key={data.value}>{data.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{required: true, message: 'This is a Required Field'}]}
          className={styles.field}
          name="name"
          label={`${title} Name`}>
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.field}
          rules={[{required: true, message: 'This is a Required Field'}]}
          name="description"
          label="Description">
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item className={styles.field} name="topics" label="Topics">
          <Select
            showSearch
            allowClear
            mode="multiple"
            filterOption={true}
            disabled={loading}
            optionFilterProp="filter"
            loading={loading}>
            {loading ? (
              <Select.Option disabled key="Loading...">
                Loading Topics...
              </Select.Option>
            ) : (
              getAllQueries?.getAllTopics?.map(company => (
                <Select.Option filter={`${company?.name}`} key={company?.id}>
                  <div key={company.id} className="all-data">
                    <img src={company?.icon} alt="" />
                    <span>{company?.name}</span>
                  </div>
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.field}
          name="sectorIndustry"
          rules={[
            {required: assetId !== 'FUNDS', message: 'Please select a status'},
          ]}
          label="Industry">
          <Select
            showSearch
            allowClear
            filterOption={true}
            disabled={loading}
            optionFilterProp="filter"
            loading={loading}>
            {loading ? (
              <Select.Option disabled key="Loading...">
                Loading Industries...
              </Select.Option>
            ) : (
              getAllQueries?.getAllIndustries?.map(company => (
                <Select.Option filter={`${company?.name}`} key={company?.id}>
                  <div className="all-data">
                    <img src={company?.icon} alt="" />
                    <span>{company?.name}</span>
                  </div>
                </Select.Option>
              ))
            )}
          </Select>
        </Form.Item>
        <h2>Additional {title} Details</h2>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="listingType"
            label="Listing Type">
            <Select placeholder="Listed">
              <Select.Option value="LISTED">Listed</Select.Option>
              <Select.Option value="UNLISTED">Unlisted</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="currency"
            label="Select Currency">
            <Select>
              {currencyCodes.map(data => (
                <Select.Option key={data.value}>{data.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          className={styles.field}
          name="exchange"
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Select Stock Exchange">
          <Select
            loading={stockLoading}
            placeholder="Select stock exchange"
            showSearch
            disabled={stockLoading}
            optionFilterProp="children">
            {stockLoading ? (
              <Select.Option disabled key="Loading">
                Loading...
              </Select.Option>
            ) : (
              stockExchange?.getAllStocksExchange
                ?.filter(
                  stock =>
                    stock?.instrument ===
                    (assetId === 'FUNDS' ? 'MUTUAL_FUND' : 'BOND'),
                )
                ?.map(item => (
                  <Select.Option value={item?.id} key={item?.id}>
                    {item.stockExchangeName}
                  </Select.Option>
                ))
            )}
          </Select>
        </Form.Item>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="language"
            label="Select Language">
            <Select>
              {supportedLanguages.map(data => (
                <Select.Option key={data.value}>{data.label}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="liquidity"
            rules={[{required: true, message: 'Please select a status'}]}
            label="Liquidity">
            <Input />
          </Form.Item>
        </div>
        <div className={styles.fieldSwitch}>
          <Form.Item
            name="isShariaCompliant"
            label={
              <Tooltip title="Does this investment complies with Sharia law">
                Sharia Compliant
              </Tooltip>
            }>
            <Switch defaultChecked={existingValues?.isShariaCompliant} />
          </Form.Item>
        </div>
        <SubmitButton label={!id ? 'Save and Proceed' : 'Update'} />
      </Form>
    </div>
  );
};
