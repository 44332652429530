import styles from './managementCard.module.scss';
import deleteImg from 'images/delete.svg';
import {useMutation} from '@apollo/client';
import editImg from 'images/edit.svg';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {DELETE_INVESTOR_UPDATE} from 'graphql/mutations/investorUpdates';
import {toast} from 'react-toastify';
import {getUserTimezone} from 'utils/helper';

const ManagementCard = ({title, date, image, id, operations}) => {
  const navigate = useNavigate();

  const [deleteInvestorUpdate] = useMutation(DELETE_INVESTOR_UPDATE);

  const handleDelete = () => {
    deleteInvestorUpdate({
      variables: {
        deleteInvestorUpdateId: id,
      },
      update(cache) {
        const normalizedId = cache.identify({
          id,
          __typename: 'InvestorUpdate',
        });
        cache.evict({id: normalizedId});
        cache.gc();
      },
    }).then(({data: {deleteInvestorUpdate}}) => {
      if (deleteInvestorUpdate.__typename === 'Error') {
        toast.error(deleteInvestorUpdate.message);
      } else {
        toast.success('Deleted Successfully');
      }
    });
  };

  const trucateTitle = () => {
    if (title.length > 35) {
      return title.substring(0, 35) + '...';
    } else return title;
  };
  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <img src={image} alt="" />
      </div>
      <div className={styles.content}>
        <h1>{trucateTitle()}</h1>
        <div>
          <span>
            {`Published - ${moment(date).format('LLL')}`} ({getUserTimezone()})
          </span>
          <div className={styles.footer}>
            {operations?.delete && (
              <div onClick={handleDelete} className={styles['action-icon']}>
                <img src={deleteImg} alt="delete" />
                <span>Delete</span>
              </div>
            )}
            {operations?.update && (
              <div
                onClick={() =>
                  navigate(`/dashboard/explore/investor-update/edit/${id}`)
                }
                className={styles['action-icon']}>
                <img src={editImg} alt="edit" />
                <span>Edit</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementCard;
