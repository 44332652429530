import moment from 'moment';
import styles from './userCard.module.scss';
import {useNavigate} from 'react-router-dom';

const UserCard = ({id, firstName, lastName, data, operations, imageUrl}) => {
  const fullName = `${firstName ?? ''} ${lastName ?? ''}`;
  const navigate = useNavigate();
  return (
    <div
      key={id}
      onClick={() =>
        operations?.view ? navigate(`/dashboard/user/${id}`) : {}
      }
      className={
        operations?.view ? styles.container : styles.disabledContainer
      }>
      <div className={styles['image-style']}>
        {imageUrl ? (
          <img src={imageUrl} alt="" />
        ) : (
          <div className={styles.dummyAvatar}>
            {firstName?.charAt(0)}
            {lastName?.charAt(0)}
          </div>
        )}
      </div>
      <h1>
        {fullName?.length > 20 ? fullName?.substring(0, 20) + '...' : fullName}
      </h1>
      <div className={styles.date}>
        <span>
          <strong>Joined:</strong>{' '}
          {moment(data?.createdAt).format('DD MMM, YYYY')}
        </span>
        <span className={styles.lastSession}>
          <strong>Last Session:</strong>{' '}
          {moment(data?.currentSessionDetails?.currentDateTime).format(
            'DD MMM, YY. hh:mm A',
          )}
        </span>
      </div>
    </div>
  );
};

export default UserCard;
