/* eslint-disable no-unused-vars */
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import {Input, Form, Spin} from 'antd';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import styles from './basePitchDeck.module.scss';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {Viewer, Worker} from '@react-pdf-viewer/core';
import UploadImage from 'components/Startup/profile/companyImage';
import SubmitButton from 'components/Startup/components/SubmitButton';
import FadeEffect from 'components/animation/fadeEffect';
import {Button} from '@dabafinance/react-components';

const BasePitchDeck = ({onSubmit, existingData}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [pdfFile, setPdfFile] = useState(null);
  const [linkType, setLinkType] = useState('INTERNAL');
  const [disableButton, setDisabledButton] = useState(true);
  const [externalFileLink, setExternalFileLink] = useState(null);
  const [externalShareLink, setExternalShareLink] = useState(null);
  const [pdfLink, setPdfLink] = useState(null);
  const [loadingPdfUpload, setLoadingPdfUpload] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [viewPdf, setViewPdf] = useState(null);
  const [pdfName, setPdfName] = useState('Supported formats pdf, docs');
  const [form] = Form.useForm();

  const isDev = process.env.REACT_APP_API_BASE_URL.includes('.daba-dev.');

  useEffect(() => {
    if (
      !imgUrl ||
      (!pdfLink && linkType === 'INTERNAL') ||
      (pdfLink && (externalFileLink || externalShareLink))
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  }, [imgUrl, pdfLink, linkType, externalFileLink, externalShareLink]);

  const fileType = ['application/pdf'];

  useEffect(() => {
    if (existingData && existingData?.pitchDeck) {
      form.setFieldsValue({
        ...existingData.pitchDeck,
      });
      setPdfLink(existingData.pitchDeck.fileLink);
      setPdfFile(existingData.pitchDeck.fileLink);
      setPdfName(existingData.pitchDeck.pdfName);
      setLinkType(existingData.pitchDeck.linkType);
      setImgUrl(existingData.pitchDeck.coverImage);
    }
  }, []);

  const handlePdfFileChange = async e => {
    const selectedFile = e.target.files[0];
    console.log('handlePdfFileChange called', new Date());
    setPdfName(selectedFile.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        setLoadingPdfUpload(true);
        const imageUrl = await UploadDocumentToBucket(
          selectedFile,
          () => {},
          isDev
            ? 'dabafinancialinstruments-nonprod'
            : 'dabafinancialinstruments-prod',
          true,
          'bonds',
        );
        setPdfLink(imageUrl);
        setPdfFile(imageUrl);
        setLoadingPdfUpload(false);
      } else {
        setPdfFile(null);
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
  };

  const handleDeleteFile = () => {
    setPdfFile(null);
    setPdfLink(null);
    setPdfName('Supported formats pdf, docs');
    setViewPdf(null);
  };

  const handlePdfFileSave = e => {
    e.preventDefault();
    setViewPdf(pdfFile);
  };

  return (
    <div className={styles.container}>
      <h1>Pitch Deck</h1>
      <span>
        Please note that you are only allowed to choose one type to proceed
      </span>
      <div className={styles['details-toggler']}>
        <span
          className={linkType === 'INTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setLinkType('INTERNAL')}>
          {' '}
          PDF UPLOAD
        </span>
        <span
          className={linkType === 'EXTERNAL' ? styles['active-toggle'] : ''}
          onClick={() => setLinkType('EXTERNAL')}>
          {' '}
          EXTERNAL LINK
        </span>
      </div>
      <div className={styles['pitch-body']}>
        <Form
          form={form}
          onFinish={values =>
            onSubmit({
              pitchDeck: {
                ...values,
                fileLink: pdfLink,
                pdfFile,
                coverImage: imgUrl,
                pdfName,
                linkType,
              },
            })
          }
          layout="vertical">
          {linkType === 'INTERNAL' && (
            <>
              {!viewPdf && (
                <form>
                  <label className={styles.upload} htmlFor="file-upload">
                    {loadingPdfUpload ? (
                      <div className={styles.spinner}>
                        <Spin size="large" />
                        <h3>Uploading...</h3>
                      </div>
                    ) : (
                      <span> {pdfName}</span>
                    )}
                    <input
                      type="file"
                      id="file-upload"
                      accept="application/pdf"
                      className={styles['input-file']}
                      required
                      onChange={handlePdfFileChange}
                    />
                  </label>
                </form>
              )}
              {viewPdf && (
                <FadeEffect>
                  <div className={styles['pdf-height']}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={viewPdf}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                </FadeEffect>
              )}
            </>
          )}
          {linkType === 'EXTERNAL' && (
            <>
              <Form.Item
                className={styles['field-width']}
                label="File Link"
                name="externalViewLink"
                rules={[
                  {
                    required: linkType === 'EXTERNAL',
                    message: 'External Link is required',
                  },
                  {
                    type: 'url',
                    message: 'Please make sure link is valid',
                  },
                ]}>
                <Input
                  value={externalFileLink}
                  onChange={e => setExternalFileLink(e.target.value)}
                />
              </Form.Item>
              <Form.Item
                className={styles['field-width']}
                label="Share Link"
                name="externalShareLink"
                rules={[
                  {
                    required: linkType === 'EXTERNAL',
                    message: 'Share link is required',
                  },
                  {
                    type: 'url',
                    message: 'Please make sure link is valid',
                  },
                ]}>
                <Input
                  value={externalShareLink}
                  onChange={e => setExternalShareLink(e.target.value)}
                />
              </Form.Item>
            </>
          )}
          {(pdfLink || viewPdf) && linkType !== 'EXTERNAL' && (
            <div className={styles['button-wrapper']}>
              <Button
                disabled={pdfFile === ''}
                label="Preview PDF"
                onClick={handlePdfFileSave}
              />
              <Button
                type="secondary"
                label="Delete"
                onClick={handleDeleteFile}
              />
            </div>
          )}
          <div className={styles.image}>
            <h1 className={styles.title}>Select Pitch Deck Preview Image</h1>
            <div>
              <UploadImage
                large
                previewUrl={imgUrl}
                setImgUrl={setImgUrl}
                isACL={true}
                folder={'bonds'}
                bucket={
                  isDev
                    ? 'dabafinancialinstruments-nonprod'
                    : 'dabafinancialinstruments-prod'
                }
              />
            </div>
          </div>
          <div className={styles.submit}>
            <SubmitButton disabled={disableButton} label="Save and Proceed" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BasePitchDeck;
