const {createSlice} = require('@reduxjs/toolkit');

const slice = createSlice({
  name: 'assetClass',
  initialState: {
    bondInstruments: {},
    mutualFundsInstruments: {},
    mutualFunds: {},
    bonds: {},
    groupedInstruments: {},
  },
  reducers: {
    handleBondsInstrumentState: (state, action) => {
      state.bondInstruments = {
        ...state.bondInstruments,
        ...action.payload,
      };
    },
    handleBondsFieldState: (state, action) => {
      state.bonds = {
        ...state.bonds,
        ...action.payload,
      };
    },
    handleMutualFundsInstruments: (state, action) => {
      state.mutualFundsInstruments = {
        ...state.mutualFundsInstruments,
        ...action.payload,
      };
    },
    handleMutualFunds: (state, action) => {
      state.mutualFunds = {
        ...state.mutualFunds,
        ...action.payload,
      };
    },
    handleUpdateGroupedInstruments: (state, action) => {
      state.groupedInstruments = {
        ...state.groupedInstruments,
        ...action.payload,
      };
    },
    handleClearGroupedInstruments: state => {
      state.groupedInstruments = {};
    },
    handleClearBondsState: state => {
      state.bonds = {};
      state.bondInstruments = {};
    },
    handleClearMutualFundsState: state => {
      state.mutualFunds = {};
      state.mutualFundsInstruments = {};
    },
  },
});

export default slice.reducer;

export const {
  handleBondsInstrumentState,
  handleClearBondsState,
  handleClearMutualFundsState,
  handleMutualFunds,
  handleMutualFundsInstruments,
  handleUpdateGroupedInstruments,
  handleClearGroupedInstruments,
  handleBondsFieldState,
} = slice.actions;
