import {useCallback, useState} from 'react';
import styles from './addUsers.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {
  DatePicker,
  Dropdown,
  Form,
  InputNumber,
  Pagination,
  Select,
  Switch,
  Table,
  Tag,
} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {
  ADD_USERS_TO_A_GROUP,
  REMOVE_MEMBER,
  RESTRICT_GROUP_MEMBER,
  UPDATE_GROUP_MEMBERS_DURATION,
} from 'graphql/mutations/groups';
import {toast} from 'react-toastify';
import {GET_GROUP_MEMBERS_BY_ID} from 'graphql/queries/groups';
import DBModal from 'components/modal/modal';
import gamerIcon from 'images/gamer.png';
import moment from 'moment';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {Search} from '@dabafinance/react-components';
import dotsIcon from 'images/dots.svg';
import {debounce, sanitizeInput} from 'utils/helper';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';

const AddUsersToGroup = ({operations}) => {
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [updateGroupMemberModal, setUpdateGroupMemberModal] = useState(false);
  const [isEditMembershipEnabled, setIsEditMembershipEnabled] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchValue, setSearchedValue] = useState(null);
  const [restrictUser, setRestrictUser] = useState({open: false, record: null});
  const [limit, setLimit] = useState(10);
  const navigate = useNavigate();
  const {id} = useParams();
  const [form] = Form.useForm();
  const typeWatch = Form.useWatch('type', form);

  const {data, refetch, loading} = useQuery(GET_GROUP_MEMBERS_BY_ID, {
    variables: {
      filters: {
        groupId: id,
        page: 1,
        limit: 10,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: error => toast.error(error.message),
    onCompleted: data => {
      setTotalPage(data?.adminGetGroupMembers?.pagination?.total),
        setIsEditMembershipEnabled(
          data?.adminGetGroupMembers?.group?.settings?.timeBasedMembership
            ?.enabled,
        );
    },
  });
  const [addMembersToGroup, {loading: loadingUpdate}] =
    useMutation(ADD_USERS_TO_A_GROUP);

  const [restrictGroupMember, {loading: loadingGroupMember}] = useMutation(
    RESTRICT_GROUP_MEMBER,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  const [removeMemberFromGroup, {loading: removeMember}] =
    useMutation(REMOVE_MEMBER);

  const handlePageChange = currentPage => {
    if (page === currentPage) return;
    setPage(currentPage);
    refetch({
      filters: {
        groupId: id,
        page: currentPage,
        limit,
        search: searchValue,
      },
    });
  };

  const handleSearch = value => {
    const sanitizedValue = sanitizeInput(value);
    setPage(1);
    setLimit(10);
    if (!sanitizedValue) {
      return refetch({
        filters: {
          groupId: id,
          page: 1,
          limit: 10,
        },
      });
    }
    refetch({
      filters: {
        groupId: id,
        search: sanitizedValue,
      },
    });
  };

  const optimizedSearchCall = useCallback(debounce(handleSearch), []);

  const handleDelete = () => {
    removeMemberFromGroup({
      variables: {
        groupId: id,
        memberIds: members,
      },
    }).then(({data: {removeMemberFromGroup}}) => {
      if (removeMemberFromGroup.__typename === 'Error') {
        toast.error(removeMemberFromGroup.message);
        setOpenRemoveModal(false);
      } else {
        setMembers([]);
        refetch();
        toast.success('Removed Successfully');
        setOpenRemoveModal(false);
      }
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <div
            onClick={() => navigate(`/dashboard/user/${record?.user?.id}`)}
            className={styles['record-name']}>
            {record?.user?.imageUrl ? (
              <img src={record?.user?.imageUrl} alt="" />
            ) : (
              <img src={gamerIcon} alt="" />
            )}
            <h4 style={{width: 100}}>
              {record?.user?.firstName} {record?.user?.lastName}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <div>
          <h4>{record?.user?.username}</h4>
        </div>
      ),
    },
    {
      title: 'Phone Number',
      dataIndex: 'expiring',
      key: 'expiring',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{`${record?.user?.countryCode ?? '--'}${
            record?.user?.phoneNumber ?? '--'
          }`}</h4>
        </div>
      ),
    },
    {
      title: 'Profile Completion',
      dataIndex: 'profileCompleted',
      key: 'invested',
      render: (text, record) => (
        <div>
          <span>{record?.user?.profileCompletion?.completionPercentage}%</span>
        </div>
      ),
    },
    {
      title: 'Lifetime Member',
      dataIndex: 'lifetimeMember',
      key: 'lifetimeMember',
      render: (text, record) => (
        <Tag color={record?.membershipDuration?.isLifetime ? 'green' : 'red'}>
          {record?.membershipDuration?.isLifetime ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    {
      title: 'Membership Duration',
      dataIndex: 'duration',
      key: 'duration',
      children: [
        {
          title: 'Start Date',
          dataIndex: 'startDate',
          key: 'startDate',
          render: (text, record) => (
            <div className={styles['record-label']}>
              <h4>
                {record?.membershipDuration?.startDate
                  ? moment(record?.membershipDuration?.startDate).format(
                      'DD MMM YYYY hh:mm A',
                    )
                  : 'N/A'}
              </h4>
            </div>
          ),
        },
        {
          title: 'End Date',
          dataIndex: 'endDate',
          key: 'endDate',
          render: (text, record) => (
            <div className={styles['record-label']}>
              <h4>
                {record?.membershipDuration?.endDate
                  ? moment(record?.membershipDuration?.endDate).format(
                      'DD MMM, YYYY hh:mm A',
                    )
                  : 'N/A'}
              </h4>
            </div>
          ),
        },
      ],
    },
    {
      title: 'Accredited',
      dataIndex: 'accredited',
      key: 'accredited',
      render: (text, record) => (
        <div>
          <h4>
            {record?.user?.investorProfile?.isAccredited
              ? 'Accredited'
              : 'Unaccredited'}
          </h4>
        </div>
      ),
    },
    {
      title: 'Messaging Restriction',
      dataIndex: 'restriction',
      key: 'restriction',
      render: (text, record) => (
        <Tag
          color={
            record?.user?.restrictedCircles?.includes(id) ? 'red' : 'green'
          }>
          {record?.user?.restrictedCircles?.includes(id)
            ? 'Restricted'
            : 'Full Access'}
        </Tag>
      ),
    },
    {
      title: 'Date Joined',
      dataIndex: 'createdAt',
      key: 'joined',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>{moment(record?.user?.createdAt).format('MMM Do YYYY')}</h4>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                label: record?.user?.restrictedCircles?.includes(id)
                  ? 'Remove Restriction'
                  : 'Restrict Member',
                key: 1,
                onClick: () =>
                  setRestrictUser({
                    open: true,
                    record: {
                      id: record?.user?.id,
                      isRestricted:
                        record?.user?.restrictedCircles?.includes(id),
                    },
                  }),
              },
            ],
          }}>
          <img role="button" src={dotsIcon} />
        </Dropdown>
      ),
    },
  ];

  const handleSubmit = values => {
    addMembersToGroup({
      variables: {
        groupId: id,
        memberIds: users.map(data => data.split(';')[2]),
        ...(values?.unit && {
          duration: {
            unit: values?.unit,
            value: parseInt(values?.value),
          },
        }),
        ...(values?.expiryTime && {
          expiryTime: moment(values.expiryTime).format(),
        }),
        isLifetime: values.isLifetime || false,
      },
    })
      .then(({data: {addMembersToGroup}}) => {
        if (addMembersToGroup.response) {
          toast.success('Member(s) added successful');
          setOpenModal(false);
          setUsers([]);
          form.resetFields();
          refetch();
        } else {
          toast.error('Member already exists');
        }
      })
      .catch(error => toast.error(error));
  };

  return (
    <div>
      <div className={styles.title}>
        <h1>Circle Members</h1>
        <div className={styles['action-buttons']}>
          <Search
            value={searchValue}
            placeholder="Search by Names"
            onChange={e => {
              setSearchedValue(e);
              optimizedSearchCall(e, page, limit);
            }}
            id={'user-search'}
          />
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  key: '1',
                  label: 'Add User(s) to Circle',
                  onClick: () => setOpenModal(true),
                  disabled: !operations?.update,
                },
                {
                  key: '2',
                  label: 'Remove User(s) from Circle',
                  onClick: () => setOpenRemoveModal(true),
                  disabled: !operations?.update,
                },
                {
                  key: '3',
                  disabled: !operations?.update || !isEditMembershipEnabled,
                  label: 'Update Circle Member(s) Duration',
                  onClick: () => setUpdateGroupMemberModal(true),
                },
              ],
            }}>
            <div>
              <SubmitButton secondary label="Circle Operations" />
            </div>
          </Dropdown>
        </div>
      </div>
      <div>
        <Table
          dataSource={data?.adminGetGroupMembers?.data}
          columns={columns}
          className={styles['users-table']}
          pagination={false}
          loading={loading}
        />
        <Pagination
          onChange={e => handlePageChange(e)}
          showSizeChanger
          showQuickJumper
          pageSize={limit}
          total={totalPage}
          className="ant-table-pagination"
          current={page}
          onShowSizeChange={(_, size) => {
            setLimit(size);
            setPage(1);
            refetch({
              filters: {
                groupId: id,
                page: 1,
                search: searchValue,
                limit: size,
              },
            });
          }}
        />
      </div>
      <DBModal
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        content={
          <div className={styles.body}>
            <h2>Add Users to Circle</h2>
            <Form form={form} onFinish={handleSubmit} layout="vertical">
              <Form.Item
                label="Select Users To Add to Circle"
                name="users"
                className={styles.width}>
                <UserSelectField users={users} setUsers={setUsers} />
              </Form.Item>
              {isEditMembershipEnabled && (
                <>
                  <Form.Item label="Membership Type" name="type">
                    <Select>
                      <Select.Option key="time">
                        Time-Based Membership
                      </Select.Option>
                      <Select.Option key="duration">
                        Duration-Based Membership
                      </Select.Option>
                      <Select.Option key="lifetime">
                        Lifetime Membership
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {typeWatch === 'time' && (
                    <Form.Item
                      rules={[
                        {required: true, message: 'This is a required field'},
                      ]}
                      name="expiryTime"
                      label="Membership Expiry Date & Time">
                      <DatePicker
                        allowClear
                        format={'YYYY-MM-DD HH:mm:ss'}
                        showTime={{
                          defaultValue: moment('00:00', 'HH:mm:ss'),
                        }}
                      />
                    </Form.Item>
                  )}
                  {typeWatch === 'duration' && (
                    <>
                      <Form.Item
                        name="unit"
                        rules={[
                          {required: true, message: 'This is a required field'},
                        ]}
                        className={styles.smallWidth}
                        label="Select Duration Unit">
                        <Select placeholder="Days" allowClear>
                          <Select.Option value="days">Days</Select.Option>
                          <Select.Option value="months">Months</Select.Option>
                          <Select.Option value="years">Years</Select.Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        rules={[
                          {required: true, message: 'This is a required field'},
                        ]}
                        name="value"
                        label="Enter Duration Value">
                        <InputNumber
                          className={styles['input-number']}
                          min={1}
                        />
                      </Form.Item>
                    </>
                  )}
                  {typeWatch === 'lifetime' && (
                    <Form.Item
                      name="isLifetime"
                      label="Enable Lifetime Membership">
                      <Switch />
                    </Form.Item>
                  )}
                </>
              )}
              <SubmitButton label="Add Users" disabled={loadingUpdate} />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={openRemoveModal}
        handleClose={() => setOpenRemoveModal(false)}
        content={
          <div className={styles.body}>
            <h2>Remove Members</h2>
            <Form onFinish={handleDelete} layout="vertical">
              <Form.Item label="Select Users" name="users" required>
                <SearchUsersInGroup
                  groupId={id}
                  members={members}
                  setMembers={setMembers}
                />
              </Form.Item>
              <SubmitButton label="Remove Users" disabled={removeMember} />
            </Form>
          </div>
        }
      />
      <DBModal
        isOpen={updateGroupMemberModal}
        handleClose={() => setUpdateGroupMemberModal(false)}
        content={
          <EditGroupMemberDuration
            groupId={id}
            onClose={() => setUpdateGroupMemberModal(false)}
            refetch={refetch}
          />
        }
      />
      <ConfirmModal
        openModal={restrictUser.open}
        setOpenModal={() => setRestrictUser({open: false, record: null})}
        loading={loadingGroupMember}
        title="Confirm Action"
        confirmText="Are you sure you want to Restrict this From Messaging in this Group"
        handleOperation={() => {
          restrictGroupMember({
            variables: {
              input: {
                groupId: id,
                userId: restrictUser?.record?.id,
                restrictMessaging: !restrictUser?.record?.isRestricted,
              },
            },
          }).then(({data: {restrictGroupMember}}) => {
            if (
              restrictGroupMember.__typename === 400 ||
              restrictGroupMember.message === 'Error'
            ) {
              toast.error(restrictGroupMember.message);
            } else {
              toast.success(restrictGroupMember.message);
              refetch();
              setRestrictUser({open: false, record: null});
            }
          });
        }}
      />
    </div>
  );
};

const EditGroupMemberDuration = ({groupId, onClose, refetch}) => {
  const [members, setMembers] = useState([]);
  const [form] = Form.useForm();
  const typeWatch = Form.useWatch('type', form);

  const [updateMembershipDuration, {loading}] = useMutation(
    UPDATE_GROUP_MEMBERS_DURATION,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  return (
    <div>
      <h2>Update Group Member(s) Duration</h2>
      <Form
        onFinish={values =>
          updateMembershipDuration({
            variables: {
              input: {
                action: values.action,
                groupId,
                ...(values?.unit && {
                  duration: {
                    unit: values?.unit,
                    value: parseInt(values?.value),
                  },
                }),
                memberIds: members,
                ...(values?.expiryTime && {
                  expiryTime: moment(values.expiryTime).format(),
                }),
                isLifetime: values.isLifetime || false,
              },
            },
          })
            .then(({data: {updateMembershipDuration}}) => {
              if (
                updateMembershipDuration.__typename === 'Error' ||
                updateMembershipDuration.statusCode === 400
              ) {
                toast.error(updateMembershipDuration.message);
              } else {
                refetch();
                toast.success(updateMembershipDuration.message);
                form.resetFields();
                setMembers([]);
                onClose();
              }
            })
            .catch(error => toast.error(error))
        }
        layout="vertical"
        form={form}>
        <Form.Item label="Choose Group Members" name="users" required>
          <SearchUsersInGroup
            groupId={groupId}
            members={members}
            setMembers={setMembers}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {required: true, message: 'Please select a duration adjustment'},
          ]}
          name="action"
          label="Select Duration Adjustment">
          <Select>
            <Select.Option value="add">
              Extend Membership Duration
            </Select.Option>
            <Select.Option value="subtract">
              Reduce Membership Duration
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Membership Type"
          rules={[{required: true, message: 'Please select a membership type'}]}
          name="type">
          <Select>
            <Select.Option key="time">Time-Based Membership</Select.Option>
            <Select.Option key="duration">
              Duration-Based Membership
            </Select.Option>
            <Select.Option key="lifetime">Lifetime Membership</Select.Option>
          </Select>
        </Form.Item>
        {typeWatch === 'time' && (
          <Form.Item
            rules={[{required: true, message: 'This is a required field'}]}
            name="expiryTime"
            label="Membership Expiry Date & Time">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
        )}
        {typeWatch === 'duration' && (
          <>
            <Form.Item
              name="unit"
              rules={[{required: true, message: 'This is a required field'}]}
              className={styles.smallWidth}
              label="Select Duration Unit">
              <Select placeholder="Days" allowClear>
                <Select.Option value="days">Days</Select.Option>
                <Select.Option value="months">Months</Select.Option>
                <Select.Option value="years">Years</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This is a required field'}]}
              name="value"
              label="Enter Duration Value">
              <InputNumber className={styles['input-number']} min={1} />
            </Form.Item>
          </>
        )}
        {typeWatch === 'lifetime' && (
          <Form.Item name="isLifetime" label="Enable Lifetime Membership">
            <Switch />
          </Form.Item>
        )}
        <SubmitButton label="Update Duration" disabled={loading} />
      </Form>
    </div>
  );
};

export const SearchUsersInGroup = ({members, setMembers, groupId}) => {
  const {data, refetch, loading} = useQuery(GET_GROUP_MEMBERS_BY_ID, {
    variables: {
      filters: {
        groupId,
        page: 1,
        limit: 100,
      },
    },
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const debouncedSetSearchValue = debounce(value => {
    const sanitizedValue = sanitizeInput(value);

    refetch({
      filters: {
        search: sanitizedValue,
        groupId,
      },
    });
  });

  return (
    <Select
      loading={loading}
      allowClear
      mode="multiple"
      showSearch
      filterOption={false}
      placeholder="Search Users in Group"
      onSearch={e => debouncedSetSearchValue(e)}
      value={members}
      onChange={e => setMembers(e)}>
      {data?.adminGetGroupMembers?.data?.map(data => (
        <Select.Option key={data?.user?.id} value={data?.user?.id}>
          <div className={styles.user}>
            <img src={data?.user?.imageUrl} alt="" />
            <span>
              {data?.user?.firstName} {data?.user?.lastName}
            </span>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AddUsersToGroup;
