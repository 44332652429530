import {useState} from 'react';
import styles from './wallet.module.scss';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useLazyQuery, useQuery} from '@apollo/client';
import {
  GENERATE_CURRENCY_EXPOSURE,
  GENERATE_LIQUIDITY_REPORT,
  GET_ALL_WALLET_PAYMENT_PROVIDERS,
} from 'graphql/queries/wallet';
import {Dropdown, Menu, Table, Tag, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import DBModal from 'components/modal/modal';
import UpdateGlobalWalletSettingsModal from './modal/UpdateGlobalWalletSettingsModal';

import dotsIcon from 'icons/dots.svg';
import AllMobileMoneyOperators from './mobileMoney/loadTable/AllMobileMoney';
import {Button} from '@dabafinance/react-components';
import {currencySymbols} from 'utils/mock';
import TotalCashBalance from './view/totalCashBalance/TotalCashBalance';
import EnableCurrencySwap from './Swap/settings/EnableCurrencySwap/EnableCurrencySwap';
import {getMenuItem} from 'utils/helper';
import {SettingOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';
import SwapSettings from './Swap/settings/SwapSettings';
import ReportFilters from './view/reports/Reports';
import {toast} from 'react-toastify';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';

const Wallet = () => {
  const [activeTab, setActiveTab] = useState('SUPPORTED');
  const [openCashBalanceModal, setOpenCashBalanceModal] = useState(false);
  const [swapSettingsModalDetails, setSwapSettingsModalDetails] = useState({});
  const {data, loading, refetch} = useQuery(GET_ALL_WALLET_PAYMENT_PROVIDERS, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [reportType, setReportType] = useState('');
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const [generateLiquidityReport, {loading: liquidity}] = useLazyQuery(
    GENERATE_LIQUIDITY_REPORT,
  );
  const [generateCurrencyExposureReport, {loading: currencyExposure}] =
    useLazyQuery(GENERATE_CURRENCY_EXPOSURE);

  const handleGenerate = () => {
    if (reportType === 'LIQUIDITY') {
      generateLiquidityReport()
        .then(({data: {generateLiquidityReport}}) => {
          toast.success(generateLiquidityReport?.responseMessage);
        })
        .finally(() => setOpenConfirmModal(false));
    } else if (reportType === 'CURRENCY_EXPOSURE') {
      generateCurrencyExposureReport()
        .then(({data: {generateCurrencyExposureReport}}) => {
          toast.success(generateCurrencyExposureReport?.responseMessage);
        })
        .finally(() => setOpenConfirmModal(false));
    }
  };

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Wallet),
  );

  const swapOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Swap),
  );

  const navigate = useNavigate();

  const handleExpand = (expanded, record) => {
    const newExpandedRows = [...expandedRows];
    if (expanded) {
      newExpandedRows.push(record.key);
    } else {
      newExpandedRows.splice(newExpandedRows.indexOf(record.key), 1);
    }
    setExpandedRows(newExpandedRows);
  };

  const onClick = e => {
    navigate(`/dashboard/wallet/transactions/all/${e.key}`);
  };

  const expandedRowRender = record => {
    const columns = [
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
      },
      {
        title: 'Deposit Status',
        dataIndex: 'deposit',
        key: 'deposit',
        render: (text, render) => (
          <Tag
            color={render?.deposit?.enabled ? 'green' : 'red'}
            key={render?.deposit?.enabled}>
            {render?.deposit.enabled ? 'ENABLED' : 'DISABLED'}
          </Tag>
        ),
      },
      {
        title: 'Withdrawal Status',
        dataIndex: 'withdrawal',
        key: 'withdrawal',
        render: (text, render) => (
          <Tag
            color={render?.withdrawal?.enabled ? 'green' : 'red'}
            key={render?.withdrawal?.enabled}>
            {render?.withdrawal.enabled ? 'ENABLED' : 'DISABLED'}
          </Tag>
        ),
      },
      {
        title: (
          <Tooltip title="This is the Amount Daba has made from fees interest for this currency under this provider">
            Total Platform Balance
          </Tooltip>
        ),
        dataIndex: 'totalPlatformBalance',
        key: 'totalPlatformBalance',
        render: (text, record) => (
          <span>
            {currencySymbols[record?.currency]?.symbol}
            {record?.totalPlatformBalance?.toLocaleString() ?? 0}
          </span>
        ),
      },
      {
        title: (
          <Tooltip title="This is Total Balance for this currency under this provider">
            Total Provider Balance
          </Tooltip>
        ),
        dataIndex: 'paymentProviderActualBalance',
        key: 'paymentProviderActualBalance',
        render: (text, record) => (
          <span>
            {currencySymbols[record?.currency]?.symbol}
            {record?.paymentProviderActualBalance !== undefined
              ? record?.paymentProviderActualBalance?.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )
              : 0}
          </span>
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        render: (text, render) => (
          <Dropdown
            trigger="hover"
            overlay={
              <Menu>
                {tabOperations?.update && (
                  <Menu.Item
                    key="1"
                    onClick={() =>
                      navigate(
                        `/dashboard/wallet/view-details/${render.provider}/${render.currency}`,
                      )
                    }>
                    <div className={styles['menu-items']}>
                      <span>Update Currency</span>
                    </div>
                  </Menu.Item>
                )}
                <Menu.Item
                  key="2"
                  onClick={() =>
                    navigate(
                      `/dashboard/wallet/transactions/${render?.provider}/${render.currency}`,
                    )
                  }>
                  <div className={styles['menu-items']}>
                    <span>View Transactions</span>
                  </div>
                </Menu.Item>
              </Menu>
            }>
            <img src={dotsIcon} onClick={e => e.stopPropagation()} />
          </Dropdown>
        ),
      },
    ];

    const currencies = record.currencies;

    return (
      <Table
        columns={columns}
        dataSource={Object.entries(currencies)?.map(([key, value]) => ({
          currency: key,
          provider: record?.provider,
          ...value,
        }))}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: 'Payment Provider',
      dataIndex: 'provider',
      key: 'provider',
    },
    {
      title: 'Provider Status',
      dataIndex: 'walletEnabled',
      key: 'walletEnabled',
      render: (text, render) => (
        <Tag color={render.enabled ? 'green' : 'red'} key={render.enabled}>
          {render.enabled ? 'ENABLED' : 'DISABLED'}
        </Tag>
      ),
    },
  ];

  const currencyColumns = [
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      fixed: 'left',
    },
    {
      title: 'Symbol',
      dataIndex: 'currencySymbol',
      key: 'currencySymbol',
    },
    {
      title: 'Currency Name',
      dataIndex: 'currencyName',
      key: 'CurrencyName',
    },

    {
      title: 'Deposit Methods',
      dataIndex: 'enabledMethods',
      key: 'enabledMethods',
      render: (text, render) => (
        <span>
          {render?.deposit?.enabledPaymentMethods?.map(data => (
            <Tag key={data} color={'blue'}>
              {data
                ?.replace(/_/g, ' ')
                ?.toLowerCase()
                ?.replace(/\b\w/g, c => c.toUpperCase())}
            </Tag>
          ))}
        </span>
      ),
    },
    {
      title: 'Withdrawal Methods',
      dataIndex: 'withdrawalMethods',
      key: 'withdrawalMethods',
      render: (text, render) => (
        <span>
          {render?.deposit?.enabledPaymentMethods?.map(data => (
            <Tag key={data} color={'blue'}>
              {data
                ?.replace(/_/g, ' ')
                ?.toLowerCase()
                ?.replace(/\b\w/g, c => c.toUpperCase())}
            </Tag>
          ))}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (text, render) => (
        <Tag color={render.enabled ? 'green' : 'red'} key={render.enabled}>
          {render.enabled ? 'ENABLED' : 'DISABLED'}
        </Tag>
      ),
    },
    {
      title: 'Swap Status',
      dataIndex: 'swap',
      key: 'swap',
      render: (text, render) => (
        <Tag
          color={render.swap?.sourceCurrencyEnabledForSwap ? 'green' : 'red'}
          key={render.swap?.sourceCurrencyEnabledForSwap}>
          {render.swap?.sourceCurrencyEnabledForSwap ? 'ENABLED' : 'DISABLED'}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, render) =>
        tabOperations?.update && (
          <>
            <Dropdown
              trigger="click"
              overlay={
                <Menu>
                  <Menu.Item
                    key="1"
                    onClick={() =>
                      navigate(
                        `/dashboard/wallet/update-wallet-currency/${render.currency}`,
                      )
                    }>
                    <div className={styles['menu-items']}>
                      <span>Update Currency</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      setSwapSettingsModalDetails({
                        openEnable: true,
                        currency: render?.currency,
                        isEnabled: render?.swap?.sourceCurrencyEnabledForSwap,
                      });
                    }}>
                    <div className={styles['menu-items']}>
                      <span>Update Swap Status</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key="3"
                    onClick={() =>
                      setSwapSettingsModalDetails({
                        open: true,
                        currency: render?.currency,
                        minAmount: render?.swap?.minSwapAmountInSourceCurrency,
                        maxAmount: render?.swap?.maxSwapAmountInSourceCurrency,
                      })
                    }>
                    <div className={styles['menu-items']}>
                      <span>Update Swap Settings</span>
                    </div>
                  </Menu.Item>
                  {!swapOperations?.view && (
                    <Menu.Item
                      onClick={() =>
                        navigate(
                          `/dashboard/wallet/swap-transactions/${render?.currency}`,
                        )
                      }>
                      Swap Transactions
                    </Menu.Item>
                  )}
                </Menu>
              }>
              <img src={dotsIcon} onClick={e => e.stopPropagation()} />
            </Dropdown>
          </>
        ),
    },
  ];

  return (
    <div className={styles.root}>
      <h1 className={styles.header}>Wallet Overview Settings</h1>
      <div className={styles.addButton}>
        <Dropdown
          trigger="click"
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() =>
                  navigate('/dashboard/wallet/create-wallet-currency')
                }>
                <div className={styles['menu-items']}>
                  <span>Currency Settings</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() =>
                  navigate('/dashboard/wallet/create-provider-settings')
                }>
                <div className={styles['menu-items']}>
                  <span> Provider Settings</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => navigate('/dashboard/wallet/mobile-money')}>
                <div className={styles['menu-items']}>
                  <span> Mobile Money Settings</span>
                </div>
              </Menu.Item>
            </Menu>
          }>
          <div>
            <SubmitButton
              disabled={!tabOperations?.create}
              type="secondary"
              handleClick={e => e.stopPropagation()}
              label="Create"
            />
          </div>
        </Dropdown>
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                onClick={() => {
                  setReportType('LIQUIDITY');
                  setOpenConfirmModal(true);
                }}>
                <div className={styles['menu-items']}>
                  <span>Liquidity Report</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => {
                  setReportType('TRANSACTION');
                  setOpenFilterModal(true);
                }}>
                <div className={styles['menu-items']}>
                  <span>Transaction Report</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="3"
                onClick={() => {
                  setReportType('CURRENCY_EXPOSURE');
                  setOpenConfirmModal(true);
                }}>
                <div className={styles['menu-items']}>
                  <span>Currency Exposure Report</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="4"
                onClick={() => {
                  setReportType('RECONCILIATION');
                  setOpenFilterModal(true);
                }}>
                <div className={styles['menu-items']}>
                  <span>Reconciliation Report</span>
                </div>
              </Menu.Item>
              <Menu.Item
                key="5"
                onClick={() => {
                  setReportType('CUSTOMER_ACTIVITY');
                  setOpenFilterModal(true);
                }}>
                <div className={styles['menu-items']}>
                  <span>Customer Activity Report</span>
                </div>
              </Menu.Item>
            </Menu>
          }>
          <div>
            <SubmitButton
              type="secondary"
              disabled={currencyExposure || liquidity || !tabOperations?.create}
              handleClick={e => e.stopPropagation()}
              label="Generate Reports"
            />
          </div>
        </Dropdown>
        <SubmitButton
          disabled={!tabOperations?.update}
          handleClick={() => setOpenUpdateModal(true)}
          label="Update General Settings"
        />
      </div>
      <section className={styles.section}>
        <div className={styles['details-toggler']}>
          <span
            className={activeTab === 'SUPPORTED' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('SUPPORTED')}>
            Platform Supported Currencies
          </span>
          <span
            className={activeTab === 'PLATFORM' ? styles['active-toggle'] : ''}
            onClick={() => setActiveTab('PLATFORM')}>
            Payment Providers
          </span>
          <span
            className={
              activeTab === 'MOBILE-MONEY' ? styles['active-toggle'] : ''
            }
            onClick={() => setActiveTab('MOBILE-MONEY')}>
            Mobile Money Operators
          </span>
        </div>
      </section>
      {activeTab === 'SUPPORTED' ? (
        <div className={styles.all}>
          <Button
            label="View All Swap Transactions"
            onClick={() => navigate('/dashboard/wallet/swap-transactions/All')}
            disabled={!swapOperations?.view}
            type="secondary"
          />
        </div>
      ) : (
        <div className={styles.all}>
          <Dropdown
            trigger="click"
            overlay={
              <Menu
                onClick={onClick}
                style={{
                  width: 150,
                }}
                mode="inline"
                items={items}
              />
            }>
            <div>
              <Button label="View All Transactions" type="secondary" />
            </div>
          </Dropdown>
          <Button
            label="Total Users Balance"
            onClick={() => setOpenCashBalanceModal(true)}
          />
        </div>
      )}

      <section>
        {activeTab === 'SUPPORTED' && (
          <Table
            loading={loading}
            columns={currencyColumns}
            dataSource={
              data?.getPlatformSupportedCurrenciesForAdmin?.currencies
            }
          />
        )}
        {activeTab === 'PLATFORM' && (
          <Table
            loading={loading}
            expandable={{
              expandedRowRender,
              onExpand: handleExpand,
              expandedRowKeys: expandedRows,
            }}
            columns={columns}
            size="small"
            dataSource={data?.getAllPaymentProviders?.providers?.map(
              (item, index) => ({
                ...item,
                key: index.toString(),
              }),
            )}
          />
        )}
        {activeTab === 'MOBILE-MONEY' && (
          <AllMobileMoneyOperators
            loading={loading}
            operations={tabOperations}
            data={data?.getMobileMoneyOperatorsForAdmin?.operators}
          />
        )}
      </section>
      <DBModal
        isOpen={openUpdateModal}
        handleClose={() => setOpenUpdateModal(false)}
        content={
          <UpdateGlobalWalletSettingsModal
            handleCloseModal={setOpenUpdateModal}
          />
        }
      />
      <DBModal
        isOpen={openCashBalanceModal}
        handleClose={() => setOpenCashBalanceModal(false)}
        content={<TotalCashBalance />}
      />
      <DBModal
        isOpen={swapSettingsModalDetails.openEnable}
        handleClose={() => setSwapSettingsModalDetails({openEnable: false})}
        content={
          <EnableCurrencySwap
            refetch={refetch}
            closeModal={() => setSwapSettingsModalDetails({openEnable: false})}
            data={swapSettingsModalDetails}
          />
        }
      />
      <DBModal
        isOpen={swapSettingsModalDetails.open}
        handleClose={() => setSwapSettingsModalDetails({open: false})}
        content={
          <SwapSettings
            refetch={refetch}
            closeModal={() => setSwapSettingsModalDetails({open: false})}
            data={swapSettingsModalDetails}
          />
        }
      />
      <DBModal
        isOpen={openFilterModal}
        handleClose={() => setOpenFilterModal(false)}
        content={
          <ReportFilters
            reportType={reportType}
            setOpenModal={setOpenFilterModal}
          />
        }
      />

      <ConfirmModal
        title={'Confirm Report Generation'}
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmText={'Are you sure you want to GENERATE this report?'}
        loading={currencyExposure || liquidity}
        handleOperation={handleGenerate}
      />
    </div>
  );
};

const items = [
  getMenuItem('USD', 'sub1', <SettingOutlined />, [
    getMenuItem(
      'USD',
      'g1',
      null,
      [
        getMenuItem('ALL', 'USD'),
        getMenuItem('Deposits', 'DEPOSIT/USD'),
        getMenuItem('Withdrawals', 'WITHDRAWAL/USD'),
      ],
      'group',
    ),
  ]),
  getMenuItem('EURO', 'sub2', <SettingOutlined />, [
    getMenuItem(
      'EURO',
      'g2',
      null,
      [
        getMenuItem('ALL', 'EUR'),
        getMenuItem('Deposits', 'DEPOSIT/EUR'),
        getMenuItem('Withdrawals', 'WITHDRAWAL/EUR'),
      ],
      'group',
    ),
  ]),
  getMenuItem('XOF', 'sub3', <SettingOutlined />, [
    getMenuItem(
      'XOF',
      'g3',
      null,
      [
        getMenuItem('ALL', 'XOF'),
        getMenuItem('Deposits', 'DEPOSIT/XOF'),
        getMenuItem('Withdrawals', 'WITHDRAWAL/XOF'),
      ],
      'group',
    ),
  ]),
];

export default Wallet;
