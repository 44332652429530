import {Form, InputNumber, Select, Spin} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './investmentDetails.module.scss';
import {useParams} from 'react-router-dom';
import {
  portfolioStrategyData,
  securityTypeOptions,
} from 'pages/campaigns/campaignData';
import {GET_CAMPAIGN_BY_ID} from 'graphql/queries/campaign';
import {useMutation, useQuery} from '@apollo/client';
import {UPDATE_CAMPAIGN} from 'graphql/mutations/campaign';
import {toast} from 'react-toastify';

const UpdateInvestmentDetails = () => {
  const [fundingTarget, setFundingTarget] = useState(0);
  const [valuation, setValuation] = useState('');
  const [minimumInvestment, setMinimumInvestment] = useState(0);
  const [maximumFundingTarget, setMaximumFundingTarget] = useState(0);
  const [portfolioStrategy, setPortfolioStrategy] = useState('');
  const [minimumFundingTarget, setMinimumFundingTarget] = useState(0);
  const [pricePerSecuirity, setPricePerSecuirity] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [securityType, setSecurityType] = useState('');
  const [vendor, setVendor] = useState('');
  const [campaignMet, setCampaignMet] = useState(false);
  const {Option} = Select;
  const {id, language} = useParams();
  let width = 350;

  const validate =
    !fundingTarget ||
    !valuation ||
    !minimumFundingTarget ||
    !maximumFundingTarget ||
    !pricePerSecuirity ||
    !minimumInvestment ||
    !pricePerSecuirity;

  useEffect(() => {
    if (validate) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [disableButton, validate]);

  const {data: campaignData, loading} = useQuery(GET_CAMPAIGN_BY_ID, {
    variables: {
      getCampaignByIdId: id,
      language,
    },
  });
  const [updateCampaign, {loading: updateLoading}] =
    useMutation(UPDATE_CAMPAIGN);

  useEffect(() => {
    if (campaignData?.getCampaignById?.investmentTerms) {
      setFundingTarget(
        campaignData?.getCampaignById?.investmentTerms?.fundingTarget,
      );
      setValuation(
        campaignData?.getCampaignById?.investmentTerms?.companyValuation,
      );
      setMinimumInvestment(
        campaignData?.getCampaignById?.investmentTerms?.minimumInvestmentAmount,
      );
      setPortfolioStrategy(
        campaignData?.getCampaignById?.recommendedPortfolioStrategy,
      );
      setMinimumFundingTarget(
        campaignData?.getCampaignById?.investmentTerms?.minimumFundingTarget,
      );
      setMaximumFundingTarget(
        campaignData?.getCampaignById?.investmentTerms?.maximumFundingTarget,
      );
      setVendor(campaignData?.getCampaignById?.vendor);
      setSecurityType(campaignData?.getCampaignById?.securityType);
      setPricePerSecuirity(campaignData?.getCampaignById?.pricePerSecurity);
      setCampaignMet(campaignData?.getCampaignById?.closeCampaignOnTargetMet);
    }
  }, [campaignData?.getCampaignById?.investmentTerms]);

  const handleSave = () => {
    const optionalFields = {};
    if (campaignData?.getCampaignById?.investmentTerms?.feeSchedule) {
      optionalFields.feeSchedule =
        campaignData?.getCampaignById?.investmentTerms?.feeSchedule?.map(
          data => ({
            feeName: data.feeName,
            feeType: data.feeType,
            fixedFeeAmount: data.fixedFeeAmount,
            variableFeeAmount: data.variableFeeAmount,
          }),
        );
    }
    updateCampaign({
      variables: {
        updateCampaignId: id,
        input: {
          fundraisingCompanyId:
            campaignData?.getCampaignById?.fundraisingCompany?.id,
          language,
          securityType,
          closeCampaignOnTargetMet: campaignMet,
          vendor,
          recommendedPortfolioStrategy: portfolioStrategy,
          pricePerSecurity: Number(pricePerSecuirity),
          investmentTerms: {
            companyValuation: Number(valuation),
            fundingTarget: Number(fundingTarget),
            minimumFundingTarget: Number(minimumFundingTarget),
            minimumInvestmentAmount: Number(minimumInvestment),
            maximumFundingTarget: Number(maximumFundingTarget),
            ...optionalFields,
          },
        },
      },
      refetchQueries: [
        {query: GET_CAMPAIGN_BY_ID, variables: {getCampaignByIdId: id}},
      ],
    })
      .then(({data: {updateCampaign}}) => {
        if (updateCampaign.__typename === 'Error') {
          toast.error(updateCampaign.message);
        } else {
          toast.success('Investment Details Updated Successfully');
        }
      })
      .catch(() => toast.error('Something went wrong'));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Form onFinish={handleSave} layout="vertical">
        <div>
          <h1 className={styles.header}>Third Party Provider</h1>
          <div>
            <Form.Item
              style={{width: 450, marginTop: 10}}
              name="Vendor"
              label="Vendor">
              <Select
                name="vendor"
                defaultValue={campaignData?.getCampaignById?.vendor}
                value={vendor}
                placeholder="Select a third party provider"
                onChange={e => setVendor(e)}>
                <Option value={'DABA'}>Daba</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <h1 className={styles.header}>Portfolio Strategy</h1>
          <div>
            <Form.Item
              style={{width: 450, marginTop: 10}}
              name="portfolio"
              label="Recommended Portfolio Strategy">
              <Select
                name="vendor"
                defaultValue={
                  campaignData?.getCampaignById?.recommendedPortfolioStrategy
                }
                value={portfolioStrategy}
                placeholder="Select a Portfolio Strategy"
                onChange={e => setPortfolioStrategy(e)}>
                {portfolioStrategyData?.map(data => (
                  <Option key={data.key}>
                    <span>{data.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <h1 className={styles.header}>
            Investment Details in Asset Currency (
            {campaignData?.getCampaignById?.assetCurrency?.symbol})
          </h1>
        </div>
        <div>
          <div className={styles.form}>
            <Form.Item
              style={{width}}
              name="fundingTarget"
              label="Funding Target">
              <InputNumber
                value={fundingTarget}
                onChange={e => setFundingTarget(e)}
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                defaultValue={
                  campaignData?.getCampaignById?.investmentTerms?.fundingTarget
                }
              />
            </Form.Item>
            <Form.Item style={{width}} name="valuation" label="Valuation">
              <InputNumber
                value={valuation}
                onChange={e => setValuation(e)}
                placeholder="Enter Valuation"
                defaultValue={
                  campaignData?.getCampaignById?.investmentTerms
                    ?.companyValuation
                }
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              style={{width}}
              name="MinimumInvestment"
              label="Minimum Investment">
              <InputNumber
                value={minimumInvestment}
                onChange={e => setMinimumInvestment(e)}
                placeholder="Enter amount"
                defaultValue={
                  campaignData?.getCampaignById?.investmentTerms
                    ?.minimumInvestmentAmount
                }
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              style={{width}}
              name="Minimum Funding Target"
              help={
                <small style={{color: 'orangeRed'}}>
                  This must be LESS than the Funding Target
                </small>
              }
              label="Minimum Funding Target">
              <InputNumber
                value={minimumFundingTarget}
                onChange={e => setMinimumFundingTarget(e)}
                placeholder="Enter amount"
                max={fundingTarget}
                defaultValue={
                  campaignData?.getCampaignById?.investmentTerms
                    ?.minimumFundingTarget
                }
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item
              style={{width}}
              name="MaximumInvestment"
              help={
                <small style={{color: 'orangeRed'}}>
                  This must be GREATER than the Funding Target
                </small>
              }
              label="Maximum Funding Target">
              <InputNumber
                value={maximumFundingTarget}
                onChange={e => setMaximumFundingTarget(e)}
                placeholder="Enter amount"
                min={fundingTarget}
                defaultValue={
                  campaignData?.getCampaignById?.investmentTerms
                    ?.maximumFundingTarget
                }
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item style={{width}} label="Security Type" name="Security">
              <Select
                value={securityType}
                onChange={e => setSecurityType(e)}
                placeholder="Equity"
                defaultValue={campaignData?.getCampaignById?.securityType}>
                {securityTypeOptions.map(data => (
                  <Option key={data.value}>
                    <span>{data.label}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              style={{width}}
              label="Price Per security"
              name="PricePersecurity">
              <InputNumber
                value={pricePerSecuirity}
                onChange={e => setPricePerSecuirity(e)}
                placeholder="Enter amount"
                defaultValue={campaignData?.getCampaignById?.pricePerSecurity}
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </div>
          <div className={styles.checkbox}>
            {/* <CheckInput value='Close campaing when funding target is met' /> */}
            <input
              value={campaignMet}
              onChange={e => setCampaignMet(e.target.checked)}
              type="checkbox"
              defaultChecked={
                campaignData?.getCampaignById?.closeCampaignOnTargetMet
              }
              id="fund"
              name="fund"
            />
            <label>Close campaign when funding target is met</label>
          </div>
          <SubmitButton
            disabled={updateLoading || disableButton}
            label="Update"
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdateInvestmentDetails;
