import {Spin} from 'antd';

import {useQuery} from '@apollo/client';
import {useParams} from 'react-router-dom';
import {GET_MOBILE_MONEY_OPERATOR_BY_NAME} from 'graphql/queries/wallet';
import MobileMoneySettings from '../MobileMoneySettings';
import {toast} from 'react-toastify';

const UpdateMobileMoneyOperator = () => {
  const {code} = useParams();

  const {data, loading} = useQuery(GET_MOBILE_MONEY_OPERATOR_BY_NAME, {
    variables: {
      countryName: code,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <MobileMoneySettings updateData={data?.getMobileMoneyOperatorByCountry} />
  );
};

export default UpdateMobileMoneyOperator;
