import {Button} from '@dabafinance/react-components';
import {Pagination, Table} from 'antd';
import {useEffect, useState} from 'react';
import defaultImage from 'images/gamer.png';
import styles from './userVerification.module.scss';
import {useNavigate} from 'react-router-dom';
import {ExportButton} from 'components/button/ExportButton/ExportButton';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';
import {GET_RECENT_USERS} from 'graphql/queries/users';
import {useQuery} from '@apollo/client';
import {toast} from 'react-toastify';

export const RecentlyJoinedUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [exportData, setExportData] = useState([]);
  const navigate = useNavigate();

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.Users),
  );

  const {
    data,
    refetch,
    loading: loadingRecentUsers,
  } = useQuery(GET_RECENT_USERS, {
    variables: {
      page: 1,
      limit: 10,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const tableData = [];
    if (data) {
      for (let i = 0; i < data?.length; i++) {
        const tableRow = {};
        tableRow.isAccredited = data[i]?.investorProfile?.isAccredited;
        tableRow.completionPercentage = `${data[i]?.profileCompletion?.completionPercentage}%`;
        tableRow.firstName = data[i]?.firstName;
        tableRow.lastName = data[i]?.lastName;
        tableRow.email = data[i]?.email;
        tableData.push(tableRow);
      }
    }
    setExportData(tableData);
  }, [data]);

  const handlePageChange = (page, pageSize) => {
    if (page === currentPage && pageSize === limit) return;
    setCurrentPage(page);
    setLimit(pageSize);
    refetch({
      page,
      limit: pageSize,
    });
  };

  const UsersColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <img
            src={record?.imageUrl ? record?.imageUrl : defaultImage}
            alt=""
          />
          <div>
            {record?.firstName} {record?.lastName}
          </div>
        </div>
      ),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <div className={styles['row-width']}>
          <span>
            {record?.email?.length > 23
              ? `${record?.email.substring(0, 23)}...`
              : record?.email}
          </span>
        </div>
      ),
    },
    {
      title: 'Profile Completion',
      dataIndex: 'completed',
      key: 'completed',
      render: (text, record) => (
        <div>
          <span>{record?.profileCompletion?.completionPercentage}%</span>
        </div>
      ),
    },
    {
      title: 'Investor Profile',
      dataIndex: 'Investor Profile',
      key: 'Basics',
      render: (text, record) => (
        <div>
          <span
            className={
              record?.investorProfile?.isAccredited
                ? styles['not-pending']
                : styles.pending
            }>
            {record?.investorProfile?.isAccredited
              ? 'Accredited'
              : 'Not Accredited'}
          </span>
        </div>
      ),
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Button
          onClick={() => navigate(`/dashboard/user/${record?.id}`)}
          label="View Details"
          type="secondary"
          disabled={!tabOperations?.update}
        />
      ),
    },
  ];

  const exportTableHeaders = [
    {label: 'First Name', key: 'firstName'},
    {label: 'Last Name', key: 'lastName'},
    {label: 'Email', key: 'email'},
    {label: 'Accredited', key: 'isAccredited'},
    {label: 'Profile Completion(%)', key: 'completionPercentage'},
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Recently Joined Users</h1>
        <ExportButton
          csvData={exportData}
          fileName="Recently Joined Users"
          csvHeaders={exportTableHeaders}
          label="Download Users"
        />
      </div>
      <div>
        <Table
          loading={loadingRecentUsers}
          dataSource={data?.getRecentUsers?.users || []}
          columns={UsersColumn}
          pagination={false}
        />
        <Pagination
          showQuickJumper
          className="ant-table-pagination"
          showSizeChanger
          current={currentPage}
          onChange={handlePageChange}
          total={data?.getRecentUsers?.totalDocs}
          pageSize={limit}
        />
      </div>
    </div>
  );
};
