import {useLazyQuery, useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Dropdown, Form, Pagination, Select, Table, Tag} from 'antd';
import {GET_ASSET_CLASS_INSTRUMENTS} from 'graphql/queries/assetManagement';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import styles from '../assetClassOverview.module.scss';
import {formatEnumType} from 'utils/helper';
import dotsIcon from 'images/dots.svg';
import {useEffect, useState} from 'react';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {
  DELETE_FINANCIAL_INSTRUMENT,
  DELETE_GROUPED_FINANCIAL_INSTRUMENT,
  UPDATE_FINANCIAL_INSTRUMENT_STATUS,
} from 'graphql/mutations/assetManagement';
import {toast} from 'react-toastify';
import filterIcon from 'icons/filter.svg';
import {assetClassStatus} from 'pages/AssetManagement/modules/constants';
import SubmitButton from 'components/Startup/components/SubmitButton';
import backIcon from 'images/back.png';
import DBModal from 'components/modal/modal';
import {handleUpdateGroupedInstruments} from 'redux/store/assetClass';
import {useDispatch} from 'react-redux';
import {AssetValuationModal} from './AssetValuationModal';

export const AssetClassInstruments = () => {
  const route = useLocation();
  const searchSubRoute = new URLSearchParams(route.search).get('subRoute');
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteGroup, setDeleteGroup] = useState({
    open: false,
    id: null,
    input: {},
  });
  const [limit, setLimit] = useState(10);
  const [activeSubTab, setActiveSubTab] = useState(searchSubRoute || 'SINGLE');
  const [currentTab, setCurrentTab] = useState('BOND');
  const [statusModal, setStatusModal] = useState({
    id: null,
    open: false,
    status: '',
  });
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openValuationModal, setOpenValuationModal] = useState({
    open: false,
    content: null,
  });
  const [deleteModal, setDeleteModal] = useState({id: null, open: false});
  const [form] = Form.useForm();
  const [statusForm] = Form.useForm();
  const {key} = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onBondsPage = key === 'FIXED_INCOME';

  const [fetchData, {data, loading, refetch}] = useLazyQuery(
    GET_ASSET_CLASS_INSTRUMENTS,
  );

  useEffect(() => {
    if (activeSubTab) {
      fetchData({
        variables: {
          input: {
            explore: false,
            instrumentKey: onBondsPage ? 'BOND' : 'MUTUAL_FUND',
            assetClassKey: key,
            page: 1,
            paginate: true,
            groups: activeSubTab !== 'SINGLE',
            limit: 10,
          },
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onError: error => toast.error(error.message),
      });
    }
  }, [activeSubTab]);

  useEffect(() => {
    statusForm.setFieldsValue({status: statusModal.status});
  }, [statusModal.open]);

  const [deleteFinancialInstrument, {loading: loadingDelete}] = useMutation(
    DELETE_FINANCIAL_INSTRUMENT,
  );
  const [updateFinancialInstrumentStatus, {loading: loadingStatusUpdate}] =
    useMutation(UPDATE_FINANCIAL_INSTRUMENT_STATUS);
  const [ungroupFinancialInstruments, {loading: loadingDeleteGrouped}] =
    useMutation(DELETE_GROUPED_FINANCIAL_INSTRUMENT);

  const columns = [
    {title: 'Name', dataIndex: 'name', key: 'name'},
    {
      title: 'Type',
      dataIndex: 'listingType',
      key: 'type',
      render: (text, record) => (
        <Tag color={record?.listingType === 'UNLISTED' ? 'default' : 'black'}>
          {record?.listingType}
        </Tag>
      ),
    },
    {title: 'Ticker', dataIndex: 'ticker', key: 'ticker'},
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <Tag
          color={
            {
              ['ENABLED']: 'green',
              ['DISABLED']: 'red',
              ['PENDING']: 'yellow',
            }[record?.status]
          }>
          {record?.status}
        </Tag>
      ),
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render: (text, record) => <Tag>{record?.currency?.currencyCode}</Tag>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'View Language Versions',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  navigate(`${record?.id}/languages`);
                },
              },
              {
                key: 3,
                label: 'Update Status',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  setStatusModal({
                    id: record?.id,
                    open: true,
                    status: record?.status,
                  });
                },
              },
              ...(onBondsPage
                ? []
                : [
                    {
                      key: 4,
                      label: 'Update Instrument Valuation',
                      onClick: e => {
                        e.domEvent.stopPropagation();
                        setOpenValuationModal({open: true, content: record});
                      },
                    },
                  ]),
              {
                key: 2,
                label: 'Delete',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  setDeleteModal({id: record?.id, open: true});
                },
              },
            ],
          }}>
          <img
            role="button"
            onClick={e => e.stopPropagation()}
            src={dotsIcon}
          />
        </Dropdown>
      ),
    },
  ];

  const groupColumns = [
    {title: 'Title', dataIndex: 'title', key: 'title'},
    {
      title: 'Investment Risk',
      dataIndex: 'risk',
      key: 'risk',
      render: (text, record) => (
        <Tag
          color={
            {'HIGH': 'red', 'MEDIUM': 'yellow', 'LOW': 'blue'}[
              record?.investmentRisk
            ]
          }>
          {record?.investmentRisk}
        </Tag>
      ),
    },
    {
      title: 'Language',
      dataIndex: 'lang',
      key: 'language',
      render: (text, record) => (
        <Tag color={record?.lang === 'EN' ? 'default' : 'black'}>
          {record?.lang}
        </Tag>
      ),
    },
    {
      title: 'Total No. of Instruments',
      dataIndex: 'count',
      key: 'count',
      render: (text, record) => <div>{record?.instruments?.length}</div>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (text, record) => (
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'Update',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  dispatch(handleUpdateGroupedInstruments({...record}));
                  navigate(
                    `/dashboard/asset-management/${key}/${
                      onBondsPage ? 'BOND' : 'MUTUAL_FUND'
                    }/update-instrument-group/${record?.groupId}`,
                  );
                },
              },
              {
                key: 2,
                label: 'Delete',
                onClick: e => {
                  e.domEvent.stopPropagation();
                  setDeleteGroup({
                    open: true,
                    id: record?.groupId,
                    input: {...record},
                  });
                },
              },
            ],
          }}>
          <img
            src={dotsIcon}
            role="button"
            onClick={e => e.stopPropagation()}
          />
        </Dropdown>
      ),
    },
  ];

  const handleFilter = (values, limit, page) => {
    const status = form.getFieldValue('status');
    const listingType = form.getFieldValue('listingType');
    setLimit(limit);

    refetch({
      input: {
        explore: false,
        instrumentKey: 'BOND',
        assetClassKey: key,
        status,
        listingType,
        groups: activeSubTab !== 'SINGLE',
        paginate: true,
        page: status || listingType ? 1 : page,
        limit,
      },
    });
    setOpenFilterModal(false);
  };

  const handlePageChange = page => {
    if (page === currentPage) return;
    setCurrentPage(page);
    handleFilter('', limit, page);
  };

  return (
    <div>
      <div className={styles.nav}>
        <div className={styles.topNav}>
          <img
            onClick={() => navigate(`/dashboard/asset-management/classes`)}
            src={backIcon}
            alt=""
          />
          <h1>{formatEnumType(key)} Financial Instruments</h1>
        </div>
        <Dropdown
          trigger="click"
          menu={{
            items: [
              {
                key: 1,
                label: 'Bond',
                disabled: !onBondsPage,
                onClick: () =>
                  navigate(`/dashboard/asset-management/create/bond/${key}`),
              },
              {
                key: 2,
                label: 'Mutual Funds',
                disabled: onBondsPage,
                onClick: () =>
                  navigate(
                    `/dashboard/asset-management/create/mutual-funds/${key}`,
                  ),
              },
            ],
          }}>
          <div>
            <Button label="Create Instrument" />
          </div>
        </Dropdown>
      </div>
      <div className={styles['details-toggler']}>
        {onBondsPage ? (
          <span
            onClick={() => {
              setCurrentTab('BOND');
            }}
            className={currentTab === 'BOND' ? styles['active-toggle'] : ''}>
            Bond
          </span>
        ) : (
          <span
            onClick={() => setCurrentTab('MUTUAL_FUND')}
            className={
              currentTab === 'MUTUAL_FUND' ? styles['active-toggle'] : ''
            }>
            Mutual Fund
          </span>
        )}
      </div>
      <div className={styles['details-toggler']}>
        <span
          onClick={() => {
            setActiveSubTab('SINGLE');
            navigate(`?subRoute=SINGLE`);
          }}
          className={activeSubTab === 'SINGLE' ? styles['active-toggle'] : ''}>
          Single
        </span>
        <span
          onClick={() => {
            setActiveSubTab('GROUP');
            navigate(`?subRoute=GROUP`);
          }}
          className={activeSubTab === 'GROUP' ? styles['active-toggle'] : ''}>
          Grouped
        </span>
      </div>
      <div className={styles.filterSection}>
        {activeSubTab === 'SINGLE' ? (
          <div
            onClick={() => setOpenFilterModal(!openFilterModal)}
            className={styles.filter}>
            <h2>Filter</h2>
            <img src={filterIcon} alt="" />
          </div>
        ) : (
          <SubmitButton
            handleClick={() =>
              navigate(
                `/dashboard/asset-management/${key}/${
                  onBondsPage ? 'BOND' : 'MUTUAL_FUND'
                }/create-instrument-group`,
              )
            }
            secondary={true}
            label="Create Group"
          />
        )}
        <div
          style={{
            display: openFilterModal ? 'block' : 'none',
          }}
          className={styles.filterItems}>
          <h2>Filter Instruments</h2>
          <Form form={form} layout="vertical" onFinish={handleFilter}>
            <Form.Item name="status" label="Status">
              <Select allowClear>
                {assetClassStatus.map(status => (
                  <Select.Option key={status.value}>
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="listingType" label="Listing Type">
              <Select allowClear>
                <Select.Option key="LISTED">Listed</Select.Option>
                <Select.Option key="UNLISTED">Unlisted</Select.Option>
              </Select>
            </Form.Item>
            <SubmitButton label="Filter" />
          </Form>
        </div>
      </div>
      <div>
        <Table
          loading={loading}
          dataSource={data?.getFinancialInstruments?.data}
          pagination={false}
          onRow={record => ({
            onClick: () =>
              activeSubTab === 'GROUP'
                ? navigate(
                    `/dashboard/asset-management/${key}/${
                      onBondsPage ? 'BOND' : 'MUTUAL_FUND'
                    }/update-instrument-group/${record?.groupId}`,
                  )
                : navigate(`${record?.id}/languages`),
            className: 'on-row',
          })}
          columns={activeSubTab === 'GROUP' ? groupColumns : columns}
        />
        <Pagination
          className="ant-table-pagination"
          current={currentPage}
          onChange={e => handlePageChange(e)}
          total={data?.getFinancialInstruments?.pagination?.total}
          onShowSizeChange={(_, size) => {
            setCurrentPage(1);
            handleFilter('', size, 1);
          }}
        />
      </div>
      <ConfirmModal
        openModal={deleteModal.open}
        loading={loadingDelete}
        title="Delete Instrument"
        confirmText="Are you sure you want to delete this instrument? This action cannot be undone."
        setOpenModal={() => setDeleteModal({id: null, open: false})}
        handleOperation={() =>
          deleteFinancialInstrument({
            variables: {
              deleteFinancialInstrumentId: deleteModal.id,
            },
          }).then(({data: {deleteFinancialInstrument}}) => {
            if (deleteFinancialInstrument.__typename === 'Error') {
              toast.error(deleteFinancialInstrument.message);
            } else {
              toast.success('Instrument Deleted');
              setDeleteModal({id: null, open: false});
              refetch();
            }
          })
        }
      />
      <ConfirmModal
        openModal={deleteGroup.open}
        setOpenModal={() => setDeleteGroup({id: null, open: false, input: {}})}
        loading={loadingDeleteGrouped}
        title="Delete Grouped Instruments"
        confirmText="Are you sure you want to delete this group? This action cannot be undone."
        handleOperation={() =>
          ungroupFinancialInstruments({
            variables: {
              input: {
                id: deleteGroup.id,
                title: deleteGroup.input.title,
                instrumentKey: deleteGroup.input.instrumentKey,
                assetClassKey: deleteGroup.input.assetClassKey,
              },
            },
          }).then(({data: {ungroupFinancialInstruments}}) => {
            if (ungroupFinancialInstruments.__typename === 'Error') {
              toast.error(ungroupFinancialInstruments.message);
            } else {
              toast.success('Deleted Successfully');
              setDeleteGroup({id: null, open: false, input: {}});
              refetch();
            }
          })
        }
      />
      <DBModal
        isOpen={openValuationModal.open}
        handleClose={() => setOpenValuationModal({open: false, data: null})}
        width={600}
        content={
          <AssetValuationModal
            refetch={refetch}
            content={openValuationModal.content}
            onClose={() => setOpenValuationModal({open: false, data: null})}
          />
        }
      />
      <DBModal
        isOpen={statusModal.open}
        handleClose={() => {
          statusForm.resetFields();
          setStatusModal({id: null, open: false, status: ''});
        }}
        content={
          <div className="confirm-modal">
            <div>
              <h1>Update Instrument Status</h1>
              <p>Are you sure you want to update this status</p>
            </div>
            <Form
              form={statusForm}
              onFinish={values =>
                updateFinancialInstrumentStatus({
                  variables: {
                    updateFinancialInstrumentStatusId: statusModal.id,
                    status: values.status,
                  },
                }).then(({data: {updateFinancialInstrumentStatus}}) => {
                  if (updateFinancialInstrumentStatus.__typename === 'Error') {
                    toast.error(updateFinancialInstrumentStatus.message);
                  } else {
                    toast.success(
                      `Instrument Status Updated to ${values.status}`,
                    );
                    setStatusModal({id: null, open: false, status: ''});
                    refetch();
                    statusForm.resetFields();
                  }
                })
              }
              layout="vertical">
              <Form.Item name="status" label="Status">
                <Select>
                  {assetClassStatus.map(data => (
                    <Select.Option key={data.value}>{data.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <SubmitButton
                disabled={loadingStatusUpdate}
                label="Update Status"
              />
            </Form>
          </div>
        }
      />
    </div>
  );
};
