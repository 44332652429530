import {useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {CREATE_BOND} from 'graphql/mutations/assetManagement';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {handleClearBondsState} from 'redux/store/assetClass';
import {currencySymbols} from 'utils/mock';

export const CreateBondFinishModal = ({handleClose}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bondsData = useSelector(state => state?.assetClass);
  const {assetId: assetKey} = useParams();

  const [createBond, {loading}] = useMutation(CREATE_BOND);

  const handleCreateBond = () => {
    const {
      opportunityDemoVideoThumbnail,
      missionAndVision,
      offer,
      description,
      language,
      topics,
      pitchDeck,
      minimumInvestmentAmount,
      faq,
      additionalDisclosures,
      documents,
      dabaScore,
      coInvestors,
      competitors,
      level1AnalystVideo,
      level1AnalystVideoThumbnail,
      level2AnalystVideo,
      level2AnalystVideoThumbnail,
      maximumFundingTarget,
      minimumFundingTarget,
      tradeable,
      liquidity,
      fundingTarget,
      companyValuation,
      analogsValues,
      disclosure,
      investmentRisk,
      isTimeSensitive,
      recommendedPortfolioStrategy,
      opportunityVoiceRecording,
      opportunityVoiceRecordingThumbnail,
      opportunityDemoVideo,
      feeSchedule,
      investmentContractSettings,
      issuer,
      additionalInvestmentTerms,
      issuerType,
      currency,
      templateId,
      name,
      riskAndEsg,
      isPaused,
      shouldNotifyUsersOnLaunch,
      endDate,
      isVisible,
      publicAudience,
      groupId,
      impactAndEsg,
      sectorIndustry,
      keyStatistics,
      exchange,
      invitedUsers,
      externallyFundedAmountBreakDown,
      invitedGroups,
      solutions,
      listingType,
      isShariaCompliant,
      fractionable,
      allocatedUnits,
      minTradeableUnits,
      maxTradeableUnits,
      tradingVolume,
      startDate,
      orderExpirationLimit,
    } = bondsData?.bondInstruments;
    const {
      ticker,
      type,
      isn,
      CUSIP,
      marketCapitalization,
      primaryBrokerDescription,
      primaryBrokerName,
      duration,
      projectFinancingDetails,
      nextCallPrice,
      outlookAsOfDate,
      couponRate,
      interestPaymentFrequency,
      isCallable,
      yieldToMaturity,
      issuanceVolume,
      currentMarketPrice,
      maturityDate,
      useOfProceeds,
      creditWatchAsOfDate,
      managementFee,
      gracePeriodPrincipal,
      gracePeriodInterest,
      nextCallDate,
      nextCouponDate,
      issuanceDate,
      legalChallenges,
      rating,
      primaryBrokerWebsite,
      primaryBrokerOperatingCountries,
    } = bondsData?.bonds;

    // eslint-disable-next-line no-unused-vars
    const {reportName, score, ...filteredDabaScore} = dabaScore || {};

    let dataToPass = {
      dabaScore: {
        ...filteredDabaScore,
      },
    };

    createBond({
      variables: {
        input: {
          ticker,
          type,
          isn,
          CUSIP,
          marketCapitalization,
          primaryBrokerDescription,
          primaryBrokerName,
          duration,
          projectFinancingDetails,
          nextCallPrice,
          outlookAsOfDate,
          couponRate,
          rating,
          interestPaymentFrequency,
          isCallable,
          yieldToMaturity,
          issuanceVolume,
          currentMarketPrice,
          maturityDate,
          useOfProceeds,
          creditWatchAsOfDate,
          managementFee,
          gracePeriodPrincipal,
          gracePeriodInterest,
          legalChallenges,
          primaryBrokerWebsite,
          primaryBrokerOperatingCountries,
          ...(nextCallDate && {
            nextCallDate: moment(nextCallDate).format(),
          }),
          ...(nextCouponDate && {
            nextCouponDate: moment(nextCouponDate).format(),
          }),
          ...(issuanceDate && {
            issuanceDate: moment(issuanceDate).format(),
          }),
          baseFinancialInstrumentInput: {
            'whatWeOffer': offer,
            ...(startDate && {'startDate': moment(startDate).format()}),
            ...(endDate && {'endDate': moment(endDate).format()}),
            'settings': {
              isPaused,
              isVisible,
              shouldNotifyUsersOnLaunch,
              publicAudience,
            },
            'issuer': {
              'id': issuer,
              'issuerType': issuerType,
            },
            groupId,
            impactAndEsg,
            sectorIndustry,
            externallyFundedAmountBreakDown,
            keyStatistics,
            key: 'BOND',
            exchange,
            invitedUsers,
            invitedGroups,
            investmentRisk,
            isTimeSensitive,
            solutions,
            allocatedUnits,
            assetClassKey: assetKey,
            opportunityDemoVideoThumbnail,
            additionalInvestmentTerms,
            listingType,
            liquidity,
            isShariaCompliant,
            fractionable,
            minTradeableUnits,
            maxTradeableUnits,
            tradingVolume,
            orderExpirationLimit,
            currency: {
              name: currencySymbols[currency]?.name,
              symbol: currencySymbols[currency]?.symbol,
              currencyCode: currencySymbols[currency]?.value,
            },
            templateId,
            name,
            riskAndEsg,
            description,
            language,
            topics,
            pitchDeck: {
              coverImage: pitchDeck?.coverImage,
              fileLink: pitchDeck?.fileLink,
              linkType: pitchDeck?.linkType,
              externalViewLink: pitchDeck?.externalViewLink,
              externalShareLink: pitchDeck?.externalShareLink,
            },
            minimumInvestmentAmount,
            faq,
            additionalDisclosures,
            documents,
            ...(score && {dabaScore: dataToPass?.dabaScore}),
            coInvestors,
            competitors,
            level1AnalystVideo,
            level1AnalystVideoThumbnail,
            level2AnalystVideo,
            level2AnalystVideoThumbnail,
            maximumFundingTarget,
            minimumFundingTarget,
            fundingTarget,
            tradeable,
            companyValuation,
            analog:
              analogsValues?.length > 0
                ? analogsValues.map(data => ({
                    name: data?.name,
                    description: data?.description,
                    companyType: data?.type,
                    valuation: Number(data?.valuation),
                    logo: data?.imageUrl,
                  }))
                : [],
            disclosure,
            missionAndVision,
            recommendedPortfolioStrategy,
            opportunityVoiceRecording,
            opportunityVoiceRecordingThumbnail,
            opportunityDemoVideo,
            feeSchedule:
              Array.isArray(feeSchedule) &&
              feeSchedule?.map(data => ({
                feeName: data?.feeName,
                feeType: data?.feeType,
                ...(data?.fixedFeeAmount && {
                  fixedFeeAmount: Number(data?.fixedFeeAmount),
                }),
                ...(data?.feeType === 'ENTRY' && {level: data?.level}),
                ...(data?.variableFeeAmount && {
                  variableFeeAmount: Number(data?.variableFeeAmount),
                }),
                ...(data?.scalable != undefined && {scalable: data?.scalable}),
                ...(data?.feeType === 'RECURRING' && {
                  frequency: data?.frequency,
                  origination: data?.origination,
                }),
                ...(data?.feeType === 'EXIT' &&
                  Array.isArray(data?.vector) && {
                    vector: data?.vector?.map(vect => ({
                      duration: vect?.duration,
                      magnitude: vect?.magnitude,
                    })),
                  }),
              })),
            investmentContractSettings,
          },
        },
      },
    })
      .then(({data: {createBond}}) => {
        if (createBond.__typename === 'Error') {
          toast.error(createBond.message);
        } else {
          toast.success('Created Successfully');
          dispatch(handleClearBondsState());
          navigate(`/dashboard/asset-management/class/${assetKey}/instruments`);
        }
      })
      .catch(error => toast.error('Something went wrong', error));
  };

  return (
    <div className="confirm-modal">
      <div>
        <h1>Create Bond</h1>
        <p>
          Please make sure the details are correctly inputted before proceeding
        </p>
      </div>
      <div className="buttons">
        <Button
          disabled={loading}
          label={!loading ? 'Create' : 'Loading...'}
          onClick={handleCreateBond}
        />
        <Button type="secondary" label="No" onClick={handleClose} />
      </div>
    </div>
  );
};
