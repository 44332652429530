import {Upload as ImageUpload, Empty} from 'antd';
import styles from './reportPdf.module.scss';
import {Button} from '@dabafinance/react-components';
import ExclamationIcon from 'images/exclamation-triangle.svg';
import FadeEffect from 'components/animation/fadeEffect';
import {toast} from 'react-toastify';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const ReportPDF = ({
  setPdfLink,
  setPdf,
  large,
  setPreviewUrl,
  previewUrl,
  pdf,
  bucket,
}) => {
  const fileType = ['application/pdf'];

  const handleChange = async ({file}) => {
    const temp = file.originFileObj;
    if (temp) {
      if (temp && fileType.includes(temp.type)) {
        // Upload file to S3 bucket
        const uploadedFile = await UploadDocumentToBucket(
          temp,
          () => {},
          bucket || 'reports-documents-bucket',
        );
        setPdfLink(uploadedFile);
      } else {
        toast.error('Please select a valid pdf file');
      }
    } else {
      toast.error('Select a PDF file');
    }
    const url = URL.createObjectURL(file.originFileObj);
    setPreviewUrl(url);
  };

  const download = () => {
    window.location = pdf;
  };

  return (
    <div>
      {pdf ? (
        <div className={styles['action-buttons']}>
          <Button
            label="Upload New PDF"
            type="primary"
            className={styles['upload-button']}
            onClick={() => {
              setPdf('');
              setPdfLink('');
            }}
          />
          <Button
            label="Download to Preview"
            type="secondary"
            className={styles['preview-button']}
            onClick={download}
          />
        </div>
      ) : (
        <div>
          {previewUrl ? (
            <FadeEffect>
              <div className={styles['info-banner']}>
                <img src={ExclamationIcon} alt="" />
                <p>
                  Your report was uploaded as a pdf, to edit this pdf you would
                  need to replace the pdf with the updated one or delete the pdf
                  in order to type out the report yourself
                </p>
              </div>
              <div className={styles['action-buttons']}>
                <Button
                  label="Upload New PDF"
                  type="primary"
                  className={styles['upload-button']}
                  onClick={() => {
                    setPreviewUrl('');
                    setPdfLink('');
                  }}
                />
                <Button
                  label="Preview"
                  type="secondary"
                  className={styles['preview-button']}
                />
                <Button
                  label="Delete Report"
                  type="secondary"
                  className={styles['button-red']}
                  onClick={() => {
                    setPreviewUrl('');
                    setPdfLink('');
                  }}
                />
              </div>
              <div
                className={
                  large
                    ? styles['large-image-uploaded']
                    : styles['image-uploaded']
                }>
                <div className={styles['large-image-uploaded-container']}>
                  <embed src={previewUrl} />
                </div>
              </div>
            </FadeEffect>
          ) : (
            <ImageUpload
              name="pdf"
              listType="picture"
              onChange={handleChange}
              maxCount={1}
              accept=".pdf">
              <UploadButton large={large} />
            </ImageUpload>
          )}
        </div>
      )}
    </div>
  );
};

const UploadButton = ({large}) => {
  return (
    <div
      className={
        large ? styles['large-company-upload'] : styles['company-upload']
      }>
      <Empty description="Upload PDF File" />
    </div>
  );
};

export default ReportPDF;
