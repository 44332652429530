import {
  handleBondsInstrumentState,
  handleMutualFundsInstruments,
} from 'redux/store/assetClass';
import {handleUpdateBond, handleUpdateMutualFund} from '../actions';
import {
  UPDATE_BOND,
  UPDATE_MUTUAL_FUND,
} from 'graphql/mutations/assetManagement';
import {possibleInstrumentRouteKeys} from './constants';
import {checkExactRoute} from 'utils/helper';
import {
  createBondsInstrumentRoute,
  createBondsStepTwoRoute,
  createMutualFundsRoute,
  createMutualFundsStepTwoRoute,
} from '../Bonds/routes';
import {
  GET_BOND_INSTRUMENT_BY_LANGUAGE,
  GET_MUTUAL_FUND_BY_LANGUAGE,
} from 'graphql/queries/assetManagement';

export const financialInstrumentConfig = {
  bond: {
    overviewSelector: state => state?.assetClass?.bondInstruments,
    handleDispatch: handleBondsInstrumentState,
    handleUpdate: handleUpdateBond,
    mutation: UPDATE_BOND,
    title: 'Bond',
    route: createBondsInstrumentRoute,
    stepTwoRoute: createBondsStepTwoRoute,
    QUERY: GET_BOND_INSTRUMENT_BY_LANGUAGE,
  },
  'mutual-funds': {
    overviewSelector: state => state?.assetClass?.mutualFundsInstruments,
    handleDispatch: handleMutualFundsInstruments,
    handleUpdate: handleUpdateMutualFund,
    mutation: UPDATE_MUTUAL_FUND,
    title: 'Mutual Fund',
    route: createMutualFundsRoute,
    stepTwoRoute: createMutualFundsStepTwoRoute,
    QUERY: GET_MUTUAL_FUND_BY_LANGUAGE,
  },
};

export const currentInstrumentKey = path => {
  return possibleInstrumentRouteKeys.filter(key =>
    checkExactRoute(path, key),
  )[0];
};
