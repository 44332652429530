import {DatePicker, Form, Input, InputNumber, Select, Spin, Switch} from 'antd';
import styles from '../assetInstrument.module.scss';
import {
  bondTypes,
  interestPaymentFrequency,
} from 'pages/AssetManagement/modules/constants';
import {getUserTimezone} from 'utils/helper';
import moment from 'moment';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useDispatch, useSelector} from 'react-redux';
import {handleBondsFieldState} from 'redux/store/assetClass';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {createBondsStepTwoRoute} from '../routes';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_RATINGS,
  GET_BOND_INSTRUMENT_BY_LANGUAGE,
} from 'graphql/queries/assetManagement';
import {UPDATE_BOND} from 'graphql/mutations/assetManagement';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {handleUpdateBond} from 'pages/AssetManagement/actions';

const BondsStepTwoOverview = () => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [updatedValues, setUpdatedValues] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {assetId, id, lang} = useParams();
  const navigate = useNavigate();
  const bondsData = useSelector(state => state?.assetClass?.bonds);

  const [updateBond, {loading: loadingUpdate}] = useMutation(UPDATE_BOND);
  const {refetch, loading: loadingQuery} = useQuery(
    GET_BOND_INSTRUMENT_BY_LANGUAGE,
    {
      variables: {
        getFinancialInstrumentId: id,
        language: lang,
      },
      skip: !id,
      onCompleted: data => setUpdateData(data?.getFinancialInstrument?.data),
    },
  );

  const {data, loading} = useQuery(GET_ALL_RATINGS, {
    variables: {
      page: 1,
      limit: 100,
    },
  });

  const handleSubmit = values => {
    // eslint-disable-next-line no-unused-vars
    const {length, period, ...restValues} = values;

    if (id) {
      setOpenUpdateModal(true);
      setUpdatedValues(restValues);
    } else {
      dispatch(
        handleBondsFieldState({
          ...restValues,
        }),
      );
      navigate(createBondsStepTwoRoute({param: assetId, name: 'broker'}));
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const {length, period} = allValues;

    if (length != null && period) {
      // Calculate the target date based on today's date with dynamic period calculation
      const targetDate = moment().add(length, period);
      // Calculate the difference in days from today to the target date
      const daysFromToday = targetDate.diff(moment(), 'days');

      // Update the form field with calculated days
      if (!Object.keys(changedValues).includes('duration'))
        form.setFieldValue('duration', daysFromToday);
    }
  };

  useEffect(() => {
    const dataToFetch = id ? updateData || {} : bondsData;
    const {
      type,
      ticker,
      isn,
      CUSIP,
      duration,
      couponRate,
      projectFinancialDetails,
      interestPaymentFrequency,
      yieldToMaturity,
      rating,
      isCallable,
      maturityDate,
      currentMarketPrice,
      marketCapitalization,
      issuanceDate,
      managementFee,
      gracePeriodPrincipal,
      gracePeriodInterest,
      nextCallDate,
      nextCouponDate,
    } = dataToFetch;
    if (dataToFetch?.type) {
      form.setFieldsValue({
        type,
        ticker,
        isn,
        CUSIP,
        duration,
        couponRate,
        managementFee,
        gracePeriodPrincipal,
        gracePeriodInterest,
        maturityDate: moment(maturityDate, 'YYYY-MM-DD HH:mm:ss'),
        issuanceDate: moment(issuanceDate, 'YYYY-MM-DD HH:mm:ss'),
        nextCallDate: nextCallDate
          ? moment(nextCallDate, 'YYYY-MM-DD HH:mm:ss')
          : null,
        nextCouponDate: nextCouponDate
          ? moment(nextCouponDate, 'YYYY-MM-DD HH:mm:ss')
          : null,
        projectFinancialDetails,
        interestPaymentFrequency,
        yieldToMaturity,
        rating: rating?.id ?? rating,
        isCallable,
        currentMarketPrice,
        marketCapitalization,
      });
    }
  }, [updateData, bondsData]);

  if (loadingQuery) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.form}>
      <h2>Basic Information</h2>
      <Form
        onValuesChange={handleValuesChange}
        onFinish={handleSubmit}
        layout="vertical"
        form={form}>
        <div className={styles.fieldFlex}>
          <Form.Item
            rules={[{required: true, message: 'Ticker is required'}]}
            className={styles.smallWidth}
            name="ticker"
            label="Ticker">
            <Input placeholder="BOAB" />
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This is a Required Field'}]}
            className={styles.smallWidth}
            name="type"
            label="Bond Type">
            <Select placeholder="Select a Type">
              {bondTypes.map(data => (
                <Select.Option key={data.value}>{data.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            name="isn"
            label="ISN (International Security Number)">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="CUSIP"
            label="CUSIP (Committee on Uniform Securities Identification Procedures number)">
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          name="projectFinancingDetails"
          className={styles.field}
          label="Project Financial Details">
          <Input.TextArea placeholder="Project Financial Details" rows={4} />
        </Form.Item>
        <h2>Key Dates</h2>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            name="maturityDate"
            rules={[{required: true, message: 'This is a Required Field'}]}
            label="Maturity Date">
            <DatePicker
              className={styles.smallWidth}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="nextCouponDate"
            label="Next Coupon Date">
            <DatePicker
              className={styles.smallWidth}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
        </div>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            name="issuanceDate"
            rules={[{required: true, message: 'This is a Required Field'}]}
            label="Issuance Date">
            <DatePicker
              className={styles.smallWidth}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="nextCallDate"
            label="Next Call Date">
            <DatePicker
              className={styles.smallWidth}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
        </div>
        <h2>Financial Metric</h2>
        <div className={styles.durationField}>
          <div className={styles.durationMultiplier}>
            <div className={styles.multiplier}>
              <Form.Item name="length" label="Duration Length">
                <InputNumber className={styles.multiplierNumber} />
              </Form.Item>
              <Form.Item name="period" label="Duration Period">
                <Select allowClear placeholder="Select Period">
                  <Select.Option value="day">Day</Select.Option>
                  <Select.Option value="week">Week</Select.Option>
                  <Select.Option value="month">Month</Select.Option>
                  <Select.Option value="year">Year</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              className={styles.smallWidth}
              rules={[{required: true, message: 'This is a Required Field'}]}
              name="duration"
              label="Total Duration in DAYS">
              <InputNumber
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                placeholder="No. of DAYS"
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="yieldToMaturity"
            label="Yield to Maturity">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="couponRate"
            rules={[{required: true, message: 'This is a Required Field'}]}
            label="Coupon Rate">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <div className={styles.fieldFlex}>
          <Form.Item
            rules={[{required: true, message: 'This is a Required Field'}]}
            className={styles.smallWidth}
            name="currentMarketPrice"
            label="Current Market Price">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item name="marketCapitalization" label="Market Capitalization">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <div className={styles.fieldFlex}>
          <Form.Item
            name="interestPaymentFrequency"
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            label="Interest Payment Frequency">
            <Select allowClear>
              {interestPaymentFrequency.map(data => (
                <Select.Option key={data.value}>{data.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="issuanceVolume" label="Issuance Volume">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <Form.Item className={styles.smallWidth} name="rating" label="Ratings">
          <Select loading={loading} allowClear>
            {data?.getAllRatings?.data?.map(rate => (
              <Select.Option key={rate?.id}>{rate?.agencyName}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="isCallable" label="Callable (Called before Maturity)">
          <Switch
            defaultChecked={bondsData?.isCallable || updateData?.isCallable}
          />
        </Form.Item>
        <h2>Bonds Calculator Metrics</h2>
        <div className={styles.fieldFlex}>
          <Form.Item
            className={styles.smallWidth}
            name="gracePeriodPrincipal"
            rules={[{required: true, message: 'This is a Required Field'}]}
            label="Grace Period Principal (Days)">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="No. of DAYS"
            />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            name="gracePeriodInterest"
            rules={[{required: true, message: 'This is a Required Field'}]}
            label="Grace Period Interest (Days)">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              placeholder="No. of DAYS"
            />
          </Form.Item>
        </div>

        <Form.Item
          className={styles.smallWidth}
          name="managementFee"
          rules={[{required: true, message: 'This is a Required Field'}]}
          label="Management Fee (%)">
          <InputNumber
            className={styles['input-number']}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <SubmitButton label="Save & Proceed" />
      </Form>
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        title="Update Bond"
        confirmText="Are you sure you want to update this bond?"
        handleOperation={() =>
          handleUpdateBond(
            updateBond,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
            true,
          )
        }
      />
    </div>
  );
};

export default BondsStepTwoOverview;
