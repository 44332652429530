import {DatePicker, Form, Input, InputNumber, Select, Spin} from 'antd';
import styles from './overview.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import trash from 'images/trash-can-outline.svg';
import plusIcon from 'images/plus-circle.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {handleMutualFunds} from 'redux/store/assetClass';
import {useEffect, useState} from 'react';
import moment from 'moment';
import DBModal from 'components/modal/modal';
import {CreateMutualFundsModal} from './CreateMutualFundsModal';
import {GET_MUTUAL_FUND_BY_LANGUAGE} from 'graphql/queries/assetManagement';
import {useParams} from 'react-router-dom';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {handleUpdateMutualFund} from '../actions';
import {UPDATE_MUTUAL_FUND} from 'graphql/mutations/assetManagement';
import {fieldRules} from 'utils/helper';

export const MutualFundsInvestment = () => {
  const [createModal, setCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const {id, lang} = useParams();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const fieldValues = useSelector(state => state?.assetClass?.mutualFunds);

  const {
    data,
    refetch,
    loading: loadingInstrument,
  } = useQuery(GET_MUTUAL_FUND_BY_LANGUAGE, {
    variables: {
      getFinancialInstrumentId: id,
      language: lang,
    },
    skip: !id,
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const [updateMutualFund, {loading: loadingUpdate}] = useMutation(
    UPDATE_MUTUAL_FUND,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  useEffect(() => {
    if (data?.getFinancialInstrument?.data) {
      const {
        investmentSteps,
        useOfProceeds,
        investmentCalculatorSlider,
        historicalPerformance,
        benefits,
        ...restOfValues
      } = data?.getFinancialInstrument?.data;
      console.log(useOfProceeds);
      form.setFieldsValue({
        ...(investmentSteps && {
          investmentSteps: investmentSteps?.map(step => ({
            step,
          })),
        }),
        ...(benefits && {
          benefits: benefits?.map(benefit => ({
            benefit,
          })),
        }),
        ...(historicalPerformance && {
          historicalPerformance: historicalPerformance?.map(data => ({
            year: moment(data?.year?.toString(), 'YYYY'),
            performance: data?.performance,
          })),
        }),

        allocations: useOfProceeds?.allocations,
        min: investmentCalculatorSlider?.min,
        max: investmentCalculatorSlider?.max,
        proceedDescription: useOfProceeds?.description,
        ...restOfValues,
      });
    }
  }, [data?.getFinancialInstrument?.data]);

  useEffect(() => {
    if (fieldValues && !id) {
      const {
        historicalPerformance,
        investmentSteps,
        useOfProceeds,
        investmentCalculatorSlider,
        benefits,
        ...restOfValues
      } = fieldValues;
      form.setFieldsValue({
        ...(investmentSteps && {
          investmentSteps: investmentSteps.map(step => ({
            step,
          })),
        }),
        ...(benefits && {
          benefits: benefits?.map(benefit => ({
            benefit,
          })),
        }),
        min: investmentCalculatorSlider?.min,
        max: investmentCalculatorSlider?.max,
        proceedDescription: useOfProceeds?.description,
        ...(historicalPerformance && {
          historicalPerformance: historicalPerformance?.map(data => ({
            year: moment(data?.year?.toString(), 'YYYY'),
            performance: data?.performance,
          })),
        }),
        allocations: useOfProceeds?.allocations,
        ...restOfValues,
      });
    }
  }, [fieldValues]);

  if (loadingInstrument) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <h2>Investment & Performance</h2>
      <Form
        onFinish={values => {
          const {
            historicalPerformance,
            investmentSteps,
            allocations,
            proceedDescription,
            min,
            max,
            benefits,
            ...restOfValues
          } = values;
          if (id) {
            setOpenUpdateModal(true);
            setUpdatedValues({
              historicalPerformance: historicalPerformance?.map(data => ({
                year: Number(data?.year?.format('YYYY')),
                performance: data?.performance,
              })),
              investmentSteps: investmentSteps?.map(data => data?.step),
              benefits: benefits?.map?.(data => data?.benefit),
              useOfProceeds: {
                ...(proceedDescription && {description: proceedDescription}),
                allocations: allocations?.map(allocation => ({
                  allocationCategory: allocation.allocationCategory,
                  percentageValue: parseInt(allocation.percentageValue),
                })),
              },
              investmentCalculatorSlider: {
                min,
                max,
              },
              ...restOfValues,
            });
          } else {
            dispatch(
              handleMutualFunds({
                historicalPerformance: historicalPerformance?.map(data => ({
                  year: Number(data?.year?.format('YYYY')),
                  performance: data?.performance,
                })),
                investmentSteps: investmentSteps?.map(data => data?.step),
                benefits: benefits?.map?.(data => data?.benefit),
                useOfProceeds: {
                  ...(proceedDescription && {description: proceedDescription}),
                  allocations: allocations?.map(allocation => ({
                    allocationCategory: allocation.allocationCategory,
                    percentageValue: parseInt(allocation.percentageValue),
                  })),
                },
                investmentCalculatorSlider: {
                  min,
                  max,
                },
                ...restOfValues,
              }),
            );
            setCreateModal(true);
          }
        }}
        form={form}
        layout="vertical">
        <Form.Item
          className={styles.smallWidth}
          label="Performance Update Frequency"
          name="performanceUpdateFrequency">
          <Select placeholder="Daily" allowClear>
            <Select.Option value="DAILY">Daily</Select.Option>
            <Select.Option value="WEEKLY">Weekly</Select.Option>
            <Select.Option value="MONTHLY">Monthly</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="projectedAnnualReturn" label="Projected Annual Return">
          <InputNumber
            className={styles['input-number']}
            placeholder="2,000,000"
            min={0}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <h3>Historical Performance</h3>
        <Form.List name="historicalPerformance">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name}) => (
                <FadeEffect key={name}>
                  <div className={styles.list} key={name}>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This Field is Required'},
                      ]}
                      className={styles.listField}
                      label="Year"
                      name={[name, 'year']}>
                      <DatePicker picker="year" format={'YYYY'} />
                    </Form.Item>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This Field is Required'},
                      ]}
                      className={styles.listField}
                      label="Performance (%)"
                      name={[name, 'performance']}>
                      <InputNumber className={styles['input-number']} />
                    </Form.Item>
                    <img src={trash} onClick={() => remove(name)} alt="" />
                  </div>
                </FadeEffect>
              ))}
              <div onClick={add} className={styles['add-item']}>
                <img src={plusIcon} alt="add-founder" />
                <span>Add Item</span>
              </div>
            </>
          )}
        </Form.List>
        <h2>Investment Terms</h2>
        <Form.Item
          className={styles.bigWidth}
          name="investmentHorizon"
          label="Investment Horizon">
          <Input />
        </Form.Item>
        <h3>Investment Calculator Slider</h3>
        <div className={styles.flexed}>
          <Form.Item
            rules={[fieldRules('This')]}
            name="min"
            label="Minimum Amount">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>

          <Form.Item
            rules={[fieldRules('This')]}
            name="max"
            label="Maximum Amount">
            <InputNumber
              className={styles['input-number']}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <h3>Investment Steps</h3>
        <Form.List name="investmentSteps">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name}) => (
                <FadeEffect key={name}>
                  <div className={styles.list} key={name}>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This Field is Required'},
                      ]}
                      className={styles.bigWidth}
                      label={`Step ${name + 1}`}
                      name={[name, 'step']}>
                      <Input placeholder="Step" />
                    </Form.Item>
                    <img src={trash} onClick={() => remove(name)} alt="" />
                  </div>
                </FadeEffect>
              ))}
              <div onClick={add} className={styles['add-item']}>
                <img src={plusIcon} alt="add-founder" />
                <span>Add Step</span>
              </div>
            </>
          )}
        </Form.List>
        <h3>Benefits</h3>
        <Form.List name="benefits">
          {(fields, {add, remove}) => (
            <>
              {fields.map(({name}) => (
                <FadeEffect key={name}>
                  <div className={styles.list} key={name}>
                    <Form.Item
                      rules={[
                        {required: true, message: 'This Field is Required'},
                      ]}
                      className={styles.bigWidth}
                      label={`${name + 1}. Benefits`}
                      name={[name, 'benefit']}>
                      <Input placeholder="Benefits" />
                    </Form.Item>
                    <img src={trash} onClick={() => remove(name)} alt="" />
                  </div>
                </FadeEffect>
              ))}
              <div onClick={add} className={styles['add-item']}>
                <img src={plusIcon} alt="add-founder" />
                <span>Add Benefit</span>
              </div>
            </>
          )}
        </Form.List>

        <h2>Use of Proceeds / Funds Allocations</h2>
        <Form.Item
          style={{width: 720}}
          name="proceedDescription"
          className={styles.field}
          label="Description">
          <Input.TextArea rows={6} name="description" />
        </Form.Item>
        <div>
          <h3>Allocations</h3>
          <Form.List name="allocations">
            {(fields, {add, remove}) => (
              <>
                {fields?.map(({name}) => (
                  <FadeEffect key={name}>
                    <div className={styles['bigList']}>
                      <Form.Item
                        rules={[{required: true, message: 'Required'}]}
                        className={styles.smallWidth}
                        name={[name, 'allocationCategory']}
                        label="Allocation Category">
                        <Input
                          name="allocationCategory"
                          placeholder="Marketing"
                        />
                      </Form.Item>
                      <Form.Item
                        rules={[{required: true, message: 'Required'}]}
                        className={styles.smallWidth}
                        name={[name, 'percentageValue']}
                        label="Percentage">
                        <InputNumber
                          name="percentageValue"
                          placeholder="% in value"
                          className={styles['input-number']}
                          max={100}
                          min={0}
                          type="number"
                        />
                      </Form.Item>
                      <img
                        onClick={() => {
                          remove(name);
                        }}
                        src={trash}
                        alt=""
                      />
                    </div>
                  </FadeEffect>
                ))}
                <div onClick={add} className={styles['add-item']}>
                  <img src={plusIcon} alt="" />
                  <span>Add Category</span>
                </div>
              </>
            )}
          </Form.List>
        </div>
        <SubmitButton label="Save and Proceed" />
      </Form>
      <DBModal
        isOpen={createModal}
        handleClose={() => setCreateModal(false)}
        content={
          <CreateMutualFundsModal handleClose={() => setCreateModal(false)} />
        }
      />
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        confirmText={'Are you sure you want to Update this Fields'}
        title="Update Mutual Fund"
        handleOperation={() =>
          handleUpdateMutualFund(
            updateMutualFund,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
            true,
          )
        }
      />
    </div>
  );
};
