import {gql} from '@apollo/client';

export const GET_REVENUE_METRICS = gql`
  query GetRevenueMetrics($filters: MetricsFilters!) {
    getRevenueMetrics(filters: $filters) {
      ... on ResponseWithRevenueMetrics {
        message
        data {
          totalRevenue {
            currency
            symbol
            amount
          }
          totalPlatformFees {
            currency
            symbol
            amount
          }
          totalTransactionFees {
            currency
            symbol
            amount
          }
          totalDabaProPlatformFees {
            currency
            symbol
            amount
          }
          totalDabaProTransactionFees {
            currency
            symbol
            amount
          }
          totalDabaProSubscriptionFees {
            currency
            symbol
            amount
          }
        }
      }
      ... on Error {
        statusCode
        message
      }
    }
  }
`;

export const GET_WALLET_METRICS = gql`
  query GetWalletMetrics($filters: MetricsFilters!) {
    getWalletMetrics(filters: $filters) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithWalletMetrics {
        message
        data {
          totalRevenue {
            currency
            symbol
            amount
          }
          grossDepositFees {
            currency
            symbol
            amount
          }
          grossWithdrawalFees {
            currency
            symbol
            amount
          }
          grossSwapFees {
            currency
            symbol
            amount
          }
        }
      }
    }
  }
`;

export const GET_ORDER_METRICS = gql`
  query GetOrderMetrics($filters: MetricsFilters!) {
    getOrderMetrics(filters: $filters) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithOrderMetrics {
        message
        data {
          stocks {
            type
            total
          }
          mutualFunds {
            type
            total
          }
          bonds {
            type
            total
          }
          others {
            type
            total
          }
        }
      }
    }
  }
`;

export const GET_VOLUME_METRIC = gql`
  query GetVolumeMetrics($filters: MetricsFilters!) {
    getVolumeMetrics(filters: $filters) {
      ... on Error {
        statusCode
        message
      }
      ... on ResponseWithVolumeMetrics {
        message
        data {
          asset
          items {
            type
            currency
            symbol
            amount
          }
        }
      }
    }
  }
`;
