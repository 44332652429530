import {useMutation} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {CREATE_MUTUAL_FUND} from 'graphql/mutations/assetManagement';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {handleClearMutualFundsState} from 'redux/store/assetClass';
import {currencySymbols} from 'utils/mock';

export const CreateMutualFundsModal = ({handleClose}) => {
  const {assetId: assetKey} = useParams();
  const mutualFundsData = useSelector(state => state?.assetClass);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    opportunityDemoVideoThumbnail,
    missionAndVision,
    offer,
    description,
    language,
    topics,
    pitchDeck,
    minimumInvestmentAmount,
    faq,
    additionalDisclosures,
    documents,
    dabaScore,
    coInvestors,
    competitors,
    tradeable,
    level1AnalystVideo,
    level1AnalystVideoThumbnail,
    level2AnalystVideo,
    level2AnalystVideoThumbnail,
    maximumFundingTarget,
    minimumFundingTarget,
    liquidity,
    fundingTarget,
    companyValuation,
    disclosure,
    investmentRisk,
    isTimeSensitive,
    recommendedPortfolioStrategy,
    opportunityVoiceRecording,
    opportunityVoiceRecordingThumbnail,
    opportunityDemoVideo,
    feeSchedule,
    investmentContractSettings,
    issuer,
    isForDabaPro,
    additionalInvestmentTerms,
    issuerType,
    currency,
    templateId,
    name,
    riskAndEsg,
    isPaused,
    shouldNotifyUsersOnLaunch,
    endDate,
    isVisible,
    publicAudience,
    groupId,
    impactAndEsg,
    sectorIndustry,
    keyStatistics,
    exchange,
    invitedUsers,
    icon,
    externallyFundedAmountBreakDown,
    invitedGroups,
    solutions,
    listingType,
    isShariaCompliant,
    fractionable,
    minTradeableUnits,
    maxTradeableUnits,
    tradingVolume,
    startDate,
    orderExpirationLimit,
  } = mutualFundsData?.mutualFundsInstruments;

  const [createMutualFund, {loading}] = useMutation(CREATE_MUTUAL_FUND, {
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  const handleCreateMutualFund = () => {
    // eslint-disable-next-line no-unused-vars
    const {reportName, score, pdfReport, ...filteredDabaScore} =
      dabaScore || {};

    let dataToPass = {
      dabaScore: {
        score,
        ...(pdfReport && {pdfReport}),
        ...filteredDabaScore,
      },
    };

    createMutualFund({
      variables: {
        input: {
          ...Object.fromEntries(
            Object.entries(mutualFundsData?.mutualFunds || {}).filter(
              // eslint-disable-next-line no-unused-vars
              ([_, value]) => value !== '',
            ),
          ),
          baseFinancialInstrumentInput: {
            'whatWeOffer': offer,
            ...(startDate && {'startDate': moment(startDate).format()}),
            ...(endDate && {'endDate': moment(endDate).format()}),
            'settings': {
              isPaused,
              isVisible,
              shouldNotifyUsersOnLaunch,
              publicAudience,
            },
            'issuer': {
              'id': issuer,
              'issuerType': issuerType,
            },
            groupId,
            impactAndEsg,
            sectorIndustry,
            externallyFundedAmountBreakDown,
            keyStatistics,
            tradeable,
            key: 'MUTUAL_FUND',
            exchange,
            invitedUsers,
            isForDabaPro,
            invitedGroups,
            investmentRisk,
            isTimeSensitive,
            solutions,
            icon,
            assetClassKey: assetKey,
            opportunityDemoVideoThumbnail,
            additionalInvestmentTerms,
            listingType,
            liquidity,
            isShariaCompliant,
            fractionable,
            minTradeableUnits,
            maxTradeableUnits,
            tradingVolume,
            orderExpirationLimit,
            currency: {
              name: currencySymbols[currency]?.name,
              symbol: currencySymbols[currency]?.symbol,
              currencyCode: currencySymbols[currency]?.value,
            },
            templateId,
            name,
            riskAndEsg,
            description,
            language,
            topics,
            pitchDeck: {
              coverImage: pitchDeck?.coverImage,
              fileLink: pitchDeck?.fileLink,
              linkType: pitchDeck?.linkType,
              externalViewLink: pitchDeck?.externalViewLink,
              externalShareLink: pitchDeck?.externalShareLink,
            },
            minimumInvestmentAmount,
            faq,
            additionalDisclosures,
            documents,
            ...(score && {dabaScore: dataToPass?.dabaScore}),
            coInvestors,
            competitors,
            level1AnalystVideo,
            level1AnalystVideoThumbnail,
            level2AnalystVideo,
            level2AnalystVideoThumbnail,
            maximumFundingTarget,
            minimumFundingTarget,
            fundingTarget,
            companyValuation,
            disclosure,
            missionAndVision,
            recommendedPortfolioStrategy,
            opportunityVoiceRecording,
            opportunityVoiceRecordingThumbnail,
            opportunityDemoVideo,
            feeSchedule:
              Array.isArray(feeSchedule) &&
              feeSchedule?.map(data => ({
                feeName: data?.feeName,
                feeType: data?.feeType,
                ...(data?.fixedFeeAmount && {
                  fixedFeeAmount: Number(data?.fixedFeeAmount),
                }),
                ...(data.feeType === 'ENTRY' && {level: data?.level}),
                ...(data?.variableFeeAmount && {
                  variableFeeAmount: Number(data?.variableFeeAmount),
                }),
                scalable: data?.scalable ?? false,
                ...(data?.feeType === 'RECURRING' && {
                  frequency: data?.frequency,
                  origination: data?.origination,
                }),
                ...(data?.feeType === 'EXIT' &&
                  Array.isArray(data?.vector) && {
                    vector: data?.vector?.map(vect => ({
                      duration: vect?.duration,
                      magnitude: vect?.magnitude,
                    })),
                  }),
              })),
            investmentContractSettings,
          },
        },
      },
    })
      .then(({data: {createMutualFund}}) => {
        if (
          createMutualFund?.__typename === 'Error' ||
          createMutualFund?.statusCode === 400
        ) {
          toast.error(createMutualFund?.message);
        } else {
          toast.success('Mutual Fund created successfully');
          handleClose();
          dispatch(handleClearMutualFundsState());
          navigate(`/dashboard/asset-management/class/FUNDS/instruments`);
        }
      })
      .catch(error => toast.error(error.message));
  };

  return (
    <div className="confirm-modal">
      <div>
        <h1>Create Mutual Fund</h1>
        <p>
          Please make sure the details are correctly inputted before proceeding
        </p>
      </div>
      <div className="buttons">
        <Button
          disabled={loading}
          label={!loading ? 'Create' : 'Loading...'}
          onClick={handleCreateMutualFund}
        />
        <Button type="secondary" label="No" onClick={handleClose} />
      </div>
    </div>
  );
};
