export const createBondsInstrumentRoute = ({param, name}) => {
  return `/dashboard/asset-management/create/bond/${param}/${name}`;
};

export const createBondsStepTwoRoute = ({param, name}) => {
  return `/dashboard/asset-management/create/bond/${param}/step-two/${name}`;
};

export const createMutualFundsRoute = ({param, name}) => {
  return `/dashboard/asset-management/create/mutual-funds/${param || ''}/${
    name || ''
  }`;
};

export const createMutualFundsStepTwoRoute = ({param, name}) => {
  return `/dashboard/asset-management/create/mutual-funds/${param}/step-two/${
    name || ''
  }`;
};
