import {Form, InputNumber, Select} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import styles from './baseInvestmentDetails.module.scss';
import {portfolioStrategyData} from 'pages/campaigns/campaignData';
import {useEffect} from 'react';
import {FeeSchedule} from 'components/feeSchedule/FeeSchedule';
import {useParams} from 'react-router-dom';

const BaseInvestmentDetails = ({onSubmit, existingValues}) => {
  const [form] = Form.useForm();
  const {Option} = Select;
  const {assetId} = useParams();

  useEffect(() => {
    if (existingValues && existingValues.investmentRisk) {
      const {feeSchedule, ...restValues} = existingValues;
      form.setFieldsValue({
        ...restValues,
        feeSchedule: feeSchedule?.map(fees => ({
          ...fees,
          vector: fees?.vector?.map(vect => ({
            duration: vect?.duration,
            feeType: vect?.magnitude?.feeType,
            ...(vect?.magnitude?.feeType === 'FIXED' && {
              fixedAmount: vect?.magnitude?.value,
            }),
            ...(vect?.magnitude?.feeType === 'VARIABLE' && {
              variableAmount: vect?.magnitude?.value,
            }),
          })),
        })),
      });
    }
  }, [existingValues]);

  return (
    <div className={styles.container}>
      <Form
        form={form}
        onFinish={values => {
          const {feeSchedule, ...restOfFields} = values;
          onSubmit({
            ...restOfFields,
            feeSchedule: feeSchedule?.map(data => ({
              feeType: data?.feeType,
              feeName: data?.feeName,
              ...(data?.fixedFeeAmount && {
                fixedFeeAmount: data?.fixedFeeAmount,
              }),
              ...(data?.variableFeeAmount && {
                variableFeeAmount: data?.variableFeeAmount,
              }),
              ...(data.feeType === 'ENTRY' && {level: data?.level}),
              scalable: data?.scalable ?? false,
              ...(data?.feeType === 'RECURRING' && {
                frequency: data?.frequency,
                origination: data?.origination,
              }),
              ...(data?.feeType === 'EXIT' &&
                data?.vector?.length && {
                  vector: data?.vector?.map(vect => ({
                    duration: vect?.duration,
                    magnitude: {
                      feeType: vect?.feeType,
                      value:
                        vect?.feeType === 'FIXED'
                          ? vect?.fixedAmount
                          : vect?.variableAmount,
                    },
                  })),
                }),
            })),
          });
        }}
        layout="vertical">
        <div>
          <h2>Portfolio Strategy</h2>
          <div>
            <Form.Item
              style={{width: 450, marginTop: 10}}
              name="recommendedPortfolioStrategy"
              label="Recommended Portfolio Strategy">
              <Select
                allowClear
                placeholder="Select a recommended portfolio strategy">
                {portfolioStrategyData?.map(data => (
                  <Option key={data.key}>
                    <span>{data.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'This is a Required Field.'}]}
              style={{width: 450, marginBottom: 10}}
              name="investmentRisk"
              label="Investment Risk">
              <Select placeholder="Select an Investment Risk">
                <Option value="LOW">Low</Option>
                <Option value="MEDIUM">Medium</Option>
                <Option value="HIGH">High</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div>
          <h2>Investment Details in Asset Currency</h2>
        </div>
        <div>
          <div className={styles.form}>
            <Form.Item
              className={styles['field-width']}
              name="fundingTarget"
              label="Funding Target">
              <InputNumber
                placeholder="Enter Funding Target Amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="companyValuation"
              label="Valuation">
              <InputNumber
                placeholder="Enter Company Valuation Amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              name="minimumInvestmentAmount"
              label="Minimum Investment"
              rules={[
                {
                  required: true,
                  message: 'This is a Required Field',
                },
                {
                  validator: (_, value) =>
                    value > form.getFieldValue('fundingTarget') &&
                    form.getFieldValue('fundingTarget')
                      ? Promise.reject(
                          new Error(
                            'This must be Less than the Funding Target',
                          ),
                        )
                      : Promise.resolve(),
                },
              ]}>
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>

            <Form.Item
              className={styles['field-width']}
              name="maximumFundingTarget"
              label="Maximum Funding Target"
              rules={[
                {
                  validator: (_, value) =>
                    value < form.getFieldValue('fundingTarget') &&
                    form.getFieldValue('fundingTarget')
                      ? Promise.reject(
                          new Error(
                            'This must be GREATER than the Funding Target',
                          ),
                        )
                      : Promise.resolve(),
                },
              ]}>
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item
              className={styles['field-width']}
              name="minimumFundingTarget"
              label="Minimum Funding Target"
              rules={[
                {
                  validator: (_, value) =>
                    value > form.getFieldValue('fundingTarget')
                      ? Promise.reject(
                          new Error(
                            'This must be LESS than the Funding Target',
                          ),
                        )
                      : Promise.resolve(),
                },
              ]}>
              <InputNumber
                placeholder="Enter amount"
                className={styles['input-number']}
                formatter={value =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          </div>
          <h2>Fee Schedule</h2>
          <p style={{marginBottom: 10}}>
            For more Information on Fees and how it works:{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.notion.so/investondaba/Trading-Flow-f60b8d750d504d08a6c8c7e17736c986#a78fd687337c4e4196122ca430308a57">
              Link
            </a>
          </p>
          <FeeSchedule
            feeName="feeSchedule"
            form={form}
            isOfStockExchange
            isInstrument
            forMutualFund={assetId === 'FUNDS'}
          />
          <SubmitButton label="Save & Continue" />
        </div>
      </Form>
    </div>
  );
};

export default BaseInvestmentDetails;
