import {useNavigate} from 'react-router-dom';
import styles from './updateWalletTransaction.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN,
  GET_WALLET_TRANSACTION_BY_ID,
} from 'graphql/queries/wallet';
import {Form, Select, Spin, Tag} from 'antd';
import {useEffect, useState} from 'react';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_USER_WALLET_TRANSACTION} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import {currencySymbols} from 'utils/mock';
import {CheckOutlined, CopyOutlined} from '@ant-design/icons';

const UpdateWalletTransaction = ({
  currency,
  closeModal,
  operation,
  id,
  refetch,
  userId,
}) => {
  const [transactionStatus, setTransactionStatus] = useState('');
  const [copied, setCopied] = useState('');

  const handleCopy = (referenceId, type) => {
    navigator.clipboard.writeText(referenceId).then(() => {
      setCopied(type);
      setTimeout(() => {
        setCopied('');
      }, 400);
    });
  };
  const [details, setDetails] = useState({});
  const navigate = useNavigate();

  const {loading, data} = useQuery(GET_WALLET_TRANSACTION_BY_ID, {
    variables: {
      transactionId: id,
      currency,
      userId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    onCompleted: data => setDetails(data?.adminGetWalletTransactionById),
    onError: error => toast.error(error.message),
  });

  const [updateUserWalletTransaction, {loading: loadingUpdate}] = useMutation(
    UPDATE_USER_WALLET_TRANSACTION,
  );

  useEffect(() => {
    setTransactionStatus(data?.adminGetWalletTransactionById?.status);
  }, [data?.adminGetWalletTransactionById?.status]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleUpdateTransaction = () => {
    updateUserWalletTransaction({
      variables: {
        input: {
          currency,
          transactionId: id,
          status: transactionStatus,
          userId: data?.adminGetWalletTransactionById?.user?.id,
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_WALLET_TRANSACTIONS_FOR_ADMIN,
          variables: {
            input: {
              currency,
            },
          },
        },
      ],
    })
      .then(({data: {updateUserWalletTransaction}}) => {
        if (updateUserWalletTransaction.__typename === 'Error') {
          toast.error(updateUserWalletTransaction.message);
        } else {
          toast.success('Settings Updated Successfully.');
          refetch();
          closeModal();
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <h1> User Wallet Transaction Details</h1>
      </div>
      <div className={styles.profile}>
        <img src={data?.adminGetWalletTransactionById?.user?.imageUrl} alt="" />
        <div
          onClick={() => navigate(`/dashboard/user/${userId}/wallet-settings`)}>
          <h2>
            {data?.adminGetWalletTransactionById?.user?.firstName}{' '}
            {data?.adminGetWalletTransactionById?.user?.lastName}
          </h2>
          <span>@{data?.adminGetWalletTransactionById?.user?.username}</span>
        </div>
      </div>
      <section className={styles.content}>
        <Tag
          className={styles.tag}
          color={
            {
              'DEPOSIT': 'black',
              'WITHDRAWAL': 'default',
              'STOCK_BUY': 'magenta',
              'STOCK_SELL': 'cyan',
              'DIVIDEND_PAYOUT': 'orange',
              'DIVIDEND_REVERSAL': 'red',
              'CURRENCY_SWAP': 'geekblue',
              'VENTURE_INVESTMENT': 'purple',
              'VENTURE_FUND_INVESTMENT': 'gold',
              'BOND_INVESTMENT': 'volcano',
              'REWARDS_PAYIN': 'lime',
            }[details?.type] || 'blue'
          }>
          {details?.type?.replace(/_/g, ' ')}
        </Tag>
        <div className={styles.details}>
          <div className={styles.summary}>
            <p>
              <strong>Reference ID:</strong>
              {details?.referenceId}
              {copied === 'referenceId' ? (
                <CheckOutlined className={styles.copy} />
              ) : (
                <CopyOutlined
                  className={styles.copy}
                  onClick={() =>
                    handleCopy(details?.referenceId, 'referenceId')
                  }
                />
              )}
            </p>
            <p>
              <strong>Provider ID:</strong>
              {details?.paymentGateway?.provider === 'HUB2' ? (
                <>
                  {details?.paymentGateway?.metadata?.id}
                  {copied === 'providerId' ? (
                    <CheckOutlined className={styles.copy} />
                  ) : (
                    <CopyOutlined
                      className={styles.copy}
                      onClick={() =>
                        handleCopy(
                          details?.paymentGateway?.metadata?.id,
                          'providerId',
                        )
                      }
                    />
                  )}
                </>
              ) : details?.paymentGateway?.metadata?.transactionId ? (
                <>
                  {details?.paymentGateway?.metadata?.transactionId}
                  {copied === 'providerId' ? (
                    <CheckOutlined className={styles.copy} />
                  ) : (
                    <CopyOutlined
                      className={styles.copy}
                      onClick={() =>
                        handleCopy(
                          details?.paymentGateway?.metadata?.transactionId,
                          'providerId',
                        )
                      }
                    />
                  )}
                </>
              ) : (
                'N/A'
              )}
            </p>
            <p>
              <strong>Transaction Amount:</strong>
              <strong>
                {currencySymbols[currency]?.symbol}{' '}
                {details?.totalTransactionAmountIncludingFees != null
                  ? Number(
                      details.totalTransactionAmountIncludingFees,
                    ).toLocaleString()
                  : 'N/A'}
              </strong>
            </p>
            <p>
              <strong>Transaction Fee:</strong>
              {details?.transactionFee}
            </p>
          </div>

          {details?.swap && (
            <div className={styles.summary}>
              <p>
                <strong>Amount Swapped:</strong>
                {details?.swap?.sourceCurrency?.currencySymbol}
                {details?.swap?.amountSwapped}
              </p>
              <p>
                <strong>Amount Received:</strong>
                {details?.swap?.targetCurrency?.currencySymbol}
                {details?.swap?.amountReceived?.toLocaleString()}
              </p>
              <p>
                <strong>Total Fee:</strong>
                {details?.swap?.sourceCurrency?.currencySymbol}
                {details?.swap?.calculatedFees}
              </p>
            </div>
          )}
          {details?.order && (
            <div className={styles.summary}>
              <p>
                <strong>Ticker:</strong>
                {details?.order?.symbol}
              </p>
              <p>
                <strong>Type:</strong>
                {details?.order?.type}
              </p>
              <p>
                <strong>Total Amount:</strong>
                {currencySymbols[details?.order?.assetCurrency]?.symbol}{' '}
                {details?.order?.price?.toLocaleString()}
              </p>
              <p>
                <strong>Fees:</strong>
                {currencySymbols[details?.order?.assetCurrency]?.symbol}{' '}
                {details?.order?.totalFee?.toLocaleString()}
              </p>
            </div>
          )}
        </div>
        {operation && (
          <Form onFinish={handleUpdateTransaction} layout="vertical">
            <div className={styles.updateStatus}>
              <h1>Update Transaction Status</h1>
              <Form.Item
                name="status"
                label="Transaction Status"
                style={{width: 270}}
                initialValue={data?.adminGetWalletTransactionById?.status}
                rules={[
                  {
                    required: true,
                    message: 'Please select a status before updating',
                  },
                ]}>
                <Select
                  value={transactionStatus}
                  onChange={e => setTransactionStatus(e)}
                  defaultValue={data?.adminGetWalletTransactionById?.status}
                  placeholder="Select a Status">
                  <Select.Option key="SUCCESSFUL">Successful</Select.Option>
                  <Select.Option key="FAILED">Failed</Select.Option>
                </Select>
              </Form.Item>
              <SubmitButton
                disabled={loadingUpdate || !operation}
                label="Update Transaction"
              />
            </div>
          </Form>
        )}
      </section>
    </div>
  );
};

export default UpdateWalletTransaction;
