import styles from '../walletDetails.module.scss';
import {Switch, Form, InputNumber, Tooltip, Collapse} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {ProviderFeeSchedule} from './ProviderFeeSchedule';

const WithdrawalWalletFields = ({
  selectedCurrency,
  form,
  currencyDetails = {},
  currencyLimits,
  loading,
}) => {
  const [enableWithdrawal, setEnableWithdrawal] = useState(false);
  const [minWithdrawalAmount, setMinWithdrawalAmount] = useState(0);
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);
  const [maxNumOfWithdrawalPerDay, setMaxNumOfDepositsPerDay] = useState(0);
  const [dailyWithdrawalLimit, setDailyLimit] = useState(0);
  const [weeklyWithdrawalLimit, setWeeklyLimit] = useState(0);

  const [monthlyWithdrawalLimit, setMonthlyLimit] = useState(0);
  const {provider} = useParams();

  useEffect(() => {
    form.resetFields();
  }, [selectedCurrency]);
  const width = 190;

  useEffect(() => {
    setEnableWithdrawal(currencyDetails[selectedCurrency]?.withdrawal?.enabled);
    setMinWithdrawalAmount(
      currencyDetails[selectedCurrency]?.withdrawal?.minWithdrawalAmount,
    );
    setMaxWithdrawalAmount(
      currencyDetails[selectedCurrency]?.withdrawal?.maxWithdrawalAmount,
    );
    setMaxNumOfDepositsPerDay(
      currencyDetails[selectedCurrency]?.withdrawal
        ?.maxNumberOfWithdrawalPerDay,
    );
    setDailyLimit(currencyDetails[selectedCurrency]?.withdrawal?.limit?.daily);
    setWeeklyLimit(
      currencyDetails[selectedCurrency]?.withdrawal?.limit?.weekly,
    );
    setMonthlyLimit(
      currencyDetails[selectedCurrency]?.withdrawal?.limit?.monthly,
    );
  }, [currencyDetails, selectedCurrency]);

  const handleMaxDepositAmountChange = value => {
    setMaxWithdrawalAmount(value);
    updateDailyLimit(value, maxNumOfWithdrawalPerDay);
  };

  const handleMaxNumOfDepositsChange = value => {
    setMaxNumOfDepositsPerDay(value);
    updateDailyLimit(maxWithdrawalAmount, value);
  };

  const updateDailyLimit = (maxDepositAmount, maxNumOfDepositsPerDay) => {
    // Update dailyLimit based on maxDepositAmount and maxNumOfDepositsPerDay
    const newDailyLimit = maxDepositAmount * maxNumOfDepositsPerDay;
    setDailyLimit(newDailyLimit);

    // Update weeklyLimit and monthlyLimit accordingly
    form.setFieldsValue({
      dailyWithdrawalLimit: newDailyLimit,
      weeklyWithdrawalLimit: newDailyLimit * 7,
      monthlyWithdrawalLimit: newDailyLimit * 30,
    });
    setWeeklyLimit(newDailyLimit * 7);
    setMonthlyLimit(newDailyLimit * 30);
  };

  return (
    <div className={styles.section}>
      <h1>Withdrawal Method</h1>
      <div className={styles.sectionType}>
        <p>Allow Withdrawal in {`${selectedCurrency}`}</p>
        <Form.Item
          initialValue={currencyDetails[selectedCurrency]?.withdrawal?.enabled}
          name="allowedWithdrawal">
          <Switch
            value={enableWithdrawal}
            onChange={e => setEnableWithdrawal(e)}
            defaultChecked={
              currencyDetails[selectedCurrency]?.withdrawal?.enabled
            }
          />
        </Form.Item>
      </div>

      <div className={styles.breakdown}>
        <Form.Item
          rules={[{required: true, message: 'This field is required'}]}
          initialValue={
            currencyDetails[selectedCurrency]?.withdrawal?.minWithdrawalAmount
          }
          name="minWithdrawalAmount"
          help={
            minWithdrawalAmount > maxWithdrawalAmount && (
              <small style={{color: 'grey'}}>
                This must be LESS than the Maximum Withdrawal Amount
              </small>
            )
          }
          label="Minimum Withdrawal Amount">
          <InputNumber
            value={minWithdrawalAmount}
            onChange={e => setMinWithdrawalAmount(e)}
            style={{width}}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            defaultValue={
              currencyDetails[selectedCurrency]?.withdrawal?.minWithdrawalAmount
            }
          />
        </Form.Item>
        <Form.Item
          initialValue={
            currencyDetails[selectedCurrency]?.withdrawal?.maxWithdrawalAmount
          }
          name="maxWithdrawalAmount"
          rules={[{required: true, message: 'This field is required'}]}
          help={
            maxWithdrawalAmount < minWithdrawalAmount && (
              <small style={{color: 'grey'}}>
                This must be Greater than the Minimum Withdrawal AMount
              </small>
            )
          }
          label="Maximum Withdrawal Amount">
          <InputNumber
            value={maxWithdrawalAmount}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            defaultValue={
              currencyDetails[selectedCurrency]?.withdrawal?.maxWithdrawalAmount
            }
            onChange={handleMaxDepositAmountChange}
            style={{width}}
          />
        </Form.Item>
        <Form.Item
          initialValue={
            currencyDetails[selectedCurrency]?.withdrawal
              ?.maxNumberOfWithdrawalPerDay
          }
          rules={[{required: true, message: 'This field is required'}]}
          name="withdrawalDepositADay"
          label="Maximum Number of Withdrawal in a Day">
          <InputNumber
            defaultValue={
              currencyDetails[selectedCurrency]?.withdrawal
                ?.maxNumberOfWithdrawalPerDay
            }
            value={maxNumOfWithdrawalPerDay}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            onChange={handleMaxNumOfDepositsChange}
            style={{width}}
          />
        </Form.Item>
      </div>
      <div>
        <h2>Limits</h2>
        <div className={styles.breakdown}>
          <Form.Item
            initialValue={
              currencyDetails[selectedCurrency]?.withdrawal?.limit?.daily
            }
            name="dailyWithdrawalLimit"
            rules={[{required: true, message: 'This field is required'}]}
            help={
              (dailyWithdrawalLimit >= weeklyWithdrawalLimit ||
                dailyWithdrawalLimit >= monthlyWithdrawalLimit ||
                minWithdrawalAmount >= dailyWithdrawalLimit ||
                maxWithdrawalAmount * maxNumOfWithdrawalPerDay >
                  dailyWithdrawalLimit) && (
                <>
                  <small style={{color: 'grey', display: 'block'}}>
                    1. Daily Limit Cannot be Greater than Weekly and Monthly
                    Limit and also cannot be less than minimum withdrawal amount
                  </small>
                  <small style={{color: 'grey', display: 'block'}}>
                    2. It also cannot be less than minimum withdrawal amount
                  </small>
                  <small style={{color: 'grey', display: 'block'}}>
                    3. It cannot be less than the Max-Withdrawal by Total No of
                    withdrawals
                  </small>
                </>
              )
            }
            label={
              <span>
                Daily Limit{' '}
                <Tooltip
                  title={`Platform Daily Limit: ${
                    currencyLimits?.daily?.toLocaleString() ?? 'Not Created'
                  }`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <InputNumber
              value={dailyWithdrawalLimit}
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              defaultValue={
                currencyDetails[selectedCurrency]?.withdrawal?.limit?.daily
              }
              onChange={e => setDailyLimit(e)}
              style={{width}}
            />
          </Form.Item>
          <Form.Item
            initialValue={
              currencyDetails[selectedCurrency]?.withdrawal?.limit?.weekly
            }
            name="weeklyWithdrawalLimit"
            help={
              (weeklyWithdrawalLimit <= dailyWithdrawalLimit ||
                weeklyWithdrawalLimit >= monthlyWithdrawalLimit) && (
                <small style={{color: 'grey'}}>
                  Weekly Limit Cannot be Greater than Monthly Limit or Less than
                  Daily Limit
                </small>
              )
            }
            rules={[{required: true, message: 'This field is required'}]}
            label={
              <span>
                Weekly Limit{' '}
                <Tooltip
                  title={`Platform Weekly Limit: ${
                    currencyLimits?.daily?.toLocaleString() ?? 'Not Created'
                  }`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <InputNumber
              value={weeklyWithdrawalLimit}
              defaultValue={
                currencyDetails[selectedCurrency]?.withdrawal?.limit?.weekly
              }
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={e => setWeeklyLimit(e)}
              style={{width}}
            />
          </Form.Item>
          <Form.Item
            initialValue={
              currencyDetails[selectedCurrency]?.withdrawal?.limit?.monthly
            }
            rules={[{required: true, message: 'This field is required'}]}
            help={
              (monthlyWithdrawalLimit <= dailyWithdrawalLimit ||
                monthlyWithdrawalLimit <= weeklyWithdrawalLimit) && (
                <small style={{color: 'grey'}}>
                  Monthly Limit Cannot be less than Weekly & Daily Limit
                </small>
              )
            }
            name="monthlyWithdrawalLimit"
            label={
              <span>
                Monthly Limit{' '}
                <Tooltip
                  title={`Platform Monthly Limit: ${
                    currencyLimits?.daily?.toLocaleString() ?? 'Not Created'
                  }`}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }>
            <InputNumber
              value={monthlyWithdrawalLimit}
              defaultValue={
                currencyDetails[selectedCurrency]?.withdrawal?.limit?.monthly
              }
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
              onChange={e => setMonthlyLimit(e)}
              style={{width}}
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <>
          <Collapse defaultActiveKey={['withdrawalFees']}>
            <Collapse.Panel
              header={<h2>Withdrawal Fee Schedule</h2>}
              key="withdrawalFees">
              <div className={styles.collapse}>
                <ProviderFeeSchedule
                  feeName="withdrawalFees"
                  customAddLabel="Add Withdrawal Fee"
                  form={form}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
          <Collapse defaultActiveKey={['withdrawalDiscount']}>
            <Collapse.Panel
              header={<h2>Withdrawal Discount</h2>}
              key="withdrawalDiscount">
              <div className={styles.collapse}>
                <ProviderFeeSchedule
                  feeName="withdrawalDiscount"
                  customAddLabel="Add Withdrawal Discount"
                  form={form}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
        </>
        <SubmitButton
          disabled={loading}
          label={`${
            provider ? 'Update' : 'Create'
          } ${selectedCurrency} Currency`}
        />
      </div>
    </div>
  );
};

export default WithdrawalWalletFields;
