import {useQuery} from '@apollo/client';
import DBModal from 'components/modal/modal';
import {
  GET_HOME_OVERVIEW_DATA,
  GET_USERS_CHART_DATA,
} from 'graphql/queries/users';
import PercentageCard from 'pages/adminUser/card/PercentageCard';
import {useState} from 'react';
import {toast} from 'react-toastify';
import styles from './home.module.scss';
import {UsersChart} from './UsersChart/UsersChart';
import {RecentlyJoinedUsers} from './users/RecentlyJoinedUsers';

const AdminDashboardHome = () => {
  const [openChartModal, setOpenChartModal] = useState(false);

  const {data, loading} = useQuery(GET_HOME_OVERVIEW_DATA, {
    errorPolicy: 'ignore',
    onError: error => toast.error(error.message),
  });
  const {data: chartData, loading: loadingChartData} = useQuery(
    GET_USERS_CHART_DATA,
    {
      errorPolicy: 'all',
      onError: () => toast.error('Error Loading Users Chart data'),
    },
  );

  return (
    <div>
      <div className={styles.header}>
        <h1>Overview</h1>
        <div></div>
      </div>
      <div className={styles['percentage-box']}>
        <PercentageCard
          handleClick={() => setOpenChartModal(true)}
          active
          chart
          amount={loading ? null : data?.getAllUsers?.statistics?.totalUsers}
          label={'Total Number of Users'}
        />
        <PercentageCard
          amount={data?.getTotalNumberOfCompanies}
          label={'Number of startups'}
        />
        <PercentageCard
          amount={
            loading ? null : data?.getAllUsers?.statistics?.totalActiveInvestors
          }
          label={'Active Investors'}
          postive
        />
      </div>
      <div className={styles['overview-revenue']}>
        <div className={styles.revenue}>
          <UsersChart
            users={chartData?.getUsersSignupChartData}
            chartStyle="line-chart"
            loading={loadingChartData}
          />
        </div>
      </div>
      <div className={styles.users}>
        <RecentlyJoinedUsers />
      </div>
      <DBModal
        isOpen={openChartModal}
        handleClose={() => setOpenChartModal(false)}
        content={
          <UsersChart
            users={chartData?.getUsersSignupChartData}
            loading={loadingChartData}
            chartStyle="modal-line-chart"
          />
        }
        width={1300}
      />
    </div>
  );
};

export {AdminDashboardHome};
