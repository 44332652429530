// /* eslint-disable no-unused-vars */
import {Form, Input} from 'antd';
import styles from './overview.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import SubmitButton from 'components/Startup/components/SubmitButton';
// import UploadImage from 'components/Startup/profile/companyImage';
import {useEffect, useState} from 'react';
// import {Button} from '@dabafinance/react-components';
import ReactQuill from 'react-quill';
// import {UploadVideoToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
// import {toast} from 'react-toastify';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';
// import {BigPlayButton, Player} from 'video-react';

export const BaseOverview = ({onSubmit, existingData}) => {
  // const [audioFile, setAudioFile] = useState(null);
  // const [videoUrl, setVideoUrl] = useState(null);
  // const [progress, setProgress] = useState(0);
  // const [audioProgress, setAudioProgress] = useState(0);
  // const [loadingVideoUpload, setLoadingVideoUpload] = useState(false);
  // const [loadingAudioUpload, setLoadingAudioUpload] = useState(false);
  // const [opportunityThumbnail, setOpportunityThumbnail] = useState(null);
  // const [opportunityThumbnailPreview, setOpportunityThumbnailPreview] =
  //   useState(null);
  const [missions, setMissions] = useState(null);
  const [solutions, setSolutions] = useState(null);
  const [offer, setOffer] = useState(null);
  const [form] = Form.useForm();
  const [disclosure, setDisclosures] = useState(null);
  // const [disableButton, setDisabledButton] = useState(true);

  const width = 700;

  // const quillEmptyState = '<p><br></p>';
  // const overviewValidators =
  //   !missions ||
  //   missions === quillEmptyState ||
  //   disclosure === quillEmptyState ||
  //   !disclosure ||
  //   offer === quillEmptyState ||
  //   !offer ||
  //   // (!opportunityThumbnail && videoUrl) ||
  //   solutions === quillEmptyState ||
  //   !solutions;

  useEffect(() => {
    if (existingData) {
      form.setFieldsValue({
        ...existingData,
      });
      setMissions(existingData?.missionAndVision || null);
      setOffer(existingData?.whatWeOffer || null);
      setSolutions(existingData?.solutions || null);
      setDisclosures(existingData?.disclosure || null);
      // setOpportunityThumbnail(existingData?.opportunityDemoVideoThumbnail);
      // setOpportunityThumbnailPreview(existingData?.opportunityDemoVideo);
    }
  }, [existingData]);

  // useEffect(() => {
  //   setDisabledButton(overviewValidators);
  //   // console.log(disableButton);
  // }, [missions, disclosure, offer, solutions, overviewValidators]);

  // const handleUploadAudio = async e => {
  //   const selectedFile = e.target.files[0];
  //   setLoadingAudioUpload(true);
  //   const response = await UploadVideoToBucket(
  //     selectedFile,
  //     'campaign-bucket-images',
  //     setAudioProgress,
  //   );
  //   setAudioFile(response);
  //   setLoadingAudioUpload(false);
  // };

  // const handleUploadVideo = async e => {
  //   const selectedFile = e.target.files[0];
  //   if (selectedFile.type === 'video/mp4') {
  //     setLoadingVideoUpload(true);
  //     const response = await UploadVideoToBucket(
  //       selectedFile,
  //       'campaign-bucket-images',
  //       setProgress,
  //     );
  //     setVideoUrl(response);
  //     setLoadingVideoUpload(false);
  //     setProgress(0);
  //   } else {
  //     toast.error('Please upload a mp4 video');
  //   }
  // };

  return (
    <div className={styles.container}>
      <div>
        {/* <div>
          <h2 className={styles['upload-title']}>
            Why is this opportunity listed? - Voice Recording
          </h2>
          {audioFile ? (
            <div className={styles['audio-file']}>
              <audio controls src={audioFile} />
              <Button
                label="Delete File"
                onClick={() => {
                  setAudioFile('');
                  setAudioProgress(0);
                }}
              />
            </div>
          ) : (
            <div>
              <label className={styles.upload} htmlFor="file-upload">
                {loadingAudioUpload ? (
                  <div className={styles.uploading}>
                    <Spin />
                    <h3>Uploading, Please wait...</h3>
                    <span>{audioProgress}%</span>
                  </div>
                ) : (
                  <h2>Upload Audio or Recording</h2>
                )}
                <input
                  type="file"
                  id="file-upload"
                  accept="audio/mpeg"
                  className={styles['input-file']}
                  required
                  onChange={handleUploadAudio}
                />
              </label>
            </div>
          )}
          <div>
            <h2 className={styles['upload-title']}>Opportunity Demo - Video</h2>
            {videoUrl ? (
              <div>
                <Player fluid={false} width={550} src={videoUrl}>
                  <BigPlayButton position="center" />
                </Player>
                <div>
                  <Button
                    className={styles['delete-button']}
                    label="Delete Video"
                    onClick={() => setVideoUrl('')}
                  />
                </div>
              </div>
            ) : (
              <div>
                <label
                  className={styles['upload-video']}
                  htmlFor="video-upload">
                  {loadingVideoUpload ? (
                    <div className={styles['upload-file']}>
                      <Spin />
                      <h2>Uploading Please Wait...</h2>
                      <span>{progress}%</span>
                    </div>
                  ) : (
                    <h2>Upload Opportunity Demo</h2>
                  )}
                  <input
                    type="file"
                    id="video-upload"
                    className={styles['input-file']}
                    required
                    onChange={handleUploadVideo}
                  />
                </label>
              </div>
            )}
            <div>
              <h2 className={styles['upload-title']}>
                Opportunity Demo - Thumbnail
              </h2>

              <UploadImage
                thumbnail
                setImgUrl={setOpportunityThumbnail}
                previewUrl={opportunityThumbnailPreview}
                setPreviewUrl={setOpportunityThumbnailPreview}
                bucket="campaign-bucket-images"
              />
            </div>
          </div>
        </div> */}
        <Form
          onFinish={values =>
            onSubmit({
              ...values,
              keyFacts: values?.keyFacts?.map(data => ({
                name: data?.name,
                value: data?.value,
              })),
              milestones: values?.milestones?.map(data => ({
                name: data?.name,
                value: data?.value,
              })),
              // additionalDisclosures: values?.additionalDisclosures?.map(
              //   data => ({
              //     name: data?.name,
              //     value: data?.value,
              //   }),
              // ),
              // opportunityDemoVideoThumbnail: opportunityThumbnail,
              missionAndVision: missions,
              whatWeOffer: offer,
              solutions,
              disclosure: disclosure,
              // opportunityVoiceRecording: audioFile,
              // opportunityDemoVideo: videoUrl,
            })
          }
          form={form}
          className={styles.form}
          layout="vertical">
          <div>
            <h3 className={styles.title}>Key Facts</h3>
            <Form.List name="keyFacts">
              {(fields, {add, remove}) => (
                <>
                  {fields?.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles['key-facts']} key={name}>
                        <div>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'name']}
                            label="Name of Fact"
                            className={styles.width}>
                            <Input name="name" placeholder="Title of Facts" />
                          </Form.Item>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'value']}
                            className={styles.width}>
                            <Input.TextArea
                              name="value"
                              rows={4}
                              placeholder="body"
                            />
                          </Form.Item>
                        </div>
                        {
                          <img
                            onClick={() => remove(name)}
                            src={deleteIcon}
                            alt=""
                          />
                        }
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Facts</h1>
                  </div>
                </>
              )}
            </Form.List>
          </div>

          <div className={styles.mission}>
            <h3 className={styles.title}>Mission &#38; Vision</h3>
            <ReactQuill
              value={missions}
              theme="snow"
              onChange={e => setMissions(e)}
              style={{width, height: 200, position: 'relative'}}
              modules={BaseOverview.modules}
              formats={BaseOverview.format}
            />
          </div>
          <div style={{marginTop: 80}}>
            <h3 className={styles.title}>What we offer</h3>
            <ReactQuill
              style={{width, height: 200, position: 'relative'}}
              value={offer}
              theme="snow"
              onChange={el => setOffer(el)}
              modules={BaseOverview.modules}
              formats={BaseOverview.format}
            />
          </div>

          <div style={{marginTop: 80}}>
            <h3 className={styles.title}>Solutions</h3>
            <ReactQuill
              style={{width, height: 200, position: 'relative'}}
              value={solutions}
              theme="snow"
              onChange={el => setSolutions(el)}
              modules={BaseOverview.modules}
              formats={BaseOverview.format}
            />
          </div>
          <div style={{marginTop: 80}}>
            {/* <h3>Key Milestones</h3> */}
            {/* <Form.List name="milestones">
              {(fields, {add, remove}) => (
                <>
                  {fields?.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles['key-facts']} key={name}>
                        <div>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'name']}
                            label="Name of Milestone"
                            className={styles.width}>
                            <Input name="name" placeholder="Title of Facts" />
                          </Form.Item>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'value']}
                            label="Description"
                            className={styles.width}>
                            <Input.TextArea
                              name="value"
                              rows={4}
                              placeholder="body"
                            />
                          </Form.Item>
                        </div>
                        {
                          <img
                            onClick={() => remove(name)}
                            src={deleteIcon}
                            alt=""
                          />
                        }
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Milestones</h1>
                  </div>
                </>
              )}
            </Form.List> */}
          </div>
          {
            <div style={{marginTop: 30}}>
              <h3 className={styles.title}>Disclosure</h3>
              <ReactQuill
                style={{width, height: 200, position: 'relative'}}
                value={disclosure}
                theme="snow"
                onChange={el => setDisclosures(el)}
                modules={BaseOverview.modules}
                formats={BaseOverview.format}
              />
            </div>
            /* 
          <div style={{marginTop: 80}}>
            <h3>Additional Disclosures</h3>
            <Form.List name="additionalDisclosures">
              {(fields, {add, remove}) => (
                <>
                  {fields?.map(({name}) => (
                    <FadeEffect key={name}>
                      <div className={styles['key-facts']} key={name}>
                        <div>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            name={[name, 'name']}
                            label="Name of Addit. Disclosure"
                            className={styles.width}>
                            <Input name="name" placeholder="Title of Facts" />
                          </Form.Item>
                          <Form.Item
                            rules={[{required: true, message: 'Required'}]}
                            label="Description"
                            name={[name, 'value']}
                            className={styles.width}>
                            <Input.TextArea
                              name="value"
                              rows={4}
                              placeholder="body"
                            />
                          </Form.Item>
                        </div>
                        {
                          <img
                            onClick={() => remove(name)}
                            src={deleteIcon}
                            alt=""
                          />
                        }
                      </div>
                    </FadeEffect>
                  ))}
                  <div onClick={add} className={styles['add-button']}>
                    <img src={plusIcon} alt="" />
                    <h1>Add Disclosures</h1>
                  </div>
                </>
              )}
            </Form.List>
          </div> */
          }
          <div style={{margin: 85}}>
            <SubmitButton label="Save & Continue" />
          </div>
        </Form>
      </div>
    </div>
  );
};

BaseOverview.modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link', 'image'],

    ['code-block'],
  ],
};

BaseOverview.format = [
  'header',
  'font',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'image',
  'link',
  'code-block',
];

export default BaseOverview;
