import {DatePicker, Form, Input, Modal, Select, Spin, Switch, Tabs} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import styles from './newNotification.module.scss';
import backIcon from 'images/back.png';
import {useNavigate} from 'react-router-dom';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  GET_NOTIFICATION_CLASSES,
  NOTIFICATION_BATCH_QUERY,
} from 'graphql/queries/notifications';
import {
  GET_SCHEDULED_NOTIFICATIONS,
  SEND_NOTIFICATION_TO_ALL_USERS,
  SEND_NOTIFICATION_TO_USERS,
} from 'graphql/mutations/notifications';
import {toast} from 'react-toastify';
import moment from 'moment';
import _ from 'lodash';
import {
  assetClassEnum,
  emailNotificationTypes,
  notificationLanguages,
  notificationOptions,
  notificationTypes,
} from 'utils/constants';
import PushNotificationData from './pushNotificationData/PushNotificationData';
import EmailNotificationFields from './emailNotificationFields/EmailNotificationFields';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';
import NotificationFilter from './notificationFilter';
import {Button} from '@dabafinance/react-components';
import {WhatsappNotificationFields} from './whatsappNotificationFields/whatsappNotificationFields';
import ReactQuill from 'react-quill';
import {onTranslateNotification} from './actions';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {disclosureTypeEnum} from 'utils/mock';
import {GET_ALL_EMAIL_DISCLOSURES} from 'graphql/queries/emailDisclosure';
import {getUserTimezone} from 'utils/helper';

const NewNotification = () => {
  const [category, setCategory] = useState([]);
  const [notificationCategory, setNotificationCategory] = useState([]);
  const [type, setType] = useState([]);
  const [activeTab, setActiveTab] = useState('APP');
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [addDisclosure, setAddDisclosure] = useState(false);
  const [disclosureType, setDisclosureType] = useState('NONE');
  const [frequency, setFrequency] = useState('');
  const [date, setDate] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [audience, setAudience] = useState('ALL_USERS');
  const [opportunityMetaData, setOpportunityMetaData] = useState({});
  const [reportMetaData, setReportMetaData] = useState({});
  const [companyMetaData, setCompanyMetaData] = useState({});
  const [bondsMetadata, setBondsMetadata] = useState({});
  const [groupMetaData, setGroupMetaData] = useState({});
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [emailHeader, setEmailHeader] = useState('');
  const [emailImage, setEmailImage] = useState('');
  const [emailPreviewImage, setEmailPreviewImage] = useState('');
  const [baseLanguage, setBaseLanguage] = useState('EN');
  const [selectedTranslation, setSelectedTranslation] = useState('');
  const [linkShouldOpenInTheApp, setLinkShouldOpenInTheApp] = useState(false);
  const [enableChangeTranslation, setEnableChangeTranslation] = useState(true);
  const [openDeleteTranslationModal, setOpenDeleteTranslationModal] =
    useState(false);

  const [selectedWhatsappTemplate, setSelectedWhatsappTemplate] = useState({});
  const isAppOrEmail = activeTab === 'APP' || activeTab === 'EMAIL';

  const [translatedLanguage, setTranslatedLanguage] = useState([]);

  const {data: getAllCountries} = useQuery(GET_ALL_COUNTRIES);
  const [getNotificationClass, {data: getNotificationClasses}] = useLazyQuery(
    GET_NOTIFICATION_CLASSES,
  );
  const [getEmailDisclosures, {data: getAllEmailDisclosures}] = useLazyQuery(
    GET_ALL_EMAIL_DISCLOSURES,
  );

  const onDisclosureType = e => {
    setDisclosureType(e);
    getEmailDisclosures({
      variables: {
        disclosureType: e,
        limit: 100,
      },
    });
  };

  const onCategoryChange = e => {
    setNotificationCategory(e);
    getNotificationClass({
      variables: {
        input: {
          active: true,
          channels: [...new Set([...notificationCategory, ...e])],
        },
      },
    });
  };

  const onTabChange = e => {
    setActiveTab(e);
    getNotificationClass({
      variables: {
        input: {
          active: true,
          channels: [e],
        },
      },
    });
  };
  /* The below code is filtering an array called `notificationTypes` based on certain conditions.
    1. Push Option was select,
    2. Only then is External Link Available
    3. if we have more than one category, it is filtered out
  */
  const filteredNotificationTypes = category.includes('SMS')
    ? notificationTypes.filter(data => data.value !== 'External Link')
    : notificationTypes.slice();

  const {Option} = Select;
  const {TextArea} = Input;
  const navigate = useNavigate();

  const {data, loading} = useQuery(NOTIFICATION_BATCH_QUERY);

  const [sendNotificationToAllUsers, {loading: loadingRequest}] = useMutation(
    SEND_NOTIFICATION_TO_ALL_USERS,
  );
  const [sendNotificationToUsers, {loading: loadingUsersRequest}] = useMutation(
    SEND_NOTIFICATION_TO_USERS,
  );
  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const {TabPane} = Tabs;

  const handleSendNotification = formValues => {
    /**
     * Schedule fields optional
     */

    const scheduleFields = {};
    if (date) {
      const now = new Date().toISOString();
      const newDate = new Date(date).toISOString();
      if (now >= newDate) {
        scheduleFields.date = new Date(
          moment(Date.now() + 180000).format('YYYY-MM-DD HH:mm:ss'),
        ).toISOString();
      } else {
        scheduleFields.date = newDate;
      }
    }
    if (!date) {
      scheduleFields.date = new Date(
        moment(Date.now() + 10000).format('YYYY-MM-DD HH:mm:ss'),
      ).toISOString();
    }
    if (!date) scheduleFields.frequency = 'ONE_TIME';
    if (formValues?.frequency) scheduleFields.frequency = formValues?.frequency;
    if (formValues?.selectedDays && formValues?.selectedDays?.length)
      scheduleFields.selectedDays = formValues?.selectedDays;

    /**
     * Filtering by either test or live campaign Optional
     */
    const optionalFields = {};
    if (formValues?.audience === 'INVESTORS_IN_A_CAMPAIGN') {
      optionalFields.campaign = formValues?.liveCampaignId;
    }
    if (formValues?.audience === 'INVESTORS_WITH_RESERVATIONS') {
      optionalFields.campaign = formValues?.testCampaignId;
    }
    if (formValues?.externalLink) {
      optionalFields.externalLink = formValues?.externalLink;
      optionalFields.shouldOpenInApp = linkShouldOpenInTheApp;
    }

    /**
     * Inclusion of optional Email Fields
     */
    if (emailHeader) {
      optionalFields.emailHeader = emailHeader;
    }
    if (emailImage) {
      optionalFields.emailBanner = emailImage;
    }
    if (formValues?.type?.length) {
      optionalFields.type = formValues?.type;
    }

    /**
     * ASSET CLASS NOTIFICATION
     *
     */
    let orderOptionalFields = {};
    if (formValues.orderFrom || formValues.orderTo) {
      orderOptionalFields.orderTransactionDate = {
        from: formValues.orderFrom
          ? new Date(formValues.orderFrom).toISOString()
          : null,
        to: formValues.orderTo
          ? new Date(formValues.orderTo).toISOString()
          : null,
      };
    }
    if (formValues?.orderStatus?.length)
      orderOptionalFields.orderStatus = formValues?.orderStatus;
    if (formValues?.orderDirection)
      orderOptionalFields.orderDirection = formValues?.orderDirection;
    if (formValues?.assetClass)
      orderOptionalFields.assetClass = formValues?.assetClass;
    if (formValues?.assetId) orderOptionalFields.assetId = formValues?.assetId;
    if (formValues?.isOrderOnHold)
      orderOptionalFields.isOrderOnHold = formValues?.isOrderOnHold;

    const whatsappVariables =
      activeTab === 'WHATSAPP'
        ? {
            category: 'WHATSAPP',
            title: formValues['title-template-variable'],
            whatsappTemplateName: selectedWhatsappTemplate?.templateName,
            whatsappTemplateVariable: [
              ...selectedWhatsappTemplate?.templateVariables
                // Ignore the username variable
                ?.slice(1)
                .filter(variable => !variable?.isAppLink)
                .map(variable => ({
                  name: variable.name,
                  value: formValues[variable.name],
                  isAppLink: variable.isAppLink,
                })),
              ...(() => {
                if (!selectedWhatsappTemplate?.templateLink?.isStatic) {
                  return [
                    {
                      name: 'single-item',
                      value: formValues['template-link-id'],
                      isAppLink: true,
                      campaignMode:
                        selectedWhatsappTemplate?.templateLink?.type ===
                        'SINGLE_OPPORTUNITY'
                          ? formValues['campaignMode']
                          : undefined,
                      listingType:
                        selectedWhatsappTemplate?.templateLink?.type ===
                        'SINGLE_OPPORTUNITY'
                          ? formValues['listingType']
                          : undefined,
                    },
                  ];
                }
                return [];
              })(),
            ],
            ...(formValues['whatsappTranslatedLanguage'] && {
              multilang: {
                language: formValues['whatsappTranslatedLanguage'],
                whatsappTemplateName: selectedWhatsappTemplate?.templateName,
                whatsappTemplateVariable: [
                  ...selectedWhatsappTemplate?.templateVariables
                    // Ignore the username variable
                    ?.slice(1)
                    .filter(variable => !variable?.isAppLink)
                    .map(variable => ({
                      name: variable.name,
                      value: formValues?.whatsappMultiLang?.[variable.name],
                      isAppLink: variable.isAppLink,
                    })),
                  ...(() => {
                    if (!selectedWhatsappTemplate?.templateLink?.isStatic) {
                      return [
                        {
                          name: 'single-item',
                          value:
                            formValues?.whatsappMultiLang?.['template-link-id'],
                          isAppLink: true,
                          campaignMode:
                            selectedWhatsappTemplate?.templateLink?.type ===
                            'SINGLE_OPPORTUNITY'
                              ? formValues?.whatsappMultiLang?.['campaignMode']
                              : undefined,
                          listingType:
                            selectedWhatsappTemplate?.templateLink?.type ===
                            'SINGLE_OPPORTUNITY'
                              ? formValues?.whatsappMultiLang?.['listingType']
                              : undefined,
                        },
                      ];
                    }
                    return [];
                  })(),
                ],
              },
            }),
          }
        : {};
    /**
     * PUSH NOTIFICATIONS METADATA
     */
    let metaData = {};
    if (!_.isEmpty(companyMetaData)) metaData.company = companyMetaData;
    else if (!_.isEmpty(reportMetaData)) metaData.report = reportMetaData;
    else if (!_.isEmpty(opportunityMetaData)) {
      metaData.opportunity = opportunityMetaData;
    } else if (!_.isEmpty(groupMetaData)) metaData.group = groupMetaData;
    else if (!_.isEmpty(bondsMetadata) && formValues.type === 'SINGLE_BOND')
      metaData.bonds = bondsMetadata;
    else if (
      !_.isEmpty(bondsMetadata) &&
      formValues.type === 'SINGLE_MUTUAL_FUND'
    )
      metaData.mutualFund = bondsMetadata;

    // Send Notification to specific User(s)
    if (formValues?.audience === 'USERS') {
      return sendNotificationToUsers({
        variables: {
          data: {
            category: activeTab === 'EMAIL' ? 'EMAIL' : formValues?.category,
            title: formValues?.title,
            body: activeTab === 'EMAIL' ? emailBody : body,
            groups: formValues?.groups,
            userIds: users.map(data => data.split(';')[2]),
            from: formValues?.senderEmail,
            replyTo: formValues?.replyEmail,
            addDisclosure,
            class: formValues?.class,
            assetClass: formValues?.assetClass,
            company: formValues?.company?.split(';')[1],
            disclosure: formValues?.disclosure,
            ...(formValues.collectionId && {
              collectionId: formValues.collectionId,
            }),
            disclosureType: formValues?.disclosureType,
            metadata: {
              ...metaData,
            },
            ...optionalFields,
            topics: formValues?.topics?.map(data => data.split(';')[1]),
            schedule: {
              ...scheduleFields,
            },
            language: formValues?.baseLanguage,
            multilang:
              translatedLanguage.length > 0
                ? translatedLanguage.map(item => {
                    return {
                      body: item?.message,
                      title: item?.title,
                      language: item?.language,
                      emailHeader: item?.header ? item?.header : undefined,
                    };
                  })
                : [],
            audience: {
              signupDateRange: {
                from: from ? new Date(from).toISOString() : null,
                to: to ? new Date(to).toISOString() : null,
              },
              ...orderOptionalFields,
              state: formValues?.state,
              city: formValues?.city,
              citizenStatus: formValues?.citizenStatus,
              countryOfBirth:
                formValues?.countryOfBirth?.length > 0
                  ? formValues?.countryOfBirth?.map(item => item.split(';')[1])
                  : formValues?.countryOfBirth,
              countryOfCitizenship:
                formValues?.countryOfCitizenship?.length > 0
                  ? formValues?.countryOfCitizenship.map(
                      item => item.split(';')[1],
                    )
                  : formValues?.countryOfCitizenship,
              country: formValues?.countryOfResidence,
              visaType: formValues?.visaType,
              investmentHorizon: formValues?.investmentHorizon,
              annualNetIncome: formValues?.netIncome,
              employmentStatus: formValues?.employmentStatus,
              taxFilingStatus: formValues?.taxFilingStatus,
              estimatedNetWorth: formValues?.estimatedNetWorth,
              investmentPreference: formValues?.investmentPreference,
              investmentExperience: formValues?.investmentExperience,
              recommendedPortfolioStrategy: formValues?.investmentStrategies,
              affiliations: formValues?.investmentAffiliation,
              investmentGoals: formValues?.investmentGoals,
            },
            ...whatsappVariables,
          },
        },
        refetchQueries: [
          {
            query: GET_SCHEDULED_NOTIFICATIONS,
            variables: {
              data: {
                isActive: 'ACTIVE',
                isScheduled: true,
                status: 'PENDING',
              },
            },
          },
        ],
      })
        .then(({data: {sendNotificationToUsers}}) => {
          if (sendNotificationToUsers.__typename === 'Error') {
            toast.error(sendNotificationToUsers.message);
          } else {
            toast.success('Notification sent Successfully');
            navigate('/dashboard/notifications');
          }
        })
        .catch(err => toast.error(err));
    }

    // Send Notification to all users

    sendNotificationToAllUsers({
      variables: {
        data: {
          category: activeTab === 'EMAIL' ? 'EMAIL' : formValues?.category,
          title: formValues?.title,
          body: activeTab === 'EMAIL' ? emailBody : body,
          groups: formValues?.groups,
          from: formValues?.senderEmail,
          replyTo: formValues?.replyEmail,
          addDisclosure,
          class: formValues?.class,
          assetClass: formValues?.assetClass,
          company: formValues?.company?.split(';')[1],
          disclosure: formValues?.disclosure,
          disclosureType: formValues?.disclosureType,
          ...(formValues.collectionId && {
            collectionId: formValues.collectionId,
          }),
          metadata: {
            ...metaData,
          },
          ...optionalFields,
          topics: formValues?.topics?.map(data => data.split(';')[1]),
          schedule: {
            ...scheduleFields,
          },
          language: formValues?.baseLanguage,
          multilang:
            translatedLanguage.length > 0
              ? translatedLanguage.map(item => {
                  return {
                    body: item?.message,
                    title: item?.title,
                    language: item?.language,
                    emailHeader: item?.header ? item.header : undefined,
                  };
                })
              : [],
          audience: {
            ...orderOptionalFields,
            category: formValues?.audience,
            accredited: formValues?.accredited,
            lastAppVisit: formValues?.latestVisit,
            isKycApproved: formValues?.isKycApproved,
            verifiedInvestors: formValues?.verifiedInvestors,
            activeInvestor: formValues?.activeInvestors,
            profileCompletion: formValues?.profileCompletion,
            signupDateRange: {
              from: from ? new Date(from).toISOString() : null,
              to: to ? new Date(to).toISOString() : null,
            },
            state: formValues?.state,
            city: formValues?.city,
            citizenStatus: formValues?.citizenStatus,
            countryOfBirth:
              formValues?.countryOfBirth?.length > 0
                ? formValues?.countryOfBirth?.map(item => item.split(';')[1])
                : formValues?.countryOfBirth,
            countryOfCitizenship:
              formValues?.countryOfCitizenship?.length > 0
                ? formValues?.countryOfCitizenship?.map(
                    item => item.split(';')[1],
                  )
                : formValues?.countryOfCitizenship,
            country: formValues?.countryOfResidence,
            visaType: formValues?.visaType,
            investmentHorizon: formValues?.investmentHorizon,
            annualNetIncome: formValues?.netIncome,
            employmentStatus: formValues?.employmentStatus,
            taxFilingStatus: formValues?.taxFilingStatus,
            estimatedNetWorth: formValues?.estimatedNetWorth,
            investmentPreference: formValues?.investmentPreference,
            investmentExperience: formValues?.investmentExperience,
            recommendedPortfolioStrategy: formValues?.investmentStrategies,
            affiliations: formValues?.investmentAffiliation,
            investmentGoals: formValues?.investmentGoals,
          },
          ...whatsappVariables,
        },
      },
      refetchQueries: [
        {
          query: GET_SCHEDULED_NOTIFICATIONS,
          variables: {
            data: {
              isActive: 'ACTIVE',
              isScheduled: true,
              status: 'PENDING',
            },
          },
        },
      ],
    })
      .then(({data: {sendNotificationToAllUsers}}) => {
        if (sendNotificationToAllUsers.__typename === 'Error') {
          toast.error(sendNotificationToAllUsers.message);
        } else {
          toast.success('Notification sent Successfully');
          navigate('/dashboard/notifications');
        }
      })
      .catch(err => toast.error(err));
  };

  const languageToBeTranslatedTo = notificationLanguages.filter(
    key => key.key !== baseLanguage,
  );

  const handleTranslation = async () => {
    if (!selectedTranslation) return;
    const translateProps = {
      sourceLang: baseLanguage,
      targetLang: selectedTranslation,
      targetText1: title,
      targetText2: activeTab === 'APP' ? body : emailBody,
    };
    if (activeTab === 'EMAIL') {
      translateProps.targetText3 = emailHeader;
    }
    try {
      const newValues = [...translatedLanguage];

      //Check if translation exists
      if (newValues.length && newValues[0]) {
        // Check if we're trying to translate in the same language
        const languageExists = newValues.some(
          data => data.language === selectedTranslation,
        );
        if (languageExists) {
          const updateTranslation = await onTranslateNotification(
            translateText,
            translateProps,
            activeTab,
          );
          const translationIndex = newValues.findIndex(
            data => data.language === selectedTranslation,
          );
          newValues[translationIndex].title = updateTranslation.title;
          newValues[translationIndex].message = updateTranslation.message;
          newValues[translationIndex].header = updateTranslation?.header;
          newValues[translationIndex].language = updateTranslation.language;
          setTranslatedLanguage(newValues);
        }
      } else {
        const triggerTranslation = await onTranslateNotification(
          translateText,
          translateProps,
          activeTab,
        );
        if (!triggerTranslation) {
          return toast.error('Something went wrong when Translating');
        }
        newValues.push(triggerTranslation);
        setTranslatedLanguage(newValues);
        setTranslatedLanguage(newValues);
      }
    } catch (error) {
      toast.error(`Something went wrong! Error: ${error}`);
    }
  };

  const handleChangeTranslatedValues = (key, event, name) => {
    let eventName = name;

    let eventValue = name ? event : event.target.value;
    const newFormValues = translatedLanguage.map((item, index) => {
      if (index === key) {
        return {...item, [eventName]: eventValue};
      }
      return item;
    });
    newFormValues[key][eventName] = eventValue;
    setTranslatedLanguage(newFormValues);
  };

  useEffect(() => {
    // Reset dynamic whatsapp template link
    form.setFieldValue('template-link-id', '');
  }, [selectedWhatsappTemplate]);

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const onDeleteTab = e => {
    e.stopPropagation();
    setTranslatedLanguage([]);
    setOpenDeleteTranslationModal(false);
  };

  /* The above code is defining a function called `disableSendButton`. This function checks several
  conditions using logical operators (`||` and `&&`) and returns a boolean value. */
  const disableSendButton = loadingRequest || loadingUsersRequest;
  return (
    <div>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>New Notification</h1>
      </div>
      <div className={styles['details-toggler']}>
        <button
          className={activeTab === 'APP' ? styles['active-toggle'] : ''}
          onClick={() => {
            setActiveTab('APP');
            form.resetFields();
            setEmailBody('');
          }}>
          {' '}
          App
        </button>
        <button
          className={activeTab === 'EMAIL' ? styles['active-toggle'] : ''}
          onClick={() => {
            form.resetFields();
            onTabChange('EMAIL');
          }}>
          {' '}
          Email
        </button>
        *
        <button
          className={activeTab === 'WHATSAPP' ? styles['active-toggle'] : ''}
          onClick={() => {
            form.resetFields();
            onTabChange('WHATSAPP');
            setCategory(['WHATSAPP']);
          }}>
          {' '}
          Whatsapp
        </button>
      </div>
      <div className={styles.form}>
        <h4 className={styles['header-text']}>DETAILS</h4>

        <Form form={form} onFinish={handleSendNotification} layout="vertical">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Base Language is required',
              },
            ]}
            label="Base Language"
            className={styles.inputField}
            name="baseLanguage">
            <Select
              value={baseLanguage}
              placeholder="Select a Language"
              onChange={e => setBaseLanguage(e)}
              name="baseLanguage">
              {notificationLanguages.map(name => (
                <Option key={name.key}>{name.label}</Option>
              ))}
            </Select>
          </Form.Item>
          {activeTab === 'APP' && (
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Category is required',
                },
              ]}
              label="Category"
              name="category"
              className={styles.inputField}>
              <Select
                mode="tags"
                value={notificationCategory}
                onChange={e => onCategoryChange(e)}
                placeholder="Select a category"
                name="category">
                <Option value="PUSH">Push</Option>
                <Option value="IN_APP">In App</Option>
              </Select>
            </Form.Item>
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Notification Class is required',
              },
            ]}
            name="class"
            label="Notification Class"
            className={styles.inputField}>
            <Select placeholder="Select a class" name="class">
              {getNotificationClasses?.getNotificationClasses?.data?.map(
                item => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ),
              )}
            </Select>
          </Form.Item>
          {activeTab === 'WHATSAPP' && (
            <WhatsappNotificationFields
              selectedWhatsappTemplate={selectedWhatsappTemplate}
              inputClassName={styles.inputField}
              previewClassName={styles.whatsappPreview}
              hiddenFieldClassName={styles.hiddenField}
              onSelectedWhatsappTemplateChange={setSelectedWhatsappTemplate}
              form={form}
              languageToBeTranslatedTo={languageToBeTranslatedTo}
              baseLanguage={baseLanguage}
            />
          )}

          {isAppOrEmail && (
            <Form.Item
              label="Translation Language"
              className={styles.inputField}
              name="translatedLanguage">
              <Select
                placeholder="Select a Language"
                allowClear
                value={selectedTranslation}
                loading={loadingTranslation}
                onChange={setSelectedTranslation}
                name="type">
                {languageToBeTranslatedTo.map(({label, key}) => (
                  <Option key={key}>{label}</Option>
                ))}
              </Select>
            </Form.Item>
          )}

          <Tabs
            tabBarExtraContent={
              <Button
                disabled={loadingTranslation || !selectedTranslation}
                label="Translate"
                onClick={handleTranslation}
              />
            }>
            {isAppOrEmail && (
              <TabPane key="Original" tab="Base Language">
                {isAppOrEmail && (
                  <>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: 'Title is required',
                        },
                      ]}
                      name="title"
                      label="Title"
                      className={styles.inputField}>
                      <Input
                        placeholder="Title of the Notification"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                      />
                    </Form.Item>
                  </>
                )}
                {activeTab === 'APP' && (
                  <div>
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message:
                            'Message is required, Maximum of 115 characters',
                          max: 115,
                        },
                      ]}
                      name="body"
                      label="Message"
                      className={styles.inputField}>
                      <TextArea
                        value={body}
                        onChange={e => setBody(e.target.value)}
                        rows={6}
                        placeholder="Type Message"
                      />
                    </Form.Item>
                  </div>
                )}
                {activeTab === 'EMAIL' && (
                  <EmailNotificationFields
                    modules={NewNotification.modules}
                    formats={NewNotification.format}
                    onEmailBodyChange={setEmailBody}
                    emailBody={emailBody}
                    header={emailHeader}
                    onHeaderChange={setEmailHeader}
                    onChangeImgUrl={setEmailImage}
                    emailPreviewImage={emailPreviewImage}
                    onChangeEmailPreviewImage={setEmailPreviewImage}
                  />
                )}
              </TabPane>
            )}
            {translatedLanguage.length &&
              translatedLanguage.map((item, index) => (
                <TabPane
                  tab={
                    <div className={styles.deleteTranslation}>
                      {
                        notificationLanguages.find(
                          lang => lang.key === item.language,
                        ).label
                      }
                      <p
                        role="button"
                        onClick={() => setOpenDeleteTranslationModal(true)}>
                        X
                      </p>
                    </div>
                  }
                  key={index}>
                  <div className={styles.updateButton}>
                    <Button
                      onClick={() =>
                        setEnableChangeTranslation(!enableChangeTranslation)
                      }
                      type="secondary"
                      label={
                        enableChangeTranslation
                          ? 'Edit Translation'
                          : 'Disable Translation'
                      }
                    />
                  </div>
                  <div key={index}>
                    {isAppOrEmail && (
                      <Form.Item label="Title" className={styles.inputField}>
                        <Input
                          placeholder="Title of the Notification"
                          value={item.title}
                          disabled={enableChangeTranslation}
                          onChange={e =>
                            handleChangeTranslatedValues(
                              index,
                              e.target.value,
                              'title',
                            )
                          }
                        />
                      </Form.Item>
                    )}
                    {!!item.header && (
                      <Form.Item
                        label="Email Header"
                        className={styles.inputField}>
                        <Input
                          placeholder="Email Header"
                          disabled={enableChangeTranslation}
                          value={item.header}
                          onChange={e =>
                            handleChangeTranslatedValues(
                              index,
                              e.target.value,
                              'header',
                            )
                          }
                        />
                      </Form.Item>
                    )}
                    {activeTab === 'EMAIL' && (
                      <div className={styles['quill-container']}>
                        <span>Email Body</span>
                        <ReactQuill
                          value={item.message}
                          modules={NewNotification.modules}
                          formats={NewNotification.format}
                          className={styles.quill}
                          onChange={e =>
                            handleChangeTranslatedValues(index, e, 'message')
                          }
                          readOnly={enableChangeTranslation}
                          placeholder="Message"
                        />
                      </div>
                    )}
                    {activeTab === 'APP' && (
                      <Form.Item label="Message" className={styles.inputField}>
                        <TextArea
                          value={item.message}
                          rows={6}
                          placeholder="Type Message"
                          disabled={enableChangeTranslation}
                          onChange={e =>
                            handleChangeTranslatedValues(
                              index,
                              e.target.value,
                              'message',
                            )
                          }
                        />
                      </Form.Item>
                    )}
                  </div>
                </TabPane>
              ))}
          </Tabs>

          {isAppOrEmail && (
            <Form.Item
              rules={[
                {
                  required: activeTab === 'APP',
                  message: 'Type is required',
                },
              ]}
              label="Type"
              className={styles.inputField}
              name="type">
              <Select
                value={type}
                placeholder="Select a type"
                onChange={e => setType(e)}
                name="type">
                {activeTab === 'APP'
                  ? filteredNotificationTypes.map(name => (
                      <Option key={name.label}>{name.value}</Option>
                    ))
                  : emailNotificationTypes.map(name => (
                      <Option key={name.label}>{name.value}</Option>
                    ))}
              </Select>
            </Form.Item>
          )}
          {activeTab === 'EMAIL' && (
            <div>
              <Form.Item name="addDisclosure" label="Add a disclosure?">
                <Switch
                  defaultChecked={addDisclosure}
                  checked={addDisclosure}
                  onChange={e => setAddDisclosure(e)}
                />
              </Form.Item>

              {addDisclosure && (
                <div>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Disclosure Type is required',
                      },
                    ]}
                    name="disclosureType"
                    label="Disclosure Type">
                    <Select
                      style={{width: 720}}
                      name="disclosureType"
                      value={disclosureType}
                      placeholder="Select"
                      onChange={e => onDisclosureType(e)}>
                      {disclosureTypeEnum.map(data => (
                        <Option key={data.value}>{data.label}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Disclosure is required',
                      },
                    ]}
                    name="disclosure"
                    label="Disclosure">
                    <Select
                      style={{width: 720}}
                      name="disclosure"
                      placeholder="Select">
                      {getAllEmailDisclosures?.getAllEmailDisclosures?.disclosures.map(
                        data => (
                          <Option key={data.id}>{data.title}</Option>
                        ),
                      )}
                    </Select>
                  </Form.Item>
                </div>
              )}
            </div>
          )}
          {/** ONLY display External Link for push notifications */}
          {type === 'EXTERNAL_LINK' && (
            <>
              <Form.Item
                rules={[
                  {
                    required: type === 'EXTERNAL_LINK',
                    message: 'External link is required',
                    type: 'url',
                  },
                ]}
                label="External Link URL"
                className={styles.inputField}
                name="externalLink">
                <Input placeholder="Please paste a valid External Link" />
              </Form.Item>
              <Form.Item
                label="Should this link open in the App?"
                className={styles.inputField}
                name="linkShouldOpenInTheApp">
                <Switch
                  value={linkShouldOpenInTheApp}
                  onChange={e => setLinkShouldOpenInTheApp(e)}
                />
              </Form.Item>
            </>
          )}
          {type === 'DIVIDENDS_ANNOUNCEMENT' && (
            <>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Asset type is required',
                  },
                ]}
                label="Asset type"
                className={styles.inputField}
                name="assetClass">
                <Select
                  value={type}
                  placeholder="Select an asset type"
                  name="assetClass">
                  {assetClassEnum.map(name => (
                    <Option key={name.label}>{name.value}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: type === 'DIVIDENDS_ANNOUNCEMENT',
                    message: 'Company is required',
                  },
                ]}
                label="Company"
                className={styles.inputField}
                name="company">
                <Select
                  value={type}
                  placeholder="Select a company"
                  name="company">
                  {data?.getAllCompanies?.map(({id, name, logoImgURL}) => (
                    <Option value={`${name};${id}`} key={id}>
                      <div className={styles['all-data']}>
                        <img src={logoImgURL} alt="" />
                        <span>{name}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          <div>
            <PushNotificationData
              type={type}
              data={data}
              form={form}
              handleReportMetaData={setReportMetaData}
              handleOpportunityMetaData={setOpportunityMetaData}
              handleCompanyMetaData={setCompanyMetaData}
              handleGroupMetaData={setGroupMetaData}
              handleBondMetaData={setBondsMetadata}
            />
          </div>
          {activeTab === 'EMAIL' && (
            <div>
              <div>
                <h1 className={styles['header-text']}>Sender Information</h1>
              </div>
              <div className={styles['audience-grid']}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: 'Sender Email required',
                    },
                  ]}
                  name="senderEmail"
                  label="Sender Email">
                  <Select
                    style={{width: 720}}
                    name="senderEmail"
                    placeholder="@">
                    {data?.getOfficialEmails.map(data => (
                      <Option key={data.email}>{data.email}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className={styles['audience-grid']}>
                <Form.Item name="replyEmail" label="Reply To Email">
                  <Select
                    style={{width: 720}}
                    name="replyEmail"
                    placeholder="@">
                    {data?.getOfficialEmails.map(data => (
                      <Option key={data.email}>{data.email}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          )}
          <div>
            <h1 className={styles['top-header-text']}>Filter audience by</h1>
          </div>
          <NotificationFilter
            audience={audience}
            setAudience={setAudience}
            users={users}
            setUsers={setUsers}
            allGroups={data?.getAllGroups?.groups}
            allCampaigns={data?.getAllCampaigns?.campaigns}
            allTopics={data?.getAllTopics}
            allCountries={getAllCountries?.getAllCountries}
            from={from}
            setFrom={setFrom}
            form={form}
            to={to}
            setTo={setTo}
          />
          <div>
            <h1 className={styles['header-text']}>Notification Schedule</h1>
          </div>
          <div className={styles['schedule-grid']}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Schedule date is required',
                },
              ]}
              name="date"
              label="Date">
              <DatePicker
                className={styles.date}
                name="Date"
                format={'YYYY-MM-DD HH:mm:ss'}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                value={date}
                onChange={e => setDate(moment(e).format('YYYY-MM-DD HH:mm:ss'))}
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
              />
            </Form.Item>
            <Form.Item name="frequency" label="Frequency">
              <Select
                value={frequency}
                onChange={e => setFrequency(e)}
                style={{width: 320}}
                name="frequency"
                placeholder="Repeated">
                <Option value="REPEATED">Repeated</Option>
                <Option value="ONE_TIME">One Time</Option>
              </Select>
            </Form.Item>
            {frequency === 'REPEATED' && (
              <Form.Item name="selectedDays" label="Selected Days">
                <Select
                  style={{width: 720}}
                  mode="multiple"
                  name="selectedDays"
                  placeholder="Monday, Tuesday, Sunday">
                  {notificationOptions.map(name => (
                    // eslint-disable-next-line react/jsx-key
                    <Option value={name.label}>{name.value}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </div>
          <Form.Item name="button">
            <SubmitButton
              disabled={disableSendButton}
              label="Send Notification"
            />
          </Form.Item>
        </Form>
      </div>
      <Modal
        open={openDeleteTranslationModal}
        onCancel={() => setOpenDeleteTranslationModal(false)}
        onOk={onDeleteTab}
        title="Delete Translation">
        Are you sure you want to delete this translation?
      </Modal>
    </div>
  );
};

NewNotification.modules = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],
    ['code-block'],
  ],
};

NewNotification.format = [
  'header',
  'font',
  'size',
  'strike',
  'bold',
  'italic',
  'underline',
  'blockquote',
  'list',
  'bullet',
  'link',
  'code-block',
];

export default NewNotification;
