import {DatePicker, Form, Input, Select, Spin} from 'antd';
import styles from './overview.module.scss';
import UploadImage from 'components/Startup/profile/companyImage';
import {isDev} from 'utils/constants';
import {useEffect, useState} from 'react';
import {fundGeographicAreas, fundMixAssetFund} from '../modules/constants';
import {getUserTimezone} from 'utils/helper';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {handleMutualFunds} from 'redux/store/assetClass';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import {createMutualFundsStepTwoRoute} from '../Bonds/routes';
import {GET_MUTUAL_FUND_BY_LANGUAGE} from 'graphql/queries/assetManagement';
import {useMutation, useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';
import {handleUpdateMutualFund} from '../actions';
import {UPDATE_MUTUAL_FUND} from 'graphql/mutations/assetManagement';

export const MutualFundDetails = () => {
  const [managerUrl, setManagerUrl] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [form] = Form.useForm();
  const {assetId, id, lang} = useParams();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updatedValues, setUpdatedValues] = useState({});

  const fieldValues = useSelector(state => state?.assetClass?.mutualFunds);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data,
    refetch,
    loading: loadingInstrument,
  } = useQuery(GET_MUTUAL_FUND_BY_LANGUAGE, {
    variables: {
      getFinancialInstrumentId: id,

      language: lang,
    },
    skip: !id,
    errorPolicy: 'all',
    onError: error => toast.error(error.message),
  });

  useEffect(() => {
    if (data?.getFinancialInstrument?.data) {
      const {fundManager, fundLastUpdatedDate, ...restOfValues} =
        data?.getFinancialInstrument?.data;
      form.setFieldsValue({
        ...restOfValues,
        fundLastUpdatedDate: fundLastUpdatedDate
          ? moment(fundLastUpdatedDate)
          : null,
        name: fundManager?.name,
        foundingDate: fundManager?.foundingDate
          ? moment(fundManager?.foundingDate)
          : null,
        website: fundManager?.website ?? null,
        icon: fundManager?.icon,
      });
      setManagerUrl(fundManager?.icon);
      setPreviewUrl(fundManager?.icon);
    }
  }, [data?.getFinancialInstrument?.data]);

  const [updateMutualFund, {loading: loadingUpdate}] = useMutation(
    UPDATE_MUTUAL_FUND,
    {
      errorPolicy: 'all',
      onError: error => toast.error(error.message),
    },
  );

  useEffect(() => {
    if (fieldValues?.fundObjective && !id) {
      const {fundLastUpdatedDate, fundManager, ...restOfValues} = fieldValues;

      form.setFieldsValue({
        fundLastUpdatedDate: fundLastUpdatedDate
          ? moment(fundLastUpdatedDate)
          : null,
        ...restOfValues,
        name: fundManager?.name,
        foundingDate: fundManager?.foundingDate
          ? moment(fundManager?.foundingDate)
          : null,
        website: fundManager?.website ?? null,
      });
      setManagerUrl(fundManager?.icon);
      setPreviewUrl(fundManager?.icon);
    }
  }, [fieldValues]);

  if (loadingInstrument) {
    return (
      <div className="query-loading">
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <h2>Fund Manager</h2>
      <Form
        form={form}
        onFinish={values => {
          const {name, foundingDate, website, ...restOfValues} = values;
          if (id) {
            setOpenUpdateModal(true);
            setUpdatedValues({
              fundManager: {name, foundingDate, website, icon: managerUrl},
              ...restOfValues,
            });
          } else {
            dispatch(
              handleMutualFunds({
                fundManager: {
                  name,
                  foundingDate,
                  website,
                  ...(managerUrl && {icon: managerUrl}),
                },
                ...restOfValues,
              }),
              navigate(
                createMutualFundsStepTwoRoute({
                  param: assetId,
                  name: 'investments',
                }),
              ),
            );
          }
        }}
        layout="vertical">
        <div className={styles.fundManager}>
          <UploadImage
            label="Upload Manager Photo"
            isACL
            previewUrl={previewUrl}
            folder="bonds"
            setPreviewUrl={setPreviewUrl}
            setImgUrl={setManagerUrl}
            bucket={
              isDev
                ? 'dabafinancialinstruments-nonprod'
                : 'dabafinancialinstruments-prod'
            }
          />
          <div className={styles.managerDetails}>
            <Form.Item
              rules={[{required: true, message: 'This is a required field'}]}
              className={styles.fundWidth}
              name="name"
              label="Full Name">
              <Input />
            </Form.Item>
            <Form.Item name="foundingDate" label="Founding Date">
              <DatePicker
                className={styles.dateWidth}
                renderExtraFooter={() => (
                  <div style={{textAlign: 'center'}}>
                    <p>Your timezone is: {getUserTimezone()}</p>
                  </div>
                )}
                showTime={{
                  defaultValue: moment('00:00', 'HH:mm:ss'),
                }}
                format={'YYYY-MM-DD HH:mm:ss'}
              />
            </Form.Item>
            <Form.Item
              rules={[{type: 'url', message: 'Input a valid link'}]}
              className={styles.fundWidth}
              name="website"
              label="Website">
              <Input />
            </Form.Item>
          </div>
        </div>
        <h2>Fund Details</h2>
        <Form.Item
          label="Fund Objective"
          className={styles.bigWidth}
          name="fundObjective">
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item
          label="Best Suited"
          className={styles.bigWidth}
          name="bestSuitedFor">
          <Input.TextArea rows={3} />
        </Form.Item>
        <div className={styles.flexed}>
          <Form.Item
            className={styles.smallWidth}
            label="Fund Approval Number"
            name="fundApprovalNumber">
            <Input />
          </Form.Item>
          <Form.Item
            name="fundGeographicArea"
            className={styles.smallWidth}
            label="Fund Geographic Area">
            <Select allowClear>
              {fundGeographicAreas.map(area => (
                <Select.Option key={area.value}>{area.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            label="Fund Compliance Information"
            name="fundComplianceInformation">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            label="Fund Volatility Information"
            name="fundVolatilityInformation">
            <Input />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a Required Field'}]}
            name="fundLastUpdatedDate"
            label="Fund Last Updated Date">
            <DatePicker
              className={styles.smallWidth}
              renderExtraFooter={() => (
                <div style={{textAlign: 'center'}}>
                  <p>Your timezone is: {getUserTimezone()}</p>
                </div>
              )}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
          <Form.Item
            name="fundAssetMixFund"
            className={styles.bigWidth}
            label="Asset Mixed Fund">
            <Select allowClear mode="multiple">
              {fundMixAssetFund.map(area => (
                <Select.Option key={area.value}>{area.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Fund Disclaimer"
            className={styles.bigWidth}
            name="fundDisclaimer">
            <Input.TextArea rows={5} />
          </Form.Item>
        </div>
        <SubmitButton label="Save & Proceed" />
      </Form>
      <ConfirmModal
        openModal={openUpdateModal}
        setOpenModal={() => setOpenUpdateModal(false)}
        loading={loadingUpdate}
        confirmText={'Are you sure you want to Update this Fields'}
        title="Update Mutual Fund"
        handleOperation={() =>
          handleUpdateMutualFund(
            updateMutualFund,
            id,
            lang,
            updatedValues,
            refetch,
            setOpenUpdateModal,
            true,
          )
        }
      />
    </div>
  );
};
