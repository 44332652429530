import {Upload as BannerImage} from 'antd';
import '../../../../../node_modules/video-react/dist/video-react.css';
import './player.css';
import styles from './upload.module.scss';
import videoImg from 'images/video.png';
import {Button} from '@dabafinance/react-components';
import {UploadDocumentToBucket} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const CompanyVideoUpload = ({
  bannerPreviewUrl,
  setBannerPreviewUrl,
  setBannerUrl,
}) => {
  const handleChange = async ({file}) => {
    const temp = file.originFileObj;
    const url = URL.createObjectURL(file.originFileObj);
    setBannerPreviewUrl(url);
    const image = await UploadDocumentToBucket(
      temp,
      () => {},
      `company-service-bucket`,
    );
    setBannerUrl(image);
  };

  const uploadButton = (
    <div className={styles['upload-container']}>
      <img src={videoImg} alt="video" />
      <div style={{marginTop: 4}}>Upload Company Banner</div>
    </div>
  );

  const handleVideoDelete = () => {
    setBannerUrl('');
    setBannerPreviewUrl('');
  };

  return (
    <div className={styles['video-container']}>
      <h1>Company Banner</h1>
      <div>
        {bannerPreviewUrl ? (
          <img src={bannerPreviewUrl} alt="company" className={styles.player} />
        ) : (
          <BannerImage
            showUploadList={false}
            onChange={handleChange}
            maxCount={1}
            listType="picture">
            {uploadButton}
          </BannerImage>
        )}
        {bannerPreviewUrl && (
          <div className={styles['upload-buttons']}>
            <BannerImage
              showUploadList={false}
              onChange={handleChange}
              maxCount={1}
              listType="picture"></BannerImage>
            <Button
              type="secondary"
              label="Delete"
              className={styles.delete}
              onClick={handleVideoDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyVideoUpload;
